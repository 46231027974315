import * as Action from '../Actions/ShortlistAction';
import API from '../../API';
import { message } from 'antd';

export const refreshshrotlistService = (user_id) => {
    return async dispatch => {
        let obj = { user_id: user_id }
        fetch(API.BASE_URL + API.REFRESH_SHORTLIST, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                dispatch(Action.loadshortlistAction(responsesJson.data));
            },
                (error) => {
                    console.error(error);
                    dispatch(message.error('This is an error message'));
                }
            )
    }
}

export const addshrotlistSerives = (act, user_id, target_id, target) => {
    return async dispatch => {
        let obj = {
            user_id: target.user_id,
            Name: target.Name,
            UserId: target.UserId,
            image1: target.image1,
        }
        let bodyData = {
            action: act,
            user_id: user_id,
            target_id: target_id
        }
        fetch(API.BASE_URL + API.SHORTLIST_ACTION_NEW, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(bodyData)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                dispatch(Action.addshrotlistAction(obj));
                dispatch(message.success('profile shortlisted'));
            },
                (error) => {
                    console.error(error);
                    dispatch(message.error('This is an error message'));
                }
            )
    }
}

export const removeshrotlistServices = (act, user_id, target_id, target) => {
    return async dispatch => {
        let obj = {
            user_id: target.user_id,
            Name: target.Name,
            UserId: target.UserId,
            image1: target.image1,
        }
        let bodyData = {
            action: act,
            user_id: user_id,
            target_id: target_id
        }
        fetch(API.BASE_URL + API.SHORTLIST_ACTION_NEW, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(bodyData)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                dispatch(Action.removeshrotlistAction(obj));
                dispatch(message.success('Profile Removed'));
            },
                (error) => {
                    console.error(error);
                    dispatch(message.error('This is an error message'));
                }
            )
    }
}

export const shortlistrefreshsucessServices = () => {
    return async dispatch => {
        dispatch(Action.shortlistrefreshsucessAction());
    }
}

