

import React, { Component } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { Select, message, Table, Modal, Icon,Input,Button, Form,Popover } from 'antd';
import API from '../API';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
const { Option } = Select;
const { TextArea } = Input;
class PhotoDelete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],StatusRply:null
        };
    }  
    state = {
       
        loading: false,
        visible: false
      };
  
      handleCancelLog = () => {
        this.setState({ visibleLog: false });
      };
      handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
  
     getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });

      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      showModalStatus = (record) => {
       
        this.setState({
           
          visibleLog: true,
          UserId: record.id1,
         
           

        });
      };
    
    send_Status = (data) => {
      this.setState({ loading: true });
      let obj = {  id: this.state.UserId, Rply: this.state.StatusRply };
      fetch(API.BASE_URL + API.SetPhotoDeleteRequest, {
          method: 'POST',
          headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
      }).then((response) => response.json())
          .then((responsesJson) => {
              if (responsesJson.status === 'success') {
                  message.success('Send Successfully');
                  this.componentDidMount();
                  this.setState({ loading: false, form: false ,visibleLog: false});
              }
              else {
                  message.error(responsesJson.status);
                  this.setState({ loading: false, form: false });
              }
          })
          .catch((error) => {
              message.error('Error. Try again !');
              this.setState({ loading: false, form: false });
          });
  }
    componentDidMount = () => {
        this.setState({ loading: true });
        fetch(API.BASE_URL + API.GetPhotoDeleteRequest)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    data: res
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
    }

    render() {
        const { visible, loading } = this.state;
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        const columns = [
            { title: 'No', dataIndex: 'id1', key: 'id1' },
            { title: 'Date', dataIndex: 'SendDate', key: 'SendDate' , ...this.getColumnSearchProps('Date')},
            { title: 'PID', dataIndex: 'id', key: 'id' },
            {
                title: 'Name', dataIndex: 'Name', key: 'Name',
                onFilter: (value, record) => record.Name.indexOf(value) === 0,
                sorter: (a, b) => a.Name.length - b.Name.length,
                sortDirections: ['descend']
            },
            { title: 'Phone', dataIndex: 'MainMob', key: 'MainMob' },
            { title: 'Reason', dataIndex: 'Reason', key: 'Reason' },
            { title: 'Suggetion', dataIndex: 'Sugg', key: 'Sugg' },
            { title: 'Reply', dataIndex: 'Rply', key: 'Rply' },
            { title: 'Reply Date', dataIndex: 'RplyDate', key: 'RplyDate' },
            { title: 'Status', dataIndex: 'Status', key: 'Status' },
            
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (value, record) =>
                    <div>
                       

                       
                       <Link to={`/AdminApp/AdminUserprofiles/${record.id}`} target="_blank" style={{ textDecoration: 'none' }}>
                                    <Button  ghost size="small"   type="primary">View</Button></Link>
        {record.Status === 'R'
                            ?  <Popover content={<p style={{  fontSize: 14 }}>{record.Comment}</p>} title="Status" >
                            <Button ghost size="small" style={{ marginRight: 10 }} type="primary" >Read</Button>
                       </Popover>
                          
                            :
                            <Button ghost size="small" style={{ marginRight: 10 }} type="danger" onClick={() => this.showModalStatus(record) }>Unread</Button>
                        }

                    

                    </div>

            },
        ];
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Photo Delete Request</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                        <Table columns={columns} dataSource={this.state.data} onChange={onChange} pagination={{ pageSize: 25 }} />

                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
                

        <Modal
          visible={this.state.visibleLog}
          title="Status Book"
          onOk={this.handleOk}
          onCancel={this.handleCancelLog}
          footer={[
            <Button key="back" onClick={this.handleCancelLog}>
              Close
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={() => this.send_Status()}>
              Submit
            </Button>,
          ]}
        >
           <TextArea rows={2} placeholder="Type Reply" name="StatusRply" value={this.state.StatusRply}  style={{marginBottom:10}} onChange={this.handleChange}/>  
     
      
        </Modal>
            </Container>
        )
    }
}
export default PhotoDelete;