import React, { Component } from "react";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { makeStyles } from '@material-ui/core/styles';
import 'react-image-crop/dist/ReactCrop.css';

import 'antd/dist/antd.css';
import { Modal, message,Form,Select ,Input,Icon} from 'antd';
import API from '../API';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import { Row, Col} from 'reactstrap';
const { TextArea } = Input;
const { Option } = Select;
export default class ImageCropUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cropResult: null,
            file: null,
            visible: false,
            croppedImage: null,
            previewImage: null,
            previewVisible: false,msgReportReasondb:[],CommentPhotoDelete:'',msgReportreasonSave:''
        };
        this.cropImage = this.cropImage.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    reset = () => {
        this.props.form.resetFields()
      }
    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result, visible: true });
        };
        reader.readAsDataURL(files[0]);
    }
  
    onChangeText = (e) => {
        this.setState({CommentPhotoDelete: e.target.value})
      }
    ProReportReasonSelect = value => {
      
      
     
      this.setState({msgReportreasonSave:`${value}`})

      console.log(this.state.msgReportreasonSave)
      }

      SendProfileReport = () => {
       
        if (this.state.msgReportreasonSave != null && this.state.msgReportreasonSave.trim() != '') {
     
        let obj = {
         
          Reason: this.state.msgReportreasonSave,
          Message : this.state.CommentPhotoDelete,
         
          user_id: this.props.user_id
         
        };
        fetch(API.BASE_URL + API.PhotoDeleteRequest, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({ isLoaded: true });
              if(result.status === 'success'){
           
               
                this.setState({ ModalDeleteReq:false,msgReportreasonSave:null,CommentPhotoDelete:null});
                message.success('Request sent successfully, Admin will take necessary action',10);
               
              }
            
              else{
                message.error('Failed');
                
              }
            },
            (error) => {
               
                message.error('Error');
              this.setState({isLoaded: true});
            }
          )
        }
          else{
            message.error('Please select report reason.');
         
          }
    
      }
    cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
            return;
        }
        
        let dataurl = this.cropper.getCroppedCanvas().toDataURL()
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) { u8arr[n] = bstr.charCodeAt(n); }
        let croppedImage = new File([u8arr], 'cropped.jpg', { type: mime });
        this.setState({
            croppedImage: croppedImage,
            cropResult: dataurl
        });

        this.upload({ croppedImage })
    }

    upload = ({ croppedImage }) => {
      
       // const hide = message.loading('Image Uploading..');
        // Dismiss manually and asynchronously
        this.setState({ visible: false ,ModalUploading:true});
        const formData = new FormData()
        formData.append('user_id', this.props.user_id);
        formData.append('image_id', this.props.image_id);
        formData.append('file', croppedImage);
      
        fetch(API.BASE_URL + API.UPLOAD_IMAGE, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(responsesJson => {
                if (responsesJson.status === 'success') {
                   // setTimeout(hide, 0);
                    this.setState({ ModalUploading:false});
                    this.props.handleChanges();
                }
                else {
                    this.setState({ ModalUploading:false,ModalError:true});
                    
                }
            })
            .catch((error) => {
                //setTimeout(hide, 0);
                this.setState({ ModalUploading:false,ModalError:true});
            });
    }
    ReadReportReason = () => {
        let obj = {
            Type: "ID",
            
        }
        fetch(API.BASE_URL + API.DropDownItem, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              //message.success('success');
              this.setState({
                isLoaded: false,
               
                msgReportReasondb:result.DbItem,
                ModalDeleteReq:true,
               
              },

               );

            },
            (error) => {
                this.refs.toast.show('Something went wrong!');
             
              this.setState({
                isLoaded: false,
                error
              });
            }
          )
    
    }
    WhatsAppMsg = () => {
   
      
              const phone = '916235445544'
              const msg = encodeURIComponent('Hello, upload my profile picture')
              const href = `https://wa.me/${phone}?text=${msg}`
              window.open(href, "_blank");
            }
    deleteImage = () => {
        const hide = message.loading('Image Removing..');
        let obj = {
            user_id: this.props.user_id,
            image_id: this.props.image_id,
            image: this.props.image
        }
        fetch(API.BASE_URL + API.DELETE_IMAGE, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    setTimeout(hide, 0);
                    this.props.handleChanges();
                }
                else if (responsesJson.status === 'Lock'){
                    setTimeout(hide, 0);
                    this.ReadReportReason();
                 
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    render() {
        
        return (
            <div>
                {this.props.image ?
                    <Card inverse style={{ margin: 10 }}>
                        <CardActionArea>
                            <CardMedia
                                style={{ height: 250 }}
                                image={this.props.image}
                                title="Contemplative Reptile"
                            />
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" onClick={() => { this.setState({ previewImage: this.state.image2, previewVisible: true }) }}>View</Button>
                            <Button size="small" color="primary" onClick={() => { this.deleteImage() }}>Remove</Button>
                        </CardActions>
                    </Card> :
                    <Card style={{ height: 292, margin: 10, textAlign: 'center' }}>
                        <CardActionArea>


                        </CardActionArea>
                        <div className="upload-btn-wrapper">
                            <button className="btns">
                                <img src={require('../images/pictures.png')} style={{ height: 100, width: 100, marginTop: 80 }} alt="logo" /><br />
                                Upload My Photo
                                    </button>
                            <input type="file" onChange={this.onChange} onClick={e => (e.target.value = null)} />
                        </div>
                    </Card>
                }
                <Modal

                    title="Modal"
                    visible={this.state.visible}
                    onOk={this.cropImage}
                    onCancel={() => this.setState({
                        src: null,
                        visible: false,
                    })}
                    okText="Ok"
                    cancelText="Cancel"
                >
                    <Cropper
                        style={{ height: 520 }}
                        aspectRatio={1 / 1}
                        ref={cropper => { this.cropper = cropper }}
                        guides={false}
                        src={this.state.src}
                        viewMode={1}
                        dragMode="move"
                        cropBoxMovable={false}
                    />
                </Modal>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.setState({ previewVisible: false })}>
                    <img alt="example" style={{ width: '100%' }} src={this.props.image} />
                </Modal>

                <Modal 
                closable={false} 
                        footer={false}
                          width={330}
                          visible={this.state.ModalUploading}
                      >
                           <Form layout="inline" style={{marginLeft:20,marginTop:20}}>
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Image Uploading....</h6><p>Please wait....</p></Form.Item>
                    </Form>
                     
              </Modal>

              <Modal  title="Error"
                           onOk={() => this.setState({ ModalError: false })}
                          onCancel={() => this.setState({ ModalError: false })}
                          width={400}
                          visible={this.state.ModalError}
                          footer={[
                            <Button key="back" onClick={() => this.setState({ ModalError: false })}>
                              Close
                            </Button>,
                            <Button key="submit" type="primary"   onClick={() =>  {this.WhatsAppMsg()}}>
                              Send Photo
                            </Button>,
                          ]}
                      >
            <p style={{textAlign:'justify',fontSize:13}}>The file size is large and difficult to upload. If you have any difficulty to upload photo please send your picture to admin through whatsapp. We will upload it. </p>
            <p style={{textAlign:'justify',fontSize:12}}>ഫയൽ വലുപ്പം വലുതും അപ്‌ലോഡുചെയ്യാൻ പ്രയാസവുമാണ്. ഫോട്ടോ അപ്‌ലോഡുചെയ്യാൻ നിങ്ങൾക്ക് എന്തെങ്കിലും പ്രയാസമുണ്ടെങ്കിൽ, വാട്ട്‌സ്ആപ്പ് വഴി നിങ്ങളുടെ ചിത്രം അഡ്മിന് അയയ്‌ക്കുക. ഞങ്ങൾ അത് അപ്‌ലോഡ് ചെയ്യും.</p>
            <p style={{textAlign:'justify',fontSize:14}}>Whatsapp Number 6235445544</p>
             
              </Modal>
           
                <Modal
                style={{marginTop:75}}
                    centered
                    visible={this.state.ModalDeleteReq}
                    footer={false}
                    closable={false}
                    width={800}
                >
                    <div>
                    <h5 style={{margin:10,marginTop:5}}>Report this profile to admin</h5>
                    <h6 style={{marginTop:15,marginBottom:1,margin:10}} >If someone is calling and disturbing you through profile calling, you can use the new feature contact view lock. If you use it, only the people whom you have partner filtered will see your contact.</h6>
                  
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} >
                    <Form.Item label="Profile Report Reason" style={{marginBottom:5}}>
          
                    <Select  placeholder="Select Profile Report Reason"
                    onChange={this.ProReportReasonSelect} >
     {this.state.msgReportReasondb.map((item) => (
                                                        <Option value={item.Name} key={item.Name}>{item.Name}</Option>
                                                    ))}
    </Select>
   
        </Form.Item>
      
        <Form.Item label="Any comments">
        
            <TextArea 
                                                name="CommentProfileReport"
                                                maxLength='500' 
                                                value={this.state.CommentPhotoDelete}
                                                onChange={this.onChangeText}
                                                placeholder="Any comments about this profile"
                                              
                                                autosize={{ minRows: 4, maxRows: 6 }} />
        
        </Form.Item>
     
    
      </Form>


                 
                       
                        <Row><Col>
                        <button   onClick={() =>  {this.SendProfileReport()}} 
                   className="DetailPageSMS" > <Icon type="flag" style={{ fontSize: 19 ,verticalAlign:4,marginRight: 4}}/><span  style={{ verticalAlign:2}}>Submit Report</span></button> 
                </Col><Col>
                <button  onClick={() => { this.setState({ ModalDeleteReq: false }) }}
                   className="DetailPageSMS" > <Icon type="close" style={{ fontSize: 19 ,verticalAlign:4,marginRight: 4}}/><span  style={{ verticalAlign:2}}>Close</span></button> 
                            
                            </Col>
                            </Row>

                           
                           
                    </div>
                </Modal>
            </div>
        );
    }
}
