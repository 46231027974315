

import React, { Component } from 'react';
import { Card, CardBody, Container,  Nav, NavItem, NavLink,Table} from 'reactstrap';
import { Button, Modal, Form, Input, Icon, Select, message } from 'antd';
import API from '../API';
import classnames from 'classnames';
const { TextArea } = Input;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
class EditBasicDetails extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            loading: true,
            error: false,
            activeTab: 'W',
            data: [],
            editID: null,
            Name: null,
            CTempStatus: null,
            form: false,
            FomTitle: 'Add New DropDown',
            Add: false,
            Edit: false,
            items: [
                { id: 1, Name: 'Requested', BDTempStatus: 'W' },
                { id: 2, Name: 'Accepted', BDTempStatus: 'A' },
                { id: 3, Name: 'Rejected', BDTempStatus: 'R' },
       
            ]
        };
    }

    componentDidMount = () => {
        message.config({ top: 100 });
        fetch(API.BASE_URL + API.get_EditRequest, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data:responsesJson, loading: false })
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.error();
            });
    }

    set_defualt = () => {
        this.setState({
            editID: null,
            Name: null,
            BDTempStatus: null,
            form: false,
            FomTitle: 'Add New DropDown',
            Add: false,
            Edit: false
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    ProfilePercentage = () => {
        let obj = { user_id: this.state.user_id }
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
       alert("Error");
          });
      }



    Set_Edit = (item) => {
        console.log(item);
        this.setState({
            FomTitle: 'Edit Profile',
            form: true,
            Edit: true,
            Add: false,
            editID: item.id,
     
            user_id: item.user_id,Name1:item.Name1,Name:item.Name, DOB:item.DOB,DOB1:item.DOB1,MainMob:item.MainMob,MainMob1:item.MainMob1, MainEmail:item.MainEmail,MainEmail1:item.MainEmail1
        })
    }

    
    set_Accept = () => {
      
        this.setState({ loading: true });
        let obj = { Name: this.state.Name1,DOB: this.state.DOB1,MainMob: this.state.MainMob1,MainEmail: this.state.MainEmail1,user_id:this.state.user_id, id:this.state.editID};
        fetch(API.BASE_URL + API.set_Accept, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Accepted Successfully');
                    this.ProfilePercentage();
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }

    set_Reject = () => {
      
        this.setState({ loading: true });
        let obj = {  id:this.state.editID };
        fetch(API.BASE_URL + API.set_Reject, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Rejected Successfully');
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }




    delete_item = () => {
        this.setState({ loading: true });
        let obj = { id:this.state.editID };
        fetch(API.BASE_URL + API.delete_Request, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Deleted Successfully');
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }



    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleCancel = () => {
        this.setState({ form: false });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
            <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Edit Basic Details Request</p>
            <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                <CardBody>
                    <div>
                      
                        <Nav tabs>
                            {this.state.items.map((item) => (
                                <NavItem key={item.id}>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === item.BDTempStatus })}
                                        onClick={() => { this.toggle(item.BDTempStatus); }}>
                                        <span style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Name}</span>
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>

                        <Table>
                            <thead>
                                <tr>
                                <th>Action</th>
                                    <th>ID</th>
                                    
                                    <th>User ID</th>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Name Edit</th>
                                    <th>DOB</th>
                                    <th>DOB Edit</th>
                                    <th>Mob</th>
                                    <th>Mob Edit</th>
                                    <th>Email</th>
                                    <th>Email Edit</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data && this.state.data.filter(book => book.BDTempStatus === this.state.activeTab).map((item) => (
                                    <tr key={item.id}>
                                         <td style={{ fontWeight: 'bold', fontSize: 13 }}>
                                            <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.Set_Edit(item) }}>Edit</Button>
                                           
                                            </td>
                                        <th scope="row" style={{ fontWeight: 'bold', fontSize: 13 }}>{item.id}</th>
                                      
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.user_id}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.date}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Name}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Name1}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.DOB}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.DOB1}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.MainMob}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.MainMob1}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.MainEmail}</td>
                                        <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.MainEmail1}</td>
                                      
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
               
                <Modal
                    centered
                    title={this.state.FomTitle}
                    visible={this.state.form}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={1000}
                >
 <Form
      {...layout}
      name="basic"
   
    >

<Form.Item label="User ID">  
       <Input.Group compact>
                           
                       
                            <Input style={{ width: '50%' }}
                                disabled
                                placeholder="userid"
                                name="userid"
                                value={this.state.user_id}
                             
                            />

</Input.Group>
                        </Form.Item>
       <Form.Item label="Name">  
       <Input.Group compact>
                            <Input style={{ width: '50%' }} 
                              disabled
                                placeholder="Name"
                                name="Name"
                                value={this.state.Name}
                         
                            />
                          
                       
                            <Input style={{ width: '50%' }}
                              
                                placeholder="Name1"
                                name="Name1"
                                value={this.state.Name1}
                                onChange={this.handleChange}
                            />

</Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="DOB" style={{marginTop:-40}}>  
                        <Input.Group compact>
                           
            <Input style={{ width: '50%' }}
                             disabled
                                placeholder="DOB"
                                name="DOB"
                                value={this.state.DOB}
                               
                            />
       
           <Input placeholder="DOB" style={{ width: '50%' }}
                                name="DOB1"
                                value={this.state.DOB1}
                                onChange={this.handleChange}
                            />
      
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="MainMob" style={{marginTop:-40}}>  
                        <Input.Group compact>
            <Input disabled style={{ width: '50%' }}
                                placeholder="MainMob"
                                name="MainMob"
                                value={this.state.MainMob}
                                
                            />
       
           <Input style={{ width: '50%' }}
                                placeholder="MainMob1"
                                name="MainMob1"
                                value={this.state.MainMob1}
                                onChange={this.handleChange}
                            />
        
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="MainEmail" style={{marginTop:-40}}>  
                        <Input.Group compact>
            <Input disabled style={{ width: '50%' }}
                                placeholder="MainEmail"
                                name="MainEmail"
                                value={this.state.MainEmail}
                               
                            />
      
        
           <Input style={{ width: '50%' }}
                                placeholder="MainEmail1"
                                name="MainEmail1"
                                value={this.state.MainEmail1}
                                onChange={this.handleChange}
                            />
         
                             </Input.Group>
                        </Form.Item>
                       
                       
      <Form.Item {...tailLayout}>
      <Button type="defult" onClick={() => { this.setState({ form: false }) }} style={{ marginRight: 10 }}>Cancel</Button>
      <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.set_Accept() }}>Accept</Button>
                                                <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.set_Reject() }}>Reject</Button>
                                                <Button ghost size="small" type="danger" onClick={() => { this.delete_item() }}>Remove</Button>
      </Form.Item>
    </Form>


                   
                </Modal>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </Container>
        )
    }
}
export default Form.create()(EditBasicDetails);