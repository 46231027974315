

import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup } from 'reactstrap';
import { Form, Input, Radio, message, Button, Select, Tag } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class PartnerCard extends Component {
    reset = () => {
        this.props.form.resetFields()
      }
    constructor(props) {
        super(props);
        this.state = {
            //step3
         
            msgReportReasondb: [],
ProfileDeleteComment:null
            //end step3
        };
      
    }

    componentDidMount() {
        this.ReadReportReason();
     
    }
    ReadReportReason = () => {
        let obj = {
            Type: "PR",
            
        }
        fetch(API.BASE_URL + API.DropDownItem, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              //message.success('success');
              this.setState({
             
                msgReportReasondb:result.DbItem,
             
              },

               );

            },
            (error) => {
               
              alert(error);
              this.setState({
                isLoaded: false,
                error
              });
            }
          )
    
  
    }
  
    handleSelectChange = value => {
        console.log(value);
        this.props.form.setFieldsValue({
         
          noteRply: `${value === value ? value : ''}`
        });
     
      this.setState({noteRply:`${value}`})
      }

   
    onChangeText = (e) => {
        this.setState({ProfileDeleteComment: e.target.value})
      }
 
      SendReportMsg = () => {
       
        if (this.state.noteRply != null && this.state.noteRply.trim() != '') {
     
        let obj = {
         
          choose: this.state.noteRply,
          user_id: this.props.User.user_id ,
          Sugg : this.state.ProfileDeleteComment
        };
        fetch(API.BASE_URL + API.DeleteProfile, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({ isLoaded: true });
              if(result.status === 'success'){
           
               
                this.setState({noteRply:null,ProfileDeleteComment:null});
                message.success('Request sent successfully, Admin will contact you',10);
                this.reset();
              }
            
              else{
                message.error('Failed');
                
              }
            },
            (error) => {
               
                message.error('Error');
              this.setState({isLoaded: true});
            }
          )
        }
          else{
            message.error('Please select report reason.');
         
          }

      }
 

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            <Card style={{ borderRadius: 10 }} >
                <CardBody>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.Requestprofiledelete}</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Form {...formItemLayout}>
                    <Form.Item label="Profile delete request reason" style={{marginBottom:5}}>
          {getFieldDecorator('Template', {
            rules: [{ required: false, message: 'Please select your Photo Change Request Reason!' }],
          })(
                    <Select  placeholder="Select a profile delete request reason"
                    onChange={this.handleSelectChange} >
     {this.state.msgReportReasondb.map((item) => (
                                                        <Option value={item.Name} key={item.Name}>{item.Name}</Option>
                                                    ))}
    </Select>
    ,
          )}
        </Form.Item>
      
        <Form.Item label="Any comments">
        
            <TextArea 
                                                name="noteRply"
                                                maxLength='500' 
                                                value={this.state.ProfileDeleteComment}
                                                onChange={this.onChangeText}
                                                placeholder="Please enter your suggestions/complaints/feedback/reason"
                                              
                                                autosize={{ minRows: 4, maxRows: 6 }} />
        
        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                                <Button type="primary" onClick={() => this.SendReportMsg()} style={{fontWeight:'bolder',backgroundColor:'#0070ba',borderColor:'#0070ba'}}>
                                              <span style={{marginLeft:15,marginRight:15}}>Submit Request</span>
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(PartnerCard));