import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Form, Icon, Input, Button, Card, Modal,Select} from 'antd';
import FooterS from '../../components/Footer';
import { connect } from 'react-redux';
import API from '../../API';
const { Option } = Select;
class forgot_password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Phone: null,
      userID: null,
      success:false
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
    console.log(event.target.name);
  }
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let obj = {
          'Phone': this.state.Phone,
          'UserID': this.state.UserID
        }
        fetch(API.BASE_URL + API.FORGOT_PASSWORD, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if(responseJson.status === 'success'){
              this.props.form.resetFields()
              this.setState({ loading: false,success:true})
            }
            else {
              this.error();
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            this.error();
            console.error(error);
          });
      }
    });
  }

  error = () => {
    Modal.error({
      title: 'Request Faild',
      content: 'Check your user id and Mobile Number...',
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '+91',
  })(
      <Select style={{ width: 70 }}>
          <Option value="+91">+91</Option>
      </Select>,
  );
    return (
      <div >
        <Container className="usercontainer">
          <Row>
            <Col sm="12" md={{ size: 4, offset: 4 }}>
              <Card style={{ marginTop: 40, borderRadius: 10 }} className="itemscentr">
              <Icon type="lock" style={{fontSize:100,marginBottom:10}}/>
                <h5 style={{ color: '#0070ba'}}>Forgot Your password ?</h5>
                <p>Enter your User id and phone number and we'll send you a SMS to get back into your phone number.</p>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <Form.Item>
                    {getFieldDecorator('Phone', {
                      initialValue: this.state.Phone,
                      setFieldsValue: this.state.Phone,
                      onChange: this.handleChange,
                      rules: [{ required: true, message: 'Please input your UserID!' }],
                    })(
                      <Input
                      addonBefore={prefixSelector}
                        name="Phone"
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Phone Number"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('UserID', {
                      initialValue: this.state.UserID,
                      setFieldsValue: this.state.UserID,
                      onChange: this.handleChange,
                      rules: [{ required: true, message: 'Please input your UserID!' }],
                    })(
                      <Input
                        name="UserID"
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="UserID"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" block style={{ backgroundColor: '#0070ba', borderColor: '#0070ba', fontWeight: 'bold' }} size={'large'}>
                      Submit
              </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
        <FooterS/>  
        <Modal
                    centered
                    visible={this.state.success}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                        <img src={require('../../images/success1.gif')} style={{ height: 250, width: 350 }} alt="success" />
                        <h5>Send successfully</h5>
                        <p>Please check your sms.</p>
                        <Button type="primary" onClick={() => { this.setState({ success: false }) }}>
                            Done
                            </Button>
                    </div>
                </Modal>
        <Modal
          centered
          visible={this.state.loading}
          footer={false}
          closable={false}
          width={300}
        >
          <Form layout="inline">
            <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
            <Form.Item><h6>Please wait</h6></Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    User: state.User,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    Login: (data) => {
      dispatch({ type: 'LOGIN', payload: data })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(forgot_password));