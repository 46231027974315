import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, Card, Steps, Modal, message,Tag} from 'antd';
import { Link } from 'react-router-dom';
import API from '../API';
import ImageUpload from '../components/imageUpload';
import { connect } from 'react-redux';
const { Step } = Steps;
const { TextArea } = Input;
const ButtonGroup = Button.Group;
const { Option } = Select;

function getAge(AgeYear) {
    var today = new Date();
    var DOB = today.getFullYear() - AgeYear;
    DOB = DOB + '-01-01';
    return DOB;

}

function datetoage(DOB) {
    var birthdate = new Date(DOB);
    var cur = new Date();
    var diff = cur - birthdate;
    var age = Math.floor(diff / 31536000000);
    return age;
}

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            current: 0,
            status: 'process',
            value: '',
            PQualificationS: [],
            PEmploymentS: [],
            PQualification: null,
            PEmployment: null



        };
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount = () => {
        //update user
        if (this.props.match.params.data !== 'null') {
            this.setState({ loading: true, add: false, update: true, user_id: this.props.match.params.data })
            let obj = { "user_id": this.props.match.params.data };
            fetch(API.BASE_URL + API.GET_UPDATE_USER, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify(obj)
            }).then((response) => response.json())
                .then((res) => {
                    this.setState({
                        loading: false,
                        //step1
                        FullName: res.FullName,
                        Gender: res.Gender,
                        HouseName: res.HouseName,
                        Place: res.Place,
                        parish: res.parish,
                        Diocese: res.Diocese,
                        Age: datetoage(res.Age),
                        Education_Category: res.Education_Category,
                        Education: res.Education,
                        Work_Category: res.Work_Category,
                        Working: res.Working,
                        Working_place: res.Working_place,
                        Height: res.Height,
                        CAddress: res.CAddress,
                        Contactphone: res.Contactphone,
                        Creatorphone: res.Creatorphone,
                        creatoremail: res.creatoremail,
                        PQualificationS: res.PQualification,
                        PEmploymentS: res.PEmployment,
                        RelCaste: res.RelCaste,
                        MaritialS: res.MaritialS,
                        PhysicalS: res.PhysicalS,
                        UserID: res.UserID,
                        testss: res.PQualification,
                        PaidAmount: res.PaidAmount,
                        PaymentS: res.PaymentS
                        //end step1
                    })
                })
                .catch((error) => {
                    message.error('Error. Try again !');
                });
        }
        //add new user
        else {
            this.setState({
                add: true,
                update: false,
                loading: false,
                user_id: null,

                FullName: null, Gender: null, HouseName: null, Place: null, parish: null, Diocese: null, Age: null,
                Education_Category: null, Education: null, Work_Category: null, Working: null, Working_place: null,
                Height: null, CAddress: null, Contactphone: null, Creatorphone: null, creatoremail: null,
                RelCaste: null, MaritialS: null, PhysicalS: null, UserID: null, PaidAmount: 200, PaymentS:'paid'
            })
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }


    add = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'FullName': this.state.FullName,
                    'Gender': this.state.Gender,
                    'DOB': getAge(this.state.Age),
                    'RelCaste': this.state.RelCaste,
                    'Height': this.state.Height,
                    'MaritialS': this.state.MaritialS,
                    'PhysicalS': this.state.PhysicalS,
                    'Creatorphone': this.state.Creatorphone,
                    'creatoremail': this.state.creatoremail,
                    'UserID': this.state.UserID,
                    'HouseName': this.state.HouseName,
                    'Place': this.state.Place,
                    'parish': this.state.parish,
                    'Diocese': this.state.Diocese,
                    'CAddress': this.state.CAddress,
                    'Contactphone': this.state.Contactphone,
                    'Education_Category': this.state.Education_Category,
                    'Education': this.state.Education,
                    'Work_Category': this.state.Work_Category,
                    'Working': this.state.Working,
                    'Working_place': this.state.Working_place,
                    'PQualification': this.state.PQualification,
                    'PEmployment': this.state.PEmployment,
                    'PaidAmount': this.state.PaidAmount,
                    'PaymentS': this.state.PaymentS
                }
                fetch(API.BASE_URL + API.ADD_USERS, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }), body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            message.success(responseJson.message);
                            let stat = this.state.current + 1;
                            this.setState({
                                loading: false,
                                current: stat,
                                status: 'process',
                                user_id: responseJson.user_id
                            });
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error(responseJson.message);
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }

    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'user_id': this.props.match.params.data,
                    'FullName': this.state.FullName,
                    'Gender': this.state.Gender,
                    'DOB': getAge(this.state.Age),
                    'RelCaste': this.state.RelCaste,
                    'Height': this.state.Height,
                    'MaritialS': this.state.MaritialS,
                    'PhysicalS': this.state.PhysicalS,
                    'Creatorphone': this.state.Creatorphone,
                    'creatoremail': this.state.creatoremail,
                    'UserID': this.state.UserID,
                    'HouseName': this.state.HouseName,
                    'Place': this.state.Place,
                    'parish': this.state.parish,
                    'Diocese': this.state.Diocese,
                    'CAddress': this.state.CAddress,
                    'Contactphone': this.state.Contactphone,
                    'Education_Category': this.state.Education_Category,
                    'Education': this.state.Education,
                    'Work_Category': this.state.Work_Category,
                    'Working': this.state.Working,
                    'Working_place': this.state.Working_place,
                    'PQualification': this.state.PQualification,
                    'PEmployment': this.state.PEmployment,
                    'PaidAmount': this.state.PaidAmount,
                    'PaymentS': this.state.PaymentS
                }
                fetch(API.BASE_URL + API.UPDATE_USERS, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.componentDidMount();
                            this.setState({
                                loading: false,
                                PQualification: [],
                                PEmployment: []
                            });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }

    deleteq = (idD) => {
        this.setState({ loading: true });
        let obj = { id: idD };
        fetch(API.BASE_URL + API.DELETE_CHIP, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    this.componentDidMount();
                    message.success('Deleted Successfully');
                    this.setState({ loading: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false });
            });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '+91',
        })(
            <Select style={{ width: 70 }}>
                <Option value="+91">+91</Option>
            </Select>,
        );
        const height_Array = [];
        for (let i = 30; i < 250; i++) {
            height_Array.push({
                value: i,
            });
        }
        return (
            <div className="fixedbox1" style={{ fontSize: 10 }}>
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Users</p>
                <Container fluid={true} style={{ marginBottom: 40 }}>

                    <Card style={{ borderRadius: 10 }}>
                        <Steps current={this.state.current} direction="horizontal" status={this.state.status}>
                            <Step />
                            <Step />
                        </Steps>
                        <hr />

                        <Form {...formItemLayout}>
                            {this.state.current === 0 &&
                                <Row>
                                    <Col sm="6" xs="12">
                                        <Form.Item label="Full Name">
                                            {getFieldDecorator('FullName', {
                                                initialValue: this.state.FullName,
                                                setFieldsValue: this.state.FullName,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please input your Full Name!' }]
                                            })(
                                                <Input
                                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="Full Name"
                                                    name="FullName"
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Gender" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Gender', {
                                                initialValue: this.state.Gender,
                                                setFieldsValue: this.state.Gender,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please select Gender!' }],
                                            })(
                                                <Radio.Group name="Gender">
                                                    <Radio.Button value="Male">Male</Radio.Button>
                                                    <Radio.Button value="Female">Female</Radio.Button>
                                                </Radio.Group>,
                                            )}
                                        </Form.Item>

                                        <Form.Item label="House Name" style={{ marginTop: -20 }}>
                                            <Input
                                                name="HouseName"
                                                value={this.state.HouseName}
                                                onChange={this.handleChange}
                                                placeholder="House Name" />
                                        </Form.Item>

                                        <Form.Item label="Place" style={{ marginTop: -20 }}>
                                            <Input
                                                name="Place"
                                                value={this.state.Place}
                                                onChange={this.handleChange}
                                                placeholder="Place" />
                                        </Form.Item>

                                        <Form.Item label="Parish" style={{ marginTop: -20 }}>
                                            <Input
                                                name="parish"
                                                value={this.state.parish}
                                                onChange={this.handleChange}
                                                placeholder="parish" />
                                        </Form.Item>

                                        <Form.Item label="Diocese" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Diocese', {
                                                initialValue: this.state.Diocese,
                                                setFieldsValue: this.state.Diocese,
                                                rules: [{ required: false, message: 'Please select Diocese!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    name={"Diocese"}
                                                    placeholder="Diocese"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Diocese', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Diocese_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>


                                        <Form.Item label="Age" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Age', {
                                                initialValue: this.state.Age,
                                                setFieldsValue: this.state.Age,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please input age!' }]
                                            })(
                                                <Input
                                                    type="number"
                                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="Age"
                                                    name="Age"
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Education Category" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Education_Category', {
                                                initialValue: this.state.Education_Category,
                                                setFieldsValue: this.state.Education_Category,
                                                rules: [{ required: false, message: 'Please select Maritial Status!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    name={"Education_Category"}
                                                    placeholder="Education Category"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Education_Category', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Education_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Education in Detail" style={{ marginTop: -20 }}>
                                            <Input
                                                name="Education"
                                                value={this.state.Education}
                                                onChange={this.handleChange}
                                                prefix={<Icon type="schedule" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Education"
                                            />
                                        </Form.Item>


                                        <Form.Item label="Work Category" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Work_Category', {
                                                initialValue: this.state.Work_Category,
                                                setFieldsValue: this.state.Work_Category,
                                                rules: [{ required: false, message: 'Please select Work Category!' }],
                                            })(

                                                <Select
                                                    showSearch
                                                    name={"Work Category"}
                                                    placeholder="Work Category"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Work_Category', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Work_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Working Firm" style={{ marginTop: -20 }}>
                                            <Input
                                                name="Working"
                                                value={this.state.Working}
                                                onChange={this.handleChange}
                                                prefix={<Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Working Firm" />
                                        </Form.Item>

                                        <Form.Item label="Working place" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Working_place', {
                                                initialValue: this.state.Working_place,
                                                setFieldsValue: this.state.Working_place,
                                                rules: [{ required: false, message: 'Please select Religion/Caste!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    name={"Working_place"}
                                                    placeholder="Working place"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Working_place', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.WorkingPlace_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>


                                    </Col>
                                    <Col sm="6" xs="12">

                                        <Form.Item label="Height (cm)">
                                            {getFieldDecorator('Height', {
                                                initialValue: this.state.Height,
                                                setFieldsValue: this.state.Height,
                                                rules: [{ required: false, message: 'Please select Height!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    placeholder="Select Height"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Height', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {height_Array.map((item) => (
                                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>


                                        <Form.Item label="Communicational Address" style={{ marginTop: -20 }}>
                                            <TextArea
                                                name="CAddress"
                                                value={this.state.CAddress}
                                                onChange={this.handleChange}
                                                placeholder="Communicational Address"
                                                autosize={{ minRows: 2, maxRows: 6 }}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Phone Number" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Contactphone', {
                                                initialValue: this.state.Contactphone,
                                                setFieldsValue: this.state.Contactphone,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please input your phone number!' }],
                                            })(<Input
                                                name="Contactphone"
                                                type="number"
                                                placeholder="Phone Number"
                                                addonBefore={prefixSelector}
                                                style={{ width: '100%', margin: 0, padding: 0 }} />)}
                                        </Form.Item>

                                        <Form.Item label="Profile creator Mob No" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Creatorphone', {
                                                initialValue: this.state.Creatorphone,
                                                setFieldsValue: this.state.Creatorphone,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please input your phone number!' }],
                                            })(<Input
                                                addonBefore={prefixSelector}
                                                style={{ width: '100%', margin: 0, padding: 0 }}
                                                name="Creatorphone"
                                                placeholder="Phone Number"
                                                type='number' />)}
                                        </Form.Item>

                                        <Form.Item label="E-mail" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('creatoremail', {
                                                initialValue: this.state.creatoremail,
                                                setFieldsValue: this.state.creatoremail,
                                                onChange: this.handleChange,
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        required: false,
                                                        message: 'The input is not valid E-mail!',
                                                    },

                                                ],
                                            })(<Input name="creatoremail"
                                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Email" />)}
                                        </Form.Item>


                                        <Form.Item label="Partner's Qualification" style={{ marginTop: -20 }}>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Partner's Qualification"
                                                optionLabelProp="label"
                                                initialValue={this.state.PQualification}
                                                setFieldsValue={this.state.PQualification}
                                                onChange={(val) => { this.handleChange({ target: { name: 'PQualification', value: val } }) }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {this.props.dropdown.Education_array.map((item) => (
                                                    <Option value={item.id} label={item.Name} key={item.id}>{item.Name}</Option>
                                                ))}
                                            </Select>
                                            {this.state.update === true &&
                                                <div>
                                                    {this.state.PQualificationS.map((person, index) => (
                                                       <Tag
                                                       closable
                                                       onClose={() => { this.deleteq(person.id) }} 
                                                     >
                                                     {person.Name}
                                                     </Tag>
                                                    ))}
                                                </div>
                                            }

                                        </Form.Item>


                                        <Form.Item label="Partner's Employment" style={{ marginTop: -20 }}>
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Partner's Employment"
                                                optionLabelProp="label"
                                                initialValue={this.state.PEmployment}
                                                setFieldsValue={this.state.PEmployment}
                                                onChange={(val) => { this.handleChange({ target: { name: 'PEmployment', value: val } }) }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {this.props.dropdown.Work_array.map((item) => (
                                                    <Option value={item.id} label={item.Name} key={item.id}>{item.Name}</Option>
                                                ))}
                                            </Select>
                                            {this.state.update === true &&
                                                <div>
                                                    {this.state.PEmploymentS.map((person, index) => (
                                                      <Tag
                                                      closable
                                                      onClose={() => { this.deleteq(person.id) }} 
                                                    >
                                                    {person.Name}
                                                    </Tag>
                                                    ))}
                                                </div>
                                            }
                                        </Form.Item>
                                        


                                <Form.Item label="Religion/Caste" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('RelCaste', {
                                                initialValue: this.state.RelCaste,
                                                setFieldsValue: this.state.RelCaste,
                                                rules: [{ required: false, message: 'Please select Religion/Caste!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    name={"RelCaste"}
                                                    placeholder="Religion/Caste"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'RelCaste', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Religion_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Maritial Status" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('MaritialS', {
                                                initialValue: this.state.MaritialS,
                                                setFieldsValue: this.state.MaritialS,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please select Gender!' }],
                                            })(
                                                <Radio.Group name="MaritialS" >

                                                    <Radio.Button value="U">Unmarried</Radio.Button>
                                                    <Radio.Button value="W">Widow/Widower</Radio.Button>
                                                    <Radio.Button value="D">Divorcee</Radio.Button>
                                                </Radio.Group>,
                                            )}

                                        </Form.Item>


                                        <Form.Item label="Physical Status" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('PhysicalS', {
                                                initialValue: this.state.PhysicalS,
                                                setFieldsValue: this.state.PhysicalS,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please select Physical Status!' }],
                                            })(
                                                <Radio.Group name="PhysicalS">
                                                    <Radio.Button value="Normal">Normal</Radio.Button>
                                                    <Radio.Button value="With Disability">With Disability</Radio.Button>
                                                </Radio.Group>,
                                            )}
                                        </Form.Item>


                                        <Form.Item label="User Id" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('UserID', {
                                                initialValue: this.state.UserID,
                                                setFieldsValue: this.state.UserID,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please input your Full Name!' }]
                                            })(
                                                <Input
                                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="User ID"
                                                    name="UserID"
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Payment Status" style={{ marginTop: -20 }}>
                                            {getFieldDecorator('PaymentS', {
                                                initialValue: this.state.PaymentS,
                                                setFieldsValue: this.state.PaymentS,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please select Gender!' }],
                                            })(
                                                <Radio.Group name="PaymentS" >

                                                    <Radio.Button value="paid">paid</Radio.Button>
                                                    <Radio.Button value="unpaid">unpaid</Radio.Button>
                                                </Radio.Group>,
                                            )}

                                        </Form.Item>
                                        {this.state.PaymentS === 'paid'
                                            ?
                                            <Form.Item label="Paid Amount" style={{ marginTop: -20 }}>
                                                <Input
                                                    name="PaidAmount"
                                                    value={this.state.PaidAmount}
                                                    onChange={this.handleChange}
                                                    prefix={<Icon type="schedule" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="Paid Amount"
                                                    type="number"
                                                />
                                            </Form.Item> : null
                                        }


                                    </Col>
                                    <Col>
                                        {this.state.add === true &&
                                            <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                                                <ButtonGroup>
                                                    <Button type="primary" onClick={() => this.add()}>
                                                        add new user
                                                    </Button>
                                                </ButtonGroup>
                                            </Form.Item>
                                        }
                                    </Col>
                                </Row>
                            }
                            {this.state.user_id != null &&
                                <Row>
                                    <ImageUpload UserId={this.state.user_id} />
                                    <Col sm="12" xs="12">
                                        {this.state.add === true &&
                                            <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }} >
                                                <Link to='/AdminApp/AdminUsers' style={{ textDecoration: 'none' }}>
                                                    <Button type="primary">
                                                        Finish
                                            </Button>
                                                </Link>
                                            </Form.Item>
                                        }
                                        {this.state.update === true &&
                                            <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }} >
                                                <Button type="primary" onClick={() => this.update()}>
                                                    update
                                            </Button>
                                            </Form.Item>
                                        }
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </Card>
                </Container>

                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </div>
        )

    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(AddUser));