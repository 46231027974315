import React, { Component } from 'react';
import { Container} from 'reactstrap';
import { Card,Divider } from 'antd';
import FooterS from '../../components/Footer';

import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import './styles.css';
import API from '../../API';
import './Pricestyles.css';
class Packages extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            Plan: []
         
         
        };
   
    }
    componentDidMount = () => {
        
        this.getPlan();
       
    }
    getPlan = () => {
   
   
        fetch(API.BASE_URL + API.GetPlan, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
           
        })
            .then((response) => response.json())
            .then((responsesJson) => {
             
                let data = responsesJson;
               
                this.setState({ Plan: data })
               
               // console.log(this.state.data);
            })
            .catch((error) => {
              console.log(error);
              // this.refs.toast.show('Something went wrong!');
            });
    }

    render() {
        return (
           
            <div>
                 <div className="colorcontainer" style={{ height: 130 }}>
                <Container >
                        <h5 style={{ color: '#fff', paddingTop: 40 }}>{this.props.lang.PackagesHead}</h5>
                        <p style={{ color: '#fff', paddingBottom: 10 }}>{this.props.lang.Packagessub}</p>
                        </Container>
                    </div>
                    <Container >
                    <Card style={{ borderRadius: 10, marginTop: 10 ,borderWidth:0}}>
               <div>
                    {this.state.Plan.map((item, index) =>
                                <div class="snip1214" >
                                  
     {item.id==='1'?
  <div class="plan">
    <h3 class="plan-title" style={{fontSize:20}}>
    {item.Name}
    </h3>
    <div class="plan-cost"><span class="plan-price">₹ {item.Price}</span></div>
    <ul class="plan-features">
    <li><i class="ion-checkmark"> </i>Unlimited Profile View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Message</li>
    <li><i class="ion-checkmark"> </i>Unlimited Interest Send</li>
    <li><i class="ion-checkmark"> </i>Unlimited Photo View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Push Notifications</li>
    <li><i class="ion-checkmark"> </i>Provide Website/Mobile App</li>
    <li><i class="ion-checkmark"> </i>Malayalam/English Language Selection</li>
      <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
      <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
      <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
      <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
      <li><i class="ion-checkmark"> </i>No Hidden Charges</li>
      <li><i class="ion-checkmark"> </i>24x7 Call/Chat Support</li>
      <li><i class="ion-checkmark"> </i>Only for Women</li>
    </ul>
   
  </div>
 :
 null
 }
 {item.id==='4'?
  <div class="plan">
    <h3 class="plan-title">
    {item.Name}
    </h3>
    <div class="plan-cost"><span class="plan-price">₹{item.Price}</span><span class="plan-type">/ {item.Validity}</span></div>
    <ul class="plan-features">
    <li><i class="ion-checkmark"> </i>Unlimited Profile View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Message</li>
    <li><i class="ion-checkmark"> </i>Unlimited Interest Send</li>
    <li><i class="ion-checkmark"> </i>Unlimited Photo View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Push Notifications</li>
    <li><i class="ion-checkmark"> </i>Provide Website/Mobile App</li>
    <li><i class="ion-checkmark"> </i>Malayalam/English Language Selection</li>
    <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
      <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
      <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
      <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
      <li><i class="ion-checkmark"> </i>No Hidden Charges</li>
      <li><i class="ion-checkmark"> </i>24x7 Call/Chat Support</li>
      <li><i class="ion-checkmark"> </i>Only for Men</li>
    </ul>
    
  </div>
:
null
}
 {item.id==='3'?
  <div class="plan">
    <h3 class="plan-title">
    {item.Name}
    </h3>
    <div class="plan-cost"><span class="plan-price">₹{item.Price}</span><span class="plan-type">/ {item.Validity}</span></div>
    <ul class="plan-features">
    <li><i class="ion-checkmark"> </i>Unlimited Profile View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Message</li>
    <li><i class="ion-checkmark"> </i>Unlimited Interest Send</li>
    <li><i class="ion-checkmark"> </i>Unlimited Photo View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Push Notifications</li>
    <li><i class="ion-checkmark"> </i>Provide Website/Mobile App</li>
    <li><i class="ion-checkmark"> </i>Malayalam/English Language Selection</li>
    <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
      <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
      <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
      <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
      <li><i class="ion-checkmark"> </i>No Hidden Charges</li>
      <li><i class="ion-checkmark"> </i>24x7 Call/Chat Support</li>
      <li><i class="ion-checkmark"> </i>Men/Women</li>
    </ul>
   
  </div>
:
null
}
{item.id==='2'?
  <div class="plan featured">
    <h3 class="plan-title">
    {item.Name}
    </h3>
    <div class="plan-cost"><span class="plan-price">₹{item.Price}</span><span class="plan-type">/ {item.Validity}</span></div>
    <ul class="plan-features">
    <li><i class="ion-checkmark"> </i><b>Unlimited Days use</b></li>
    <li><i class="ion-checkmark"> </i>Special Premium care</li>
      <li><i class="ion-checkmark"> </i>Admin Profile Suggetions</li>
    <li><i class="ion-checkmark"> </i>Unlimited Profile View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Message</li>
    <li><i class="ion-checkmark"> </i>Unlimited Interest Send</li>
    <li><i class="ion-checkmark"> </i>Unlimited Photo View</li>
    <li><i class="ion-checkmark"> </i>Unlimited Push Notifications</li>
    <li><i class="ion-checkmark"> </i>Provide Website/Mobile App</li>
    <li><i class="ion-checkmark"> </i>Malayalam/English Language Selection</li>
    <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
      <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
      <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
      <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
      <li><i class="ion-checkmark"> </i>No Hidden Charges</li>
      <li><i class="ion-checkmark"> </i>24x7 Call/Chat Support</li>
      <li><i class="ion-checkmark"> </i>Men/Women</li>
      
    </ul>

  </div>
:
null
}

</div>
)}
       </div>   
       <Divider style={{paddingTop:40}}>More Payment Options</Divider>                    
<p>Account Number: <b>14140200007597</b></p>
<p>Account Name: <b>KCBC Prolife Samithi Charitable Trust Malabar Region</b></p>
<p>IFSC: <b>FDRL0001414</b></p>
<p>Federal Bank - Thamarassery Branch</p>

</Card>
                  </Container>

         
            <FooterS/>
        </div>
       
        )
    }
}
function mapStateToProps(state) {
    return {
      
        lang: state.lang
    }
}

export default connect(mapStateToProps )(Packages);