import React, { Component } from 'react';
import { Card, Container, Col, Row, CardTitle, CardText, CardImg, CardImgOverlay, CardSubtitle, CardBody } from 'reactstrap';
import { Upload, Icon, message, Modal, Button } from 'antd';
import ImageCropUpload from './ImageCropUpload';
import API from '../API';
class ImageUpload extends Component {
    state = {
        loading: false,
        selectedFile: null,
        previewImage: '',
        image1: null, image2: null,
        image3: null, image4: null,
        image5: null, image6: null
    };

    componentDidMount = () => {
        this.handleChanges()
    }


    handleChanges = () => {
        let obj = { user_id: this.props.UserId }
        fetch(API.BASE_URL + API.ALL_IMAGES, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                this.setState({
                    loading: false,
                    image1: responsesJson.image1,
                    image2: responsesJson.image2,
                    image3: responsesJson.image3,
                    image4: responsesJson.image4,
                    image5: responsesJson.image5,
                    image6: responsesJson.image6,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }



    render() {
        return (
            <Container>
                <Row>
                    <Col sm="4" xs="12">
                        <ImageCropUpload
                            image={this.state.image1}
                            image_id={'image1'}
                            user_id={this.props.UserId}
                            handleChanges={() => this.handleChanges()} />
                    </Col>
                    <Col sm="4" xs="12">
                        <ImageCropUpload
                            image={this.state.image2}
                            image_id={'image2'}
                            user_id={this.props.UserId}
                            handleChanges={() => this.handleChanges()} />
                    </Col>
                    <Col sm="4" xs="12">
                        <ImageCropUpload
                            image={this.state.image3}
                            image_id={'image3'}
                            user_id={this.props.UserId}
                            handleChanges={() => this.handleChanges()} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="4" xs="12">
                        <ImageCropUpload
                            image={this.state.image4}
                            image_id={'image4'}
                            user_id={this.props.UserId}
                            handleChanges={() => this.handleChanges()} />
                    </Col>
                    <Col sm="4" xs="12">
                        <ImageCropUpload
                            image={this.state.image5}
                            image_id={'image5'}
                            user_id={this.props.UserId}
                            handleChanges={() => this.handleChanges()} />
                    </Col>
                    <Col sm="4" xs="12">
                        <ImageCropUpload
                            image={this.state.image6}
                            image_id={'image6'}
                            user_id={this.props.UserId}
                            handleChanges={() => this.handleChanges()} />
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default ImageUpload;