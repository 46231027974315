import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Form, Icon, Input, Button, Card, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import API from '../API';
class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      username: null,
      password: null,
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
    console.log(event.target.name);
  }
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
        if (!err) {
      if(this.state.username === "admin" && this.state.password === "prolife@2019"){
          let response = {auth:true}
        this.props.AdminLogin(response);
        this.props.history.push("/AdminApp/AdminHome");
        this.setState({ loading: false });
      }
      else{
        this.error();
        this.props.history.push("/Admin");
        this.setState({ loading: false });
      }
    }
    })
}

  error = () => {
    Modal.error({
      title: 'Login Faild',
      content: 'Check your user name and password...',
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="logincontainer">
        <Container>
          <Row>
            <Col sm="12" md={{ size: 4, offset: 4 }}>
              <Card style={{ marginTop: 40, borderRadius: 10 }} className="itemscentr">
                <img src={require('../images/logo.png')} style={{ height: 33,margin: 20 }} alt="logo" />
                <h5 style={{ color: '#0070ba', fontWeight: 'bold' }}>Admin</h5>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <Form.Item>
                    {getFieldDecorator('username', {
                      initialValue: this.state.username,
                      setFieldsValue: this.state.username,
                      onChange: this.handleChange,
                      rules: [{ required: true, message: 'Please input your username!' }],
                    })(
                      <Input
                        name="username"
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Username"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('confirm', {
                      initialValue: this.state.password,
                      setFieldsValue: this.state.password,
                      onChange: this.handleChange,
                      rules: [
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        }
                      ],
                    })(<Input.Password onBlur={this.handleConfirmBlur} name="password" placeholder="Password" />)}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" block style={{ backgroundColor: '#0070ba', borderColor: '#0070ba', fontWeight: 'bold' }} size={'large'}>
                      Login
              </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          centered
          visible={this.state.loading}
          footer={false}
          closable={false}
          width={300}
        >
          <Form layout="inline">
            <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
            <Form.Item><h6>Register is processing</h6><p>please wait</p></Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    authA: state.authA,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    AdminLogin: (data) => {
      dispatch({ type: 'ADMINLOGIN', payload: data })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AdminLogin));