import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-circular-progressbar/dist/styles.css';
import { HashRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Store, PersistedStore } from './redux/Store/store';
import { PersistGate } from 'redux-persist/integration/react';
import './Font/Ubuntu-Light.ttf';

import './Font/Ubuntu-Bold.ttf';
import './Font/Ubuntu-Regular.ttf';
import './Font/Ubuntu-Medium.ttf';

const Myapp = <Provider store={Store}>
    <PersistGate loading={null} persistor={PersistedStore}>
        <HashRouter>
            <App />
        </HashRouter>
    </PersistGate>
</Provider>
ReactDOM.render(Myapp, document.getElementById('root'));
serviceWorker.register();
