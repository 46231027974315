

import React, { Component } from 'react';
import { Card, CardBody, Row, Col, CardImg, ButtonGroup } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, DatePicker, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class EditAdmin_MyprofileCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            issuccess: false,
            current: 0,
            value: '',
            user_id: null,
            date: this.props.data.DOB,
            //step1
            Status:this.props.data.Status,
            FullName: this.props.data.FullName,
            Gender: this.props.data.Gender,
            DOB: this.props.data.DOB,
            RelCaste: this.props.data.RelCaste,
            Height: this.props.data.Height,
            Weight: this.props.data.Weight,
            MaritialS: this.props.data.MaritialS,
            PhysicalS: this.props.data.PhysicalS,
            aboutCandidate: this.props.data.AboutMe,
            ProfileCreator: this.props.data.Pcreator,
            Creatorphone: this.props.data.Creatorphone,
            creatoremail: this.props.data.creatoremail,
            Password:this.props.data.Password,
            AcType:this.props.data.AcType,
            //end step1
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    ondateChange = (value) => {
        if (value != null) {
            this.setState({ DOB: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ DOB: null, date: null });
        }
    }

    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'user_id':this.props.user_id,
                    'Status': this.state.Status,
                    'FullName': this.state.FullName,
                    'Gender': this.state.Gender,
                    'ProfileCreator': this.state.ProfileCreator,
                    'creatoremail': this.state.creatoremail,
                    'Creatorphone': this.state.Creatorphone,
                    'DOB': this.state.DOB,
                    'RelCaste': this.state.RelCaste,
                    'Height': this.state.Height,
                    'Weight': this.state.Weight,
                    'MaritialS': this.state.MaritialS,
                    'PhysicalS': this.state.PhysicalS,
                    'aboutCandidate': this.state.aboutCandidate,
                    'Password':this.state.Password,
                    'AcType':this.state.AcType
                }
                fetch(API.BASE_URL + API.ADMIN_UPDATE_PROFILE, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.props.reload();
                            this.setState({ loading: false });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '+91',
        })(
            <Select style={{ width: 70 }}>
                <Option value="+91">+91</Option>
            </Select>,
        );
        const height_Array = [];
        for (let i = 30; i < 250; i++) {
            height_Array.push({
                value: i,
            });
        }
        const Weight_array = [];
        for (let i = 20; i < 160; i++) {
            Weight_array.push({
                value: i,
            });
        }
        const dateFormat = 'YYYY/MM/DD';
        return (
            <Card>
                <CardBody>
                    <Form {...formItemLayout}>
                    <Form.Item label="Account Status" >
                            {getFieldDecorator('Status', {
                                initialValue: this.state.Status,
                                setFieldsValue: this.state.Status,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please Account Status!' }],
                            })(
                                <Radio.Group name="Status">
                                    <Radio.Button value="A">Active</Radio.Button>
                                    <Radio.Button value="D">Deactive</Radio.Button>
                                    <Radio.Button value="N">New Registration</Radio.Button>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label="Type" style={{ marginTop: -20 }}>
                            {getFieldDecorator('AcType', {
                                initialValue: this.state.AcType,
                                setFieldsValue: this.state.AcType,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please Type!' }],
                            })(
                                <Radio.Group name="AcType">
                                    <Radio.Button value="1">ON</Radio.Button>
                                    <Radio.Button value="0">OFF</Radio.Button>
                                   
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label="Full Name" style={{ marginTop: -20 }}>
                            {getFieldDecorator('FullName', {
                                initialValue: this.state.FullName,
                                setFieldsValue: this.state.FullName,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please input your Full Name!' }]
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Full Name"
                                    name="FullName"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Gender" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Gender', {
                                initialValue: this.state.Gender,
                                setFieldsValue: this.state.Gender,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Gender!' }],
                            })(
                                <Radio.Group name="Gender">
                                    <Radio.Button value="Male">Male</Radio.Button>
                                    <Radio.Button value="Female">Female</Radio.Button>
                                </Radio.Group>,
                            )}
                        </Form.Item>

                        <Form.Item label="Date of Birth" style={{ marginTop: -20 }}>
                            {getFieldDecorator('date', {
                                initialValue: moment(this.state.date, dateFormat),
                                setFieldsValue: this.state.date,
                                rules: [{ type: 'object', required: false, message: 'Please select Date!' }],
                            })(
                                <DatePicker format="YYYY-MM-DD" placeholder="Select Time" onChange={this.ondateChange} />
                            )}
                        </Form.Item>

                        <Form.Item label="Religion/Caste" style={{ marginTop: -20 }}>
                            {getFieldDecorator('RelCaste', {
                                initialValue: this.state.RelCaste,
                                setFieldsValue: this.state.RelCaste,
                                rules: [{ required: false, message: 'Please select Religion/Caste!' }],
                            })(
                                <Select
                                    showSearch
                                    name={"RelCaste"}
                                    placeholder="Religion/Caste"
                                    optionFilterProp="children"
                                    onChange={(val) => { this.handleChange({ target: { name: 'RelCaste', value: val } }) }}
                                    filterOption={(input, option) =>
                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.props.dropdown.Religion_array.map((item) => (
                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label="Height (cm)" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Height', {
                                initialValue: this.state.Height,
                                setFieldsValue: this.state.Height,
                                rules: [{ required: false, message: 'Please select Height!' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Select Height"
                                    optionFilterProp="children"
                                    onChange={(val) => { this.handleChange({ target: { name: 'Height', value: val } }) }}
                                    filterOption={(input, option) =>
                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {height_Array.map((item) => (
                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>


                        <Form.Item label="Weight (kg)" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Weight', {
                                initialValue: this.state.Weight,
                                setFieldsValue: this.state.Weight,
                                rules: [{ required: false, message: 'Please select Weight!' }],
                            })(
                                <Select
                                    name="Weight"
                                    showSearch
                                    placeholder="Select Weight"
                                    optionFilterProp="children"
                                    onChange={(val) => { this.handleChange({ target: { name: 'Weight', value: val } }) }}
                                    filterOption={(input, option) =>
                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {Weight_array.map((item) => (
                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label="Maritial Status" style={{ marginTop: -20 }}>
                            {getFieldDecorator('MaritialS', {
                                initialValue: this.state.MaritialS,
                                setFieldsValue: this.state.MaritialS,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Gender!' }],
                            })(
                                <Radio.Group name="MaritialS" >

                                    <Radio.Button value="U">Unmarried</Radio.Button>
                                    <Radio.Button value="W">Widow/Widower</Radio.Button>
                                    <Radio.Button value="D">Divorcee</Radio.Button>
                                </Radio.Group>,
                            )}

                        </Form.Item>

                        <Form.Item label="Physical Status" style={{ marginTop: -20 }}>
                            {getFieldDecorator('PhysicalS', {
                                initialValue: this.state.PhysicalS,
                                setFieldsValue: this.state.PhysicalS,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Physical Status!' }],
                            })(
                                <Radio.Group name="PhysicalS">
                                    <Radio.Button value="Normal">Normal</Radio.Button>
                                    <Radio.Button value="With Disability">With Disability</Radio.Button>
                                </Radio.Group>,
                            )}
                        </Form.Item>

                        <Form.Item label="About the Candidate" style={{ marginTop: -20 }}>
                            <TextArea
                                name="aboutCandidate"
                                value={this.state.aboutCandidate}
                                onChange={this.handleChange}
                                placeholder="About the Candidate"
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Form.Item>
                        <Form.Item label="Profile Creator">
                            {getFieldDecorator('ProfileCreator', {
                                initialValue: this.state.ProfileCreator,
                                setFieldsValue: this.state.ProfileCreator,
                                rules: [{ required: false, message: 'Please select EProfile Creator!' }],
                            })(
                                <Select placeholder="Profile Creator" name="ProfileCreator"
                                    onChange={(val) => { this.handleChange({ target: { name: 'ProfileCreator', value: val } }) }}>
                                    <Option value="Self">Self</Option>
                                    <Option value="Parents">Parents</Option>
                                    <Option value="Siblings">Siblings</Option>
                                    <Option value="Relatives">Relatives</Option>
                                    <Option value="Friends">Friends</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Profile creator Mob No" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Creatorphone', {
                                initialValue: this.state.Creatorphone,
                                setFieldsValue: this.state.Creatorphone,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please input your phone number!' }],
                            })(<Input
                                addonBefore={prefixSelector}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                                name="Creatorphone"
                                placeholder="Phone Number"
                                type='number' />)}
                        </Form.Item>

                        <Form.Item label="Profile creator E-mail" style={{ marginTop: -20 }}>
                            {getFieldDecorator('creatoremail', {
                                initialValue: this.state.creatoremail,
                                setFieldsValue: this.state.creatoremail,
                                onChange: this.handleChange,
                                rules: [
                                    {
                                        type: 'email',
                                        required: false,
                                        message: 'The input is not valid E-mail!',
                                    },

                                ],
                            })(<Input name="creatoremail"
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email" />)}
                        </Form.Item>
                        <Form.Item label="Password" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Password', {
                                initialValue: this.state.Password,
                                setFieldsValue: this.state.Password,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please input your Full Name!' }]
                            })(
                                <Input.Password 
                                placeholder="input password" />
                            )}
                        </Form.Item>
                        
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={() => this.update()} style={{ fontWeight: 'bolder', backgroundColor: '#0070ba', borderColor: '#0070ba' }}>
                                    <span style={{ marginLeft: 15, marginRight: 15 }}>Update</span>
                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(EditAdmin_MyprofileCard));