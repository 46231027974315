const styles = ({
    linkdec: {
        textDecoration: 'none'
       
        
    },
   
    menuitemicon:{
        fontSize: 20,
         marginRight: 20,
          color: '#797676' 
    },
    content:{
        overflow: 'auto',
        background: '#f2f3f5'
    
    },
    btnlogout:{
        backgroundColor: '#026c70',
      
       
    },
   
    logoutxt:{
        fontSize: 20,
         fontWeight: 'bolder',
         fontFamily: "Nunito",
    },
    logoutxt2:{
        fontSize: 20,
        fontFamily: "Nunito",
    }
})
export default styles;