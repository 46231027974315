import { combineReducers } from 'redux';
import alertReducer from './alertReducer';
import dropdownReducer from './dropdownReducer';
import AdminLoginReducer from './AdminLoginReducer';
import UserLoginReducer from './UserLoginReducer';
import LanguageReducer from './LanguageReducer';
import ShortlistReducer from './ShortlistReducer';
import IntrestedReducer from './IntrestedReducer';
import NotificationReducer from './NotificationReducer';
const RootReducer = combineReducers({
    dropdown:dropdownReducer,
    User:UserLoginReducer,
    authA:AdminLoginReducer,
    alert:alertReducer,
    lang:LanguageReducer,
    Shortlist:ShortlistReducer,
    Intrested:IntrestedReducer,
    Notification:NotificationReducer
})
export default RootReducer;