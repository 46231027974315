

import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, message, Button,Modal} from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
class PassChangeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3
            confirm: null,
            current: null,
            ShowAlert: false

            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    user_id: this.props.User.user_id,
                    current: this.state.current,
                    confirm: this.state.confirm,
                }
                fetch(API.BASE_URL + API.EDIT_PASSWORD, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.setState({ 
                                loading: false, 
                                confirm: null,
                                current: null, 
                                ShowAlert: true });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false });
                            this.error();
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        this.error();
                        console.error(error);
                    });
            }
        });
    }
    error = () => {
        Modal.error({
          title: 'Faild',
          content: 'Try Again...',
        });
      }
      conferm_logout = () => {
        const response = {
            auth: false,
            user_id: 0,
            userName: null,
            propic: null,
            state: 0
        };
        this.props.Logout(response);
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Card style={{ borderRadius: 10 }} className="change">
                <CardBody>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.UserProfileChangePW}</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Form >
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                                <Form.Item label= {this.props.lang.PWChangeCurrentPW} hasFeedback >
                                    {getFieldDecorator('current', {
                                        initialValue: this.state.current,
                                        setFieldsValue: this.state.current,
                                        onChange: this.handleChange,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your Current password!',
                                            }
                                        ],
                                    })(<Input.Password placeholder="Current Password" name="current" />)}
                                </Form.Item>
                                <Form.Item label={this.props.lang.PWChangeNewPW} hasFeedback style={{ marginTop: -30 }}>
                                    {getFieldDecorator('password', {
                                        initialValue: this.state.confirm,
                                        setFieldsValue: this.state.confirm,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                            {
                                                validator: this.validateToNextPassword,
                                            },
                                        ],
                                    })(<Input.Password placeholder="New Password" />)}
                                </Form.Item>

                                <Form.Item hasFeedback style={{ marginTop: -20 }}>
                                    {getFieldDecorator('confirm', {
                                        initialValue: this.state.confirm,
                                        setFieldsValue: this.state.confirm,
                                        onChange: this.handleChange,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            {
                                                validator: this.compareToFirstPassword,
                                            },
                                        ],
                                    })(<Input.Password onBlur={this.handleConfirmBlur} name="confirm" placeholder="Confirm Password" />)}
                                </Form.Item>
                                <Form.Item style={{ marginBottom:20 }}>
                                    <Button block type="danger" style={{fontWeight: 'bolder'}} onClick={() => this.update()}>
                                        Change password
                                </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
                <Modal
                    centered
                    visible={this.state.ShowAlert}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                    <img src={require('../../images/success1.gif')} style={{ height: 250, width: 350 }} alt="success" />
                        <h5>Password updated</h5>
                        <p>Your password has been changed sccessfully</p>
                        <p>user new password to log in.</p>
                        <Button type="primary" onClick={() => { this.setState({ ShowAlert: false }) }} style={{marginRight:10}}>
                            keep continue.
                            </Button>
                            <Button type="danger" onClick={() => { this.conferm_logout() }}>
                            Logout
                            </Button>
                    </div>
                </Modal>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        Logout: (data) => {
            dispatch({ type: 'LOGOUT', payload: data })
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(PassChangeCard));