
import React, { Component } from 'react';
import { Row, Col, Card,CardHeader,CardBody, CardImg,CardFooter,CardText,Container } from 'reactstrap';
import { List,Icon, Divider,Modal, Popover, Button, message, Form, Select, Input,Tag,Layout } from 'antd';
import API from '../../API';
import { connect } from 'react-redux';

import Not_aprooved_card from '../../components/not_aprooved_card';
import dateFormat from 'dateformat';
import { FaUserAlt,FaUserCog,FaUserGraduate ,FaChurch,FaWhatsapp} from 'react-icons/fa';
import { MdPlace,MdWork } from "react-icons/md";

import './styles.css'
import Shortlistbutton from '../../components/Shortlistbutton';
import IntrestedButton from '../../components/IntrestedButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
const { Content } = Layout;

const { TextArea } = Input;
const { Option } = Select;


const ButtonGroup = Button.Group;
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}


class Details extends Component {
  
  reset = () => {
    this.props.form.resetFields()
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  handleSelectChange = value => {
    console.log(value);
    this.props.form.setFieldsValue({
      note: `${value === value ? value : ''}`,
      noteRply: `${value === value ? value : ''}`,
    });
  this.setState({note:`${value}`})
  this.setState({noteRply:`${value}`})
  };

  ProReportReasonSelect = value => {
    console.log(value);
    this.props.form.setFieldsValue({
     
      ReportReason: `${value === value ? value : ''}`
    });
 
  this.setState({ReportReason:`${value}`})
  }

  constructor(props) {
    super(props);
    this.state = {
      showId: null,
      isLoaded:false,
      exceedWA:false,
      exceedContact:false,
      exceedSMS:false,
      ConLock:false,
      items: [],
      previewVisible: false,
      askshow:false,
      askWhatsapp:false,
      ModalContactDetails: false,
      ModalProfileReport:false,
      ModalMSG:false,
      note: null,
      msgTempRplydb:[],
      msgTempdb:[],
      ProfileReportReasonDB:[],
      ReportReason:null,
      CommentProfileReport:null,
      ModalAccountOff:false
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleCancel = () => this.setState({ previewVisible: false});

  handleChange = (event) => {
    this.setState({
        [event.target.name]: event.target.value
    });
    console.log(event.target.name);
   
}
onChangeText = (e) => {
  this.setState({CommentProfileReport: e.target.value})
}
handleCancel = e => {
  console.log(e);
  this.setState({
    ModalMSG: false,AskSMS:false ,askWhatsapp:false,previewVisible:false,askshow:false
  });
};

  componentDidMount() {
    if (this.props.User.user_id) {
      this.visitors();
    }
    let obj = { "user_id": this.props.User.user_id, "target_id": this.props.match.params.id };
    fetch(API.BASE_URL + API.GET_DETAILS, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then((result) => {

        if (result[0].Type === '0'){
          
          this.setState({
            ModalAccountOff: true
           
          });
        }
        else{

        this.setState({
          loading: false,
          items: result
        });
      }
      },
        (error) => {
          alert(error);
          this.setState({ loading: false, error });
        }
      )
  }

  //check visitors
  visitors = () => {
    let obj = { "user_id": this.props.User.user_id, "target_id": this.props.match.params.id };
    fetch(API.BASE_URL + API.PROFILVISITORS_ACTION, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then((result) => {
        this.setState({ loading: false });
      },
        (error) => {
          alert(error);
          this.setState({ loading: false, error });
        }
      )
  }
  //end visitors
  ContactCounter = () => {
    let obj = { user_id: this.props.User.user_id };
    fetch(API.BASE_URL + API.CONTACTVIEWCOUNT, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
    }).then((response) => response.json())
        .then((responsesJson) => {
            this.setState({
              
                remaining_view: responsesJson.views,
              
              
              askshow:true
            })
        })
        .catch((error) => {
            message.error('Error. Try again !');
        });


  }
  WhatsAppCounter = () => {
    let obj = { user_id: this.props.User.user_id };
    fetch(API.BASE_URL + API.CONTACTVIEWCOUNT, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
    }).then((response) => response.json())
        .then((responsesJson) => {
            this.setState({
              
                remaining_view: responsesJson.views,
              
      
              askWhatsapp:true
            })
        })
        .catch((error) => {
            message.error('Error. Try again !');
        });


  }



    WhatsAppMsg = (MyuserID,TarMobile,TarName,MYName,DOBwp,Heightwp,Placewp,Religionwp,EduCatwp,WorkCatwp) => {
   
const Userid=MyuserID
      const phone = '91'+TarMobile
      const msg = encodeURIComponent('Hello '+ TarName+', You have interest from '+MYName+'('+MyuserID+'), '+getAge(DOBwp)+' yr, '+Heightwp+' cm, '+Placewp+' (Place), '+this.Religion(Religionwp)+' (Religion), '+this.EduCat(EduCatwp)+' (Education), '+this.WorkCat(WorkCatwp)+' (Occupation). More Details https://prolifemarry.com/#/Msg/'+MyuserID)
      const href = `https://wa.me/${phone}?text=${msg}`
      window.open(href, "_blank");
    }

    WhatsappViewAndSend = () => {
      this.setState({ askWhatsapp: false });
      let obj = {
        user_id: this.props.User.user_id,
        target_id: this.props.match.params.id
      };
      fetch(API.BASE_URL + API.WhatsappSend, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
      })
        .then(response => response.json())
        .then((responsesJson) => {
        
            this.setState({ isLoaded: true,
              MyName: responsesJson.MyName,
              MyID: responsesJson.MyID,
              TarName: responsesJson.TarName,
              TarMobile: responsesJson.TarMobile,
              DOBwp: responsesJson.DOB,
              Heightwp: responsesJson.Height,
              Placewp: responsesJson.Place,
              Religionwp: responsesJson.Religion,
              EduCatwp: responsesJson.EduCat,
              WorkCatwp: responsesJson.WorkCat
            
            });
            if(responsesJson.status === 'success'){
             
              this.componentDidMount();
              this.WhatsAppMsg(this.state.MyID,this.state.TarMobile,this.state.TarName,this.state.MyName,this.state.DOBwp,this.state.Heightwp,this.state.Placewp,this.state.Religionwp,this.state.EduCatwp,this.state.WorkCatwp);
            }
            else if (responsesJson.status === 'exceeded'){
              this.setState({ exceedWA:true});
            }
            else{
              message.error('faild');
            }
          },
          (error) => {
            message.error("error");
            this.setState({isLoaded: true});
          }
        )
    }
    ProfileReport = () => {
      
      let obj = {
        Type: "RP",
        
    }
    fetch(API.BASE_URL + API.DropDownItem, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    })
        .then(res => res.json())
        .then(
          (result) => {
            //message.success('success');
            this.setState({
              
            
              ProfileReportReasonDB:result.DbItem,
              ModalProfileReport:true,
             
            },

             );

          },
          (error) => {
             
            alert(error);
            this.setState({
              isLoaded: false,
              error
            });
          }
        )
  

  }
    WhatsappViewAndSendWithout = () => {
     
      let obj = {
        user_id: this.props.User.user_id,
        target_id: this.props.match.params.id
      };
      fetch(API.BASE_URL + API.WhatsappSendWithout, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
      })
        .then(response => response.json())
        .then((responsesJson) => {
        
            this.setState({ isLoaded: true,
              MyName: responsesJson.MyName,
              MyID: responsesJson.MyID,
              TarName: responsesJson.TarName,
              TarMobile: responsesJson.TarMobile,
              DOBwp: responsesJson.DOB,
              Heightwp: responsesJson.Height,
              Placewp: responsesJson.Place,
              Religionwp: responsesJson.Religion,
              EduCatwp: responsesJson.EduCat,
              WorkCatwp: responsesJson.WorkCat
            
            });
            if(responsesJson.status === 'success'){
             
            this.WhatsAppMsg(this.state.MyID,this.state.TarMobile,this.state.TarName,this.state.MyName,this.state.DOBwp,this.state.Heightwp,this.state.Placewp,this.state.Religionwp,this.state.EduCatwp,this.state.WorkCatwp);
            }
            else if (responsesJson.status === 'exceeded'){
              this.setState({ exceed:true});
            }
            else{
              message.error('faild');
            }
          },
          (error) => {
            message.error("error");
            this.setState({isLoaded: true});
          }
        )
    }
  //end add intrested

  //end change intrsted actions

  //View Contact
  view_contact = () => {
    this.setState({ askshow: false });
    let obj = {
      user_id: this.props.User.user_id,
      target_id: this.props.match.params.id
    };
    fetch(API.BASE_URL + API.VIEW_CONATCT, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ isLoaded: true });
          if(result.status === 'success'){

            this.componentDidMount();
            this.setState({ ModalContactDetails:true});
          }
          else if (result.status === 'exceeded'){
            this.setState({ exceedContact:true});
          }
          else if (result.status === 'Lock') {
            this.setState({ ConLock: true })
        }
          else{
            message.error('faild');
          }
        },
        (error) => {
          
          this.setState({isLoaded: true});
        }
      )
  }
  //end view contact
  SMSCounter = () => {
    let obj = { user_id: this.props.User.user_id };
    fetch(API.BASE_URL + API.SMSCounter, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
    }).then((response) => response.json())
        .then((responsesJson) => {
            this.setState({
              
                SMS_view: responsesJson.views,
              
              
              AskSMS:true
            })
        })
        .catch((error) => {
            message.error('Error. Try again !');
        });


  }

  SendProfileReport = () => {
       
    if (this.state.ReportReason != null && this.state.ReportReason.trim() != '') {
 
    let obj = {
     
      Reason: this.state.ReportReason,
      Message : this.state.CommentProfileReport,
     
      user_id: this.props.User.user_id,
      target_id: this.props.match.params.id
    };
    fetch(API.BASE_URL + API.ProfileReportSend, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ isLoaded: true });
          if(result.status === 'success'){
       
           
            this.setState({ ModalProfileReport:false,ReportReason:null,CommentProfileReport:null});
            message.success('Report sent successfully, Admin will take necessary action',10);
            this.reset();
          }
        
          else{
            message.error('Failed');
            
          }
        },
        (error) => {
           
            message.error('Error');
          this.setState({isLoaded: true});
        }
      )
    }
      else{
        message.error('Please select report reason.');
     
      }

  }
    //View Contact
    ViewSMS = () => {
      this.setState({ AskSMS: false });
      let obj = {
        user_id: this.props.User.user_id,
        target_id: this.props.match.params.id
      };
      fetch(API.BASE_URL + API.VIEW_SMS, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({ isLoaded: true });
            if(result.status === 'success'){
              message.success('Message sent successfully',3);
              this.componentDidMount();
            }
            else if (result.status === 'exceeded'){
              this.setState({ exceedSMS:true});
            }
            else{
              message.error('failed');
            }
          },
          (error) => {
            message.error('error');
            this.setState({isLoaded: true});
          }
        )
    }

    //end view contact
TopUpSMS = ()=>  {
  this.setState({exceedSMS:false});
  this.props.history.push("/UserApp/TopUp");
}  
    //View Contact
        SendMsg = () => {
       
          this.props.form.validateFieldsAndScroll(['note'], (err, values) => {
            if (!err) {

        
          let obj = {
            user_id: this.props.User.user_id,
            target_id: this.props.match.params.id,
            Message : this.state.note
          };
          fetch(API.BASE_URL + API.SendMsg, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
          })
            .then(res => res.json())
            .then(
              (result) => {
                this.setState({ isLoaded: true });
                if(result.status === 'success'){
                  message.success('Message sent successfully',3);
                 

                  this.ReadMsg();
                  this.reset();
                }
                
                else{
                  message.error('Failed');
                }
              },
              (error) => {
                message.error('error');
                this.setState({isLoaded: true});
              }
            )
          }
          else{

            alert("error");
          }
        });
        }

        ReadMsg = () => {
        
           
         
            let obj = { user_id: this.props.User.user_id,
              target_id: this.props.match.params.id
            }
            fetch(API.BASE_URL + API.ReadMsg, {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              body: JSON.stringify(obj)
            })
              .then(res => res.json())
              .then(
                (result) => {
                  //message.success('success');
                  this.setState({
                    isLoaded: false,
                    Notifcations: result.Notifcations.reverse(),
                    IncomeMsg: result.IncomeMsg.reverse(),
                    msgTempdb:result.msgTempdb,
                msgTempRplydb:result.msgTempRplydb,
                    ModalMSG:true
                  },

                   );

                },
                (error) => {
                  message.error('Loading Faild');
                  alert(error);
                  this.setState({
                    isLoaded: false,
                    error
                  });
                }
              )
        
      
        }
        SendMsgRply = () => {
          this.props.form.validateFieldsAndScroll(['noteRply'], (err, values) => {
    
            if (!err) {
         
          let obj = {
            RplyId: this.state.MsgRplyId,
         
            Message : this.state.noteRply
          };
          fetch(API.BASE_URL + API.SendMsgRply, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
          })
            .then(res => res.json())
            .then(
              (result) => {
                this.setState({ isLoaded: true });
                if(result.status === 'success'){
                  message.success('Reply sent successfully',3);
                 
                  this.ReadMsg();
                  this.reset();
                  this.setState({ ModalMSGReply:false});
                }
              
                else{
                  message.error('Failed');
                }
              },
              (error) => {
                message.error('error');
                this.setState({isLoaded: true});
              }
            )
          }
        });
        }

        DeleteMsg = (id) => {
        
                   let obj = {
                     RplyId: id
                  
                   };
                   fetch(API.BASE_URL + API.DeleteMsg, {
                     method: 'POST',
                     headers: new Headers({
                       'Content-Type': 'application/x-www-form-urlencoded',
                     }),
                     body: JSON.stringify(obj)
                   })
                     .then(res => res.json())
                     .then(
                       (result) => {
                         this.setState({ isLoaded: true });
                         if(result.status === 'success'){
                          message.success('Message Delete successfully',3);
                          
         
                           this.ReadMsg();
                           
                         }
                        
                         else{
                           message.error('Failed');
                         }
                       },
                       (error) => {
                         message.error('error');
                         this.setState({isLoaded: true});
                       }
                     )
                 }
                 DeleteMsgMain = (id) => {
        
                  let obj = {
                    RplyId: id
                 
                  };
                  fetch(API.BASE_URL + API.DeleteMsgMain, {
                    method: 'POST',
                    headers: new Headers({
                      'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                  })
                    .then(res => res.json())
                    .then(
                      (result) => {
                        this.setState({ isLoaded: true });
                        if(result.status === 'success'){
                         message.success('Message Delete successfully',3);
                         
        
                          this.ReadMsg();
                        
                        }
                       
                        else{
                          message.error('Failed');
                        }
                      },
                      (error) => {
                        message.error('error');
                        this.setState({isLoaded: true});
                      }
                    )
                }
    changeSMS = () => {
      let obj = {
        user_id: this.props.User.user_id
        
      };
      fetch(API.BASE_URL + API.CHANGESMS, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
      })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({ AskSMS: true })
          },
          (error) => {
            message.error('Loading faild');
           
          }
        )
    }
  Religion(Relig) {
    let array = this.props.dropdown.Religion_array;
    let filteredArray = '';
    return filteredArray = array.filter(function (obj) {
        return obj.id === Relig;
    }).map(function (obj) {
        return obj.Name;
    });
}

Diocese(Dioce) {
  let array = this.props.dropdown.Diocese_array;
  let filteredArray = '';
  return filteredArray = array.filter(function (obj) {
      return obj.id === Dioce;
  }).map(function (obj) {
      return obj.Name;
  });
}

WorkCat(Work) {
    let array = this.props.dropdown.Work_array;
    let filteredArray = '';
    return filteredArray = array.filter(function (obj) {
        return obj.id === Work;
    }).map(function (obj) {
        return obj.Name;
    });
}

EduCat(Edu) {
    let array = this.props.dropdown.Education_array;
    let filteredArray = '';
    return filteredArray = array.filter(function (obj) {
        return obj.id === Edu;
    }).map(function (obj) {
        return obj.Name;
    });
}

Workingplace(Wp) {
  let array = this.props.dropdown.WorkingPlace_array;
  let filteredArray = '';
  return filteredArray = array.filter(function (obj) {
      return obj.id === Wp;
  }).map(function (obj) {
      return obj.Name;
  });
}

Maritial(Wp) {
  if(Wp === 'U'){
    return 'Unmarried';
  } 
  else if (Wp === 'W') {
    return 'Widow/Widower';
  }
  else{
    return 'Divorcee';
  }
}


  render() {
    const content = (
      <div>
         
<button block  onClick={() =>   {this.ProfileReport()}} className="listbtn">Report this profile to admin</button>

    </div>
          
  );

    const {  Notifcations,IncomeMsg } = this.state;
    const { getFieldDecorator } = this.props.form;
    if (this.state.loading) {
      return (
        <div>
          <p>Loading</p>
        </div>
      )
    }

    return (
          
      <Content className="scrollBox1" style={{ background: '#f2f3f5' }}>
      <Container>
      <div className="scrollBox1">
    
        <div className="paddesk" >
          {this.state.items.map((item) =>
          <div>
            <Card style={{ marginTop: 30, marginLeft: 10,marginRight: 10,marginBottom: 0,paddingBottom:20}} key={item.id}>
              <Row style={{ margin: 20 ,marginBottom:-5}}>
                <Col sm="4" xs="12" >
                  <div onClick={() => { this.setState({ previewImage: item.image1, previewVisible: true }) }}>
                    {item.image1 ? <CardImg alt="logo" src={item.image1} className="recomend-image" style={{ marginTop: 5,marginBottom:5,  borderRadius: 4 }} />
                      : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} className="recomend-image" />}
                  </div>
                  
                      <Row>
                        <Col sm="3" xs="3" style={{padding:5} } >
                        {item.image2 ? <Card inverse  onClick={() => { this.setState({ previewImage: item.image2, previewVisible: true }) }}>
                             <CardImg src={item.image2} alt="Card image cap"  />
                              
                          </Card>: null}
                        </Col>
                        <Col sm="3" xs="3" style={{padding:5} } >
                        {item.image3 ? <Card inverse  onClick={() => { this.setState({ previewImage: item.image3, previewVisible: true }) }}>
                             <CardImg src={item.image3} alt="Card image cap"  />
                             
                          </Card>: null}
                        </Col>
                        <Col sm="3" xs="3" style={{padding:5} } >
                        {item.image4 ?  <Card inverse  onClick={() => { this.setState({ previewImage: item.image4, previewVisible: true }) }}>
                             <CardImg src={item.image4} alt="Card image cap"  />
                            
                          </Card>: null}
                        </Col>
                        <Col sm="3" xs="3" style={{padding:5} } >
                        {item.image5 ? <Card inverse onClick={() => { this.setState({ previewImage: item.image5, previewVisible: true }) }}>
                            <CardImg src={item.image5} alt="Card image cap"  />
                              
                          </Card>: null}
                        </Col>
                        <Col sm="3" xs="3" style={{padding:5} } >
                        {item.image6 ? <Card inverse  onClick={() => { this.setState({ previewImage: item.image6, previewVisible: true }) }}>
                             <CardImg src={item.image6} alt="Card image cap"  />
                             
                          </Card>: null}
                        </Col>

                      </Row>
                  
                

                </Col>
                <Col sm="8" xs="12">
<Row>
  <Col style={{ fontSize: 22,fontWeight: '600',textTransform: 'uppercase'  }}>
  {item.Name} <span style={{fontSize:16}}>({item.UserId}) </span>
  </Col>
  
  <Col >
  <Popover placement="bottomRight" trigger="click" content={content}
                                    overlayStyle={{
                                        background: "transparent",
                                        width: "170px"
                                    }}>
                                    <MoreHorizIcon className="morebtn" style={{float:"right",marginRight:0}} />
                                </Popover>
  </Col>
</Row>
                
                
                  <table style={{marginLeft:6,marginTop:10}}>

                  
                    <tr>
                    <td style={{paddingRight: 10,fontSize: 15,paddingBottom: 10}}><FaUserAlt /> </td> <td style={{ fontSize: 15,fontWeight: '500',paddingBottom: 10 }}>
                     {getAge(item.DOB)} Yrs&nbsp;(
                        {dateFormat((item.DOB), "yyyy-mmm-dd")  })/&nbsp;
                         {item.Gender}
                      </td>
                    
                    </tr>
                    <tr>
                    <td style={{paddingRight: 10,fontSize: 20,paddingBottom: 10}}><FaUserCog /> </td> <td style={{ fontSize: 15, fontFamily: 'Segoe UI',fontWeight: '500' ,paddingBottom: 10 }}>
                    {item.Height} cm/&nbsp;
                    {item.Weight} kg
                      </td>
                    
                    </tr>
                    <tr>
                    <td style={{paddingRight: 10,fontSize: 20,paddingBottom: 10}}><FaChurch /> </td> <td style={{ fontSize: 15, fontFamily: 'Segoe UI',fontWeight: '500' ,paddingBottom: 10 }}>
                    {this.Religion(item.Religion)}
                      </td>
                    
                    </tr>
                    <tr >
                    <td style={{paddingRight: 10,fontSize: 20,paddingBottom: 10}}> <MdPlace/> </td> <td style={{ fontSize: 15, fontFamily: 'Segoe UI',fontWeight: '500' ,paddingBottom: 10 }}>
                    {item.Place}
                      </td>
                    
                    </tr>
                    <tr>
                    <td style={{paddingRight: 10,fontSize: 18,paddingBottom: 10}}><FaUserGraduate />  </td> <td style={{ fontSize: 15, fontFamily: 'Segoe UI',fontWeight: '500' ,paddingBottom: 10 }}>
                    {item.EduDetail}/&nbsp;
                    
                    {this.EduCat(item.EduCat)}
                      </td>
                    
                    </tr>
                    <tr>
                    <td style={{paddingRight: 10,fontSize: 18,paddingBottom: 10}}><MdWork /></td> <td style={{ fontSize: 15, fontFamily: 'Segoe UI',fontWeight: '500' ,paddingBottom: 10 }}>
                    {item.WorkDetail}/&nbsp;
                    {this.WorkCat(item.WorkCat)}/&nbsp;
                    {this.Workingplace(item.WorkingPlace)}
                      </td>
                    
                    </tr>
                  </table>
                
              
                
                </Col>
              </Row>
            </Card>
            <Card style={{ marginTop: 15, marginLeft: 10,marginRight: 10,marginBottom: 0,paddingBottom:20,padding:20}} key={item.id}>
          
            <Row style={{marginRight:0}} >
                   
                   <Col style={{paddingRight:0}}> <Shortlistbutton
                                    target={{'user_id':item.id,'Name':item.Name,'UserId':item.UserId,'image1':item.image1}}
                                    target_id={item.id}
                                    Shortlist={this.props.Shortlist.profiles.filter(Shortlist => Shortlist.user_id === item.id)[0]} /></Col>
                    <Col style={{paddingRight:0}}><IntrestedButton 
                                target={{'user_id':item.id,'Name':item.Name,'UserId':item.UserId,'image1':item.image1}}
                                Intrested={this.props.Intrested.profiles.filter(Intrested => Intrested.user_id === item.id)[0]}/></Col>
                        
                 <Col style={{paddingRight:0}}>
                    
                         <div>
                  {item.cont
                    ?  <button  onClick={() =>   {this.setState({ ModalContactDetails: true })}} lang={this.props.lang.lang}
                    className="DetailPageContactUnlock"> <span  style={{ verticalAlign:2}}>{this.props.lang.DetailContact}</span></button>
                   
                    :<div >
                      {this.props.User.Status === 'A'
                      ?
                      <button  onClick={() =>  {this.ContactCounter()}} lang={this.props.lang.lang}
                      className="DetailPageContactLock"> <span  style={{ verticalAlign:2}}>{this.props.lang.DetailContact}</span></button>
                      :<Not_aprooved_card/>
                      }
                     
                    </div>
                   
                  }
                   </div>
                 
                   </Col>
                   </Row>
                   <Row style={{marginRight:0}}>
                   <Col style={{paddingRight:0}}>
                   <button    onClick={() =>  {this.ReadMsg()}} lang={this.props.lang.lang}
                   className="DetailPageSMS" ><span  style={{ verticalAlign:2}}>{this.props.lang.SendMsg}</span></button>
</Col>
                   <Col style={{paddingRight:0}}>
                   <button   onClick={() =>  {this.SMSCounter()}} lang={this.props.lang.lang}
                   className="DetailPageSMS" > <span  style={{ verticalAlign:2}}>{this.props.lang.SendSMS}</span></button>
</Col>
<Col style={{paddingRight:0}}>
{item.cont
                    ?  <div>
                    <button  className="DetailPageWhatsApp" lang={this.props.lang.lang} onClick={() =>  {this.WhatsappViewAndSendWithout()}}
                    > <span  style={{ verticalAlign:2}}>{this.props.lang.SendWhats}</span></button>
                  
                         </div>
                    :<div >
                      {this.props.User.Status === 'A'
                      ?
                      <button  className="DetailPageWhatsApp" lang={this.props.lang.lang} onClick={() =>  {this.WhatsAppCounter()}}
                      > <span  style={{ verticalAlign:2}}>{this.props.lang.SendWhats}</span></button>
  
                      :<Not_aprooved_card/>
                      }
                     
                    </div>
                   
                  }
</Col>

                    </Row>

           </Card>

            
            <Card title="Basic Details" style={{ marginTop: 15, marginLeft: 10,marginRight: 10,marginBottom: 20}} key={item.id}>
          
            <CardHeader style={{fontWeight:500}}>{this.props.lang.BasicDeat}</CardHeader>
          
            <Row style={{marginLeft:5,marginTop:10,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.PCreator}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.Pcreator}
                     </Col>
                     <Col lg="2" md="5"  sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Cast}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{this.Religion(item.Religion)}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Age}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{getAge(item.DOB)} Years
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.DOB}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{dateFormat((item.DOB), "yyyy-mmm-dd") }
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Height}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.Height} cm
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Wight}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.Weight} kg
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.MStatus}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{this.Maritial(item.Maritial)}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.PStatus}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.Physical}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMainLast" lang={this.props.lang.lang}>{this.props.lang.FinSta}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSubLast" lang={this.props.lang.lang}>{item.FinStatus}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMainLast" lang={this.props.lang.lang}>{this.props.lang.Aboutme}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSubLast"   lang={this.props.lang.lang}>{item.AboutMe}
                     </Col>
                    </Row>
           
            
            </Card>

            <Card style={{ marginTop: 15, marginLeft: 10,marginRight: 10,marginBottom: 20}} key={item.id}>
          
          <CardHeader style={{fontWeight:500}}>{this.props.lang.UserProfileContactAddress}</CardHeader>
        
          <Row style={{marginLeft:5,marginTop:10,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.HName}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.HName}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Place}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.Place}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Parish}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.Parish}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Diocese}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{this.Diocese(item.Diocese)}
                     </Col>
                    </Row>
                    {item.cont
                    ? 
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.PNumber}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.PNumber}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.SEmail}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.Email}
                     </Col>
                    </Row>:null}
                    
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.CAddress}:
                     </Col>
                     <Col lg="5" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.Address}
                     </Col>
                  
                  
                    </Row>
           
          
        
             
            
          
          
          </Card>
            <Card title="Education And Occupation" style={{ marginTop: 15, marginLeft: 10,marginRight: 10,marginBottom: 20}} key={item.id}>
          
          <CardHeader style={{fontWeight:500}}>{this.props.lang.UserProfileEducation}</CardHeader>
        
          <Row style={{marginLeft:5,marginTop:10,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.WorkFirm}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.WorkDetail}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.WorkCat}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{this.WorkCat(item.WorkCat)}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.EduDet}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.EduDetail}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.EduCat}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{this.EduCat(item.EduCat)}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.WorkPlace}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{this.Workingplace(item.WorkingPlace)}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.AnnualIn}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.AnnualIncome}
                     </Col>
                    </Row>
                  
          
          </Card>

          <Card  style={{ marginTop: 15, marginLeft: 10,marginRight: 10,marginBottom: 20}} key={item.id}>
          
          <CardHeader style={{fontWeight:500}}>{this.props.lang.UserProfileFamily}</CardHeader>
        
          <Row style={{marginLeft:5,marginTop:10,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.FName}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.FName}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.FOccu}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.FOccupation}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.MName}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.MName}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.MOccu}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.MOccupation}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.MBro}:
                     </Col>
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatSub" lang={this.props.lang.lang}>{item.MBros}
                     </Col>
                     <Col lg="2" md="6"  sm="6" xs="6" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.UBro}:
                     </Col>
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatSub"   lang={this.props.lang.lang}>{item.UBros}
                     </Col>
                     <Col lg="2" md="6"  sm="6" xs="6" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Priest}:
                     </Col>
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatSub"   lang={this.props.lang.lang}>{item.PBros}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.MSis}:
                     </Col>
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatSub" lang={this.props.lang.lang}>{item.MSis}
                     </Col>
                     <Col lg="2" md="6"  sm="6" xs="6" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.USis}:
                     </Col>
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatSub"   lang={this.props.lang.lang}>{item.USis}
                     </Col>
                     <Col lg="2" md="6"  sm="6" xs="6" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Nuns}:
                     </Col>
                     <Col lg="2" md="6" sm="6" xs="6" className="DeatSub"   lang={this.props.lang.lang}>{item.NSis}
                     </Col>
                    </Row>
          
        
          </Card>

          <Card style={{ marginTop: 15, marginLeft: 10,marginRight: 10,marginBottom: 20}} key={item.id}>
          
          <CardHeader style={{fontWeight:500}}>{this.props.lang.UserProfilePatner}</CardHeader>
          <Row style={{marginLeft:5,marginTop:10,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Smrg}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.SMarriage}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Handi}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.Handi}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.preferredqualifications}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.PQualification.map((person, index) => (
          
          <Tag 
          key={person.id}>
               {person.Name}
           </Tag>
       ))}
                     </Col>
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.preferredemployment}:
                     </Col>
                     <Col lg="4" md="7" sm="7" xs="7" className="DeatSub"   lang={this.props.lang.lang}>{item.PEmployment.map((person, index) => (
          
          <Tag
              
             
               key={person.id}>
               {person.Name}
           </Tag>
       ))}
                     </Col>
                    </Row>
                    <Row style={{marginLeft:5,marginRight:5}} >
                     <Col lg="2" md="5" sm="5" xs="5" className="DeatMain" lang={this.props.lang.lang}>{this.props.lang.Patner}:
                     </Col>
                     <Col lg="10" md="7" sm="7" xs="7" className="DeatSub" lang={this.props.lang.lang}>{item.About}
                     </Col>
                    
                    </Row>
          
          
      
          </Card>
</div>
          )}
          <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
          </Modal>
          <Modal
                    centered
                    visible={this.state.exceedWA}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                    <img src={require('../../images/limit.png')} style={{ height: 100, width: 100,marginBottom:20}} alt="success" />
                      <h6>Your limit to send the Whatsapp Message has been expired. Click the below button to topup the contact.</h6><hr/>
                      <h6>താങ്കൾക്ക് Whatsapp Message അയക്കാനുള്ള ഉള്ള പരിധി കഴിഞ്ഞിരിക്കുന്നു. Whatsapp Message ടോപ് അപ്പ് ചെയ്യുവാനായി താഴെ നൽകിയിരിക്കുന്ന ബട്ടൺ ക്ലിക്ക് ചെയ്യുക.</h6>
                      <Row><Col>  <button className="DetailPageWhatsApp"   onClick={() => { this.TopUpSMS() }}>
                            Topup
                            </button>
                            </Col>
                            <Col>  <button className="DetailPageWhatsApp"   onClick={() => { this.setState({ exceedWA: false }) }}>
                            Close
                            </button>
                            </Col>
                            </Row> 
                    </div>
                </Modal>
                <Modal
                    centered
                    visible={this.state.exceedSMS}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                    <img src={require('../../images/limit.png')} style={{ height: 100, width: 100,marginBottom:20}} alt="success" />
                      <h6>Your limit to send the SMS has been expired. Click the below button to topup the SMS.</h6><hr/>
                      <h6>താങ്കൾക്ക് SMS അയക്കാനുള്ള ഉള്ള പരിധി കഴിഞ്ഞിരിക്കുന്നു. SMS ടോപ് അപ്പ് ചെയ്യുവാനായി താഴെ നൽകിയിരിക്കുന്ന ബട്ടൺ ക്ലിക്ക് ചെയ്യുക.</h6>
                     <Row><Col>  <button className="DetailPageWhatsApp"   onClick={() => { this.TopUpSMS() }}>
                            Topup
                            </button>
                            </Col>
                            <Col>  <button className="DetailPageWhatsApp"   onClick={() => { this.setState({ exceedSMS: false }) }}>
                            Close
                            </button>
                            </Col>
                            </Row> 
                    </div>
                </Modal>
                <Modal
                    centered
                    visible={this.state.exceedContact}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                    <img src={require('../../images/limit.png')} style={{ height: 100, width: 100,marginBottom:20}} alt="success" />
                      <h6>Your limit to view the profile contact has been expired. Click the below button to top up the contact.</h6><hr/>
                      <h6>താങ്കൾക്ക് പ്രൊഫൈൽ കോൺടാക്ട് കാണുവാൻ ഉള്ള പരിധി കഴിഞ്ഞിരിക്കുന്നു. കോൺടാക്ട് ടോപ് അപ്പ് ചെയ്യുവാനായി താഴെ നൽകിയിരിക്കുന്ന ബട്ടൺ ക്ലിക്ക് ചെയ്യുക.</h6>
                     <Row><Col>  <button className="DetailPageWhatsApp"   onClick={() => { this.TopUpSMS() }}>
                            Topup
                            </button>
                            </Col>
                            <Col>  <button className="DetailPageWhatsApp"   onClick={() => { this.setState({ exceedContact: false }) }}>
                            Close
                            </button>
                            </Col>
                            </Row> 
                    </div>
                </Modal>
                <Modal
                    centered
                    visible={this.state.ConLock}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                    <img src={require('../../images/privacy.png')} style={{ height: 100, width: 100,marginBottom:20}} alt="success" />
                      <h6> This profile is locked. If you would like to see the contact details please let them know of interest. If they have accepted, you can view their profile details.</h6><hr/>
                      <h6 style={{fontSize:14,lineHeight:1.5}}>  ഈ പ്രൊഫൈൽ ലോക്ക് ചെയ്തിരിക്കുന്നു.നിങ്ങൾക്കു ഈ കോൺടാക്ട് കാണണമെങ്കിൽ Interest അറിയിക്കുക. Interest അവർ അംഗീകരിച്ചാൽ കോൺടാക്ട് നിങ്ങൾക്ക് കാണാൻ സാധിക്കും.</h6>
                        
                            <Button  onClick={() => { this.setState({ ConLock: false }) }}>Close</Button>
                    </div>
                </Modal>
                <Modal
                  
                    centered
                    visible={this.state.askshow}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={500}
                    title={this.props.lang.DetailPageViewCounter}
                >
                    <div className="aligitemncenter">
                 
                    <center>
                    <h5 lang={this.props.lang.lang} >{this.props.lang.Balance} Contact View: {this.state.remaining_view} Nos</h5>  </center>


                    <h6 lang={this.props.lang.lang} >{this.props.lang.DetailPageMsg}</h6><hr/>
              
                       <Row><Col>
                            <button  className="DetailPageContactUnlock" onClick={() => {this.view_contact()}}
                    >View Contact</button>
             </Col>   <Col>  
                            <button className="DetailPageContactLock" onClick={() => { this.setState({ askshow: false }) }}>
                           Close
                            </button>
                            </Col> </Row>
                    </div>
                    
                   
                </Modal>

                <Modal
                    centered
                    visible={this.state.askWhatsapp}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={500}
                    title={this.props.lang.DetailPageViewCounter}
                >
                    <div className="aligitemncenter">
                  
                    <center>  <h5 lang={this.props.lang.lang} >{this.props.lang.Balance} Whatsapp: {this.state.remaining_view} Nos</h5>  
                                           </center>


                    <h6 lang={this.props.lang.lang} >{this.props.lang.DetailPageMsg}</h6><hr/>
              
                       <Row><Col>

                       <button  className="DetailPageWhatsApp" onClick={() =>  {this.WhatsappViewAndSend()}}
                    > {this.props.lang.Send}</button>
                  
                             </Col>   <Col>  
                            <button className="DetailPageContactLock" onClick={() => { this.setState({ askWhatsapp: false }) }}>
                           {this.props.lang.Close}
                            </button>
                            </Col> </Row>
                    </div>
                    
                   
                </Modal>
                <Modal
                    centered
                    visible={this.state.AskSMS}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={500}
                    title={this.props.lang.DetailPageSMSCounter}
                >
                    <div className="aligitemncenter">
                   
                    <center>
                                          
                                            <h5 lang={this.props.lang.lang} >{this.props.lang.Balance} SMS: {this.state.SMS_view} Nos</h5>  
                                            </center>

                                           
                   
                    <h6 style={{marginTop:15}}lang={this.props.lang.lang} >{this.props.lang.DetailPageMsgSMS}</h6>
                        <Divider style={{margin:10}}></Divider>
                        <Row><Col>
                        <button   onClick={() =>  {this.ViewSMS()}}
                   className="DetailPageSMS" >{this.props.lang.Send}</button> 
                </Col><Col>
                <button  onClick={() => { this.setState({ AskSMS: false }) }}
                   className="DetailPageSMS" >{this.props.lang.Close}</button> 
                            
                            </Col>
                            </Row>
                    </div>
                </Modal>
                <Modal
                style={{marginTop:75}}
                    centered
                    visible={this.state.ModalMSGReply}
                    footer={false}
                    title={this.props.lang.RplyMsg}
                    width={800}
                    closable={false}
                >
                    <div>
                   
                    <h6 style={{marginTop:-10,marginBottom:15}}lang={this.props.lang.lang} >{this.props.lang.RplyMsgDis}</h6>
                  
                    <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} onSubmit={this.handleSubmit}>
                    <Form.Item label={this.props.lang.Template}  style={{marginBottom:5}}>
          {getFieldDecorator('Template', {
            rules: [{ required: false, message: 'Please select your Message Template!' }],
          })(
            <Select  
              placeholder={this.props.lang.SelectPlaceholder}
              onChange={this.handleSelectChange}

            >
               {this.state.msgTempRplydb.map((item) => (
                                                        <Option value={item.Name} key={item.Name}>{item.Name}</Option>
                                                    ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={this.props.lang.Messages}>
          {getFieldDecorator('noteRply', {
            
            rules: [{ required: true, message: 'Please input your Messages!' }],
          })(
          
            <TextArea 
                                                name="noteRply"
                                                maxLength='500' 
                                                value={this.state.noteRply}
                                                onChange={this.handleChange}
                                                placeholder={this.props.lang.MsgPlaceholder}
                                              
                                                autosize={{ minRows: 4, maxRows: 6 }} />
         )}
        </Form.Item>
     
      
      </Form>


                 
                       
                        <Row>
                        <Col sm="0" xs="0" sm="2" md="2" lg="2" xl="2"> </Col>
                          <Col>
                        <button   onClick={() =>  {this.SendMsgRply()}} 
                   className="DetailPageSMS" >Send Reply</button> 
                </Col><Col>
                <button  onClick={() => { this.setState({ ModalMSGReply: false }) }}
                   className="DetailPageSMS" >Close</button> 
                            
                            </Col>
                            <Col sm="0" xs="0" sm="2" md="2" lg="2" xl="2"> </Col>
                            </Row>

                           
                           
                    </div>
                </Modal>
                <Modal
                style={{marginTop:75}}
                    centered
                    visible={this.state.ModalMSG}
                   footer={false}
                    width={800}
                    title={this.props.lang.SendMsg}
                    onCancel={this.handleCancel}
                >
                    <div>
                   
                    <h6 style={{marginTop:-10,marginBottom:15}} lang={this.props.lang.lang} >{this.props.lang.MsgDis}</h6>
                  
                    <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} onSubmit={this.handleSubmit}>
                    <Form.Item label={this.props.lang.Template} style={{marginBottom:5}}>
          {getFieldDecorator('Template', {
            rules: [{ required: false, message: 'Please select your Message Template!' }],
          })(
            <Select  
              placeholder={this.props.lang.SelectPlaceholder}
              onChange={this.handleSelectChange}

            >
              {this.state.msgTempdb.map((item) => (
                                                        <Option value={item.Name} key={item.Name}>{item.Name}</Option>
                                                    ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={this.props.lang.Messages}>
          {getFieldDecorator('note', {
            
            rules: [{ required: true, message: 'Please input your Messages!' }],
          })(
          
            <TextArea 
                                                name="note"
                                                maxLength='500' 
                                                value={this.state.note}
                                                onChange={this.handleChange}
                                                placeholder={this.props.lang.MsgPlaceholder}
                                              
                                                autosize={{ minRows: 4, maxRows: 6 }} />
         )}
        </Form.Item>
     
      
      </Form>
 
    
                       
                          <Row>
                           
                          <Col sm="0" xs="0" sm="2" md="2" lg="2" xl="2"> </Col><Col>
                        <button   onClick={() =>  {this.SendMsg()}} 
                   className="DetailPageSMS" > {this.props.lang.Send}</button> 
                </Col><Col>
                <button  onClick={() => { this.setState({ ModalMSG: false }) }}
                   className="DetailPageSMS" > {this.props.lang.Close}</button> 
                            
                            </Col>
                            <Col sm="0" xs="0" sm="2" md="2" lg="2" xl="2"></Col>
                            </Row>
{this.state.Notifcations && this.state.Notifcations.length > 0 ?

<div>
                            <Divider orientation="left">{this.props.lang.UserMessageSentMsg}</Divider>
                            
                            <List
                  grid={{ gutter: 10,  xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,}}
                    dataSource={Notifcations}
                    renderItem={item => (
                      <List.Item style={{marginBottom:10}}>
                      <Card >
                      <CardBody>
                          <Row>
                            
                            <Col  xs="12" sm="12" md="12" lg="12" xl="12">
                          
                            <CardText>  {item.Msg} </CardText>
                            <small className="text-muted"> {dateFormat((item.SendDate), "yyyy-mmm-dd")}</small>
                           
                            </Col>
                           
                          </Row>
                          {item.RplyDate ? <div>
                          <Divider orientation="left" style={{fontSize:14}}>Reply Message</Divider>

                          <Row>
                          <Col  xs="1" sm="1" md="1" lg="1" xl="1">
                          
                          
                          </Col>
                            <Col  xs="11" sm="11" md="11" lg="11" xl="11">
                          
                            <CardText>  {item.Rply} </CardText>
                            <small className="text-muted">{dateFormat((item.RplyDate), "yyyy-mmm-dd")}</small>
                            
                            </Col>
                           
                          </Row></div>
:
<div></div>
}


                          </CardBody>
                          <CardFooter><Button type="danger" onClick={() =>  {this.DeleteMsgMain(item.id)}} >Delete Message</Button></CardFooter>
                      </Card>
                      </List.Item>
                    )}
                  />

</div>
                  :

                  <div></div>
                  }
{this.state.IncomeMsg && this.state.IncomeMsg.length > 0 ?
                  <div>
<Divider orientation="left">{this.props.lang.RecvMsg}</Divider>
                            <List
                  grid={{ gutter: 10,  xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,}}
                    dataSource={IncomeMsg}
                    renderItem={item => (
                      <List.Item style={{marginBottom:10}}>
                      <Card >
                      <CardBody>
                          <Row>
                            
                            <Col  xs="12" sm="12" md="12" lg="12" xl="12">
                          
                            <CardText>  {item.Msg} </CardText>
         
                            <small className="text-muted">{dateFormat((item.RplyDate), "yyyy-mmm-dd")}</small>
                            </Col>
                           
                          </Row>
                          {item.RplyDate ? <div>
                          <Divider orientation="left" style={{fontSize:14}}>{this.props.lang.RplyMsg}</Divider>

                          <Row>
                          <Col  xs="1" sm="1" md="1" lg="1" xl="1">
                          
                          
                          </Col>
                            <Col  xs="11" sm="11" md="11" lg="11" xl="11">
                          
                            <CardText>  {item.Rply} </CardText>
                            <small className="text-muted">{dateFormat((item.RplyDate), "yyyy-mmm-dd")}</small>
                            
                            </Col>
                           
                          </Row></div>
:
<div></div>
}


                          </CardBody>
                          <CardFooter>
                          {item.RplyDate ? 
                             <Button type="danger" onClick={() =>  {this.DeleteMsg(item.id)}} >{this.props.lang.DeleteReply}</Button>
                 :
                
                   <Button onClick={() => { this.setState({ ModalMSGReply: true,MsgRplyId:item.id }) }}>{this.props.lang.Reply}</Button>
                
                          }

                  </CardFooter>
                      </Card>
                      </List.Item>
                    )}
                  />


</div>

:
<div></div>
}
                    </div>
                </Modal>
              

  
                <Modal
                style={{marginTop:75}}
                    centered
                    visible={this.state.ModalProfileReport}
                    footer={false}
                    closable={false}
                    width={800}
                >
                    <div>
                    <h5 style={{margin:10,marginTop:5}}>Report this profile to admin</h5>
                    <h6 style={{marginTop:15,marginBottom:1,margin:10}} >If someone is calling and disturbing you through profile calling, you can use the new feature contact view lock. If you use it, only the people whom you have partner filtered will see your contact.</h6>
                  
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} >
                    <Form.Item label="Profile Report Reason" style={{marginBottom:5}}>
          {getFieldDecorator('ProReportSelect', {
            rules: [{ required: false, message: 'Please select Profile Report Reason!' }],
          })(
                    <Select  placeholder="Select Profile Report Reason"
                    onChange={this.ProReportReasonSelect} >
     {this.state.ProfileReportReasonDB.map((item) => (
                                                        <Option value={item.Name} key={item.Name}>{item.Name}</Option>
                                                    ))}
    </Select>
    ,
          )}
        </Form.Item>
      
        <Form.Item label="Any comments">
        
            <TextArea 
                                                name="CommentProfileReport"
                                                maxLength='500' 
                                                value={this.state.CommentProfileReport}
                                                onChange={this.onChangeText}
                                                placeholder="Any comments about this profile"
                                              
                                                autosize={{ minRows: 4, maxRows: 6 }} />
        
        </Form.Item>
     
    
      </Form>


                 
                       
                        <Row><Col>
                        <button   onClick={() =>  {this.SendProfileReport()}} 
                   className="DetailPageSMS" >Submit Report</button> 
                </Col><Col>
                <button  onClick={() => { this.setState({ ModalProfileReport: false }) }}
                   className="DetailPageSMS" >Close</button> 
                            
                            </Col>
                            </Row>

                           
                           
                    </div>
                </Modal>

                <Modal
                            title="Contact Details"
                            centered
                            footer={false}
                            onCancel={() => this.setState({ ModalContactDetails: false })}
                            width={350}
                            visible={this.state.ModalContactDetails}
                        >
              
              {this.state.items.map((item) =>
          <div>
            <h6>Contact Number: {item.PNumber}</h6>
            <h6>  Email:{item.Email}</h6>
         </div>
        
          )}
            
                    
                    
                </Modal>

                <Modal
                            title="Sorry"
                            centered
                            footer={false}
                            onCancel={() => this.setState({ ModalAccountOff: false })}
                            width={350}
                            visible={this.state.ModalAccountOff}
                        >
              
             
          <div>
            <h6>This profile is no longer available</h6>
           
         </div>
        
        
            
                    
                    
                </Modal>

                
        </div>
      </div>



</Container>
</Content>
    )
  }
}
function mapStateToProps(state) {
  return {
    User: state.User,
    dropdown: state.dropdown,
    lang:state.lang,
    Shortlist: state.Shortlist,
    Intrested:state.Intrested
  }
}
const WrappedApp = Form.create({ name: 'coordinated' })(Details);
export default connect(mapStateToProps)(Form.create()(Details));