import React, { Component } from 'react';
import './styles.css';

import { Layout,Tabs,PageHeader } from 'antd';
import { connect } from 'react-redux';

import {Card,CardBody ,Container } from 'reactstrap';

import Deactivated_card from '../../components/Deactivated_card';
import Not_aprooved_card from '../../components/not_aprooved_card';
import Payement_due_card from '../../components/payement_due_card';
import MyMatches from './MyMatches';
import Newest from './Newest';
import SuperMatches from './SuperMatches';
import AdminSugg from './AdminSugg';
const { Content } = Layout;
const { TabPane } = Tabs;


  
class UserMyMatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
        
            isLoaded: false,
      
            Activated: false,
            deactivated: false,
            not_aprooved: false,
            payment_due: false,

        };
        this.arrayholder = [];
    }
    componentDidMount = () => {
    
        
      
    }



    render() {

        const {  mode } = this.state;
        return (
        
          
            
            <Content style={{ background: '#f2f3f5' }} >
            <PageHeader title={this.props.lang.drawerMyMatches} lang={this.props.lang.lang}  className="UserIntrestDrop" style={{ marginTop:10}}/>
           

                 
             
                                      
                   
{this.props.User.Status === 'D' &&
                            <Deactivated_card />
                        }
                        {this.props.User.Status === 'N' &&
                            <Not_aprooved_card />
                        }
                        {this.props.User.Status === 'P' &&
                            <Payement_due_card />
                        }
                        {this.props.User.Status === 'A' &&
                        <Card style={{margin:10,marginTop:0}}>
           
                        <CardBody style={{paddingTop:0,background:'#fafafa'}}>
                        <Tabs defaultActiveKey="1" tabPosition={mode} tabBarStyle={{ marginTop: 11, fontSize: 10 }} size={'large'} tabBarGutter={0} >
                 
                        <TabPane tab={this.props.lang.Newest} key={1} >
                        <Newest/>
                            </TabPane>
                        <TabPane tab={this.props.lang.drawerMyMatches} key={2} >
                        <MyMatches/>
                            </TabPane>
                            <TabPane tab={this.props.lang.SuperMatches} key={3} >
                        <SuperMatches/>
                            </TabPane>
                    <TabPane tab={this.props.lang.AdminSugg} key={4}>
                    <AdminSugg/>
                    </TabPane>
                   
                   </Tabs>
                   </CardBody>
      </Card>
                        }
                   
                  
                  
        </Content>
     
               
                   

        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,  lang:state.lang
    }
}


export default connect(mapStateToProps)(UserMyMatches);