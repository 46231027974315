import React, { Component } from 'react';
import API from '../../API';
import { Container,CardTitle, CardText } from 'reactstrap';
import { Divider ,Card,message,Progress,Button } from 'antd';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import './styles.css';
import { Link } from 'react-router-dom';

class HomeStati extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            items: [],

        };
    }
    componentDidMount = () => {
        message.config({ top: 100 });
       
        this.setState({ loading: true });
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.GET_UserBasicDetail, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    items: res
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
    }


    render() {
        return (
            <div style={{marginLeft:20,marginRight:20,marginBottom:20}}>
             {this.state.items.map((item) =>
            
                <Card style={{marginTop:13,marginLeft:10,marginRight:10,borderRadius:3}}>
              <div className="web">
                <Row>
                <Col >
             <center><span className="HomeStatiMain" >{item.Status==='A'?'Premium': item.Status==='N'? 'Waiting For Approval': 'Deactive'

             }</span> <br>
       </br><span className="HomeStatiSub" lang={this.props.lang.lang}> {this.props.lang.HomeStatiUser}</span></center>
                </Col>
                <Col >
      <center><span className="HomeStatiMain">{item.ViewContactResult}</span> <br>
       </br> <span className="HomeStatiSub" lang={this.props.lang.lang}> {this.props.lang.HomeStatiViewCOntact}</span></center>
                </Col>
                <Col >
      <center><span className="HomeStatiMain" >{item.ExpDate}</span> <br>
       </br><span className="HomeStatiSub" lang={this.props.lang.lang}> {this.props.lang.HomeStatiDaysRemaining}</span></center>
                </Col>
                
</Row>
<Divider dashed />
<Row>
                <Col >
      <center><span className="HomeStatiMain" >{item.Visitors}</span> <br>
       </br><span className="HomeStatiSub" lang={this.props.lang.lang}> {this.props.lang.HomeStatiProfileVisitors}</span></center>
                </Col>
                <Col >
      <center><span className="HomeStatiMain" >{item.Accepted}</span> <br>
       </br><span className="HomeStatiSub" lang={this.props.lang.lang}> {this.props.lang.HomeStatiInterestAccepted}</span></center>
                </Col>

                <Col >
      <center><span className="HomeStatiMain" >{item.Requested}</span> <br>
       </br><span className="HomeStatiSub" lang={this.props.lang.lang}> {this.props.lang.HomeStatiInterestRequested}</span></center>
                </Col>
                <Col >
            <center><span className="HomeStatiMain" >{item.IntOnYou}</span> <br>
       </br><span className="HomeStatiSub" lang={this.props.lang.lang}> {this.props.lang.HomeStatiInterestedonYou}</span></center>
                </Col>
</Row></div>

<div className="mob">
                <Row>
              <Col xs='4' className="HomeStatiMainmob">{item.Status==='A'?'Premium': item.Status==='N'? 'Waiting For Approval': 'Deactive'}</Col>
              <Col xs='8' className="HomeStatiSub" lang={this.props.lang.lang}>{this.props.lang.HomeStatiUser}</Col>
</Row>
<Row>
              <Col xs='2' className="HomeStatiMainmob">{item.ViewContactResult}</Col>
              <Col xs='10' className="HomeStatiSub" lang={this.props.lang.lang}>{this.props.lang.HomeStatiViewCOntact}</Col>
</Row>        
<Row>
              <Col xs='2' className="HomeStatiMainmob">{item.ExpDate}</Col>
              <Col xs='10' className="HomeStatiSub" lang={this.props.lang.lang}>{this.props.lang.HomeStatiDaysRemaining}</Col>
</Row>      
<Row>
              <Col xs='2' className="HomeStatiMainmob">{item.Visitors}</Col>
              <Col xs='10' className="HomeStatiSub" lang={this.props.lang.lang}>{this.props.lang.HomeStatiProfileVisitors}</Col>
</Row> 
<Row>
              <Col xs='2' className="HomeStatiMainmob">{item.Accepted}</Col>
              <Col  xs='10' className="HomeStatiSub" lang={this.props.lang.lang}>{this.props.lang.HomeStatiInterestAccepted}</Col>
</Row> 
<Row>
              <Col xs='2' className="HomeStatiMainmob">{item.Requested}</Col>
              <Col xs='10' className="HomeStatiSub" lang={this.props.lang.lang}>{this.props.lang.HomeStatiInterestRequested}</Col>
</Row> 
<Row>
              <Col xs='2' className="HomeStatiMainmob">{item.IntOnYou}</Col>
              <Col  xs='10' className="HomeStatiSub" lang={this.props.lang.lang}>{this.props.lang.HomeStatiInterestedonYou}</Col>
</Row> 
</div>
{ this.props.User.Count >= 0 && this.props.User.Count <= 80 ?
 <div>

 <Divider dashed />

<div style={{fontSize:15,fontWeight:500,float:"left"}}>
Your Profile is {this.props.User.Count}% Complete
</div>
<div style={{float:"right"}}><Link to='/UserApp/UserProfile' >
                                 
                                 <Button type="link">Add Profile Details</Button>
                                                                 </Link></div>


<Progress
strokeColor="red"
 percent={this.props.User.Count} />
<div>
Get more response by adding full details in your profile

</div> 

</div>  
:
null
}
{this.props.User.Count > 80 && this.props.User.Count < 90  ?
    <div>

<Divider dashed />

<div style={{fontSize:15,fontWeight:500,float:"left"}}>
Your Profile is {this.props.User.Count}% Complete
</div>
<div style={{float:"right"}}><Link to='/UserApp/UserProfile' >
                                 
                                 <Button type="link">Add Profile Details</Button>
                                                                 </Link></div>
<Progress
strokeColor="orange"
percent={this.props.User.Count} />
<div>
Get more response by adding full details in your profile
</div>
</div> 
:
null
}

        </Card>
             )}
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        User: state.User,
        lang:state.lang
    }
}

export default connect(mapStateToProps)(HomeStati);