import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ProtectedRouter from './components/ProtectedRouter';
//pages
import Home from './Web/Home/Home';
import Aboutus from './Web/About/about';
import Packages from './Web/Packages/Packages';
import Story from './Web/Story/Story';
import Contact from './Web/Contact/contact';
import Login from './Web/login/Login';
import Register from './Web/Register/Register';
import SearchID from './Web/SearchID/SearchID';
import Msg from './Web/Msg/Msg';
import download from './Web/download/download';
import UserThanks from './components/UserThanks';
import UserFailed from './components/UserFailed';
import DetailswithoutUser from './Web/DetailsPage/Detailswithout_user';
import forgot_password from './Web/forgot_password/forgot_password';
//Policies
import Privacy from './Web/Policies/Privacy';
import Terms from './Web/Policies/Terms';
import Refund from './Web/Policies/Refund';
//login pages
import UserApp from './User/UserApp/userApp';
import AdminLogin from './admin/AdminLogin';
import AdminApp from './admin/adminApp';
import PhotoUpdate from './User/Update_pages/Edit_MyphotosCard';
//end login pages
//end pages 
function Router(props) {
  return <Switch>
    <Switch>
      <Route path='/' component={Home} exact />
      <Route path='/aboutus' component={Aboutus} />
      <Route path='/Packages' component={Packages} />
      <Route path='/Story' component={Story} />
      <Route path='/contact' component={Contact} />
      <Route path='/login' component={Login} />
      <Route path='/Register' component={Register} />
      <Route path='/download' component={download} />
      <Route path='/forgottpassword' component={forgot_password} />
      <ProtectedRouter authed={props.User.auth} path='/UserApp' component={UserApp} />
      <Route path='/ProSearch/:id' component={SearchID} />
      <Route path='/Details/:id' component={DetailswithoutUser} />
      <Route path='/admin' component={AdminLogin} />
      <Route path='/Msg/:id' component={Msg} />
      <ProtectedRouter authed={props.authA.auth} path='/AdminApp' component={AdminApp} />
      <Route path='/UserThanks' component={UserThanks} />
      <Route path='/UserFailed' component={UserFailed} />
      <Route path='/PhotoUpdate' component={PhotoUpdate} />
      <Route path='/Privacy' component={Privacy} />
      <Route path='/Terms' component={Terms} />
      <Route path='/Refund' component={Refund} />
    </Switch>
  </Switch>
}
function mapStateToProps(state) {
  return {
    authA: state.authA,
    User: state.User
  }
}

export default connect(mapStateToProps)(Router);