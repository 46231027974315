
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Col, Row, CardImg, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import styles from './styles';
import { Icon, List, Divider } from 'antd';
class Recommended_ListItem extends Component {
   
    render() {
        return (
            <Container className="rightlistbox">
                <Row style={{marginTop:2}}>
                    <Col sm='8' xs="8" className="rightheading" lang={this.props.lang.lang}>{this.props.lang.RecomendListShortlist}</Col>
                    <Col sm='4' xs="4" className="rightviewall" ><Link to='/UserApp/userShortlist' className="UserHomeShortlistMore" lang={this.props.lang.lang}>{this.props.lang.RecomendListMore}</Link></Col>
                </Row>
                <Divider style={{ marginBottom: 0 ,marginTop:12}} />
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.props.Shortlist.profiles}
                    renderItem={item => (
                        <div className="rightlistitem">
                            <Link to={`/UserApp/Details/${item.user_id}`} target="_blank" style={{textDecoration:'none'}}>
                            <Row>
                                <Col sm='4' xs="4" style={{marginRight:-15}}>
                                    <div> {item.image1 ? <img src={item.image1} className="rightlistimage" alt="prlife" />
                                        : <img src={require('../../images/placeholder.jpg')} className="rightlistimage" alt="prolife" />}</div>
                                </Col>
                                <Col sm='8' xs="8">
                                <div className="rightlistname">{item.Name}</div>
                                <div className="rightlistregisterid">{item.UserId}</div>
                                </Col>
                            </Row>
                            </Link>
                        </div>
                    )}
                />
            
            
            </Container>
        )
    }
}
function mapStateToProps(state) {
    return {
        Shortlist: state.Shortlist,
        lang: state.lang
    }
}



export default connect(mapStateToProps)(Recommended_ListItem);