import React, { Component } from 'react';
import { Container} from 'reactstrap';
import { Card,Divider } from 'antd';
import FooterS from '../../components/Footer';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import './styles.css';
class Aboutus extends Component {
    render() {
        return (
           
            <div>
                 <div className="colorcontainer" style={{ height: 130 }}>
                <Container >
                        <h5 style={{ color: '#fff', paddingTop: 40 }}>{this.props.lang.Homeaboutushead}</h5>
                        <p style={{ color: '#fff', paddingBottom: 10 }}>{this.props.lang.aboutWHoWEAre}</p>
                        </Container>
                    </div>
                    <Container >
               
            
                <p style={{paddingTop: 30}} lang={this.props.lang.lang}> {this.props.lang.Homeaboutustxt} </p>
             
                <ul>
                    <li>കെസിബിസി മലബാർ പ്രോലൈഫ് സമിതിയുടെ നേതൃത്വത്തിൽ വൈദികരുടെ മേൽനോട്ടത്തിലുള്ള ഒരു ക്രിസ്ത്യൻ മാട്രിമോണിയൽ സർവീസ്</li>
                  <li>വെബ്സൈറ്റ് മലയാളത്തിലും ഇംഗ്ലീഷിലും ലഭ്യമാണ്</li>
                  <li>സ്ത്രീകൾക്ക് എല്ലാവിധ സേവനങ്ങളും തികച്ചും സൗജന്യം</li>
                  <li>പുരുഷന്മാർക്ക് വെറും 500 രൂപ മാത്രം</li>
                  <li>അനുയോജ്യമായ പ്രൊഫൈലുകൾ നിങ്ങൾക്കു ലഭ്യമാക്കുന്നു</li>
                  <li>100% സുരക്ഷിതവും സ്വകാര്യതയും ഉറപ്പു നൽകുന്നു</li>
                  <li>ProLifeMarry യിൽ നിന്ന് പങ്കാളിയെകണ്ടെത്തുന്ന സാമ്പത്തികമായി പിന്നോക്കം നിൽക്കുന്ന പെൺകുട്ടികൾക്ക് സാമ്പത്തിക സഹായം നൽകുന്നതാണ്</li>
                  <li>വിവാഹം നടന്നാലും യാതൊരു ഫീസും ഈടാക്കുന്നതല്ല</li>
                  <li>ഭാര്യ/ഭർത്താവ് നഷ്ടപ്പെട്ടവർക്കും വിവാഹമോചനം നേടിയവർക്കും ഈ സേവനം ഉപയോഗിക്കാവുന്നതാണ്</li>
                  <li>വാട്ട്‌സ്ആപ്പ് ചാറ്റ്, SMS , മെസ്സേജ്  അനവധി ആധുനിക സംവിധാനങ്ങൾ വളരെ ലളിതമായി ഉപയോഗിക്കാവുന്ന രീതിയിൽ ലഭ്യമാക്കിയിരിക്കുന്നു</li>
                  <li>വെബ്സൈറ്റ് / മൊബൈൽ ആപ്പ് ലഭ്യമാണ്</li>

                  <li>മൊബൈൽ ആപ്പ് ഡൗൺലോഡ് ലിങ്ക്  <a href="tinyurl.com/prolifemarry">https://tinyurl.com/prolifemarry</a></li>
                  <li>24x7 കോൾ/ചാറ്റ് പിന്തുണ</li>
              </ul>
                <Divider style={{marginTop:0}}/>
          
<Row>
        
<Col > 
<Row>
<Col xs="3" sm="3" md="3" lg="3">
    <img src={require('./fr.jpg')} className="recomend-image" alt="logo" />
    </Col>
    <Col xs="9" sm="9" sm="9" lg="9">
    <h4 style={{paddingTop: 10,paddingBottom:2}}>{this.props.lang.AboutName}   <span style={{fontSize:15}}> {this.props.lang.AboutDirector}</span></h4>
              
               <h6 style={{paddingBottom:10}}><i>{this.props.lang.AboutMsg}</i> </h6>
    </Col>
  
    
</Row>
               
               <p style={{paddingTop: 20,paddingBottom:30,textAlign:"justify",fontSize:14}} lang={this.props.lang.lang}>
                   
  കുടുംബത്തെ "ഗാർഹിക  സഭ" എന്നാണ് രണ്ടാം വത്തിക്കാൻ കൗൺസിൽ വിശേഷിപ്പിക്കുന്നത്  കുടുംബത്തിലാണ് 
  ഒരുവൻ്റെ വിശ്വാസ ജീവിതം മുളപൊട്ടി ഫലം ചുടുന്നത്. എന്നിൽ ആദ്യമാതാപിതാക്കളെ സൃഷ്ട്ടിച്ച് ദൈവത്തിന്റെ 
  സൃഷ്ട്ടി കർമ്മത്തിൽ പങ്കാളിയാകാൻ  ആദ്യ മാതാപിതാക്കളെ ദൈവം ചുമതലപ്പെടുത്തി അനുഗ്രഹിച്ചു. 
  "സന്താനപുഷ്ടിയുള്ളവരായി പെരുകുവിൽ (ഉൽപ 1:28) പ്രായപൂർത്തിയായ സ്ത്രീപുരുഷൻമാർ ദൈവത്തിന്റെ 
  ഈ വിളി സ്വീകരിച്ച് നേരത്തെ തന്നെ വിവാഹ ജീവിതത്തിലേക്ക് പ്രവേശിക്കണമെന്നാണ് ദൈവം ആഗ്രഹിക്കുന്നത്. 
  പകേഷ പല പല കാരണങ്ങൾകൊണ്ട് ഇന്ന് ക്രിസ്ത്യാനികളുടെ ഇടയിൽ വിവാഹ പ്രായം വർദ്ധിക്കുന്നുണ്ട്. 
  വിവാഹ പ്രായം വർദ്ധിക്കുന്നതോടെ അനുയോജ്യരായ പങ്കാളിയെ ലഭിക്കാനുള്ള സാധ്യതയും കുറയുന്നു. 
  അതിനാൽ പങ്കാളിയെ ലഭിക്കാൻ യുവതിയുവാക്കൾ ആധുനിക വിവാഹ ബ്യൂറോകളെയും മാട്രിമോണിയൽ
   സൈറ്റുകളെയുമാണ് ആശ്രയിക്കുന്നത്. പക്ഷേ ഇന്നത്തെ  പല വിവാഹ സൈറ്റുകളും സാധാരണക്കാർക്ക് 
   താങ്ങാവുന്നതിലും കൂടുതൽ ചാർജ്ജ് ഈടാക്കുന്നതാണ്. 
   യുവജനങ്ങളുടെ വിഷമം മനസ്സിലാക്കി മലബാറിലെ പ്രേ- ലൈഫ് സമിതിയുടെ ആഭിമുഖ്യത്തിൽ സഭയിലെ 
   സാധാരണക്കാരന് വളരെ എളുപ്പത്തിൽ ചേരാൻ കഴിയുന്നതും വളരെ ലളിതമായ ഒരു പുതിയ വെബ്സൈറ്റും മൊബൈൽ ആപ്പും
    ആരംഭിച്ചു. Prolifemarry.com യാണ് മലയാളത്തിൽ ലഭ്യമായ ആദ്യത്തെ ക്രിസ്ത്യൻ മാട്രിമോണിയൽ വെബ്സൈറ്റ്. 
    prolifemarry.com ആരംഭിച്ചതിലൂടെ വളരെയേറെപ്പേർക്ക് ഇതുവഴി പങ്കാളിയെ കണ്ടെത്താൻ സാധിച്ചു
     എന്നുള്ളത് സന്തോഷകരമായ കാര്യമാണ്. പെൺകുട്ടികൾക്ക് സൗജന്യ റെജിസ്ട്രഷനാണ് വെബ്സെറ്റ് 
     ഓഫർ ചെയുന്നത്, അതിനാൽ മാതാപിതാക്കൾ മുൻ കൈ എടുത്ത് നമ്മുടെ മക്കളെ പ്രായപൂർത്തിയാകുബോൾ 
     തന്നെ വിവാഹ ജീവിതത്തിലേക്ക്  കൈപിടിച്ച് ഉയർത്തുന്നതിനായ്  Prolifemarry.com ൽ റെജിസ്റ്റർ ചെയുന്നത് നല്ലതാണ്. </p>
     </Col>


            </Row>
                  </Container>

         
            <FooterS/>
        </div>
       
        )
    }
}
function mapStateToProps(state) {
    return {
      
        lang: state.lang
    }
}

export default connect(mapStateToProps )(Aboutus);