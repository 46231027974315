import React, { Component } from 'react';
import { Card, CardBody, Row, Col, CardImg, ButtonGroup } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, DatePicker, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class EditAdmin_EducationWorkCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3
            Education_Category:this.props.data.Education_Category,
             Education: this.props.data.Education,
              Work_Category: this.props.data.Work_Category,
               Working: this.props.data.Working,
               Working_place:this.props.data.Working_place,
               Income: this.props.data.AnnualIncome 
            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'user_id': this.props.data.user_id,
                    'Education_Category':this.state.Education_Category,
                    'Education':this.state.Education,
                    'Work_Category':this.state.Work_Category,
                    'Working':this.state.Working,
                    'Working_place':this.state.Working_place,
                    'Income':this.state.Income
                }
                fetch(API.BASE_URL + API.ADMIN_UPDATE_EDUCATIONWORK, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.props.reload();
                            this.setState({ loading: false });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            <Card style={{ borderRadius: 10 }}>
                <CardBody>
                    <Form {...formItemLayout}>
                            <Form.Item label="Education Category">
                        {getFieldDecorator('Education_Category', {
                            initialValue: this.state.Education_Category,
                            setFieldsValue: this.state.Education_Category,
                            rules: [{ required: true, message: 'Please select Maritial Status!' }],
                        })(
                            <Select 
                            showSearch
                            name="Education_Category" 
                            placeholder="Work Category"
                                onChange={(val) => { this.handleChange({ target: { name: 'Education_Category', value: val } }) }}
                                filterOption={(input, option) =>
                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.dropdown.Education_array.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="Education in Detail" style={{ marginTop: -20 }}>

                        <Input
                            name="Education"
                            maxLength='100' 
                            value={this.state.Education}
                            onChange={this.handleChange}
                            prefix={<Icon type="schedule" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Education"
                        />

                    </Form.Item>
                    
                   
                            <Form.Item label="Work Category"  style={{ marginTop: -20 }}>
                        {getFieldDecorator('Work_Category', {
                            initialValue: this.state.Work_Category,
                            setFieldsValue: this.state.Work_Category,
                            rules: [{ required: true, message: 'Please select Work Category!' }],
                        })(
                            <Select
                            showSearch
                             name="Work_Category"
                              placeholder="Work Category"
                                onChange={(val) => { this.handleChange({ target: { name: 'Work_Category', value: val } }) }}
                                filterOption={(input, option) =>
                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.dropdown.Work_array.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="Working Details" style={{ marginTop: -20 }}>
                        <Input
                            name="Working"
                            maxLength='100' 
                            value={this.state.Working}
                            onChange={this.handleChange}
                            prefix={<Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Working Details" />
                    </Form.Item>

                    <Form.Item label="Working place" style={{ marginTop: -20 }}>
                        {getFieldDecorator('Working_place', {
                            initialValue: this.state.Working_place,
                            setFieldsValue: this.state.Working_place,
                            rules: [{ required: false, message: 'Please select Working place!' }],
                        })(
                            <Select
                                showSearch
                                name={"Working_place"}
                                placeholder="Working place"
                                optionFilterProp="children"
                                onChange={(val) => { this.handleChange({ target: { name: 'Working_place', value: val } }) }}
                                filterOption={(input, option) =>
                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.dropdown.WorkingPlace_array.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Annual Income" style={{ marginTop: -20 }}>
                        <Input
                            name="Income"
                            maxLength='20' 
                            value={this.state.Income}
                            onChange={this.handleChange}
                            prefix={<Icon type="money-collect" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Annual Income" />

                    </Form.Item>

                   

                    <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                                    <ButtonGroup>
                                        <Button type="primary" onClick={() => this.update()}>
                                            Update
                                                </Button>
                                    </ButtonGroup>
                                </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(EditAdmin_EducationWorkCard));