import * as types from './Types';
export const saveDropDowns = (data) => ({
    type: types.SAVE_DROPDOWNS,
    payload: data
});







