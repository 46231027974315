import React, { Component } from 'react';
import './styles.css'
import { Row, Col } from 'reactstrap';
import { message, List, Layout, Input, Select, PageHeader,Tabs } from 'antd';
import {
    Card,  CardBody,
  Container 
  } from 'reactstrap';

import ListItem2 from '../../components/ListItem2/Lisitem2';
import Loadingplaceholder from '../../components/Loading_placeholder';
import Deactivated_card from '../../components/Deactivated_card';
import Not_aprooved_card from '../../components/not_aprooved_card';
import Payement_due_card from '../../components/payement_due_card';
import API from '../../API';
import { connect } from 'react-redux';
import {intrestedrefreshsucessServices} from '../../redux/Services/IntrestedSerives';
const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
class UserIntrested extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            activeTab: '1',
            Intrested: [],
            requested: [],
            Activated: false,
            deactivated: false,
            not_aprooved: false,
            payment_due: false,
        };
        this.arrayIntrested = [];
        this.arrayrequested = [];
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentDidMount(){
        this.loaddata()
    }
    loaddata = () => {
        message.config({ top: 100 });
        if (this.props.User.Status === 'A') {/// ---A--- activated account
            this.setState({ Activated: true });
            let obj = { user_id: this.props.User.user_id }
            fetch(API.BASE_URL + API.GET_INTRESTED, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify(obj)
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // message.success('success');
                        this.setState({
                            isLoaded: false,
                            Intrested: result.intersted_array,
                            requested: result.requested_array

                        },
                            function () {
                                this.arrayIntrested = result.intersted_array;
                                this.arrayrequested = result.requested_array;
                            });
                    },
                    (error) => {
                        message.error('Loading faild');
                        alert(error);
                        this.setState({
                            isLoaded: false,
                            error
                        });
                    }
                )
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.Intrested.refresh === true) {
          this.loaddata();
          this.props.intrestedrefreshsucessServices();
        }
      }

    //input text
    SearchIntrested(text) {
        const newData = this.arrayIntrested.filter(function (item) {
            const itemData = item.Name.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ Intrested: newData, text: text })
    }
    Searchrequested(text) {
        const newData = this.arrayrequested.filter(function (item) {
            const itemData = item.Name.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ requested: newData, text: text })
    }
    //end inputtext

    //dropdowns
    SelectIntrested(text) {
        if (text.target.value === 'All') {
            this.componentDidMount();
        }
        else {
            const newData = this.arrayIntrested.filter(function (item) {
                const itemData = item.Istatus.toUpperCase()
                const textData = text.target.value.toUpperCase()
                return itemData.indexOf(textData) > -1
            })
            this.setState({ Intrested: newData, text: text })
        }
    }
    Selectequested(text) {
        if (text.target.value === 'All') {
            this.componentDidMount();
        }
        else {
            const newData = this.arrayrequested.filter(function (item) {
                const itemData = item.Istatus.toUpperCase()
                const textData = text.target.value.toUpperCase()
                return itemData.indexOf(textData) > -1
            })
            this.setState({ requested: newData, text: text })
        }
    }
    //end dropdowns
    render() {
        const { isLoaded, Intrested, requested, deactivated, not_aprooved, payment_due, Activated } = this.state;
        const {  mode } = this.state;
        return (
            <Content  style={{ background: '#f2f3f5' }}>
         <PageHeader title={this.props.lang.drawerIntrested} lang={this.props.lang.lang} className="UserIntrestDrop" style={{ marginTop:10}}/>
       
           
            <Card style={{margin:10,marginTop:0}}>
           
            <CardBody style={{paddingTop:0,background:'#fafafa'}}>
           

                        {this.props.User.Status === 'D' &&
                            <Deactivated_card />
                        }
                        {this.props.User.Status === 'N' &&
                            <Not_aprooved_card />
                        }
                        {this.props.User.Status === 'P' &&
                            <Payement_due_card />
                        }
                        {this.props.User.Status === 'A' &&
<Tabs defaultActiveKey="1" tabPosition={mode} tabBarStyle={{ marginTop: 11, fontSize: 10 }} size={'large'} tabBarGutter={0}>
                    <TabPane tab={this.props.lang.UserInterestIntrestOnu} key={1} style={{ fontSize: 22 }}>
                      
                        <Row style={{ marginTop: 10, marginBottom: 10 ,background:'#fafafa'}}>
                                      <Col sm="3" ></Col>
                                        <Col sm="6" ><Input placeholder={this.props.lang.UserInterestPlaceHolder} lang={this.props.lang.lang} className="UserIntrestDrop"
                                            onChange={(text) => this.SearchIntrested(text)} /></Col>
                                        <Col sm="3">
                                            <Select defaultValue="All" style={{ width: 140 }} onChange={(val) => { this.SelectIntrested({ target: { value: val } }) }}>
                                                <Option value="All" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestAll}</Option>
                                                <Option value="Requested" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestReq}</Option>
                                                <Option value="Accepted" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestAcc} </Option>
                                                <Option value="Rejected" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestRej}</Option>
                                            </Select>
                                        </Col>
                                    </Row>
<div style={{marginBottom:20}}>
                                    {isLoaded
                                        ? <Loadingplaceholder />
                                        : <List
                                            itemLayout="vertical"
                                            size="large"
                                            dataSource={Intrested}
                                            renderItem={item => (
                                                <ListItem2 item={item} />
                                            )}
                                        />
                                    }
                       </div>

                    </TabPane>
                    <TabPane tab={this.props.lang.UserInterestRequest} key={2}>
                   
                                    <Row style={{ marginTop: 10, marginBottom: 10 ,background:'#fafafa'}}>
                                      <Col sm="3" ></Col>
                                        <Col sm="6" ><Input placeholder={this.props.lang.UserInterestPlaceHolder} lang={this.props.lang.lang} className="UserIntrestDrop"
                                            onChange={(text) => this.Searchrequested(text)} /></Col>
                                        <Col sm="3">
                                            <Select defaultValue="All" style={{ width: 140 }} onChange={(val) => { this.Selectequested({ target: { value: val } }) }}>
                                                <Option value="All" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestAll}</Option>
                                                <Option value="Requested" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestReq}</Option>
                                                <Option value="Accepted" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestAcc} </Option>
                                                <Option value="Rejected" lang={this.props.lang.lang} className="UserIntrestDrop">{this.props.lang.UserInterestRej}</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <div style={{marginBottom:20}}>
                                    {isLoaded
                                        ? <Loadingplaceholder />
                                        : <List
                                            itemLayout="vertical"
                                            size="large"
                                            dataSource={requested}
                                            renderItem={item => (
                                                <ListItem2 item={item} />
                                            )}
                                        />
                                    }
                                    </div>
                    </TabPane>
                   
                </Tabs>

                                }
         
      
         </CardBody>
      </Card>
      
        </Content>
        );
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        Intrested:state.Intrested,
        lang:state.lang
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        intrestedrefreshsucessServices: () => {
        dispatch(intrestedrefreshsucessServices());
      }
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(UserIntrested);