import React, { Component } from 'react';
import { Card, Container, Col, Row, CardTitle, CardText, CardImg, CardImgOverlay, CardSubtitle, CardBody } from 'reactstrap';
import { Upload, Icon, message, Modal, Button,Radio,Form } from 'antd';
import ImgCrop from 'antd-img-crop';
import moment from 'moment';
import { connect } from 'react-redux';
import API from '../../API';
const ButtonGroup = Button.Group;
class EditAdmin_IdProof extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            issuccess: false,
            current: 0,
            value: '',
            user_id: null,
            date: this.props.data.DOB,
            //step1
            IdProofStatus: this.props.data.IdProofStatus
           
            //end step1
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    state = {
        loading: false,
        selectedFile: null,
        previewVisible: false,
        previewImage: '',
        image1: null, image2: null

    };

    componentDidMount = () => {
        this.handleChanges()
    }
    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    customRequest = (files, { file }) => {
        this.setState({ loading: true });
        const formData = new FormData()
        formData.append('user_id', this.props.data.user_id);
        formData.append('image_id', files.target.image_id);
        formData.append('file', file);
        fetch(API.BASE_URL + API.UPLOAD_IMAGEID, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(responsesJson => {
                if (responsesJson.status === 'success') {
                    this.handleChanges();
                }
                else {
                    alert("try again");
                }

            })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handleChanges = () => {
        let obj = { user_id: this.props.data.user_id }
        fetch(API.BASE_URL + API.ALL_IDIMAGES, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                this.setState({
                    loading: false,
                    image1: responsesJson.image1,
                    image2: responsesJson.image2

                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    deleteImage = (imageId, image_value) => {
        let obj = {
            user_id: this.props.data.user_id,
            image_id: imageId,
            image: image_value
        }
        fetch(API.BASE_URL + API.DELETE_IMAGEID, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    this.handleChanges();
                }
                else {
                    alert("try again");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    user_id: this.props.data.user_id,
                    IdProofStatus: this.state.IdProofStatus
                   
                }
                fetch(API.BASE_URL + API.ADMIN_UPDATE_IdProofStatus, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.props.reload();
                            this.setState({ loading: false });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            <Card style={{ borderRadius: 10 }}>
                <CardBody>
                    <p style={{ fontSize: 17, fontWeight: 'bolder' }}>Upload Your ID Proof</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Row>
                        <Col sm="12" xs="12">
                            <Card style={{ marginBottom: 10, borderRadius: 10 }}>
                                <CardBody>
                                <Form {...formItemLayout}>
                    <Form.Item label="Id Proof Status" >
                    { getFieldDecorator('IdProofStatus', {
                                initialValue: this.state.IdProofStatus,
                                setFieldsValue: this.state.IdProofStatus,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please select Id Proof Status!' }],
                            })(
                                <Radio.Group name="IdProofStatus">
                                    <Radio.Button value="A">Approved</Radio.Button>
                                    <Radio.Button value="R">Rejected</Radio.Button>
                                    <Radio.Button value="W">Waiting For Approval</Radio.Button>
                                    <Radio.Button value="N">New</Radio.Button>
                                </Radio.Group>
                          ,
                          )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={() => this.update()}>
                                    Update
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                        </Form>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6" xs="12">
                            {this.state.image1 ?
                                <Card inverse>
                                    <CardImg width="100%" height="325" src={this.state.image1} alt="Card image cap" style={{ borderRadius: 10 }} />
                                    <CardImgOverlay className="aligncenter">
                                        <CardTitle>Id Proof</CardTitle>
                                        <Button ghost shape="round" style={{ marginRight: 10 }}
                                            onClick={() => { this.setState({ previewImage: this.state.image1, previewVisible: true }) }}>View</Button>
                                        <Button ghost shape="round" onClick={() => { this.deleteImage('image1', this.state.image1) }}>Delete</Button>
                                    </CardImgOverlay>
                                </Card> :
                                <Card style={{ borderRadius: 10 }}>
                                    
                                        <Upload
                                            name="file"
                                            className="imageupload height"
                                            showUploadList={false}
                                            beforeUpload={this.beforeUpload}
                                            customRequest={(file) => { this.customRequest({ target: { image_id: 'image1' } }, file) }}>
                                            <div className="aligncenter">
                                                <Icon type={this.state.loading ? 'loading' : 'plus'} style={{ margin: 30, marginBottom: 10, fontSize: 30 }} />
                                                <h5 className="ant-upload-text" tyle={{ margin: 50 }}>Upload Id proof - Front Side</h5>
                                            </div>
                                        </Upload>
                                   
                                </Card>
                            }
                        </Col>
                        <Col sm="6" xs="12">
                            {this.state.image2 ?
                                <Card inverse>
                                    <CardImg width="100%" height="325" src={this.state.image2} alt="Card image cap" style={{ borderRadius: 10 }} />
                                    <CardImgOverlay className="aligncenter">
                                        <CardTitle>Id Proof</CardTitle>
                                        <Button ghost shape="round" style={{ marginRight: 10 }}
                                            onClick={() => { this.setState({ previewImage: this.state.image2, previewVisible: true }) }}>View</Button>
                                        <Button ghost shape="round" onClick={() => { this.deleteImage('image2', this.state.image2) }}>Delete</Button>
                                    </CardImgOverlay>
                                </Card> :
                                <Card style={{ borderRadius: 10 }}>
                                   
                                        <Upload
                                            name="file"
                                            className="imageupload height"
                                            showUploadList={false}
                                            beforeUpload={this.beforeUpload}
                                            customRequest={(file) => { this.customRequest({ target: { image_id: 'image2' } }, file) }}>
                                            <div className="aligncenter">
                                                <Icon type={this.state.loading ? 'loading' : 'plus'} style={{ margin: 30, marginBottom: 10, fontSize: 30 }} />
                                                <h5 className="ant-upload-text" tyle={{ margin: 50 }}>Upload Id proof -  Back Side</h5>
                                            </div>
                                        </Upload>
                                   
                                </Card>
                            }
                        </Col>
                    </Row>
                    <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                    </Modal>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown
    }
}

export default connect(mapStateToProps)(Form.create()(EditAdmin_IdProof));