

import React, { Component } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Button, Modal, Form, Input, Icon, message, Popover, Table, Popconfirm, Select, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import {Switch,Route} from 'react-router-dom';
import API from '../API';
import classnames from 'classnames';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
const { Option } = Select;
const { TextArea } = Input;
class AdminSearchResult extends Component {
    
     
    showModal = (record) => {
        this.setState({ 
            UserId: record.id,
            visible: true,
           
        });
        
            message.config({ top: 100 });
        
      let obj = { LogId : record.id };
       fetch(API.BASE_URL + API.GET_User_Log, {
           method: 'POST',
           headers: new Headers({
               'Content-Type': 'application/x-www-form-urlencoded',
           }),
           body: JSON.stringify(obj)
       }).then((response) => response.json())
           .then((responsesJson) => {
               this.setState({ LogData: responsesJson, loading: false },
                   function () { this.arrayholder = responsesJson });
           })
           .catch((error) => {
               //message.error('Error. Try again !');
           });
          
      };
      
      showModalSMS = (record) => {
       
        this.setState({
           
          visibleSMSModal: true,
          UserId: record.id,
          Name:record.Name,
           MainMob: record.MainMob
           

        });
      };
     
     
    constructor(props) {
        super(props);

     
        this.state = {
            loading: true,
            error: false,
            activeTab: 'All',
            data: [],
            visibleSMSModal: false,
            loading: true,
            issuccess: false,
            Age_from: this.props.match.params.Age_from,
            Age_to: this.props.match.params.Age_to,
            Diocese: this.props.match.params.Diocese,
            Height_from: this.props.match.params.H_from,
            Height_to: this.props.match.params.H_to,
            M_status: this.props.match.params.M_status,
            W_place: this.props.match.params.W_place,
            p_status: this.props.match.params.p_status,
            E_cat: this.props.match.params.E_cat,
            W_cat: this.props.match.params.W_cat,
            Religion: this.props.match.params.Religion,
            Place: this.props.match.params.Place,
            FStatus:this.props.match.params.FStatus, 
             Gender:this.props.match.params.Gender,
            items: []
          
        };
        this.arrayholder = [];
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    handleCancel = () => {
        this.setState({ visible: false });
      };
      handleCancelCustomSMS = () => {
        this.setState({ visibleSMSModal: false });
      };
    componentDidMount = () => {
        message.config({ top: 100 });

        let obj = {
           
            Diocese: this.state.Diocese,
            Age_from: this.state.Age_from,
            Age_to: this.state.Age_to,
            Height_from: this.state.Height_from,
            Height_to: this.state.Height_to,
            M_status: this.state.M_status,
            W_place: this.state.W_place,
            p_status: this.state.p_status,
            E_cat: this.state.E_cat,
            W_cat: this.state.W_cat,
Religion:this.state.Religion,
            Place: this.state.Place,
            FStatus:this.state.FStatus,
            Gender:this.state.Gender,
        }

        fetch(API.BASE_URL + API.GET_USERS_Search, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data: responsesJson, loading: false },
                    function () { this.arrayholder = responsesJson });
            })
            .catch((error) => {
                //message.error('Error. Try again !');
            });
    }



   
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    delete_item = (data) => {
        this.setState({ loading: true });
        let obj = { user_id: data.id };
        fetch(API.BASE_URL + API.DELETE_USERS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Deleted Successfully');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }
    UserLog = (data) => {
        this.setState({ loading: true });
        let obj = { UserId: this.state.UserId, LogType: this.state.LogType, LogContent: this.state.LogContent, LogDate: this.state.DPLog };
        fetch(API.BASE_URL + API.UserLog, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Send Successfully');
                  
                    this.setState({ loading: false, form: false });
                    
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }

    

    send_sms = (data) => {
        this.setState({ loading: true });
        let obj = { UserId: data.UserId, Name: data.Name, MainMob: data.MainMob, Password: data.Password,id: data.id  };
        fetch(API.BASE_URL + API.SEND_SMS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Send Successfully');
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }

    send_Custom_sms = (data) => {
        this.setState({ loading: true });
        let obj = {  Name: this.state.Name, MainMob: this.state.MainMob, Msg: this.state.SMSContent };
        fetch(API.BASE_URL + API.SEND_SMS_CUSTOM, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Send Successfully');
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }

    Activation = (data, action) => {
        this.setState({ loading: true });
        let obj = { action_type: action, user_id: data.id };
        fetch(API.BASE_URL + API.ACTIVATION, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('success');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }
    OnOff = (data, action) => {
        this.setState({ loading: true });
        let obj = { OnOff_type: action, user_id: data.id };
        fetch(API.BASE_URL + API.OnOff, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('success');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }

   
  
    //search function
    SearchFilterFunction(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.Name.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    SearchFiltergisterID(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.UserId.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    SearchFiltergisterphone(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.MainMob.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    SearchFilterDate(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.RegDate.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    //end search function 
    ondateChangePayed = (value) => {
        if (value != null) {
            this.setState({ DPLog: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ DPLog: null, date: null });
        }
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    render() {
        const { visible, loading } = this.state;
        const columns = [
            { title: 'No', dataIndex: 'id', key: 'id' },
            { title: 'Image', dataIndex: 'image1', 
            render:  (value, record) => <img style={{ width:150 }} src={record.image1} />
        },
            { title: 'User ID', dataIndex: 'UserId', key: 'UserId' },
            {
                title: 'Name', dataIndex: 'Name', key: 'Name',
                onFilter: (value, record) => record.Name.indexOf(value) === 0,
                sorter: (a, b) => a.Name.length - b.Name.length,
                sortDirections: ['descend']
            },
           
            { title: 'Mobile Numer', dataIndex: 'MainMob', key: 'MainMob' },
       
            { title: 'Reg Date', dataIndex: 'RegDate', key: 'RegDate', ...this.getColumnSearchProps('RegDate') },
           
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (value, record) =>
                    <div>
                       

                         <Link to={`/AdminApp/AdminUserprofiles/${record.id}`} target="_blank" style={{ textDecoration: 'none' }}>
                                    <Button  ghost size="small"   type="primary">Edit</Button></Link>
                                  
                                      <Popover content={
                            <div>
                                 <Button ghost size="small" style={{ marginRight: 10 }} type="primary"  onClick={() => { this.showModal(record) }}>
        Log
        </Button>
                                   <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => this.send_sms(record)}>SMS</Button>
                                 
        <Button type="primary" style={{ marginRight: 10 }} ghost size="small" onClick={() => { this.showModalSMS(record) }}>
        Customized SMS
        </Button>
        <a href={`https://wa.me/91${record.MainMob}`}  target="_blank" style={{  border: '1px solid #1890ff', marginRight: '5px', padding:'3px'}} >Msg Whatsapp 1</a>

<a href={`https://wa.me/91${record.PNumber}`}  target="_blank" style={{  border: '1px solid #1890ff', marginRight: '5px', padding:'3px'}} >Msg Whatsapp 2</a>

                                    <Popconfirm title="Sure to delete?" onConfirm={() => this.delete_item(record)}>
                            <Button ghost size="small" type="danger">Remove</Button>
                        </Popconfirm>
                            </div>
                        } trigger="hover">
                            <Button ghost size="small" style={{ marginLeft: 10, marginRight: 10 }} type="primary">...</Button>
                        </Popover>
                        {record.Status === 'A'
                            ? <Button ghost size="small" style={{ marginRight: 10 }} type="danger" onClick={() => this.Activation(record, 'D')}>Deactivate</Button>
                            : <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => this.Activation(record, 'A')}>Activate</Button>
                        }
 {record.Type === '1'
                            ? <Button ghost size="small" style={{ marginRight: 10 }} type="danger" onClick={() => this.OnOff(record, '0')}>OFF</Button>
                            : <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => this.OnOff(record, '1')}>ON</Button>
                        }
                    </div>

            },

        ];
        const LogCol = [
            { title: 'No', dataIndex: 'id', key: 'id' },
            { title: 'Log Date', dataIndex: 'RegDate', key: 'RegDate', ...this.getColumnSearchProps('RegDate') },
            { title: 'Type', dataIndex: 'Type', key: 'Type', ...this.getColumnSearchProps('Type') },
            { title: 'Comment', dataIndex: 'Comment', key: 'Comment' },
            { title: 'Who Done', dataIndex: 'WhoDone', key: 'WhoDone', ...this.getColumnSearchProps('WhoDone') },
            

        ];
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        function onChangelog(date, dateString) {
            console.log(date, dateString);
          }
          const { getFieldDecorator } = this.props.form;
          const dateFormat = 'YYYY/MM/DD';
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>User - All</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                        <div>
                            <Row style={{ margin: 10, marginLeft: 0 }}>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="search Name"
                                        onChange={(text) => this.SearchFilterFunction(text)} />
                                </Col>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="User ID"
                                        onChange={(text) => this.SearchFiltergisterID(text)} />
                                </Col>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="Search Phone number"
                                        onChange={(text) => this.SearchFiltergisterphone(text)} />
                                </Col>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="Search Reg Date"
                                        onChange={(text) => this.SearchFilterDate(text)} />
                                </Col>
                                {/*<Col sm="3" xs="12" align="right">
                                    <Link to={`/AdminApp/AddUser/${null}`} style={{ textDecoration: 'none' }}>
                                        <Button type="primary">Add New +</Button>
                                    </Link>
                                </Col>*/}
                            </Row>

                            <Table columns={columns} dataSource={this.state.data} onChange={onChange} pagination={{ pageSize: 25 }} />
                        </div>
                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
                <Modal
          visible={visible}
          title="Log"
          onOk={this.handleOk}
          width={800}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={() => this.UserLog()}>
              Submit
            </Button>,
          ]}
        >

<Row >
    <Col>
    <Input placeholder="Type" name="LogType" value={this.state.LogType}   onChange={this.handleChange}/>
    </Col>
    <Col>      {getFieldDecorator('LogType', {
                                initialValue: moment(this.state.DPLog, dateFormat),
                                setFieldsValue: this.state.DPLog,
                                rules: [{ type: 'object', required: true, message: 'Please select Date!' }],
                            })(
                                <DatePicker format="YYYY-MM-DD" placeholder="Select Time" onChange={this.ondateChangePayed} />
                            )}</Col>  </Row>
    <br></br>
    <Row>
        <Col>
    <TextArea rows={4} placeholder="LogComments" name="LogContent" value={this.state.LogContent}   onChange={this.handleChange}/>
    </Col>

    </Row>

             
    <Table columns={LogCol} dataSource={this.state.LogData} onChange={onChange} pagination={{ pageSize: 25 }} />
  
   
     
      
        </Modal>

        <Modal
          visible={this.state.visibleSMSModal}
          title="Send Customize SMS"
          onOk={this.handleOk}
          onCancel={this.handleCancelCustomSMS}
          footer={[
            <Button key="back" onClick={this.handleCancelCustomSMS}>
              Close
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={() => this.send_Custom_sms()}>
              Submit
            </Button>,
          ]}
        >
             
       <TextArea rows={4} placeholder="Type Messages" name="SMSContent" value={this.state.SMSContent}   onChange={this.handleChange}/>
      
        </Modal>
            </Container>
        )
    }
}
export default Form.create()(AdminSearchResult);