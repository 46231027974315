import * as Action from '../Actions/IntrestedAction';
import API from '../../API';
import { message } from 'antd';

export const loadintrestedService = (user_id) => {
    return async dispatch => {
        let obj = { user_id: user_id }
        fetch(API.BASE_URL + API.REFRESH_INTRESTED, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                dispatch(Action.loadintrestedAction(responsesJson.data));
            },
                (error) => {
                    console.error(error);
                    dispatch(message.error('This is an error message'));
                }
            )
    }
}

export const intrestedService = (user_id,target,act, intrested) => {
    return async dispatch => {
        let obj = {
            user_id:target.user_id,
            Name:target.Name,
            image1:target.image1,
            UserId:target.UserId,
            intrested:intrested,
            Istatus: act,
          }
          let bodyData = {
            action: act,
            user_id:user_id,
            target_id:target.user_id,
          }
          fetch(API.BASE_URL + API.INTREST_ACTIONS_NEW, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(bodyData)
          })
            .then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.status) {
                dispatch(message.success('Profile ' + act + ' Sucessfully'));
                if (act === 'Requested') {
                    dispatch(Action.addintrestedAction(obj));
                  }
                  else if (act === 'Canceld') {
                    dispatch(Action.removeintrestedAction(obj));
                  }
                  else {
                    dispatch(Action.updateintrestedAction(obj));
                  }
              }
            })
            .catch((error) => {
                dispatch(message.error('This is an error message'));
            });
    }
}

export const intrestedrefreshsucessServices = () => {
  return async dispatch => {
      dispatch(Action.intrestedrefreshsucessAction());
  }
}