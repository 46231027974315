const response ={auth:false};
const Adminlogin = (data,parse) =>{
return parse;
}
const AdminLogout = (data,parse) =>{
    return parse;
    }
const AdminLoginReducer = (state = response, action) =>{
    switch (action.type) {
        case 'ADMINLOGIN':{
            return Adminlogin(state,action.payload);
        }
        case 'ADMINLOGOUT':{
            return AdminLogout(state,action.payload);
        }
        default:{
            return state;
        }
               
    }
    }
    export default AdminLoginReducer ;