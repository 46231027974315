import React, { Component } from 'react';
import { Card, Container, Col, Row, CardTitle, CardText, CardImg, CardImgOverlay, CardSubtitle, CardBody } from 'reactstrap';
import { Upload, Icon, message, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
import ImageCropUpload from '../../components/ImageCropUpload';
class MyphotosCard extends Component {
    state = {
        loading: false,
        selectedFile: null,
        previewVisible: false,
        previewImage: '',
        image1: null, image2: null,
        image3: null, image4: null,
        image5: null, image6: null
    };

    componentDidMount = () => {
        this.handleChanges()
    }
    ProfilePercentage = () => {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
            //this.refs.toast.show('Something went wrong!');
          });
      }
      Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
               

            })
            .catch((error) => {
                console.error(error);
            });
            
    }
    handleChanges = () => {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.ALL_IMAGES, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
               
                this.setState({
                    loading: false,
                    image1: responsesJson.image1,
                    image2: responsesJson.image2,
                    image3: responsesJson.image3,
                    image4: responsesJson.image4,
                    image5: responsesJson.image5,
                    image6: responsesJson.image6,
                });

                this.ProfilePercentage();
                this.Refrsh();
                this.props.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    }




    render() {
        return (
            <Card style={{ borderRadius: 10 }} >
            <CardBody>
            <div style={{ borderRadius: 10 }}>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.UserProfileMyPhoto}</p>
                    
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <p style={{fontSize:16,fontWeight:600}}>
                        Get more response by adding your photo.
                        </p>
                    <Row>
                        <Col sm="4" xs="12">
                            <ImageCropUpload
                                image={this.state.image1}
                                image_id={'image1'}
                                user_id={this.props.User.user_id}
                                handleChanges={() => this.handleChanges()} />
                                <p style={{textAlign:"center",fontWeight:600}}>Profile Photo</p>
                        </Col>
                        <Col sm="4" xs="12">
                            <ImageCropUpload
                                image={this.state.image2}
                                image_id={'image2'}
                                user_id={this.props.User.user_id}
                                handleChanges={() => this.handleChanges()} />
                                 <p style={{textAlign:"center",fontWeight:600}}>Photo 1</p>
                        </Col>
                        <Col sm="4" xs="12">
                            <ImageCropUpload
                                image={this.state.image3}
                                image_id={'image3'}
                                user_id={this.props.User.user_id}
                                handleChanges={() => this.handleChanges()} />
                                   <p style={{textAlign:"center",fontWeight:600}}>Photo 2</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" xs="12">
                            <ImageCropUpload
                                image={this.state.image4}
                                image_id={'image4'}
                                user_id={this.props.User.user_id}
                                handleChanges={() => this.handleChanges()} />
                                  <p style={{textAlign:"center",fontWeight:600}}>Photo 3</p>
                        </Col>
                        <Col sm="4" xs="12">
                            <ImageCropUpload
                                image={this.state.image5}
                                image_id={'image5'}
                                user_id={this.props.User.user_id}
                                handleChanges={() => this.handleChanges()} />
                                  <p style={{textAlign:"center",fontWeight:600}}>Photo 4</p>
                        </Col>
                        <Col sm="4" xs="12">
                            <ImageCropUpload
                                image={this.state.image6}
                                image_id={'image6'}
                                user_id={this.props.User.user_id}
                                handleChanges={() => this.handleChanges()} />
                                  <p style={{textAlign:"center",fontWeight:600}}>Family Photo</p>
                        </Col>
                    </Row>
            </div>
            </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(MyphotosCard);