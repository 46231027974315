import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Result, Button,message, Icon, Typography} from 'antd';
import FooterS from '../components/Footer';
import { Link } from 'react-router-dom';
import API from '../API';
const { Paragraph, Text } = Typography;
class download extends Component {
  
 
    render() {
        return (
            <div style={{backgroundColor:'#fff'}}>
                <Result
    status="error"
    title="Submission Failed"
    subTitle="വീണ്ടും ശ്രമിക്കുക. കൂടുതൽ വിവരങ്ങൾക്ക് വിളിക്കുക 6235 44 55 44 "
    extra={[
        <Link to={`/UserApp/UserPayment`} style={{ textDecoration: 'none' }}>
      <Button type="primary" key="console">
        Try Again
      </Button></Link>
      
    ]}
  >
   
  </Result>
               
            </div>
        )
    }
}
export default download;
