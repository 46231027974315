import React, { Component } from 'react';
import { Col, Row ,Container} from 'reactstrap';
import { Layout, message, Table, PageHeader, Icon, Card, Button ,Divider,Modal} from 'antd';
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import API from '../../API';
import moment from 'moment';
import './styles.css';
import './Pricestyles.css';
import { Link } from 'react-router-dom';

import dateFormat from 'dateformat';
const { Content } = Layout;
class UserPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            
            Plan: [],
            remaining_days: 0,
            remaining_view: 0,
            paid_amount: 0,
            exp_date: null,
            hecked: false,
            payment_amount: 0,
            amount: 50000,
            date: new Date(),
            Name: this.props.User.FullName,
       ModalPaySuccess:false
        };
        this.handleChange = this.handleChange.bind(this);
        this.paymentHandler = this.paymentHandler.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.arrayholder = [];
    }
  //*************************start getplan function****************************************
  handleCancel = () => {
    this.setState({ ModalPaySuccess: false })
}
  getPlan = () => {
   
   
    fetch(API.BASE_URL + API.GetPlan, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
       
    })
        .then((response) => response.json())
        .then((responsesJson) => {
         
            let data = responsesJson;
           
            this.setState({ Plan: data })
           
           // console.log(this.state.data);
        })
        .catch((error) => {
          console.log(error);
          // this.refs.toast.show('Something went wrong!');
        });
}
//*************************End getplan function****************************************
    componentDidMount = () => {
        this.Refrsh();
        this.getPlan();
        message.config({ top: 100 });
        let obj = { user_id: this.props.User.user_id };
        fetch(API.BASE_URL + API.GET_PAYMENT_DETAILS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({
                    data: responsesJson.history,
                    remaining_days: responsesJson.days,
                    remaining_view: responsesJson.views,
                    paid_amount: responsesJson.paid_amount,
                    exp_date: responsesJson.exp_date,
                    ActivateDate: responsesJson.ActivateDate,
                    TransName: responsesJson.TransName,
                    sms:responsesJson.sms,
                    loading: false
                })
               
            })
            .catch((error) => {
                message.error('Error. Try again !');
            });
        if (this.props.User.Status === 'A') {/// ---A--- activated account
            this.setState({ Activated: true,UserPriceTable:true });
        }
        else if (this.props.User.Status === 'D') {
            this.setState({ deactivated: true, isLoaded: true }); /// ---D--- Deactivated
        }
        else if (this.props.User.Status === 'P') {
            this.setState({ payment_due: true, isLoaded: true }); /// ---P--- exprired pay and continue
        }
        else {
            this.setState({ not_aprooved: true, isLoaded: true }); /// ---N--- new account
        }

        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }

    Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
            })
            .catch((error) => {
                console.error(error);
            });
    }
    handleChange() {
        this.setState({
            checked: !this.state.checked
        })
    }
    changeAmount(e) {
        this.setState({ amount: e.target.value })
    }
    paymentHandler() {
        let options = {
            "key": "rzp_live_xI5StCbM4mOQ1G",
            "amount": this.state.amount, // 2000 paise = INR 20, amount in paisa
            "name":"Prolifemarry.com",
            "description":  this.state.PayType,
            
            "prefill": {
                "name": this.state.Name,
                "email": this.state.MainEmail,
                "contact":"+91"+this.state.MainMob
            },
            "notes": {
                "address": this.state.UserId,
                "name": this.state.Name,
                "email": this.state.MainEmail,
                "contact":"+91"+this.state.MainMob
            },
            
            "handler": (response) => this.payment_action(response),
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
    }

    addDays(date) {
        var myInt = parseInt(this.state.NoDays); 
        var result = new Date(date);
        result.setDate(result.getDate() + myInt);
        return moment(result).format('YYYY-MM-DD');
    }

    payment_action = (res) => {
        if (res != null) {
            message.config({ top: 100 });
            let obj = {
                user_id: this.props.User.user_id,
                TransName: this.state.PayType,
                PayType: this.state.PayType,
                PayMethod: 'Payment Gateway',
                PDate: moment(this.state.date).format('YYYY-MM-DD'),
                ADate: moment(this.state.date).format('YYYY-MM-DD'),
                EDate: moment(this.state.date).format('YYYY-MM-DD'),
            
                Amount: this.state.amount/100,
                TransId: res.razorpay_payment_id,
                Details: 'Details',
                SMSno:this.state.SmsContact,
                Contact:this.state.Contact
            };
            fetch(API.BASE_URL + API.TopupRecharge, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify(obj)
            }).then((response) => response.json())
                .then((responsesJson) => {
                    if (responsesJson.status === "success") {
                        message.success('Your payment was successful');
                      
                        this.setState({ModalPaySuccess:true});
                    }
                })
                .catch((error) => {
                    message.error('Error. Try again !');
                });
        }
        else {
            console.log('faild');
        }
    }
    Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
            

            })
            .catch((error) => {
                console.error(error);
            });
            
    }
    GetDetails = (PayAmount,TypePay,Contact,SMS,Validity) => {
        
            message.config({ top: 100 });
            let obj = {
                user_id: this.props.User.user_id
             
            };
            fetch(API.BASE_URL + API.GetUserForPayment, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify(obj)
            }).then((response) => response.json())
                .then((responsesJson) => {
                    this.setState({
                        Name: responsesJson.Name,
                        MainEmail: responsesJson.MainEmail,
                        MainMob: responsesJson.MainMob,
                        UserId: responsesJson.UserId,
                        RegDate: responsesJson.RegDate,
                        amount:PayAmount*100,
                        Contact:Contact,
                        PayType:TypePay,
                        NoDays:Validity,
                        SmsContact:SMS
                    })
                    this.paymentHandler();
                })
                .catch((error) => {
                    message.error('Error. Try again !');
                });
       
    }

    render() {
        
       
        return (
            <Content className="scrollBox1" style={{ background: '#f2f3f5' }}>
            <PageHeader title={this.props.lang.TopupRecharge} lang={this.props.lang.lang} className="UserIntrestDrop" style={{ marginTop:10,marginBottom: -10}}/>
            <Container>

          
                  
                                <Card style={{ borderRadius: 10, marginTop: 10 }}>
                                    <div>
                                {this.state.Plan.map((item, index) =>
                                <div class="snip1214" >
                                  
     {item.id==='5'?
  <div class="plan">
  <h3 class="plan-title">
  {item.Name}
  </h3>
  <div class="plan-cost"><span class="plan-price">₹{item.Price}</span><span class="plan-type">/ {item.Validity}</span></div>
  <ul class="plan-features">

  <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
    <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
    <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
    <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
  
  </ul>
  <div class="plan-select"><Button type="primary" onClick={() => this.GetDetails((item.Price),item.Name,item.Contact,item.Sms,item.Days)}>Pay Now</Button></div>
</div>
 :
 null
 }
 {item.id==='6'?
  <div class="plan">
    <h3 class="plan-title">
    {item.Name}
    </h3>
    <div class="plan-cost"><span class="plan-price">₹{item.Price}</span><span class="plan-type">/ {item.Validity}</span></div>
    <ul class="plan-features">
  
    <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
      <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
      <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
      <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
    
    </ul>
    <div class="plan-select"><Button type="primary" onClick={() => this.GetDetails((item.Price),item.Name,item.Contact,item.Sms,item.Days)}>Pay Now</Button></div>
  </div>
:
null
}
{item.id==='7'?
  <div class="plan">
  <h3 class="plan-title">
  {item.Name}
  </h3>
  <div class="plan-cost"><span class="plan-price">₹{item.Price}</span><span class="plan-type">/ {item.Validity}</span></div>
  <ul class="plan-features">

  <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
    <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
    <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
    <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
  
  </ul>
  <div class="plan-select"><Button type="primary" onClick={() => this.GetDetails((item.Price),item.Name,item.Contact,item.Sms,item.Days)}>Pay Now</Button></div>
</div>
:
null
}
{item.id==='8'?
    <div class="plan">
    <h3 class="plan-title">
    {item.Name}
    </h3>
    <div class="plan-cost"><span class="plan-price">₹{item.Price}</span><span class="plan-type">/ {item.Validity}</span></div>
    <ul class="plan-features">
  
    <li><i class="ion-checkmark"> </i>{item.Contact} Contact View</li>
      <li><i class="ion-checkmark"> </i>{item.Whatsapp} Whatsapp Message</li>
      <li><i class="ion-checkmark"> </i>{item.Sms} SMS</li>
      <li><i class="ion-checkmark"> </i>{item.Validity} Validity</li>
    
    </ul>
    <div class="plan-select"><Button type="primary" onClick={() => this.GetDetails((item.Price),item.Name,item.Contact,item.Sms,item.Days)}>Pay Now</Button></div>
  </div>
:
null
}
</div>
)}

<Divider style={{paddingTop:40}}>More Payment Options</Divider>                    
<p>Account Number: <b>14140200007597</b></p>
<p>Account Name: <b>KCBC Prolife Samithi Charitable Trust Malabar Region</b></p>
<p>IFSC: <b>FDRL0001414</b></p>
<p>Federal Bank - Thamarassery Branch</p>
<p>
     <b>Please call/Whatsapp 623544 5544 | 85909 90889</b> 
    </p>
    </div>
                                </Card>
                           
                          
                            

                    <Modal
                    centered
                    visible={this.state.ModalPaySuccess}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={300}
                >
                    <div className="aligitemncenter">
                        <div>
                           
                            <Icon type="check-circle" style={{ fontSize: 50, color: '#026c70', marginTop: 20 }} />
                            <h6 style={{ marginTop: 10, marginBottom: 30 }}>Top Up Recharge successfully added</h6>
                        </div>
                        <Row>
                            <Col sm="12" xs="12">
                                <Link to="/UserApp/UserPayment" >
                                <Button>Go to My Account</Button> </Link>
                                    
                            </Col>
                            
                        </Row>
                    </div>
                </Modal>

                </Container>
        </Content>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserPayment);