

import React, { Component } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { Select, message, Table, Modal, Icon,Input,Button, Form,Popover } from 'antd';
import API from '../API';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import ImageCropUpload from './ImageCropUploadSuccess';
const { Option } = Select;
const { TextArea } = Input;
class SuccessStory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],StatusRply:null
        };
    }  
    state = {
       
        loading: false,
        visible: false
      };
  
      handleCancelLog = () => {
        this.setState({ visibleLog: false });
      };
      handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
  


    
      showModalStatus = (record) => {
       
        this.setState({
           
          visibleLog: true,
          UserId: record.id1,
         
           

        });
      };
      Delete = (record) => {
        this.setState({ loading: true });
        let obj = {  id: record.id};
        fetch(API.BASE_URL + API.DeleteStory, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Send Successfully');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false ,visibleLog: false});
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }
    DeletePhoto = (record) => {
        this.setState({ loading: true });
        let obj = {  id: record.id};
        fetch(API.BASE_URL + API.DeletePhotoStory, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Delete Successfully');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false ,visibleLog: false});
                }
                else {
                    message.error(responsesJson.status);
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }


    send_Status = () => {
      this.setState({ loading: true });
      let obj = {  Name: this.state.Name };
      fetch(API.BASE_URL + API.SaveStory, {
          method: 'POST',
          headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
      }).then((response) => response.json())
          .then((responsesJson) => {
              if (responsesJson.status === 'success') {
                  message.success('Send Successfully');
                  this.componentDidMount();
                  this.setState({ loading: false, form: false ,visibleLog: false});
              }
              else {
                  message.error(responsesJson.status);
                  this.setState({ loading: false, form: false });
              }
          })
          .catch((error) => {
              message.error('Error. Try again !');
              this.setState({ loading: false, form: false });
          });
  }
    componentDidMount = () => {
        this.setState({ loading: true });
        fetch(API.BASE_URL + API.GetSuccessStory)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    data: res
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
    }

    render() {
        const { visible, loading } = this.state;
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        const columns = [
            { title: 'No', dataIndex: 'id', key: 'id' },
            { title: 'Image', dataIndex: 'Photo', 
            render:  (value, record) => <img style={{ width:250 }} src={record.Photo} />
        },
           
            { title: 'Name', dataIndex: 'Name', key: 'Name' },
            { title: 'Date', dataIndex: 'Date', key: 'Date' },
           
          
            
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (value, record) =>
                    <div>
                       

                       <ImageCropUpload
                          
                            id={record.id}
                            handleChanges={() => this.componentDidMount()} 
                            />
                      
                      <Button ghost size="small" style={{ marginRight: 10 }} type="danger" onClick={() => this.DeletePhoto(record) }>Delete Photo</Button>
                            <Button ghost size="small" style={{ marginRight: 10 }} type="danger" onClick={() => this.Delete(record) }>Delete</Button>
                     

                    

                    </div>

            },
        ];
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Success Stories</p>
                
              
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                <div style={{paddingRight:20,paddingTop:20}}  align="Right">
                <Button size="small"  onClick={() => this.setState({visibleLog:true}) }>Add</Button>
                </div>
                    <CardBody>
                        <Table columns={columns} dataSource={this.state.data} onChange={onChange} pagination={{ pageSize: 25 }} />

                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
                

        <Modal
          visible={this.state.visibleLog}
          title="Add Details"
          onOk={this.handleOk}
          onCancel={this.handleCancelLog}
          footer={[
            <Button key="back" onClick={this.handleCancelLog}>
              Close
            </Button>,
            <Button key="submit" type="primary" loading={loading} onClick={() => this.send_Status()}>
              Submit
            </Button>,
          ]}
        >  <TextArea rows={1} placeholder="Name" name="Name" value={this.state.Name}  style={{marginBottom:10}} onChange={this.handleChange}/>  
           
      
        </Modal>
            </Container>
        )
    }
}
export default SuccessStory;