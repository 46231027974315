import * as types from './Types';
export const ViewNotification = (data) => ({
    type: types.VIEW_NOTIFICATION,
    payload: data
});







