import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup } from 'reactstrap';
import { Form, Input, Select, Button, message, InputNumber } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class EditAdmin_contactCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3
            HouseName: this.props.data.HouseName,
            Place: this.props.data.Place,
            Parish: this.props.data.Parish,
            Diocese: this.props.data.Diocese,
            CAddress: this.props.data.CAddress,
            Contactphone: this.props.data.Contactphone,
            Contactemail: this.props.data.Email
            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'user_id': this.props.data.user_id,
                    'HName': this.state.HouseName,
                    'Place': this.state.Place,
                    'Parish': this.state.Parish,
                    'Diocese': this.state.Diocese,
                    'Address': this.state.CAddress,
                    'PNumber': this.state.Contactphone,
                    'Email': this.state.Contactemail
                }
                fetch(API.BASE_URL + API.ADMIN_UPDATE_CONTACT, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.props.reload();
                            this.setState({ loading: false });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '+91',
        })(
            <Select style={{ width: 70 }}>
                <Option value="+91">+91</Option>
            </Select>,
        );
        return (
            <Card style={{ borderRadius: 10 }} >
                <CardBody>
                    <Form {...formItemLayout}>
                        <Form.Item label="House Name" >
                            <Input
                                name="HouseName"
                                maxLength='100'
                                value={this.state.HouseName}
                                onChange={this.handleChange}
                                placeholder="House Name" />
                        </Form.Item>


                        <Form.Item label="Place" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Place', {
                                initialValue: this.state.Place,
                                setFieldsValue: this.state.Place,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please input your Fathers Name!' }],
                            })(
                                <Input
                                    name="Place"
                                    maxLength='200'
                                    placeholder="Place" />)}
                        </Form.Item>
                        <Form.Item label="Parish" style={{ marginTop: -20 }}>
                            <Input
                                name="Parish"
                                maxLength='100'
                                value={this.state.Parish}
                                onChange={this.handleChange}
                                placeholder="parish" />
                        </Form.Item>

                        <Form.Item label="Diocese" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Diocese', {
                                initialValue: this.state.Diocese,
                                setFieldsValue: this.state.Diocese,
                                rules: [{ required: false, message: 'Please select Diocese!' }],
                            })(
                                <Select
                                    showSearch
                                    name={"Diocese"}
                                    placeholder="Diocese"
                                    optionFilterProp="children"
                                    onChange={(val) => { this.handleChange({ target: { name: 'Diocese', value: val } }) }}
                                    filterOption={(input, option) =>
                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.props.dropdown.Diocese_array.map((item) => (
                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>


                        <Form.Item label="Communicational Address" style={{ marginTop: -20 }}>
                            <TextArea
                                name="CAddress"
                                maxLength='500'
                                value={this.state.CAddress}
                                onChange={this.handleChange}
                                placeholder="Communicational Address"
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Form.Item>


                        <Form.Item label="Phone Number" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Contactphone', {
                                initialValue: this.state.Contactphone,
                                setFieldsValue: this.state.Contactphone,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please input your phone number!' }],
                            })(<Input
                                name="Contactphone"
                                type="number"
                                maxLength='20'
                                placeholder="Phone Number"
                                addonBefore={prefixSelector}
                                style={{ width: '100%', margin: 0, padding: 0 }} />)}
                        </Form.Item>

                        <Form.Item label="E-mail" style={{ marginTop: -20 }}>
                            {getFieldDecorator('Contactemail', {
                                initialValue: this.state.Contactemail,
                                setFieldsValue: this.state.Contactemail,
                                onChange: this.handleChange,
                                rules: [{ type: 'email', message: 'The input is not valid E-mail!', },],
                            })(<Input
                                name="Contactemail"
                                maxLength='50'
                                placeholder="E-mail" />)}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={() => this.update()}>
                                    Update
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(EditAdmin_contactCard));