

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Container, Row, Col} from 'reactstrap';
import { Select, Form, Button, message, Icon, Modal, Radio } from 'antd';
import ReactToPrint from 'react-to-print';
import API from '../API';
import Printpage_table from './Components/printpage_table';
import Printpage_Grid from './Components/printpage_grid';
const { Option } = Select;
class Adminreport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Diocese: null,
            drop: this.props.dropdown.Diocese_array,
            data: null,
            show: false,
            layout: 'Table'

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    get_report = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = { "Diosis": this.state.Diocese };
                fetch(API.BASE_URL + API.GET_REPORT, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                }).then((response) => response.json())
                    .then((res) => {
                        this.setState({
                            loading: false,
                            data: res
                        })
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                    });
            }
        })
    }

    ProfilePer = () => {
         fetch(API.BASE_URL + API.InserProPer, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                   
                }).then((response) => response.json()) }

   LastSeen = () => {
                    fetch(API.BASE_URL + API.InserLastSeen, {
                               method: 'POST',
                               headers: new Headers({
                                   'Content-Type': 'application/x-www-form-urlencoded',
                               }),
                              
                           }).then((response) => response.json()) }
        InsertSMS = () => {
                            fetch(API.BASE_URL + API.InsertSMS, {
                                       method: 'POST',
                                       headers: new Headers({
                                           'Content-Type': 'application/x-www-form-urlencoded',
                                       }),
                                      
                                   }).then((response) => response.json()) }

    print = () => {

    }
    render() {
        const formItemLayout = {
            labelCol: { xs: { span: 24 }, sm: { span: 8 } },
            wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Reports</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                        <Row>
                            <Col sm="4" xs="12">
                                <Form {...formItemLayout}>
                                    <Form.Item label="Select Diocese">
                                        {getFieldDecorator('Diocese', {
                                            initialValue: this.state.Diocese,
                                            setFieldsValue: this.state.Diocese,
                                            rules: [{ required: true, message: 'Please select Diocese!' }],
                                        })(
                                            <Select
                                                showSearch
                                                name={"Diocese"}
                                                placeholder="Diocese"
                                                optionFilterProp="children"
                                                onChange={(val) => { this.handleChange({ target: { name: 'Diocese', value: val } }) }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {this.props.dropdown.Diocese_array.map((item) => (
                                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Form>

                            </Col>
                            <Col sm="2" xs="12">
                                <Button type="primary" onClick={() => this.get_report()}>Generate Report</Button>
                            </Col>
                            <Col sm="4" xs="12">
                            <Form {...formItemLayout}>
                                <Form.Item>
                                    {getFieldDecorator('layout', {
                                        initialValue: this.state.layout,
                                        setFieldsValue: this.state.layout,
                                        onChange: this.handleChange,
                                        rules: [{ required: false, message: 'Please select layout!' }],
                                    })(
                                        <Radio.Group name="layout">
                                            <Radio.Button value="Table">Table</Radio.Button>
                                            <Radio.Button value="Grid">Grid</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                </Form>
                            </Col>
                            <Col sm="2" xs="12" align="right">
                                <ReactToPrint
                                    trigger={() => <Button type="danger">Print Report</Button>}
                                    content={() => this.componentRef}
                                />

                            </Col>
                        </Row>
<Row>
<Button type="primary" onClick={() => this.ProfilePer()}>Insert Profile Percentage</Button>
<Button type="primary" onClick={() => this.LastSeen()}>Insert Last Seen</Button>
<Button type="primary" onClick={() => this.InsertSMS()}>Insert SMS</Button>
</Row>



                        {this.state.data != null ?
                            <div>
                                {this.state.layout === 'Table' &&
                                    <div>
                                        <Printpage_table {...this.state} ref={el => (this.componentRef = el)} />
                                    </div>
                                }
                                {this.state.layout === 'Grid' &&
                                    <div>
                                         <Printpage_Grid {...this.state} ref={el => (this.componentRef = el)} />
                                    </div>
                                }
                            </div>

                            : null
                        }
                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </Container>
        )
    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(Adminreport));