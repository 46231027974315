

import React, { Component } from 'react';
import { Card, CardBody, Row, Col, CardImg, ButtonGroup, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, DatePicker, message, Modal, Popover, Table, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class Edit_Payment extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            loading: true,
            error: false,
            activeTab: 'E',
            data: [],
            editID: null,
            Name: null,
            Type: null,
            form: false,
            FomTitle: 'Add New DropDown',
            Add: false,
            Edit: false,

        };
    }

    componentDidMount = () => {
        message.config({ top: 100 });
        let obj = { 'user_id': this.props.data.user_id, };
        fetch(API.BASE_URL + API.GET_PAYMENT, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data: responsesJson, loading: false })
            })
            .catch((error) => {
                message.error('Error. Try again !');
            });
    }
    Set_Add = () => {
        this.setState({
            FomTitle: 'Add New Dropdown',
            form: true,
            Add: true,
            Edit: false,
            editID: null,
            Name: null,
            Type: null,
        })
    }
    Set_Edit = (item) => {
        console.log(item);
        this.setState({
            FomTitle: 'Update Dropdown',
            form: true,
            Edit: true,
            Add: false,
            editID: item.id,
            TransId: item.TransId,
            TransName: item.TransName,
            PayType: item.PayType,
            PayMethod: item.PayMethod,
            Pdate: item.PayedDate,
            Adate: item.ActivateDate,
            Edate: item.ExpDate,
            Amount: item.Amount,
            Details: item.Comment

        })
    }

    delete_item = (idD) => {
        this.setState({ loading: true });
        let obj = { id: idD };
        fetch(API.BASE_URL + API.DELETE_PAYMENT, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Deleted Successfully');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleCancel = () => {
        this.setState({ form: false });
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    ondateChangePayed = (value) => {
        if (value != null) {
            this.setState({ payed: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ payed: null, date: null });
        }
    }

    ondateChangeActivate = (value) => {
        if (value != null) {
            this.setState({ Activate: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ Activate: null, date: null });
        }
    }
    ondateChangeExpiry = (value) => {
        if (value != null) {
            this.setState({ Expiry: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ Expiry: null, date: null });
        }
    }
    Add_new = () => {
        this.setState({ loading: true });
        let obj = {
            'user_id': this.props.data.user_id,
            'TransName': this.state.TransName,
            'PayType': this.state.PayType,
            'PayMethod': this.state.PayMethod,
            'PDate': this.state.payed,
            'ADate': this.state.Activate,
            'EDate': this.state.Expiry,
            'Amount': this.state.Amount,
            'TransId': this.state.TransId,
            'Details': this.state.Details
        };
        fetch(API.BASE_URL + API.ADD_PAYMENT, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {

                if (responsesJson.status === 'success') {
                    message.success('Added Successfully');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again11 !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again22 !');
                this.setState({ loading: false, form: false });
                this.componentDidMount();
            });
    }
    FullActivation = () => {
        this.setState({ loading: true });
        let obj = {
            'user_id': this.props.data.user_id,
            
        };
        fetch(API.BASE_URL + API.FullActivationLadies, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {

                if (responsesJson.status === 'success') {
                    message.success('Added Successfully');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
                this.componentDidMount();
            });
    }
    Edit_item = () => {
        this.setState({ loading: true });
        let obj = {
            id: this.state.editID,
            'user_id': this.props.data.user_id,
            'TransName': this.state.TransName,
            'PayType': this.state.PayType,
            'PayMethod': this.state.PayMethod,
            'PDate': this.state.payed,
            'ADate': this.state.Activate,
            'EDate': this.state.Expiry,
            'Amount': this.state.Amount,
            'TransId': this.state.TransId,
            'Details': this.state.Details
        };
        fetch(API.BASE_URL + API.UPDATE_PAYMENT, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Item updated');
                    this.componentDidMount();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }


    render() {
        const columns = [
            { title: 'No', dataIndex: 'id', key: 'id' },
            { title: 'Transaction Name', dataIndex: 'TransName', key: 'TransName' },
            { title: 'Payment Type', dataIndex: 'PayType', key: 'PayType' },
            { title: 'Payment Method', dataIndex: 'PayMethod', key: 'PayMethod' },
            { title: 'Payed Date', dataIndex: 'PayedDate', key: 'PayedDate' },
            { title: 'Activate Date', dataIndex: 'ActivateDate', key: 'ActivateDate' },
            { title: 'Expiry Date', dataIndex: 'ExpDate', key: 'ExpDate' },
            { title: 'Amount', dataIndex: 'Amount', key: 'Amount' },
            { title: 'Transaction ID', dataIndex: 'TransId', key: 'TransId' },
            { title: 'Details', dataIndex: 'Comment', key: 'Comment' },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (value, record) =>
                    <div>
                        <Popconfirm title="Sure to delete?" onConfirm={() => { this.delete_item(record.id) }}>
                            <Button ghost size="small" type="danger">Remove</Button>
                        </Popconfirm>
                        <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.Set_Edit(record) }}>Edit</Button>

                    </div>
            },

        ];
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        
        const { getFieldDecorator } = this.props.form;
        const dateFormat = 'YYYY/MM/DD';
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">

                <Card style={{ borderRadius: 10 }}>
                    <CardBody>
                        <div>
                            <div align="Right" style={{ margin: 10 }}>
                                <Button type="primary" onClick={() => { this.Set_Add() }}>Add New +</Button>
                               
                            </div>
                            <div align="left" style={{ margin: 10 }}>
                              
                                <Button type="primary" onClick={() => { this.FullActivation() }}>Full Activate Ladies</Button>
                            </div>

                            <Table bordered columns={columns} dataSource={this.state.data} onChange={onChange} scroll={{ x: 800 }} pagination={{ pageSize: 25 }} />

                        </div>
                    </CardBody>
                </Card>

                <Modal
                    centered
                    style={{ top: 80 }}
                    title={this.state.FomTitle}
                    visible={this.state.form}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={500}
                >
                    <Form style={{ marginTop: -20 }}>
                        <Form.Item label="Transaction Name" style={{ marginTop: -30 }}>
                            <Input
                                placeholder="Transaction Name"
                                name="TransName"
                                value={this.state.TransName}
                                onChange={this.handleChange}
                            />
                        </Form.Item>
                        <Form.Item label="Payment Type" style={{ marginTop: -30 }}>
                            {getFieldDecorator('PayType', {
                                initialValue: this.state.PayType,
                                setFieldsValue: this.state.PayType,
                                rules: [{ required: true, message: 'Please select Payment Type!' }],
                            })(
                                <Select placeholder="Payment Type" name="PayType"
                                    onChange={(val) => { this.handleChange({ target: { name: 'PayType', value: val } }) }}>
                                    <Option value="Account Renew">Account Renew</Option>
                                    <Option value="Contact Topup">Contact Topup</Option>
                                    <Option value="New Registration Basic">New Registration Basic</Option>
                                    <Option value="New Registration Pro">New Registration Pro</Option>
                                    <Option value="New Registration Unlimited">New Registration Unlimited</Option>
                                    <Option value="New Registration Free">New Registration Free</Option>

                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Payment Method" style={{ marginTop: -30 }}>
                            {getFieldDecorator('PayMethod', {
                                initialValue: this.state.PayMethod,
                                setFieldsValue: this.state.PayMethod,
                                rules: [{ required: true, message: 'Please select Payment Method!' }],
                            })(
                                <Select placeholder="Payment Method" name="PayMethod"
                                    onChange={(val) => { this.handleChange({ target: { name: 'PayMethod', value: val } }) }}>
                                     <Option value="Free">Free</Option>
                                    <Option value="Google Pay">Google Pay</Option>
                                    <Option value="Payment gateway">Payment gateway</Option>
                                    <Option value="Account Transfer">Account Transfer</Option>

                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Payed Date" style={{ marginTop: -30 }}>
                            {getFieldDecorator('PayedDate', {
                                initialValue: moment(this.state.Pdate, dateFormat),
                                setFieldsValue: this.state.Pdate,
                                rules: [{ type: 'object', required: true, message: 'Please select Date!' }],
                            })(
                                <DatePicker format="YYYY-MM-DD" placeholder="Select Time" onChange={this.ondateChangePayed} />
                            )}
                        </Form.Item>
                        <Form.Item label="Activation Date" style={{ marginTop: -30 }}>
                            {getFieldDecorator('ActivateDate', {
                                initialValue: moment(this.state.Adate, dateFormat),
                                setFieldsValue: this.state.Adate,

                            })(
                                <DatePicker format="YYYY-MM-DD" placeholder="Select Time" onChange={this.ondateChangeActivate} />
                            )}
                        </Form.Item>
                        <Form.Item label="Expiry Date" style={{ marginTop: -30 }}>
                            {getFieldDecorator('ExpiryDate', {
                                initialValue: moment(this.state.Edate, dateFormat),
                                setFieldsValue: this.state.Edate,

                            })(
                                <DatePicker format="YYYY-MM-DD" placeholder="Select Time" onChange={this.ondateChangeExpiry} />
                            )}
                        </Form.Item>
                        <Form.Item label="Amount" style={{ marginTop: -30 }}>
                            <Input

                                placeholder="Amount"
                                name="Amount"
                                value={this.state.Amount}
                                onChange={this.handleChange}
                            />
                        </Form.Item>
                        <Form.Item label="Transaction ID" style={{ marginTop: -30 }}>
                            <Input

                                placeholder="Transaction ID"
                                name="TransId"
                                value={this.state.TransId}
                                onChange={this.handleChange}
                            />
                        </Form.Item>

                        <Form.Item label="Details of Transactions" style={{ marginTop: -30 }}>
                            <TextArea
                                name="Details"
                                value={this.state.Details}
                                onChange={this.handleChange}
                                maxLength='900'
                                placeholder="Details of Transactions"
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Form.Item>

                        <Form.Item align="right" style={{ textAlign: "right" }}>
                            <Button type="defult" onClick={() => { this.setState({ form: false }) }} style={{ marginRight: 10 }}>Cancel</Button>
                            {this.state.Edit ? <Button type="primary" onClick={() => this.Edit_item()}>Update -></Button> : null}
                            {this.state.Add ? <Button type="primary" onClick={() => this.Add_new()}>Add +</Button> : null}
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </Container>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(Edit_Payment));