import React, { Component } from 'react';
import { List, Layout, message, Icon, PageHeader,Divider,Skeleton,Button } from 'antd';
import { Card, Container, Row, Col, CardImg, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import styles from '../../styles';
import './styles.css';
import dateFormat from 'dateformat';
import Loadingplaceholder from '../../components/Loading_placeholder';
import Deactivated_card from '../../components/Deactivated_card';
import Not_aprooved_card from '../../components/not_aprooved_card';
import Payement_due_card from '../../components/payement_due_card';
import API from '../../API';
import { connect } from 'react-redux';
import { loadNotification } from '../../redux/Services/NotificationServices';
const { Content } = Layout;

class UserNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      Notifcations: [],
      Activated: false,
      deactivated: false,
      not_aprooved: false,
      payment_due: false,
   
      page: 1,
      data: [],
      initLoading: true,
      loading: false,
      limit:20,
      list: [],
    };
    this.arrayNotifcations = [];
  }

  componentDidMount() {
    if (this.props.User.Status === 'A') {/// ---A--- activated account

     

      this.setState({ Activated: true,page:1 });
      message.config({ top: 100 });
      let obj = { user_id: this.props.User.user_id,  limit: this.state.limit,
        page: this.state.page }
      fetch(API.BASE_URL + API.GET_NOTIFICATION, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
      })
      .then((response) => response.json())
      .then((responsesJson) => {
          if (responsesJson.data) {
           
              this.setState({ data: responsesJson.Notifcations,isLoaded: false,   initLoading: false })
          }
          else {
              this.setState({ isLoaded: false });
          }
          this.setState({
              loading: false,
            
          });
      })
      .catch((error) => {
          this.setState({
              error: true,
              isLoaded: false,
            
          });
         
          //console.error(error);
      });
    }
    else if (this.props.User.Status === 'D') {
      this.setState({ deactivated: true, isLoaded: true }); /// ---D--- Deactivated
    }
    else if (this.props.User.Status === 'P') {
      this.setState({ payment_due: true, isLoaded: true }); /// ---P--- exprired pay and continue
    }
    else {
      this.setState({ not_aprooved: true, isLoaded: true }); /// ---N--- new account
    }
    this.props.loadNotification(this.props.User.user_id);
  }

  onLoadMore = () => {

    var a=this.state.page;
    a=a+1;
   
    this.setState(
      { page: a, loading: true} )

    message.config({ top: 100 });
    let obj = { user_id: this.props.User.user_id,  limit: this.state.limit,
      page: a }
    fetch(API.BASE_URL + API.GET_NOTIFICATION, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    })
    .then((response) => response.json())
    .then((responsesJson) => {
        if (responsesJson.data) {
            let listData = this.state.data;
            let data = listData.concat(responsesJson.Notifcations)
            this.setState({ data: data,isLoaded: false,   initLoading: false,loading:false})
        }
        else {
            this.setState({ isLoaded: false });
        }
        this.setState({
           
           
        });
    })
    .catch((error) => {
        this.setState({
            error: true,
            isLoaded: false,
           
        });
       
        //console.error(error);
    });
  }



  render() {
    const { initLoading, loading, list } = this.state;
    const loadMore =
      !initLoading && !loading ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}
        >
          <Button onClick={this.onLoadMore}>loading more</Button>
        </div>
      ) : null;
    const { isLoaded, Notifcations, deactivated, not_aprooved, payment_due, Activated } = this.state;
    return (
     
       
        <Content className="scrollBox1" style={{ background: '#f2f3f5' }}>
        <PageHeader title={this.props.lang.drawerNotification} lang={this.props.lang.lang} className="HeaderTitile" style={{ marginTop:10}}/>
        <Container>
          
            {deactivated &&
              <Deactivated_card />
            }
            {not_aprooved &&
              <Not_aprooved_card />
            }
            {payment_due &&
              <Payement_due_card />
            }
            {Activated &&
              <div style={{marginBottom:20}}>
                {isLoaded
                  ? <Loadingplaceholder />
                  : <List
                  grid={{ gutter: 10,  xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2,}}
                    loading={initLoading}
                    loadMore={loadMore}
        
                    dataSource={this.state.data}
                    renderItem={item => (
                      <List.Item style={{marginBottom:10}}>
                       <Skeleton avatar title={false} loading={item.loading} active><Card style={{ borderRadius: 0 }}>
                        <CardBody >
                          <Row>
                            <Col xs="2" sm="3" md="3" lg="3" xl="2" >
                              {item.image1 ? <CardImg alt="logo" src={item.image1} style={{ borderRadius: 5}} />
                                : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} style={{ borderRadius: 5 }} />}
                            </Col>
                            <Col xs="10" sm="9" md="9" lg="9" xl="10">
                            
                          
                             <div style={{float:'left'}}>
                             <Link to={`/UserApp/Details/${item.user_id}`} target="_blank"  className="UserNotViewHead">{item.Name}</Link></div>
                            
                             <div style={{float:'right'}}>
                             <a className="NotificationDate" >{dateFormat((item.date), "mmm d, yyyy")}</a> 
                             </div> <br style={{clear:'both'}}/>
                           
                             
<div >
                              <p className="NotificationHeading" ><span style={{fontWeight:"500"}}>{item.subject}</span> - {item.heading} {item.Name}</p>
                              </div>
                            </Col>
                            
                          </Row>
                         
                        </CardBody>
                      </Card>
                     
             
            </Skeleton>
                      </List.Item>
                    )}
                  />
                }
              </div>
            }
        
             </Container>
        </Content>
     
    );
  }
}
function mapStateToProps(state) {
  return {
    User: state.User,
    lang:state.lang
  }
}
function mapDispatchToProps(dispatch) {
  return {
   
    loadNotification: (user_id) => {
      dispatch(loadNotification(user_id));
    }
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(UserNotification);