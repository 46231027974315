import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
class Printpage_Grid extends Component {
    render() {
        let Diocese =null;
        let i=0;
            this.props.drop.map((item) => {
                if(item.id === this.props.Diocese ){
                    Diocese = item.Name;
                  }
                })
        return (
            <div style={{margin:50}}>
                <h5 style={{textAlign:'center'}}>Report</h5>
                <span>Diocese : </span><span>{Diocese}</span>
                <Row>
                {this.props.data.map((item) => (
                    <Col sm="4" xs="4">
                         <table className="printTbl" style={{width:'100%',marginTop:50}}>
                         <tr>
                    <th className="pth">No/Diocese</th>
                    <td className="ptd">{i=i+1}/{Diocese}</td>
                      
                    </tr>
                    <tr>
                    <th className="pth">Name</th>
                    <td className="ptd">{item.Name}</td>
                      
                    </tr>
                        <tr>
                        <th className="pth">House Name</th>
                            <td className="ptd">{item.HName} </td>
                        </tr>
                        <tr>
                       
                        <th className="pth">UserID</th>
                        <td className="ptd">{item.UserId}</td>
                    </tr>
                        <tr>
                        <th className="pth">Password</th>
                            <td className="ptd">{item.Password}</td>
                           
                        </tr>
                </table>
                    </Col>
                    )
                )}
                </Row>
            </div>
        );
    }
}
export default Printpage_Grid;
