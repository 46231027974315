import * as types from '../Actions/Types';
const response = {
    auth: false,
    user_id: 0,
    userName: null,
    propic: null,
    state: 0
};
const login = (data, parse) => {
    return parse;
}
const Logout = (data, parse) => {
    return response;
}
const refresh = (data, parse) => {
    return parse;
}

const UserLoginReducer = (state = response, action) => {
    switch (action.type) {
        case types.LOGIN: {
            return login(state, action.payload);
        }
        case types.LOGOUT: {
            return Logout(state, action.payload);
        }
        case types.REFRESH: {
            return refresh(state, action.payload);
        }
        default: {
            return state;
        }

    }
}
export default UserLoginReducer;