import * as types from './Types';
export const loadintrestedAction = (data) => ({
    type: types.LOAD_INTRESTED,
    payload: data
});
export const addintrestedAction = (data) => ({
    type: types.ADD_INTRESTED,
    payload: data
});
export const removeintrestedAction = (data) => ({
    type: types.REMOVE_INTRESTED,
    payload: data
});
export const updateintrestedAction = (data) => ({
    type: types.UPDATE_INTRESTED,
    payload: data
});

export const intrestedrefreshsucessAction = () => ({
    type: types.REFRESH_INTRESTED_SUCCESS
});
