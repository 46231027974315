import * as types from '../Actions/Types';
const response = {
    profiles: [],
    refresh: false
};

const addshortlist = (data, parse) => {
    return [...data, parse];
}

const removeshortlist = (data, parse) => {
    let foundIndex = data.findIndex(x => x.user_id === parse.user_id);
    const stateTemp = [
        ...data.slice(0, foundIndex),
        ...data.slice(foundIndex + 1)
    ];
    return stateTemp;
}

const ShortlistReducer = (state = response, action) => {
    switch (action.type) {
        case types.LOAD_SHORTLIST: {
            return {
                ...state,
                refresh: false,
                profiles: action.payload
            }
        }

        case types.ADD_SHORTLIST: {
            return {
                ...state,
                refresh: true,
                profiles: addshortlist(state.profiles, action.payload)
            }
        }

        case types.REMOVE_SHORTLIST: {
            return {
                ...state,
                refresh: true,
                profiles: removeshortlist(state.profiles, action.payload)
            }
        }

        case types.REFRESH_SHORTLIST_SUCCESS:
            return {
                ...state,
                refresh: false
            }

        default: {
            return state;
        }
    }
}
export default ShortlistReducer;