import React, { Component } from 'react';
import { Container} from 'reactstrap';
import { Card,Divider,List,Spin } from 'antd';
import FooterS from '../../components/Footer';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import './styles.css';
import API from '../../API';
const { Meta } = Card;

class Packages extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
          slides: [],
          isLoaded: false,
         
        };
   
    }
    componentDidMount = () => {
        
      this.send();
       
    }
    send = () => {
      fetch(API.BASE_URL + API.SuccessStory, {
          method: 'POST',
          headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
          }),
         
      }).then((response) => response.json())
          .then((responsesJson) => {
              this.setState({
               slides: responsesJson.Story,isLoaded:true
                              
              })
          })
          .catch((error) => {
              //message.error('Error. Try again !');
              alert(error)
          });
  }


    render() {
      const { isLoaded } = this.state;
        return (
           
            <div>
                 <div className="colorcontainer" style={{ height: 130 }}>
                <Container >
                        <h5 style={{ color: '#fff', paddingTop: 40 }}>{this.props.lang.SuccessHead}</h5>
                        <p style={{ color: '#fff', paddingBottom: 10 }}>{this.props.lang.Successsub}</p>
                        </Container>
                    </div>
                    <Container style={{marginTop:20}}>
                    {!isLoaded &&
                            <div className="example">
                            <Spin />
                          </div>
                        }
                   
                   {isLoaded &&
                             <List
                             grid={{
                               gutter: 16,
                               xs: 1,
                               sm: 2,
                               md: 4,
                               lg: 4,
                               xl: 6,
                               xxl: 3,
                             }}
                             dataSource={this.state.slides}
                             renderItem={item => (
                               <List.Item>
                                 <Card    style={{ textAlign: 'center', margin: 10 }}  hoverable 
                                    cover={<img alt="example" src={item.Photo}  className="CardImage" />}>
                                    <Meta style={{textTransform:'capitalize'}} title={item.Name} />
                                 </Card>
                               </List.Item>
                             )}
                           />
                        }
                  
                  </Container>

         
            <FooterS/>
        </div>
       
        )
    }
}
function mapStateToProps(state) {
    return {
      
        lang: state.lang
    }
}

export default connect(mapStateToProps )(Packages);