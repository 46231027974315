const response ={alert:false};
const hide = (data,parse) =>{
    let dd ={alert:true};
    return dd;
    }
const alertReducer = (state = response, action) =>{
    switch (action.type) {
        case 'HIDE':{
            return hide(state,action.payload);
        }
        default:{
            return state;
        }
               
    }
    }
    export default alertReducer ;