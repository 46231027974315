import React, { Component } from 'react';

import { Layout, Menu, Icon, Modal,Button} from 'antd';

import {Row, Col,CardImg} from 'reactstrap';
import { Link } from 'react-router-dom';
import ProtectedRouter from '../components/ProtectedRouter';
import { connect } from 'react-redux';
//screens
import AdminHome from './AdminHome';

import AdminUsers_Active from './AdminUsers_Active';
import AdminUser_NewReg from './AdminUser_NewReg';
import AdminUser_Deactive from './AdminUser_Deactive';
import AdminUser_Delete from './AdminUser_Delete';
import AdminUser_Photo from './AdminUser_Photo';
import AdminUser_Expiry from './AdminUser_Expiry';
import AdminUser_Photo_Male from './AdminUser_Photo_Male';
import AdminUser_All from './AdminUser_All';
import AdminUser_Search from './UserSearch/UserSearch';
import AdminUser_Backup from './AdminUser_Backup';
import AdminUser_LastSeen from './AdminUser_LastSeen';
import AdminLog from './AdminLog';
import AdminDropDowns from './AdminDropDowns';
import AddUser from './Add_User';
import AdminUserprofiles from './AdminUserprofiles';
import Adminreport from './Adminreport';
import AdminContact from './AdminContatct';
import AdminAccepted from './AdminAccepted';
import View_contact from './view_contact';
import AdminPayment from './AdminPayment';
import UserSearchResult from './AdminSearchResult';
import EditProfile from './EditProfile';
import EditContact from './EditContact';
import ProfileDelete from './ProfileDelete';
import PhotoDelete from './PhotoDelete';
import ProfileReport from './ProfileReport';
import Inforamation from './Inforamation';
import SuccessStory from './SuccessStory';
//end screens
const { Content, Sider } = Layout;
const { SubMenu } = Menu;
class AdminApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confermlog: false
        };
    }
    toggle = () => {
        this.setState({
            collapsed: true
        });
    };
    logout = () => {
        this.setState({ confermlog: true })
    }
    conferm_logout = () => {
        const response = { auth: false };
        this.props.AdminLogout(response);
        this.setState({ confermlog: false });
    }
    render() {
        return (
            <Layout>
                <Layout>
                    <Sider width={240}
                        breakpoint="lg"
                        collapsedWidth="0"
                        onBreakpoint={broken => {
                           // console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            //console.log(collapsed, type);
                        }}>
                        <div className="logo" />
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100%' }}
                        > <Menu.Item key="0" style={{height:60}}>
                            
                            <span style={{fontSize:25,fontWeight:'bold'}}>Admin</span>
                                </Menu.Item>
                            <Menu.Item key="1">
                                <Link to='/AdminApp/AdminHome' style={{ textDecoration: 'none' }}>
                                    <Icon type="home" theme="outlined" />
                                    <span style={{  fontSize: 13 }}>Home</span>
                                </Link>
                            </Menu.Item>
                            <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="user" />
              <span>User Details</span>
            </span>
          }
        >
                            <Menu.Item key="2">
                                <Link to='/AdminApp/AdminUsers_Active' style={{ textDecoration: 'none' }}>
                                  
                                    <span style={{  fontSize: 13 }}>User - Active</span>
                                </Link>
                            </Menu.Item>
                          
                            <Menu.Item key="3">
                                <Link to='/AdminApp/AdminUser_NewReg' style={{ textDecoration: 'none' }}>
                                
                                    <span style={{  fontSize: 13 }}>User - New Registration</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Link to='/AdminApp/AdminUser_Deactive' style={{ textDecoration: 'none' }}>
                                 
                                    <span style={{  fontSize: 13 }}>User - Deactive</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="5">
                                <Link to='/AdminApp/AdminUser_Delete' style={{ textDecoration: 'none' }}>
                              
                                    <span style={{  fontSize: 13 }}>User - Delete</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="15">
                                <Link to='/AdminApp/AdminUser_Photo' style={{ textDecoration: 'none' }}>
                              
                                    <span style={{  fontSize: 13 }}>User - Photo - Female</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="16">
                                <Link to='/AdminApp/AdminUser_Photo_Male' style={{ textDecoration: 'none' }}>
                              
                                    <span style={{  fontSize: 13 }}>User - Photo - Male</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="17">
                                <Link to='/AdminApp/AdminUser_Expiry' style={{ textDecoration: 'none' }}>
                              
                                    <span style={{  fontSize: 13 }}>User - Expiry</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <Link to='/AdminApp/AdminUser_All' style={{ textDecoration: 'none' }}>
                                 
                                    <span style={{ fontSize: 13 }}>User - All</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="18">
                                <Link to='/AdminApp/AdminUser_Search' style={{ textDecoration: 'none' }}>
                                 
                                    <span style={{ fontSize: 13 }}>User - Search</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="16">
                                <Link to='/AdminApp/AdminUser_lastSeen' style={{ textDecoration: 'none' }}>
                                 
                                    <span style={{ fontSize: 13 }}>User - Last Seen</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="25">
                                <Link to='/AdminApp/AdminUser_Backup' style={{ textDecoration: 'none' }}>
                                 
                                    <span style={{ fontSize: 13 }}>User - Backup</span>
                                </Link>
                            </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="7">
                                <Link to='/AdminApp/AdminPayment' style={{ textDecoration: 'none' }}>
                                    <Icon type="money-collect" />
                                    <span style={{  fontSize: 13 }}>Payment</span>
                                </Link>
                            </Menu.Item>

                            <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="user" />
              <span>Delete Request</span>
            </span>
          }
        >
                        <Menu.Item key="21">
                                <Link to='/AdminApp/ProfileDelete' style={{ textDecoration: 'none' }}>
                                    <Icon type="money-collect" />
                                    <span style={{  fontSize: 13 }}>Profile Delete Request</span>
                                </Link>
                            </Menu.Item>
                          
                            <Menu.Item key="22">
                                <Link to='/AdminApp/PhotoDelete' style={{ textDecoration: 'none' }}>
                                    <Icon type="money-collect" />
                                    <span style={{  fontSize: 13 }}>Photo Delete Request</span>
                                </Link>
                            </Menu.Item>
                          
                            </SubMenu>

                            <SubMenu
          key="sub1"
          title={
            <span>
              <Icon type="user" />
              <span>Edit Request</span>
            </span>
          }
        >
                        <Menu.Item key="19">
                                <Link to='/AdminApp/EditProfile' style={{ textDecoration: 'none' }}>
                                    <Icon type="money-collect" />
                                    <span style={{  fontSize: 13 }}>Edit Profile Request</span>
                                </Link>
                            </Menu.Item>
                          
                            <Menu.Item key="20">
                                <Link to='/AdminApp/EditContact' style={{ textDecoration: 'none' }}>
                                    <Icon type="money-collect" />
                                    <span style={{  fontSize: 13 }}>Edit Contact Request</span>
                                </Link>
                            </Menu.Item>
                          
                            </SubMenu>
                           
                            <Menu.Item key="23">
                                <Link to='/AdminApp/ProfileReport' style={{ textDecoration: 'none' }}>
                                    <Icon type="book"  />
                                    <span style={{  fontSize: 13 }}>Profile Report</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="8">
                                <Link to='/AdminApp/AdminLog' style={{ textDecoration: 'none' }}>
                                    <Icon type="book"  />
                                    <span style={{  fontSize: 13 }}>Logs</span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="9">
                                <Link to='/AdminApp/AdminDropDowns' style={{ textDecoration: 'none' }}>
                                    <Icon type="select" />
                                    <span style={{ fontSize: 13 }}>Dropdowns</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="24">
                                <Link to='/AdminApp/Inforamation' style={{ textDecoration: 'none' }}>
                                    <Icon type="select" />
                                    <span style={{ fontSize: 13 }}>Information Message</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="10">
                                <Link to='/AdminApp/reports' style={{ textDecoration: 'none' }}>
                                    <Icon type="database" />
                                    <span style={{  fontSize: 13 }}>Reports</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="11">
                                <Link to='/AdminApp/AdminContact' style={{ textDecoration: 'none' }}>
                                    <Icon type="message"  />
                                    <span style={{ fontSize: 13 }}>Contacts</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="26">
                                <Link to='/AdminApp/SuccessStory' style={{ textDecoration: 'none' }}>
                                    <Icon type="user"  />
                                    <span style={{ fontSize: 13 }}>Success Story</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="12">
                                <Link to='/AdminApp/AdminAccepted' style={{ textDecoration: 'none' }}>
                                    <Icon type="check-circle" />
                                    <span style={{  fontSize: 13 }}>Accepted</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="13">
                                <Link to='/AdminApp/View_contact' style={{ textDecoration: 'none' }}>
                                    <Icon type="user"  />
                                    <span style={{  fontSize: 13 }}>View Contact quota</span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="14" onClick={() => { this.logout() }}>
                                <Icon type="logout" style={{  color: '#db2a2a' }} />
                                <span style={{ fontSize: 13, color: '#db2a2a' }}>Logout</span>
                            </Menu.Item>

                        </Menu>
                    </Sider>
                    <Layout>

                        <Content style={{ overflow: 'initial' }}>
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminHome' component={AdminHome} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminAccepted' component={AdminAccepted} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_Deactive' component={AdminUser_Deactive} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUsers_Active' component={AdminUsers_Active} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_NewReg' component={AdminUser_NewReg} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_Delete' component={AdminUser_Delete} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_Photo' component={AdminUser_Photo} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_Expiry' component={AdminUser_Expiry} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_Photo_Male' component={AdminUser_Photo_Male} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_All' component={AdminUser_All} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_Backup' component={AdminUser_Backup} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_Search' component={AdminUser_Search} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUser_LastSeen' component={AdminUser_LastSeen} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminLog' component={AdminLog} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminPayment' component={AdminPayment} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminDropDowns' component={AdminDropDowns} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/EditProfile' component={EditProfile} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/EditContact' component={EditContact} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AddUser/:data' component ={AddUser}/>
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/reports' component ={Adminreport}/>
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminContact' component ={AdminContact}/>
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/ProfileDelete' component ={ProfileDelete}/>
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/PhotoDelete' component ={PhotoDelete}/>
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/ProfileReport' component ={ProfileReport}/>
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/AdminUserprofiles/:id' component={AdminUserprofiles} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/Inforamation' component={Inforamation} />
                            <ProtectedRouter authed={this.props.authA.auth} path='/AdminApp/SuccessStory' component={SuccessStory} />
                            <ProtectedRouter authed={this.props.authA.auth}
                                path='/AdminApp/UserSearchResult/:Diocese/:Age_from/:Age_to/:H_from/:H_to/:M_status/:W_place/:p_status/:E_cat/:W_cat/:Religion/:Place/:FStatus/:Gender' component={UserSearchResult} />
                         
                        </Content>
                    </Layout>
                </Layout>
                <Modal
                    centered
                    visible={this.state.confermlog}
                    footer={false}
                    closable={false}
                    width={400}
                >
                    <div className="aligitemncenter">
                        <span style={{ fontSize: 20, fontWeight: 'bolder' }}>Logout from Admin ?</span>
                    </div>
                    <Row style={{ marginTop: 30 }}>
                        <Col sm="6" xs="6">
                            <Button block  type="danger" onClick={() => { this.setState({ confermlog: false }) }} ghost>
                                Cancel
                            </Button>
                        </Col>
                        <Col sm="6" xs="6">
                            <Button block  type="primary" onClick={() => { this.conferm_logout() }} style={{ backgroundColor: '#0070ba', borderColor: '#0070ba' }}>
                                Logout
                            </Button>
                        </Col>
                    </Row>


                </Modal>
            </Layout>
        )

    }

}

function mapStateToProps(state) {
    return {
        authA: state.authA,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        AdminLogout: (data) => {
            dispatch({ type: 'ADMINLOGOUT', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminApp);