import React, { Component } from 'react';
class Printpage_table extends Component {
    render() {
        let Diocese = null;
        let i = 0;
        this.props.drop.map((item) => {
            if (item.id === this.props.Diocese) {
                Diocese = item.Name;
            }
        })
        return (
            <div style={{ margin: 50 }}>
                <h5 style={{ textAlign: 'center' }}>Report</h5>
                <span>Diocese : </span><span>{Diocese}</span>
                <table className="printTbl" style={{ width: '100%' }}>
                    <tr><th className="pth">SNo</th>
                       
                        <th className="pth">Full Name</th>
                        <th className="pth">Gender</th>
                        <th className="pth">Mob</th>
                       
                    </tr>
                    <tbody>
                        {this.props.data.map((item) => (
                            <tr> <td className="ptd">{i = i + 1}</td>
                              
                                <td className="ptd">{item.Name}</td>
                                <td className="ptd">{item.Gender}</td>
                                <td className="ptd">{item.MainMob}</td>
                              
                            </tr>
                        ))}
                    </tbody>

                </table>

            </div>
        );
    }
}
export default Printpage_table;
