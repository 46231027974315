import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.css';
import { List,message,PageHeader,Layout} from 'antd';

import Loadingplaceholder from '../../components/Loading_placeholder';
import Deactivated_card from '../../components/Deactivated_card';
import Not_aprooved_card from '../../components/not_aprooved_card';
import Payement_due_card from '../../components/payement_due_card';
import API from '../../API';
import ListItem2 from '../../components/ListItem2/Lisitem2';
import {shortlistrefreshsucessServices} from '../../redux/Services/ShortListServices';
import {  Container } from 'reactstrap';

const { Content } = Layout;

class userShortlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      shortlsited: [],
      Activated: false,
      deactivated: false,
      not_aprooved: false,
      payment_due: false,
    };
    this.shortlsited_array = [];
  }
  componentDidMount(){
    this.loaddata();
  }
  loaddata = () => {
    if (this.props.User.Status === 'A') {/// ---A--- activated account
      this.setState({ Activated: true });
      message.config({ top: 100 });
      let obj = { user_id: this.props.User.user_id }
      fetch(API.BASE_URL + API.GET_SHORTLIST, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: JSON.stringify(obj)
      })
        .then(res => res.json())
        .then(
          (result) => {
            //message.success('success');
            this.setState({
              isLoaded: false,
              shortlsited: result.reverse()
            },
              function () {
                this.shortlsited_array = result;
              });
          },
          (error) => {
            message.error('Loading faild');
            alert(error);
            this.setState({
              isLoaded: false,
              error
            });
          }
        )
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.Shortlist.refresh === true) {
      this.loaddata();
      this.props.shortlistrefreshsucessServices()
    }
  }


  render() {
    const { isLoaded,shortlsited} = this.state;
    return (
      <Content className="scrollBox1" style={{ background: '#f2f3f5' }}>
          <PageHeader title={this.props.lang.drawerShortList} lang={this.props.lang.lang} className="UserIntrestDrop" style={{ marginTop:10,marginBottom:-30}}/>
      <Container>

           
        <div className="shortlist">
            {this.props.User.Status === 'D'
              ?<Deactivated_card />
              :null
            }
            {this.props.User.Status === 'N'
              ?<Not_aprooved_card />
              :null
            }
            {this.props.User.Status === 'P'
              ?<Payement_due_card />
              :null
            }
            {this.props.User.Status === 'A'
              ?<div>
                {isLoaded
                  ? <Loadingplaceholder />
                  : <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={shortlsited}
                    renderItem={item => (
                      <ListItem2
                      item={item}/>
                    )}
                  />
                }
              </div>
              :null
            }
        </div>
        </Container>
        </Content>
     
    );
  }
}
function mapStateToProps(state) {
  return {  
    User: state.User,
    Shortlist:state.Shortlist,
    lang:state.lang
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    shortlistrefreshsucessServices: () => {
      dispatch(shortlistrefreshsucessServices());
    }
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(userShortlist);