import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './commonstyles.css';
import { Icon, Modal } from 'antd';
import { Row, Col } from 'reactstrap';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { intrestedService } from '../redux/Services/IntrestedSerives';
class IntrestedButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modal2Visible: false
        }
    }

    request = (act, intrested) => {
        this.setState({ modal2Visible: false })
        this.props.intrestedService(this.props.User.user_id, this.props.target, act, intrested)
    }
    render() {
        return (
            <div>
                {this.props.Intrested
                    ? <div>
                        {this.props.Intrested.intrested === "IN"
                            ? <div>
                                {this.props.Intrested.Istatus === 'Requested' &&
                                    <button className="listiteminrequestbtn" lang={this.props.lang.lang} onClick={() => this.setState({ modal2Visible: true })}>
                                      
                                        {this.props.lang.Waiting} 
                            </button>}
                                {this.props.Intrested.Istatus === 'Accepted' &&
                                    <button className="listitemacceptbtn" lang={this.props.lang.lang} onClick={() => this.setState({ modal2Visible: true })}>
                                      
                                        {this.props.lang.Accepted}    
                            </button>}
                                {this.props.Intrested.Istatus === 'Rejected' &&
                                    <button className="listiteminrejectbtn" lang={this.props.lang.lang} onClick={() => this.setState({ modal2Visible: true })}>
                                       
                                        {this.props.lang.Rejected}
                            </button>}
                            </div>
                            : <div> {/*OUT*/}
                                {this.props.Intrested.Istatus === 'Requested' &&
                                    <button className="listiteminrequestbtn" lang={this.props.lang.lang} onClick={() => this.setState({ modal2Visible: true })}>
                                     
                                        {this.props.lang.Waiting}    
                            </button>}
                                {this.props.Intrested.Istatus === 'Accepted' &&
                                    <button className="listitemacceptbtn" lang={this.props.lang.lang} onClick={() => this.setState({ modal2Visible: true })}>
                                    
                                        {this.props.lang.Accepted} 
                            </button>}
                                {this.props.Intrested.Istatus === 'Rejected' &&
                                    <button className="listiteminrejectbtn" lang={this.props.lang.lang} onClick={() => this.setState({ modal2Visible: true })}>
                                 
                                        {this.props.lang.Rejected}
                            </button>}
                            </div>}
                        <Modal
                            title="Interest Request"
                            centered
                            footer={false}
                            onCancel={() => this.setState({ modal2Visible: false })}
                            width={400}
                            visible={this.state.modal2Visible}
                        >{this.props.Intrested.intrested === "IN"
                            ? <Row>
                                <Col sm="6" xs="6">
                                    <button className="listpopupAcptbtn" lang={this.props.lang.lang}
                                        onClick={() => this.request('Accepted', 'IN')}>
                                        <CheckCircleOutlineIcon /><br />
                                        {this.props.lang.Accept} 
                            </button>
                                </Col>
                                <Col sm="6" xs="6">
                                    <button className="listpopuprjbtn" lang={this.props.lang.lang}
                                        onClick={() => this.request('Rejected', 'IN')}>
                                        <HighlightOffIcon /><br />
                                        {this.props.lang.Reject}
                            </button>
                                </Col>
                            </Row>
                            : <Row>
                                <Col sm="12" xs="12">
                                    <button className="listpopupcamcelbtn" lang={this.props.lang.lang}
                                        onClick={() => this.request('Canceld', 'OUT')}>
                                        <HighlightOffIcon /><br />
                                        {this.props.lang.cancelsendinterest} 
                                        </button>
                                </Col>
                            </Row>
                            }
                        </Modal>
                    </div>
                    : <button className="listiteminbtnIntrest" lang={this.props.lang.lang}
                        onClick={() => this.props.intrestedService(this.props.User.user_id, this.props.target, 'Requested', 'OUT')}>
                 
                        {this.props.lang.sendinterest}  
                        </button>
                }
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        intrestedService: (user_id, target, act, intrested) => {
            dispatch(intrestedService(user_id, target, act, intrested));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IntrestedButton);