

import React, { Component } from 'react';
import { Card, CardBody, Container,  Nav, NavItem, NavLink,Table} from 'reactstrap';
import { Button, Modal, Form, Input, Icon, Select, message } from 'antd';
import API from '../API';
import classnames from 'classnames';
const { TextArea } = Input;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
class EditContact extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            loading: true,
            error: false,
            activeTab: 'W',
            data: [],
            editID: null,
            Name: null,
            CTempStatus: null,
            form: false,
            FomTitle: 'Add New DropDown',
            Add: false,
            Edit: false,
            items: [
                { id: 1, Name: 'Requested', CTempstatus: 'W' },
                { id: 2, Name: 'Accepted', CTempstatus: 'A' },
                { id: 3, Name: 'Rejected', CTempstatus: 'R' },
       
            ]
        };
    }

    componentDidMount = () => {
        message.config({ top: 100 });
        fetch(API.BASE_URL + API.get_EditContactRequest, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data:responsesJson, loading: false })
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.error();
            });
    }

    set_defualt = () => {
        this.setState({
            editID: null,
            Name: null,
            CTempStatus: null,
            form: false,
            FomTitle: 'Add New DropDown',
            Add: false,
            Edit: false
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }




    Set_Edit = (item) => {
        console.log(item);
        this.setState({
            FomTitle: 'Edit Contact',
            form: true,
            Edit: true,
            Add: false,
            editID: item.id,
            Name: item.Name,
            user_id: item.user_id,HName1:item.HName1,HName:item.HName, Place1:item.Place1,Place:item.Place,Parish:item.Parish,Parish1:item.Parish1, Diocese:item.Diocese,Diocese1:item.Diocese1,
            Address1:item.Address1,Address:item.Address, PNumber1:item.PNumber1,PNumber:item.PNumber,Email1:item.Email1,Email:item.Email
        })
    }

    
    set_Accept = () => {
      
        this.setState({ loading: true });
        let obj = { HName: this.state.HName1,Place: this.state.Place1,Parish: this.state.Parish1,Diocese: this.state.Diocese1,Address: this.state.Address1,PNumber: this.state.PNumber1,
            Email: this.state.Email1,user_id:this.state.user_id, id:this.state.editID};
        fetch(API.BASE_URL + API.set_AcceptContact, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Accepted Successfully');
                    this.ProfilePercentage();
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }
    ProfilePercentage = () => {
        let obj = { user_id: this.state.user_id }
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
            alert("Error");
          });
      }
    set_Reject = () => {
      
        this.setState({ loading: true });
        let obj = {  id:this.state.editID };
        fetch(API.BASE_URL + API.set_RejectContact, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Rejected Successfully');
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }




    delete_item = () => {
        this.setState({ loading: true });
        let obj = { id:this.state.editID };
        fetch(API.BASE_URL + API.delete_RequestContact, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Deleted Successfully');
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }



    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleCancel = () => {
        this.setState({ form: false });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Edit Contact Request</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                        <div>
                          
                            <Nav tabs>
                                {this.state.items.map((item) => (
                                    <NavItem key={item.id}>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === item.CTempstatus })}
                                            onClick={() => { this.toggle(item.CTempstatus); }}>
                                            <span style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Name}</span>
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <div >
                            <Table style={{overflowX:"auto"}}>
                                <thead>
                                    <tr>
                                    <th>Action</th>
                                        <th>ID</th>
                                        
                                        <th>User ID</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>HName</th>
                                        <th>HName Edit</th>
                                        <th>Place</th>
                                        <th>Place Edit</th>
                                        <th>Parish</th>
                                        <th>parish Edit</th>
                                        <th>Diocese</th>
                                        <th>Diocese Edit</th>
                                        <th>Address</th>
                                        <th>Address Edit</th>
                                        <th>Phone</th>
                                        <th>Phone Edit</th>
                                        <th>Email</th>
                                        <th>Email Edit</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data && this.state.data.filter(book => book.CTempstatus === this.state.activeTab).map((item) => (
                                        <tr key={item.id}>
                                               <td style={{ fontWeight: 'bold', fontSize: 13 }}>
                                            <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.Set_Edit(item) }}>Edit</Button>
                                           
                                            </td>
                                            <th scope="row" style={{ fontWeight: 'bold', fontSize: 13 }}>{item.id}</th>
                                          
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.user_id}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Date}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Name}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.HName}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.HName1}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Place}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Place1}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Parish}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Parish1}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Diocese}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Diocese1}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Address}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Address1}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.PNumber}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.PNumber1}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Email}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Email1}</td>
                                         
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>
               
                <Modal
                    centered
                    title={this.state.FomTitle}
                    visible={this.state.form}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={1000}
                >
 <Form
      {...layout}
      name="basic"
   
    >
       <Form.Item label="Name">  
       <Input.Group compact>
                            <Input style={{ width: '50%' }} 
                              disabled
                                placeholder="Name"
                                name="Name"
                                value={this.state.Name}
                         
                            />
                          
                       
                            <Input style={{ width: '50%' }}
                                disabled
                                placeholder="userid"
                                name="userid"
                                value={this.state.user_id}
                             
                            />

</Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="House Name" style={{marginTop:-40}}>  
                        <Input.Group compact>
                           
                           


            <Input style={{ width: '50%' }}
                             disabled
                                placeholder="House Name"
                                name="HName"
                                value={this.state.HName}
                                onChange={this.handleChange}
                            />
       
           <Input placeholder="House Name" style={{ width: '50%' }}
                                name="HName1"
                                value={this.state.HName1}
                                onChange={this.handleChange}
                            />
      
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="Place" style={{marginTop:-40}}>  
                        <Input.Group compact>
            <Input disabled style={{ width: '50%' }}
                                placeholder="Place"
                                name="Place"
                                value={this.state.Place}
                                onChange={this.handleChange}
                            />
       
           <Input style={{ width: '50%' }}
                                placeholder="Place"
                                name="Place1"
                                value={this.state.Place1}
                                onChange={this.handleChange}
                            />
        
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="Parish" style={{marginTop:-40}}>  
                        <Input.Group compact>
            <Input disabled style={{ width: '50%' }}
                                placeholder="Parish"
                                name="Parish"
                                value={this.state.Parish}
                                onChange={this.handleChange}
                            />
      
        
           <Input style={{ width: '50%' }}
                                placeholder="Parish"
                                name="Parish1"
                                value={this.state.Parish1}
                                onChange={this.handleChange}
                            />
         
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="Diocese" style={{marginTop:-40}}>  
                        <Input.Group compact>
            <Input disabled style={{ width: '50%' }}
                                placeholder="Diocese"
                                name="Diocese"
                                value={this.state.Diocese}
                                onChange={this.handleChange}
                            />
       
         
           <Input style={{ width: '50%' }}
                                placeholder="Diocese"
                                name="Diocese1"
                                value={this.state.Diocese1}
                                onChange={this.handleChange}
                            />
      
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="Address" style={{marginTop:-40}}>  
                        <Input.Group compact>
                        <TextArea rows={4}  disabled style={{ width: '50%' }}
                                placeholder="Address"
                                name="Address"
                                value={this.state.Address}
                                onChange={this.handleChange}
                            />
        
        <TextArea rows={4} 
            style={{ width: '50%' }}
                                placeholder="Address"
                                name="Address1"
                                value={this.state.Address1}
                                onChange={this.handleChange}
                            />
         
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="PNumber" style={{marginTop:-40}}>  
                        <Input.Group compact>
            <Input disabled style={{ width: '50%' }}
                                placeholder="PNumber"
                                name="PNumber"
                                value={this.state.PNumber}
                                onChange={this.handleChange}
                            />
     
           <Input style={{ width: '50%' }}
                                placeholder="PNumber"
                                name="PNumber1"
                                value={this.state.PNumber1}
                                onChange={this.handleChange}
                            />
      
                             </Input.Group>
                        </Form.Item>
                        <br />
                        <Form.Item label="Email" style={{marginTop:-40}}>  
                        <Input.Group compact>
            <Input disabled style={{ width: '50%' }}
                                placeholder="Email"
                                name="Email"
                                value={this.state.Email}
                                onChange={this.handleChange}
                            />
        
           <Input style={{ width: '50%' }}
                                placeholder="Email"
                                name="Email1"
                                value={this.state.Email1}
                                onChange={this.handleChange}
                            />
        
                             </Input.Group>
                        </Form.Item>
                       
      <Form.Item {...tailLayout}>
      <Button type="defult" onClick={() => { this.setState({ form: false }) }} style={{ marginRight: 10 }}>Cancel</Button>
      <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.set_Accept() }}>Accept</Button>
                                                <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.set_Reject() }}>Reject</Button>
                                                <Button ghost size="small" type="danger" onClick={() => { this.delete_item() }}>Remove</Button>
      </Form.Item>
    </Form>


                   
                </Modal>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </Container>
        )
    }
}
export default Form.create()(EditContact);