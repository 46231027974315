import React, { Component } from 'react';
import './styles.css'
import { Row, Col } from 'reactstrap';
import { message, List, Layout, Input, Select, PageHeader, Icon,Tabs ,Divider,Button,Modal,Form} from 'antd';
import {
    Card,CardHeader,CardBody, CardImg,CardFooter,CardText,
    CardTitle, CardSubtitle ,Container
  } from 'reactstrap';

import { Tab, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ListItem2 from '../../components/ListItem2/Lisitem2';
import Loadingplaceholder from '../../components/Loading_placeholder';
import Deactivated_card from '../../components/Deactivated_card';
import Not_aprooved_card from '../../components/not_aprooved_card';
import Payement_due_card from '../../components/payement_due_card';
import API from '../../API';
import dateFormat from 'dateformat';
import { connect } from 'react-redux';
import {intrestedrefreshsucessServices} from '../../redux/Services/IntrestedSerives';
const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
class UserMessage extends Component {
    reset = () => {
        this.props.form.resetFields()
      }
      handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            console.log('Received values of form: ', values);
          }
        });
      };
      handleSelectChange = value => {
        console.log(value);
        this.props.form.setFieldsValue({
         
          noteRply: `${value === value ? value : ''}`
        });
     
      this.setState({noteRply:`${value}`})
      };
      handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            activeTab: '1',
            Intrested: [],
            requested: [],
            msgTempRplydb:[],
            Activated: false,
            deactivated: false,
            not_aprooved: false,
            payment_due: false,
        };
        this.SendMsg = [];
        this.IncomeMsg = [];
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentDidMount(){
        this.loaddata()
    }
    DeleteMsg = (id) => {
        
        let obj = {
          RplyId: id
       
        };
        fetch(API.BASE_URL + API.DeleteMsg, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({ isLoaded: true });
              if(result.status === 'success'){
               message.success('Message Delete successfully',3);
               

                this.loaddata();
                
              }
             
              else{
                message.error('Failed');
              }
            },
            (error) => {
              message.error('error');
              this.setState({isLoaded: true});
            }
          )
      }
      ReadMsgTemp = (setId) => {
    
        fetch(API.BASE_URL + API.ReadMsgTemp, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        
        })
          .then((response) => response.json())
          .then((responsesJson) => {

            this.setState({
              isLoaded: false,
              msgTempRplydb: responsesJson.msgTempRplydb,
              ModalMSGReply: true,
              MsgRplyId:setId
            });

           
          })
          .catch((error) => {
            this.setState({
              isLoaded: false,
            });
           // this.refs.toast.show('Something went wrong!');
            //console.error(error);
          });
      }
    
     
    DeleteMsgMain = (id) => {
        
        let obj = {
          RplyId: id
       
        };
        fetch(API.BASE_URL + API.DeleteMsgMain, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({ isLoaded: true });
              if(result.status === 'success'){
               message.success('Message Delete successfully',3);
               

                this.loaddata();
              
              }
             
              else{
                message.error('Failed');
              }
            },
            (error) => {
              message.error('error');
              this.setState({isLoaded: true});
            }
          )
      }
      SendMsgRply = () => {
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {

        let obj = {
          RplyId: this.state.MsgRplyId,
       
          Message : this.state.noteRply
        };
        fetch(API.BASE_URL + API.SendMsgRply, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({ isLoaded: true });
              if(result.status === 'success'){
                message.success('Message sent successfully',3);
               
                this.loaddata();
                this.reset();
                this.setState({ ModalMSGReply:false,noteRply:null});
               
              }
            
              else{
                message.error('Failed');
              }
            },
            (error) => {
              message.error('error');
              this.setState({isLoaded: true});
            }
          )
        }
    });
      }
    loaddata = () => {
        message.config({ top: 100 });
        if (this.props.User.Status === 'A') {/// ---A--- activated account
            this.setState({ Activated: true });
            let obj = { user_id: this.props.User.user_id }
            fetch(API.BASE_URL + API.GetReadMessage, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify(obj)
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // message.success('success');
                        this.setState({
                            isLoaded: false,
                            SendMsg: result.SendMsg,
                            IncomeMsg: result.IncomeMsg

                        },
                            function () {
                                this.SendMsg = result.SendMsg;
                                this.IncomeMsg = result.IncomeMsg;
                            });
                    },
                    (error) => {
                        message.error('Loading faild');
                        alert(error);
                        this.setState({
                            isLoaded: false,
                            error
                        });
                    }
                )
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.Intrested.refresh === true) {
          this.loaddata();
          this.props.intrestedrefreshsucessServices();
        }
      }

    //input text
    SearchSend(text) {
        const newData = this.SendMsg.filter(function (item) {
            const itemData = item.Name.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ SendMsg: newData, text: text })
    }
    SearchIncomeMsg(text) {
        const newData = this.IncomeMsg.filter(function (item) {
            const itemData = item.Name.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ IncomeMsg: newData, text: text })
    }
    //end inputtext

    //dropdowns
    SelectIntrested(text) {
        if (text.target.value === 'All') {
            this.componentDidMount();
        }
        else {
            const newData = this.arrayIntrested.filter(function (item) {
                const itemData = item.Istatus.toUpperCase()
                const textData = text.target.value.toUpperCase()
                return itemData.indexOf(textData) > -1
            })
            this.setState({ Intrested: newData, text: text })
        }
    }
    Selectequested(text) {
        if (text.target.value === 'All') {
            this.componentDidMount();
        }
        else {
            const newData = this.arrayrequested.filter(function (item) {
                const itemData = item.Istatus.toUpperCase()
                const textData = text.target.value.toUpperCase()
                return itemData.indexOf(textData) > -1
            })
            this.setState({ requested: newData, text: text })
        }
    }
    //end dropdowns
    render() {
        const { isLoaded, SendMsg,IncomeMsg, deactivated, not_aprooved, payment_due, Activated } = this.state;
        const {  mode } = this.state;
        const { getFieldDecorator } = this.props.form;
        
    
        return (
          <Content style={{ background: '#f2f3f5' }}>
        <PageHeader title={this.props.lang.Messages} lang={this.props.lang.lang} className="UserIntrestDrop" style={{ marginTop:10}}/>
         
            
            <Card style={{margin:10,marginTop:0}}>
           
            <CardBody style={{paddingTop:0,background:'#fafafa'}}>
           

                        {this.props.User.Status === 'D' &&
                            <Deactivated_card />
                        }
                        {this.props.User.Status === 'N' &&
                            <Not_aprooved_card />
                        }
                        {this.props.User.Status === 'P' &&
                            <Payement_due_card />
                        }
                        {this.props.User.Status === 'A' &&
<Tabs defaultActiveKey="1" tabPosition={mode} tabBarStyle={{ marginTop: 11, fontSize: 10 }} size={'large'} tabBarGutter={0} >
                 
                    <TabPane tab={this.props.lang.UserMessageIncomeMsg} key={1} style={{ fontSize: 22 }}>
                  
                        <Row style={{ marginTop: -10, marginBottom: 5 ,background:'#fafafa'}}>
                        <Col sm="3" xs="0" sm="3" md="3" lg="3" xl="3"></Col>
                                        <Col  xs="12" sm="6" md="6" lg="6" xl="6" ><Input placeholder={this.props.lang.UserInterestPlaceHolder} lang={this.props.lang.lang} className="UserIntrestDrop"
                                            onChange={(text) => this.SearchIncomeMsg(text)} /></Col>
                                     
                                    </Row>

                                    {isLoaded
                                        ? <Loadingplaceholder />
                                        : <List
                                            itemLayout="horizontal"
                                            size="large"
                                           
                                            dataSource={IncomeMsg}
                                            renderItem={item => (
                                                <List.Item style={{marginBottom:-15,borderBottom:0}}>
<Container>
<Card  >
                      <CardBody>
                          <Row>
                            
                          <Col xs="4" sm="3" md="3" lg="3" xl="2" >
                                                        {item.image1 ? <CardImg alt="logo" src={item.image1} style={{ borderRadius: 5 }} />
                                                          : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} style={{ borderRadius: 5 }} />}
                                                      </Col>

                            <Col  xs="8" sm="9" md="9" lg="9" xl="10">
                            <CardTitle><h5><Link to={`/UserApp/Details/${item.UserID}`} target="_blank"  >{item.Name}</Link></h5></CardTitle>   
                            <CardText style={{marginBottom:7}}>  {item.Msg} </CardText>
                            <small className="text-muted"> {dateFormat((item.SendDate), "yyyy-mmm-dd")}</small>
                           
                            </Col>
                           
                          </Row>
                          {item.RplyDate ? <div>
                          <Divider orientation="left" style={{fontSize:14}}>{this.props.lang.RplyMsg}</Divider>

                          <Row>
                          <Col  xs="1" sm="1" md="1" lg="1" xl="1">
                          
                          
                          </Col>
                            <Col  xs="11" sm="11" md="11" lg="11" xl="11">
                          
                            <CardText>  {item.Rply} </CardText>
                            <small className="text-muted">{dateFormat((item.RplyDate), "yyyy-mmm-dd")}</small>
                            
                            </Col>
                           
                          </Row></div>
:
<div></div>
}


                          </CardBody>
                          <CardFooter>   {item.RplyDate ? 
                             <Button type="danger" onClick={() =>  {this.DeleteMsg(item.id)}} >{this.props.lang.DeleteReply}</Button>
                 :
                
                   <Button onClick={() => {  this.ReadMsgTemp(item.id ) }}>{this.props.lang.Reply}</Button>
                
                          }
                          <Link to={`/UserApp/Details/${item.UserID}`} target="_blank" style={{float:"right"}} > <Button >{this.props.lang.popupviewprofile}</Button></Link>
                          </CardFooter>
                      </Card>
                      </Container>             
                                                </List.Item>
                                              )}
                                        />
                                    }
                     

                    </TabPane>
                    <TabPane tab={this.props.lang.UserMessageSentMsg} key={2}>
                  
                        <Row style={{ marginTop: 10, marginBottom: 10 ,background:'#fafafa'}}>
                        <Col sm="3" xs="0" sm="3" md="3" lg="3" xl="3"></Col>
                                        <Col  xs="12" sm="6" md="6" lg="6" xl="6" ><Input placeholder={this.props.lang.UserInterestPlaceHolder} lang={this.props.lang.lang} className="UserIntrestDrop"
                                            onChange={(text) => this.SearchSend(text)} /></Col>
                                     
                                    </Row>

                                    {isLoaded
                                        ? <Loadingplaceholder />
                                        : <List
                                            itemLayout="horizontal"
                                            size="large"
                                           
                                            dataSource={SendMsg}
                                            renderItem={item => (
                                                <List.Item style={{marginBottom:-15,borderBottom:0}}>
<Container>
<Card >
                      <CardBody>
                          <Row>
                            
                          <Col xs="2" sm="2" md="2" lg="2" xl="2" >
                                                        {item.image1 ? <CardImg alt="logo" src={item.image1} style={{ borderRadius: 5 }} />
                                                          : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} style={{ borderRadius: 5 }} />}
                                                      </Col>

                            <Col  xs="10" sm="10" md="10" lg="10" xl="10">
                            <CardTitle><h5><Link to={`/UserApp/Details/${item.TarID}`} target="_blank"  >{item.Name}</Link></h5></CardTitle>   
                            <CardText>  {item.Msg} </CardText>
                            <small className="text-muted"> {dateFormat((item.SendDate), "yyyy-mmm-dd")}</small>
                           
                            </Col>
                           
                          </Row>
                          {item.RplyDate ? <div>
                          <Divider orientation="left" style={{fontSize:14}}>{this.props.lang.RplyMsg}</Divider>

                          <Row>
                          <Col  xs="1" sm="1" md="1" lg="1" xl="1">
                          
                          
                          </Col>
                            <Col  xs="11" sm="11" md="11" lg="11" xl="11">
                          
                            <CardText>  {item.Rply} </CardText>
                            <small className="text-muted">{dateFormat((item.RplyDate), "yyyy-mmm-dd")}</small>
                            
                            </Col>
                           
                          </Row></div>
:
<div></div>
}


                          </CardBody>
                          <CardFooter><Button type="danger" onClick={() =>  {this.DeleteMsgMain(item.id)}} >{this.props.lang.DeleteMsg}</Button>
                          <Link to={`/UserApp/Details/${item.TarID}`} target="_blank" style={{float:"right"}} > <Button >{this.props.lang.popupviewprofile}</Button></Link>
                          
                          </CardFooter>
                      </Card></Container>
                                              
                                                </List.Item>
                                              )}
                                        />
                                    }
                       



                  
                    </TabPane>
                   
                </Tabs>

                                }
         
      
         </CardBody>
      </Card>

      <Modal
                style={{marginTop:75}}
                    centered
                    visible={this.state.ModalMSGReply}
                    footer={false}
                    closable={false}
                    width={800}
                >
                    <div>
                    <h5 lang={this.props.lang.lang} >{this.props.lang.RplyMsg}</h5>
                    <h6 style={{marginTop:15}}lang={this.props.lang.lang} >{this.props.lang.RplyMsgDis}</h6>
                  
                    <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} onSubmit={this.handleSubmit}>
                    <Form.Item label="Template" style={{marginBottom:5}}>
          {getFieldDecorator('Template', {
            rules: [{ required: false, message: 'Please select your Message Template!' }],
          })(
            <Select  
              placeholder="Select a Message Template and change input text Below"
              onChange={this.handleSelectChange}

            >
               {this.state.msgTempRplydb.map((item) => (
                                                        <Option value={item.Name} key={item.Name}>{item.Name}</Option>
                                                    ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Message">
          {getFieldDecorator('noteRply', {
            
            rules: [{ required: true, message: 'Please input your Messages!' }],
          })(
          
            <TextArea 
                                                name="noteRply"
                                                maxLength='500' 
                                                value={this.state.noteRply}
                                                onChange={this.handleChange}
                                                placeholder="Messages"
                                              
                                                autosize={{ minRows: 4, maxRows: 6 }} />
         )}
        </Form.Item>
     
    
      </Form>


                 
                       
                        <Row><Col>
                        <button   onClick={() =>  {this.SendMsgRply()}} 
                   className="DetailPageSMS" > <Icon type="message" style={{ fontSize: 19 ,verticalAlign:4,marginRight: 4}}/><span  style={{ verticalAlign:2}}>Send Reply</span></button> 
                </Col><Col>
                <button  onClick={() => { this.setState({ ModalMSGReply: false }) }}
                   className="DetailPageSMS" > <Icon type="close" style={{ fontSize: 19 ,verticalAlign:4,marginRight: 4}}/><span  style={{ verticalAlign:2}}>Close</span></button> 
                            
                            </Col>
                            </Row>

                           
                           
                    </div>
                </Modal>
              
        </Content>
        );
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        Intrested:state.Intrested,
        lang:state.lang
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        intrestedrefreshsucessServices: () => {
        dispatch(intrestedrefreshsucessServices());
      }
    }
  }
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(UserMessage));