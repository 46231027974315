import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Result, Button,message} from 'antd';
import FooterS from '../components/Footer';
import { Link } from 'react-router-dom';
import API from '../API';
class download extends Component {
  
    componentDidMount = () => {
        this.setState({ loading: true });
        fetch(API.BASE_URL + API.UserThanks)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    data: res
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
    }
    render() {
        return (
            <div style={{backgroundColor:'#fff'}}>
               <Result
    status="success"
    title="Payment Received Successfully"
    subTitle="നിങ്ങളുടെ പണം സ്വീകരിച്ചിരിക്കുന്നു. ഉടൻ തന്നെ അക്കൗണ്ട് ആക്ടിവേറ്റ് ചെയ്യുന്നതാണ്. കൂടുതൽ വിവരങ്ങൾക്ക് വിളിക്കുക 6235 44 55 44 "
    extra={[
        <Link to={`/UserApp/UserProfile`} style={{ textDecoration: 'none' }}>
      <Button type="primary" key="console">
        Go Home
      </Button></Link>
      ,
      <Link to='https://api.whatsapp.com/send?phone=9745114151' style={{ textDecoration: 'none' }}>
      <Button type="primary" key="console">
        Massege to Admin
      </Button></Link>
     
      
    ]}
  />
               
            </div>
        )
    }
}
export default download;
