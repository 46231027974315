
import React, { Component } from 'react';
import {Row, Col, Card, CardImg,Container} from 'reactstrap';
import { Icon, Modal} from 'antd';
import API from '../../API';
import { connect } from 'react-redux';
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
class DetailswithoutUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showId: null,
      loading: true,
      items: [],
      previewVisible: false
    };
  }
  handleCancel = () => this.setState({ previewVisible: false });

  componentDidMount() {
    let obj = { "user_id": this.props.match.params.id};
    fetch(API.BASE_URL + API.DETAILS_PAGE, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    })
      .then(res => res.json())
      .then((result) => {
          this.setState({
            loading: false,
            items: result
          });
        },
        (error) => {
          alert(error);
          this.setState({loading: false,error});
        }
      )
  }
  //end visitors
  render() {
    if (this.state.loading) {
      return (
        <div>
          <p>Loading</p>
        </div>
      )
    }
    return (
      <Container>
        {this.state.items.map((item) =>
          <Card style={{ marginTop: 20, marginBottom: 20, borderRadius: 10 }}>
            <Row style={{ margin: 20 }}>
              <Col sm="4" xs="12" >
                <div onClick={() => { this.setState({ previewImage: item.image1, previewVisible: true }) }}>
                  {item.image1 ? <CardImg alt="logo" src={item.image1} className="recomend-image" style={{ margin: 10, borderRadius: 10 }} />
                    : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} className="recomend-image" />}
                </div>

                <center>
                  <p style={{ fontSize: 20, fontWeight: 'bolder' }}>{item.Name} </p>
                  <p style={{ fontSize: 16, fontWeight: 'bolder' }}>User ID: {item.UserId}</p></center>
                <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Age {getAge(item.DOB)} </td>
                    <td>DOB: {(item.DOB)}</td>
                    <td>{item.Gender}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col sm="8" xs="12">
                <table className="table table-bordered">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td><Icon type="home" style={{ marginRight: 8, fontSize: 20 }} /></td><td>House Name:</td>
                      <td>{item.HName}</td>
                    </tr>
                    <tr>
                      <td><Icon type="compass" style={{ marginRight: 8, fontSize: 20 }} /></td>
                      <td>Place:</td>
                      <td>{item.Place}</td>

                    </tr>
                    <tr>
                      <td rowspan="3"><Icon type="bank" style={{ marginRight: 8, fontSize: 20 }} /></td>
                      <td>Parish:</td>
                      <td>{item.Parish}</td>

                    </tr>
                    <tr>

                      <td>Diocese:</td>
                      <td>{item.Diocese}</td>

                    </tr>
                    <tr>

                      <td>Religion:</td>
                      <td>{item.Religion}</td>

                    </tr>

                    <tr>
                      <td rowspan="3"><Icon type="desktop" style={{ marginRight: 8, fontSize: 20 }} /></td>
                      <td>Occupation Details:</td>
                      <td>{item.WorkDetail}</td>

                    </tr>
                    <tr>

                      <td>Occupation Category:</td>
                      <td>{item.WorkCat}</td>

                    </tr>
                    <tr>

                      <td>Working Place:</td>
                      <td>{item.WorkingPlace}</td>

                    </tr>
                    <tr>
                      <td rowspan="2"><Icon type="read" style={{ marginRight: 8, fontSize: 20 }} /></td>
                      <td>Education Details:</td>
                      <td>{item.EduDetail}</td>

                    </tr>
                    <tr>

                      <td>Education Category:</td>
                      <td>{item.EduCat}</td>

                    </tr>
                    <tr>
                      <td ><Icon type="money-collect" style={{ marginRight: 8, fontSize: 20 }} /></td>
                      <td>Annual Income:</td>
                      <td>{item.AnnualIncome}</td>

                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>

            <Row style={{ margin: 15 }}>
              <Col sm="6" xs="12">

                <table className="table table-bordered">
                  <thead>

                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="2"><b>Basic Details</b></td></tr>
                    <tr>
                      <td>Contact Address</td>
                      <td>{item.Address}</td>

                    </tr>
                    <tr>

                      <td>Height:</td>
                      <td>{item.Height} cm</td>

                    </tr>
                    <tr>

                      <td>Weight:</td>
                      <td>{item.Weight} kg</td>

                    </tr>
                    <tr>

                      <td>Maritial Status: </td>
                      <td>{item.Maritial}</td>

                    </tr>
                    <tr>

                      <td>Physical Status:</td>
                      <td>{item.Physical}</td>

                    </tr>

                    <tr>

                      <td><i>A few words about me:</i></td>
                      <td>{item.AboutMe}</td>

                    </tr>
                  </tbody>
                </table>
                {item.Gender !== 'Female' &&
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td colspan="2"><b>Contact Details</b></td></tr>
                      <tr>
                        <td>Profile Created:</td>
                        <td>{item.Pcreator}</td>
                      </tr>
                      <tr>
                        <td>Profile Creator Mob No:</td>
                        <td>{item.MainMob}</td>
                      </tr>
                      <tr>
                        <td>Personal Phone Number:</td>
                        <td>{item.PNumber}</td>

                      </tr>
                      <tr>
                        <td>Profile Creator Email:</td>
                        <td>{item.MainEmail}</td>
                      </tr>
                      <tr>
                        <td>Personal Email:</td>
                        <td>{item.Email}</td>
                      </tr>
                    </tbody>
                  </table>
                }

              </Col>
              <Col sm="6" xs="12">

                <table className="table table-bordered">
                  <thead>

                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="2"><b>Family Details</b></td></tr>
                    <tr>
                      <td>Father's Name:</td>
                      <td>{item.FName}</td>

                    </tr>
                    <tr>

                      <td>Father's Occupation:</td>
                      <td>{item.FOccupation}</td>

                    </tr>
                    <tr>

                      <td>Mother's Name:</td>
                      <td>{item.MName}</td>

                    </tr>
                    <tr>

                      <td>Mother's Occupation:</td>
                      <td>{item.MOccupation}</td>

                    </tr>
                    <tr>

                      <td>Financial Status:</td>
                      <td>{item.FinStatus}</td>

                    </tr>


                  </tbody>
                </table>
                <table className="table table-bordered">
               <tbody>
                  <tr>
                    <td colspan="3"><b>Number of Brothers</b></td></tr>
                  <tr>
                    <th>Married</th>
                    <th>Unmarried</th>
                    <th>Priest</th>
                  </tr>
                  <tr>
                    <td>{item.MBros}</td>
                    <td>{item.UBros}</td>
                    <td>{item.PBros}</td>
                  </tr>
                  <tr>
                    <th colspan="3"><b>Number of Sisters</b></th></tr>
                  <tr>
                    <th>Married</th>
                    <th>Unmarried</th>
                    <th>Nuns</th>
                  </tr>
                  <tr>
                    <td>{item.MSis}</td>
                    <td>{item.USis}</td>
                    <td>{item.NSis}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>

            </Row>


            <Row style={{ margin: 15, marginTop: 0 }}>
              <Col sm="12" xs="12">
                <p style={{ fontSize: 17, fontWeight: 'bolder' }}>Photos</p>
                <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: 0, marginBottom: 10 }} />
              </Col>
              <Col sm="12" xs="12">
                <Row>



                  <Col sm="2" xs="6" >
                    <Card inverse style={{ margin: 10, borderRadius: 10 }} onClick={() => { this.setState({ previewImage: item.image2, previewVisible: true }) }}>
                      {item.image2 ? <CardImg src={item.image2} alt="Card image cap" style={{ borderRadius: 10 }} />
                        : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} className="recomend-image" />}
                    </Card>
                  </Col>
                  <Col sm="2" xs="6">
                    <Card inverse style={{ margin: 10, borderRadius: 10 }} onClick={() => { this.setState({ previewImage: item.image3, previewVisible: true }) }}>
                      {item.image3 ? <CardImg src={item.image3} alt="Card image cap" style={{ borderRadius: 10 }} />
                        : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} className="recomend-image" />}
                    </Card>
                  </Col>
                  <Col sm="2" xs="6">
                    <Card inverse style={{ margin: 10, borderRadius: 10 }} onClick={() => { this.setState({ previewImage: item.image4, previewVisible: true }) }}>
                      {item.image4 ? <CardImg src={item.image4} alt="Card image cap" style={{ borderRadius: 10 }} />
                        : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} className="recomend-image" />}
                    </Card>
                  </Col>
                  <Col sm="2" xs="6">
                    <Card inverse style={{ margin: 10, borderRadius: 10 }} onClick={() => { this.setState({ previewImage: item.image5, previewVisible: true }) }}>
                      {item.image5 ? <CardImg src={item.image5} alt="Card image cap" style={{ borderRadius: 10 }} />
                        : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} className="recomend-image" />}
                    </Card>
                  </Col>
                  <Col sm="2" xs="6">
                    <Card inverse style={{ margin: 10, borderRadius: 10 }} onClick={() => { this.setState({ previewImage: item.image6, previewVisible: true }) }}>
                      {item.image6 ? <CardImg src={item.image6} alt="Card image cap" style={{ borderRadius: 10 }} />
                        : <CardImg alt="logo" src={require('../../images/placeholder.jpg')} className="recomend-image" />}
                    </Card>
                  </Col>

                </Row>
              </Col>
            </Row>
          </Card>
        )}



        <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
        </Modal>
      </Container>
    )
  }
}
function mapStateToProps(state) {
  return {
    User: state.User,
  }
}
export default connect(mapStateToProps)(DetailswithoutUser);