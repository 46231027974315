

import React, { Component } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Button, Modal, Form, Input, Icon, message, Popover, Table } from 'antd';
import { Link } from 'react-router-dom';
import API from '../API';

class View_contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      searchText: '',
      update_modal: false,
      NewLimit: null,
      update_id: null,
      UserID: null
    };
    this.arrayholder = [];
  }

  componentDidMount = () => {
    message.config({ top: 100 });
    fetch(API.BASE_URL + API.GET_VIEW_CONTACT, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    }).then((response) => response.json())
      .then((responsesJson) => {
        this.setState({ data: responsesJson, loading: false },
          function () { this.arrayholder = responsesJson });
      })
      .catch((error) => {
        this.setState({ loading: false })
        message.error(error);
      });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
    console.log(event.target.name);
  }

  update_popup = (data) => {
    this.setState({
      update_modal: true,
      update_id: data.id,
      UserID: data.UserId,
      NewLimit: data.View_Limit
    })
  }

  update = () => {
    this.setState({ loading: true,update_modal:false});
    let obj = {
      id: this.state.update_id,
      Limit: this.state.NewLimit
    };
    fetch(API.BASE_URL + API.UPDATE_VIEW_CONTACT, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(obj)
    }).then((response) => response.json())
      .then((responsesJson) => {
        if (responsesJson.status === 'success') {
          message.success('success');
          this.componentDidMount();
          this.setState({ loading: false, form: false });
        }
        else {
          message.error(responsesJson.status);
          this.setState({ loading: false, form: false });
        }
      })
      .catch((error) => {
        message.error('Error. Try again !');
        this.setState({ loading: false, form: false });
      });
  }


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
            </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  //end search function 
  handleCancel = () => {
    this.setState({ update_modal: false });
  }
  render() {
    function onChange(pagination, filters, sorter) {
      console.log('params', pagination, filters, sorter);
    }
    const columns = [
      {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        className: 'defultcolumn'
      },
      {
        title: 'UserId',
        dataIndex: 'UserId',
        key: 'UserId',
        className: 'defultcolumn'
      },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        className: 'defultcolumn'
      },
      {
        title: 'Limit',
        dataIndex: 'View_Limit',
        key: 'View_Limit',
        className: 'defultcolumn'
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        render: (value, record) =>
          <div>
            <Button ghost size="small" type="primary" onClick={() => this.update_popup(record)}>Update</Button>
          </div>
      }

    ];
    function onChange(pagination, filters, sorter) {
      console.log('params', pagination, filters, sorter);
    }
    return (
      <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
        <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>View Contact Quota</p>
        <Card style={{ borderRadius: 10, marginBottom: 10 }}>
          <CardBody>
            <Table columns={columns} dataSource={this.state.data} onChange={onChange} pagination={{ pageSize: 25 }} />
          </CardBody>
        </Card>
        <Modal
          centered
          visible={this.state.loading}
          footer={false}
          closable={false}
          width={300}
        >
          <Form layout="inline">
            <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
            <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
          </Form>
        </Modal>

        <Modal
          centered
          title={'Change Pckage'}
          visible={this.state.update_modal}
          footer={false}
          onCancel={this.handleCancel}
          width={500}
        >
          <p>UserID : {this.state.UserID}</p>
          <Form>
            <Form.Item>
              <Input
                prefix={<Icon type="edit" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Update"
                name="NewLimit"
                value={this.state.NewLimit}
                onChange={this.handleChange}
                type="number"
              />
            </Form.Item>
            <Form.Item align="right" style={{ textAlign: "right" }}>
              <Button type="defult" onClick={() => { this.setState({ update_modal: false }) }} style={{ marginRight: 10 }}>Cancel</Button>
              <Button type="primary" onClick={() => this.update()}>Update</Button>
            </Form.Item>
          </Form>
        </Modal>
      </Container>
    )
  }
}
export default Form.create()(View_contact);