
import React, { Component } from 'react';
import API from '../../API';
import '../../App.css';
import './styles.css';
import styles from '../../styles';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
//import { Card} from 'antd';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Modal,Icon,Card,Spin } from 'antd';
import Slider from "react-slick";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import FooterS from '../../components/Footer';
import { GiLovers,GiPayMoney } from "react-icons/gi";
import { SiFreelancer } from "react-icons/si";
import { FaUserLock ,FaMobileAlt} from "react-icons/fa";
import { IoMdChatboxes } from "react-icons/io";
import { MdLanguage } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
const { Meta } = Card;
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            slides: [],
            Story: [],
            alert: false,
            isLoaded: false
        };
    }
   
    

    componentDidMount = () => {

        if (this.props.alert.alert !== true) {
            this.setState({ alert: true })
        }
      this.send();
    

    }


    send = () => {
        fetch(API.BASE_URL + API.HomeProfileSlide, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
           
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({
                 slides: responsesJson.Profile,
                 Story: responsesJson.Story,
                 isLoaded:true
                                
                })
            })
            .catch((error) => {
                //message.error('Error. Try again !');
                alert(error)
            });
    }

    onChangeid = (event) => {
        this.setState({
            id: event.target.value
        })
    }
    hidealert = () => {
        this.setState({ alert: false })
        this.props.hide(true)
    }
    render() {
        const { isLoaded } = this.state;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                       
                    }},
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        initialSlide: 3
                    }},
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }}
            ]
        };
        return (
            <div>

                <Carousel>
                    <Carousel.Item>
                        <img
                            src={require('../../images/1.jpg')}
                            alt="First slide"
                            className="example1"
                        />
                        <Carousel.Caption>
                            <h2 style={{ color: '#fff', marginTop: -200, textAlign: 'center',fontFamily:'Ubuntu-Regular' }}>Welcome to Prolifemarry.com</h2>
                            <h5 style={{ color: '#fff', textAlign: 'center',fontFamily:'Ubuntu-Regular' }}>A venture of KCBC (Kerala Catholic Bishops Council) Malabar Prolife</h5>
                            <Link to='/Aboutus' style={{ textDecoration: 'none' }}>
                                <button className="button" style={{ marginRight: 20 }} >Login</button>
                            </Link>
                            <Link to='/Register' style={{ textDecoration: 'none' }}>
                                <button className="button">Register</button>
                            </Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                
                </Carousel>

                <Container className="AboutContainer" >
        <h5 style={{ color: '#026c70', fontSize: 20,fontFamily:'Ubuntu-Medium' }} className="HomeHeadMain" lang={this.props.lang.lang} >{this.props.lang.Homeaboutushead}</h5>
                    <Row>
                        <Col sm="12" xs="12" md="8" lg="8">
                            
        <p style={{ marginTop: 0, marginBottom: 20, textAlign: "justify", fontSize: 15,fontFamily:'Ubuntu-Regular' }} className="HomeHeadSub" lang={this.props.lang.lang}>{this.props.lang.Homeaboutustxt}</p>
                        </Col>
                        <Col sm="12" xs="12" md="4" lg="4">
                            <InputGroup style={{ marginTop: 0 }}>
                                <FormControl
                                className="HomeSearchidButton"
                                lang={this.props.lang.lang}
                                    placeholder={this.props.lang.HomesearchinputMain}
                                    aria-label="PROFILE ID"
                                    aria-describedby="PROFILE ID"
                                    onChange={this.onChangeid} />
                                <InputGroup.Append>
                                    <Link to={`/ProSearch/${this.state.id}`} style={styles.linkdecoration}>
                                        <Button className="HomeSearchidButton" lang={this.props.lang.lang}
                                            style={{ backgroundColor: '#026c70', borderColor: '#0070ba' ,fontFamily:'Ubuntu-Regular' }}>
                                            {this.props.lang.Homesearchbtn}
                                            </Button>
                                    </Link>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                    </Row>
                   

               
                </Container>
                
                <div className="colorcontainer">
                    <Container>
                        <Row>
                        <Col sm="3" xs="12" className="Features" >
                            <center>  <GiLovers style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                    Auto Profile Matching
                            </p></center>
                            </Col>
                            <Col sm="3" xs="12" className="Features" >
                                <center><SiFreelancer style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                    Free for Ladies
                            </p></center>
                            </Col>
                            <Col sm="3" xs="12" className="Features" >
                            <center><FaUserLock style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                    100% Secure & Ensured Privacy
                            </p></center>
                            </Col>
                            <Col sm="3" xs="12" className="Features" >
                            <center>  <IoMdChatboxes style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                    Message, SMS, Whatsapp Chat
                            </p></center>
                            </Col>
                           
                        </Row>
                    </Container>
                </div>
               
              <Container style={{ marginTop: 40, marginBottom: 40 }}>
                    <h5 style={{ textAlign: 'center', margin: 40, color: '#026c70' }}>Over Thousands of Happy Profiles</h5>
                    {!isLoaded &&
                            <div className="example">
                            <Spin />
                          </div>
                        }
                  
                  {isLoaded &&
                    <Slider {...settings}>
                        {this.state.slides.map(function (profile,i) {
                            return (
                                <div key={i}>
                                  
                                    <Card 
                                        style={{ textAlign: 'center', margin: 10 }}
                                        hoverable
                                        cover={<img alt="example" src={profile.image1}  className="CardImage" />}>
                                        <Meta style={{textTransform:'capitalize'}} title={profile.Name} description={profile.Diocese} />
                                    </Card>
                                </div>)
                        })
                        }
                    </Slider>}
                    </Container>  
                    <div className="colorcontainer">
                    <Container>
                        <Row>
                        <Col sm="3" xs="12" className="Features" >
                            <center>  <MdLanguage style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                Malayalam/English Language Selection
                            </p></center>
                            </Col>
                            <Col sm="3" xs="12" className="Features" >
                                <center><BiSupport style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                24x7 Call/Chat Support
                            </p></center>
                            </Col>
                            <Col sm="3" xs="12" className="Features" >
                            <center><GiPayMoney style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                Marriage Financial Support
                            </p></center>
                            </Col>
                            <Col sm="3" xs="12" className="Features" >
                            <center>  <FaMobileAlt style={{ fontSize: '50px', color: '#fff', marginBottom: 15 }}  /> 
                                <p style={{ color: '#fff' }}>
                                Provide Website/Mobile App
                            </p></center>
                            </Col>
                           
                        </Row>
                    </Container>
                   
                </div>
                <Container>
<h5 style={{ textAlign: 'center', margin: 40, color: '#026c70' }}>Happy Success Stories</h5>
                    {!isLoaded &&
                            <div className="example">
                            <Spin />
                          </div>
                        }
                  
                  {isLoaded &&
                    <Slider {...settings}>
                        {this.state.Story.map(function (profile,i) {
                            return (
                                <div key={i}>
                                  
                                    <Card 
                                        style={{ textAlign: 'center', margin: 10 }}
                                        hoverable
                                        cover={<img alt="example" src={profile.Photo}  className="CardImage" />}>
                                        <Meta style={{textTransform:'capitalize'}} title={profile.Name}  />
                                    </Card>
                                </div>)
                        })
                        }
                    </Slider>}


                 

<br></br>
                    <Row  className="justify-content-center" style={{ margin: 10 }}>
                                <Col sm="4" xs="4" md="3" lg="2" style={{ marginTop: 10 }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.prolifemarry">
                                        <img src={require('../../images/play.png')} alt="logo" className="recomend-image" />
                                    </a>
                                </Col>
                                <Col sm="4" xs="4" md="3" lg="2" style={{ marginTop: 10 }}>
                                  
                                    <Link to="/download">
                                        <img src={require('../../images/appstore.png')} alt="logo" className="recomend-image" />
                                    </Link>
                                </Col>
                            </Row>
                </Container>  
                <Modal
                    style={{ marginTop: 15 }}
                    visible={this.state.alert}
                    footer={false}
                    onCancel={() => { this.hidealert() }}
                    width={500}>
                    <div className="aligitemncenter" style={{ marginTop: 10 }}>
                        <h6>Get the app</h6>
                        <img src={require('../../images/phone12.png')} style={{ marginBottom: 20 }} alt="success" />
                        <hr />
                        <Row style={{ margin: 10 }}>
                            <Col sm="6" xs="6" style={{ marginTop: 10 }}>
                                <a href="https://play.google.com/store/apps/details?id=com.prolifemarry">
                                    <img src={require('../../images/play.png')} alt="logo" className="recomend-image" />
                                </a>
                            </Col>
                            <Col sm="6" xs="6" style={{ marginTop: 10 }}>
                                <Link to="/download">
                                    <img src={require('../../images/appstore.png')} alt="logo" className="recomend-image" />
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <FooterS />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        alert: state.alert,
        lang:state.lang,
        dropdown: state.dropdown
    }
}
function mapDispatchToProps(dispatch) {
    return {
        hide: (data) => {
            dispatch({ type: 'HIDE', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
