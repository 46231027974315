import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Form, Icon, Input, Button, message, Modal } from 'antd';
import FooterS from '../../components/Footer';
class download extends Component {
    render() {
        return (
            <div style={{backgroundColor:'#fff'}}>
                <Container className="logincontainer" style={{textAlign:'center'}}>
                    <Row style={{paddingTop:50}}>
                        <Col>
                      
                        <h6>iOS Mobile App ഉടൻ തന്നെ ലഭ്യമാകുന്നതാണ്.</h6>
                        <Row style={{margin:10}}>
                            <Col sm="6" style={{marginTop:10}}>
                            <a href="https://play.google.com/store/apps/details?id=com.prolifemarry">
                                <img src={require('../../images/play.png')} alt="logo" className="recomend-image"/>
                                </a>
                            </Col>
                            <Col sm="6" style={{marginTop:10}}>
                                <div>
                                <img src={require('../../images/appstore.png')} alt="logo" className="recomend-image"/>
                                </div>
                      
                            </Col>
                        </Row>
                        </Col>
                        <Col>
                        <img src={require('../../images/phone1.png')} alt="logo" style={{marginLeft:-8}}/>
                        </Col>
                    </Row>
                </Container>
                <FooterS/>
            </div>
        )
    }
}
export default download;
