

import React, { Component } from 'react';
import { Card, CardBody,ButtonGroup } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, DatePicker, message,Progress ,Modal} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import API from '../../API';
import './styles.css'
const { TextArea } = Input;
const { Option } = Select;
class Edit_MyprofileCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            loading: false,
            issuccess: false,
            current: 0,
            status: 'process',
            value: '',
            user_id: null,
            date: this.props.data.DOB,
            //step1
            FullName: this.props.data.FullName,
            Gender: this.props.data.Gender,
            DOB: this.props.data.DOB,
            RelCaste: this.props.data.RelCaste,
            Height: this.props.data.Height,
            Weight: this.props.data.Weight,
            MaritialS: this.props.data.MaritialS,
            PhysicalS: this.props.data.PhysicalS,
            aboutCandidate: this.props.data.AboutMe,
            ProfileCreator: this.props.data.Pcreator,
            Creatorphone: this.props.data.Creatorphone,
            creatoremail: this.props.data.creatoremail,
            Count: this.props.data.Count
            //end step1
        };
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
     
       
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    ondateChange = (value) => {
        if (value != null) {
            this.setState({ DOB: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ DOB: null, date: null });
        }
    }
    ProfilePercentage = () => {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
            this.refs.toast.show('Something went wrong!');
          });
      }

      Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
               

            })
            .catch((error) => {
                console.error(error);
            });
            
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'user_id': this.props.User.user_id,
                    'FullName': this.state.FullName,
                    'Gender': this.state.Gender,
                    'ProfileCreator': this.state.ProfileCreator,
                    'creatoremail': this.state.creatoremail,
                    'Creatorphone': this.state.Creatorphone,
                    'DOB': this.state.DOB,
                    'RelCaste': this.state.RelCaste,
                    'Height': this.state.Height,
                    'Weight': this.state.Weight,
                    'MaritialS': this.state.MaritialS,
                    'PhysicalS': this.state.PhysicalS,
                    'aboutCandidate': this.state.aboutCandidate
                 
                }
                fetch(API.BASE_URL + API.EDIT_MYPROFILE, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                         
                         
                            this.ProfilePercentage();
                            this.Refrsh();
                            this.props.reload();
                          
                           // message.success('Updated Successfully');
                            this.setState({ loading: false,ModalVerify:true });
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '+91',
        })(
            <Select style={{ width: 70 }}>
                <Option value="+91">+91</Option>
            </Select>,
        );
        const height_Array = [];
        for (let i = 30; i < 250; i++) {
            height_Array.push({
                value: i,
            });
        }
        const Weight_array = [];
        for (let i = 20; i < 160; i++) {
            Weight_array.push({
                value: i,
            });
        }
        const dateFormat = 'YYYY/MM/DD';
        return (
            <div>
               
            <Card style={{marginBottom:10}}>
            <CardBody>

            { this.props.User.Count>= 0 && this.props.User.Count<= 80 ?
 <div>


<div style={{fontSize:15,fontWeight:500,float:"left"}}>
Your Profile is  {this.props.User.Count}% Complete
</div>

<Progress
strokeColor="red"
 percent= {this.props.User.Count} />
<div style={{fontSize:13}}>
Get more response by adding full details in your profile

</div> 

</div>  
:
null
}
{this.props.User.Count > 80 && this.props.User.Count < 90  ?
    <div>


<div style={{fontSize:15,fontWeight:500,float:"left"}}>
Your Profile is {this.props.User.Count}% Complete
</div>

<Progress
strokeColor="orange"
percent={this.props.User.Count} />
<div style={{fontSize:13}}>
Get more response by adding full details in your profile
</div>
</div> 
:
null
}
{this.props.User.Count >= 90   ?
    <div>


<div style={{fontSize:15,fontWeight:500,float:"left"}}>
Your Profile is {this.props.User.Count}% Complete
</div>

<Progress

percent={this.props.User.Count} />
<div style={{fontSize:13}}>
Get more response by adding full details in your profile
</div>
</div> 
:
null
}
        
 </CardBody>
            </Card>
            <Card style={{ borderRadius: 4 }}>
                <CardBody>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.UserProfileMyProfile}</p>
                   
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />

                            <Form {...formItemLayout}>

                                <Form.Item label={this.props.lang.FullName} >
                                    {getFieldDecorator('FullName', {
                                        initialValue: this.state.FullName,
                                        setFieldsValue: this.state.FullName,
                                        onChange: this.handleChange,
                                        rules: [{ required: true, message: 'Please input your Full Name!' }]
                                    })(
                                        <Input
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder="Full Name"
                                            name="FullName"
                                            maxLength={98}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label={this.props.lang.Gender} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('Gender', {
                                        initialValue: this.state.Gender,
                                        setFieldsValue: this.state.Gender,
                                        onChange: this.handleChange,
                                        rules: [{ required: true, message: 'Please select Gender!' }],
                                    })(
                                        <Radio.Group  name="Gender">
                                            {this.state.Gender==='Male'?
 <Radio.Button value="Male">{this.props.lang.Male} </Radio.Button>
                                            :
                                            <Radio.Button value="Female">{this.props.lang.Female} </Radio.Button>
                                            }
                                           
                                          
                                        </Radio.Group>,
                                    )}
                                </Form.Item>

                                <Form.Item label={this.props.lang.DOB}  style={{ marginTop: -20 }}>
                                    {getFieldDecorator('date', {
                                        initialValue: moment(this.state.date, dateFormat),
                                        setFieldsValue: this.state.date,
                                        rules: [{ type: 'object', required: true, message: 'Please select Date!' }],
                                    })(
                                        <DatePicker format="YYYY-MM-DD" placeholder="Select Time" onChange={this.ondateChange} />
                                    )}
                                </Form.Item>

                                <Form.Item label= {this.props.lang.Cast} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('RelCaste', {
                                        initialValue: this.state.RelCaste,
                                        setFieldsValue: this.state.RelCaste,
                                        rules: [{ required: true, message: 'Please select Religion/Caste!' }],
                                    })(
                                        <Select
                                            showSearch
                                            name={"RelCaste"}
                                            placeholder="Religion/Caste"
                                            optionFilterProp="children"
                                            onChange={(val) => { this.handleChange({ target: { name: 'RelCaste', value: val } }) }}
                                            filterOption={(input, option) =>
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {this.props.dropdown.Religion_array.map((item) => (
                                                <Option value={item.id} key={item.id}>{item.Name}</Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label= {this.props.lang.Height} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('Height', {
                                        initialValue: this.state.Height,
                                        setFieldsValue: this.state.Height,
                                        rules: [{ required: true, message: 'Please select Height!' }],
                                    })(
                                        <Select
                                            showSearch
                                            placeholder="Select Height"
                                            optionFilterProp="children"
                                            onChange={(val) => { this.handleChange({ target: { name: 'Height', value: val } }) }}
                                            filterOption={(input, option) =>
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {height_Array.map((item) => (
                                                <Option value={item.value} key={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>


                                <Form.Item label= {this.props.lang.Wight} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('Weight', {
                                        initialValue: this.state.Weight,
                                        setFieldsValue: this.state.Weight,
                                        rules: [{ required: true, message: 'Please select Weight!' }],
                                    })(
                                        <Select
                                            name="Weight"
                                            showSearch
                                            placeholder="Select Weight"
                                            optionFilterProp="children"
                                            onChange={(val) => { this.handleChange({ target: { name: 'Weight', value: val } }) }}
                                            filterOption={(input, option) =>
                                                option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {Weight_array.map((item) => (
                                                <Option value={item.value} key={item.value}>{item.value}</Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label= {this.props.lang.MStatus} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('MaritialS', {
                                        initialValue: this.state.MaritialS,
                                        setFieldsValue: this.state.MaritialS,
                                        onChange: this.handleChange,
                                        rules: [{ required: false, message: 'Please select Gender!' }],
                                    })(
                                        <Radio.Group name="MaritialS" >

                                            <Radio.Button value="U">{this.props.lang.UserSearchUnmarried}</Radio.Button>
                                            <Radio.Button value="W">{this.props.lang.UserSearchWidow}</Radio.Button>
                                            <Radio.Button value="D">{this.props.lang.UserSearchDivorcee}</Radio.Button>
                                        </Radio.Group>,
                                    )}

                                </Form.Item>

                                <Form.Item label= {this.props.lang.PStatus} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('PhysicalS', {
                                        initialValue: this.state.PhysicalS,
                                        setFieldsValue: this.state.PhysicalS,
                                        onChange: this.handleChange,
                                        rules: [{ required: true, message: 'Please select Physical Status!' }],
                                    })(
                                        <Radio.Group name="PhysicalS">
                                            <Radio.Button value="Normal">{this.props.lang.UserSearchNormal}</Radio.Button>
                                            <Radio.Button value="With Disability">{this.props.lang.UserSearchDisable}</Radio.Button>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>

                                <Form.Item label= {this.props.lang.About} style={{ marginTop: -20 }}>
                                    <TextArea
                                        name="aboutCandidate"
                                        value={this.state.aboutCandidate}
                                        onChange={this.handleChange}
                                        placeholder="About the Candidate"
                                        autosize={{ minRows: 2, maxRows: 6 }}
                                        maxLength={950}
                                    />
                                </Form.Item>
                                <Form.Item label= {this.props.lang.PCreator}  style={{ marginTop: -15 }}>
                                    {getFieldDecorator('ProfileCreator', {
                                        initialValue: this.state.ProfileCreator,
                                        setFieldsValue: this.state.ProfileCreator,
                                        rules: [{ required: true, message: 'Please select EProfile Creator!' }],
                                    })(
                                        <Select placeholder="Profile Creator" name="ProfileCreator"
                                            onChange={(val) => { this.handleChange({ target: { name: 'ProfileCreator', value: val } }) }}>
                                             <Option value="Self">{this.props.lang.Self}</Option>
                                                    <Option value="Parents">{this.props.lang.Parents}</Option>
                                                    <Option value="Siblings">{this.props.lang.Siblings}</Option>
                                                    <Option value="Relatives">{this.props.lang.Relatives}</Option>
                                                    <Option value="Friends">{this.props.lang.Friends}</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label= {this.props.lang.PCreMob} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('Creatorphone', {
                                        initialValue: this.state.Creatorphone,
                                        setFieldsValue: this.state.Creatorphone,
                                        onChange: this.handleChange,
                                        rules: [{ required: true, message: 'Please input your phone number!' }],
                                    })(<Input
                                        addonBefore={prefixSelector}
                                        style={{ width: '100%', margin: 0, padding: 0 }}
                                        name="Creatorphone"
                                        placeholder="Phone Number"
                                        type='number' 
                                        maxLength={10} />)}
                                </Form.Item>

                                <Form.Item label= {this.props.lang.Email} style={{ marginTop: -20 }}>
                                    {getFieldDecorator('creatoremail', {
                                        initialValue: this.state.creatoremail,
                                        setFieldsValue: this.state.creatoremail,
                                        onChange: this.handleChange,
                                        rules: [
                                            {
                                                type: 'email',
                                                required: false,
                                                message: 'The input is not valid E-mail!',
                                            },

                                        ],
                                    })(<Input name="creatoremail"
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Email" 
                                        maxLength='48'/>)}
                                </Form.Item>
                                <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                                    <ButtonGroup>
                                        <Button type="primary" onClick={() => this.update()} style={{fontWeight:'bolder',backgroundColor:'#0070ba',borderColor:'#0070ba'}}>
                                              <span style={{marginLeft:15,marginRight:15}}>Update</span>
                                                </Button>
                                    </ButtonGroup>
                                </Form.Item>
                            </Form>
                </CardBody>
            </Card>


            <Modal  title="Please Wait.."
                           onOk={() => this.setState({ ModalVerify: false })}
                          onCancel={() => this.setState({ ModalVerify: false })}
                          width={400}
                          visible={this.state.ModalVerify}
                      >
            <h6 style={{textAlign:'justify'}}>Your some details will be updated only after admin verification.</h6>
            <p style={{textAlign:'justify'}}>അഡ്‌മിൻ പരിശോധനയ്ക്ക് ശേഷം മാത്രമേ നിങ്ങളുടെ ചില വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യുകയുള്ളൂ.</p>
            
             
              </Modal>
            </div>
        )
    }
}
function mapStateToProps(state) 
{
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(Edit_MyprofileCard));