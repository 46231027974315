import React, { Component } from 'react';

import { Row, Col,Container } from 'reactstrap';
import { List, Skeleton, Card } from 'antd';
class Loadingplaceholder extends Component {
    render() {
        return (
            <List itemLayout="vertical"
                size="large"
                dataSource={[1, 2, 3, 4, 5]}
                renderItem={item => (
                    <Container >
                    <Row> <Col sm={{ size: 12, offset: 0 }}  xs={{ size: 10, offset: 1 }}  lg={{ size: 12, offset: 0}} xl={{ size: 10, offset: 1}}>

                    <Card style={{ borderRadius: 10,marginTop:10}}>
                        <List.Item>
                            <Row>
                                <Col sm="3" xs="12">
                                    <img
                                        className="recomend-image"
                                        style={{ borderRadius: 4 }}
                                        alt="logo"
                                        src={require('../images/placeholder.jpg')}
                                    />
                                </Col>
                                <Col sm="9" xs="12">
                                    <Skeleton active paragraph={{ rows: 3 }} />
                                </Col>
                            </Row>
                        </List.Item>
                    </Card> </Col></Row> </Container>
                )} />
        )
    }
}
export default Loadingplaceholder;