import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Icon, Popover, Avatar , Button,Menu,Modal,Badge} from 'antd';
import UseStyles from './UseStyles';
import { Navbar, Nav, NavLink, NavbarBrand, Container, NavbarText, Row, Col } from 'reactstrap';
import styles from './styles';
import { setenglish, setmalayalam } from '../../redux/Services/LanguageServices';
import { FiLogIn } from "react-icons/fi";


function Header(props) {
   console.log(props.Notification.NoNotification);
   
    const { container } = props;
    const classes = UseStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleChange = event => {
        if (event.target.value === 'en') {
            props.setenglish();
        }
        else {
            props.setmalayalam();
        }
    };
    const setlang = (event) => {
        return event.replace("\"", "");
    }

    const drawer = (
        <div>
            <Navbar>
                <Nav className="mr-auto" navbar>

                    <NavLink >
                        <Row>
                            <Col sm={8} xs={8} md={8} lg={8} xl={8}>
                                <img src={require('../../images/logo.png')} className={classes.headerLogo} alt="logo" />
                            </Col>
                            <Col sm={4} xs={4} md={4} lg={4} xl={4}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={props.lang.lang}
                                    onChange={handleChange}
                                    className={classes.langdrop2}
                                    disableUnderline={true} 
                                >
                                    <MenuItem style={{fontSize:12}} value={'en'}>Eng</MenuItem>
                            <MenuItem style={{fontSize:12}} value={'ml'}>മല</MenuItem>
                                </Select>
                            </Col>
                        </Row>

                    </NavLink>
                </Nav>
            </Navbar>

            <Divider />
            {props.User.auth === true
                ?
                <Link to='/UserApp/UserProfile' onClick={handleDrawerToggle} >
                  
                    <div style={{ margin: 10 }}>
                        <Row >
                            <Col sm='4' xs="4" style={{ margin: 0 ,marginRight:-15}}>
                                <div> {props.User.image ? <img src={props.User.image} className="Drawerimage" alt="logo" />
                                    : <img src={require('../../images/placeholder.jpg')} className="Drawerimage" alt="logo" />}</div>
                            </Col>
                            <Col sm='8' xs="8" style={{ margin: 0 }}>
                                <span className="popuptxt">{props.User.userName}</span><br />
                                <span className="popuptxt2">{props.User.register_d}</span>
                            </Col>
                        </Row>
                    </div>

                </Link>
                : null
            }

            {props.User.auth === false &&
            <div>
                <Link to='/Register' onClick={handleDrawerToggle} >
                    <ListItem button style={{ marginTop: 10, marginBottom: 10  }}>
                        <ListItemIcon>
                            <Icon type="user-add" style={{ fontSize: 23, color: '#026c70' }} />
                        </ListItemIcon>
                        <span style={styles.hraderItem2}>{props.lang.headerRegisterBtn}</span>
                    </ListItem>
                </Link>
                 <Link to='/login' onClick={handleDrawerToggle} >
                 <ListItem button style={{ marginTop: 10, marginBottom: 10  }}>
                     <ListItemIcon>
                         <FiLogIn style={{ fontSize: 23, color: '#026c70' }} />
                     </ListItemIcon>
                     <span style={styles.hraderItem2}>{props.lang.HeaderloginBtn}</span>
                 </ListItem>
             </Link></div>
                }
            <Divider />
            <List>

                {props.User.auth === false &&
                <div>

<Menu
mode="inline"
defaultSelectedKeys={['1']}
defaultOpenKeys={['sub1']}
style={{ height: '100%',backgroundColor:'#f8f9fa' }}>


<Menu.Item key="1" className="DrawerMenu">
    <Link to='/' style={styles.linkdec}>
     
        <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.Home}</span>
    </Link>
</Menu.Item>
<Menu.Item key="2" className="DrawerMenu">
    <Link to='/Aboutus' style={{ textDecoration: 'none' }}>
      
        <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.AboutMenu}</span>
    </Link>
</Menu.Item>
<Menu.Item key="4" className="DrawerMenu">
    <Link to='/Packages' style={{ textDecoration: 'none' }}>
      
        <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.PackagesHead}</span>
    </Link>
</Menu.Item>
<Menu.Item key="5" className="DrawerMenu">
    <Link to='/Story' style={{ textDecoration: 'none' }}>
      
        <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.SuccessHead}</span>
    </Link>
</Menu.Item>
<Menu.Item key="3" className="DrawerMenu">
    <Link to='/contact' style={styles.linkdec}>
     
        <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.ContactMenu}</span>
    </Link>
</Menu.Item>


</Menu>

</div>
                   }

                {props.User.auth === true &&
                         <div><Menu
                   mode="inline"
                   defaultSelectedKeys={['1']}
                   defaultOpenKeys={['sub1']}
                   style={{ height: '100%',backgroundColor:'#f8f9fa' }}>
                 

                  
                   <Menu.Item key="1" className="DrawerMenu">
                       <Link to='/UserApp/UserHome'  style={styles.linkdec} >
                          
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerhome}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="3" className="DrawerMenu">
                       <Link to='/UserApp/UserNotification' style={styles.linkdec}>
                          
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerNotification}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="2" className="DrawerMenu">
                       <Link to='/UserApp/UserSearch' style={styles.linkdec}>
                           
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerAdvanceSearch}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="13" className="DrawerMenu">
                       <Link to='/UserApp/userMyMatches' style={styles.linkdec}>
                          
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerMyMatches}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="4" className="DrawerMenu">
                       <Link to='/UserApp/Intrested' style={styles.linkdec}>
                           
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerIntrested}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="5" className="DrawerMenu">
                       <Link to='/UserApp/userShortlist' style={styles.linkdec}>
                           
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerShortList}</span>
                       </Link>
                   </Menu.Item>

                   <Menu.Item key="6" className="DrawerMenu">
                       <Link to='/UserApp/UserProfilevisitors' style={styles.linkdec}>
                           
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerVisitors}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="7" className="DrawerMenu">
                                <Link to='/UserApp/UserMessage' style={styles.linkdec}>
                                  
                                    <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.Messages}</span>
                                </Link>
                            </Menu.Item>
                   <Menu.Item key="8" className="DrawerMenu">
                       <Link to='/UserApp/UserPayment' style={styles.linkdec}>
                          
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerPayments}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="9" className="DrawerMenu">
                       <Link to='/UserApp/UserProfile' style={{ textDecoration: 'none' }}>
                          
                           <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerMyProfile}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="14" className="DrawerMenu">
                       <Link to='/' style={{ textDecoration: 'none' }}>
                         
                           <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.Home}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="10" className="DrawerMenu">
                       <Link to='/Aboutus' style={{ textDecoration: 'none' }}>
                         
                           <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.AboutMenu}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="15" className="DrawerMenu">
                       <Link to='/Packages' style={{ textDecoration: 'none' }}>
                         
                           <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.PackagesHead}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="16" className="DrawerMenu">
                       <Link to='/Story' style={{ textDecoration: 'none' }}>
                         
                           <span  className="UserMenuItems" lang={props.lang.lang}>{props.lang.SuccessHead}</span>
                       </Link>
                   </Menu.Item>
                   <Menu.Item key="11" className="DrawerMenu">
                       <Link to='/contact' style={styles.linkdec}>
                        
                           <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.ContactMenu}</span>
                       </Link>
                   </Menu.Item>
                
                   <Menu.Item key="12"  onClick={() => props.Logout('null')}>
                     
                       <span className="UserMenuItems" lang={props.lang.lang}>{props.lang.drawerlogout}</span>
                   </Menu.Item>
               </Menu>
                   
             


                    </div>}

               
            </List>

        </div>
    );

    const content = (
        <div>
            <div style={{ margin: 5 }}>
                <Row >
                    <Col sm='4' xs="4" style={{ margin: 0,marginRight:-15 }}>
                        <div> {props.User.image ? <img src={props.User.image} className="Drawerimage" alt="logo" />
                            : <img src={require('../../images/placeholder.jpg')} className="Drawerimage" alt="logo" />}</div>
                    </Col>
                    <Col sm='8' xs="8" style={{ margin: 0 }}>
                        <span className="popuptxt">{props.User.userName}</span><br />
                        <span className="popuptxt2">{props.User.register_d}</span><br />
                        <Link to='/UserApp/UserProfile' style={styles.linkdecoration}>
                   
                        <span className="MyprofileSpan">My Profile</span>
               </Link>
                       
                    </Col>
                </Row>
             
               
            </div>
            <Link to='/UserApp/UserHome' style={styles.linkdecoration}>
                <button block className="popbtn">{props.lang.popuphome}</button>
            </Link>
           
            <Divider />
            <button block className="popbtn"
                onClick={() => props.Logout('null')}>{props.lang.popuplogout}</button>
        </div>
    );

    return (
        <div>
            
       

        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders" >
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper }}
                        ModalProps={{ keepMounted: true }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <header className={classes.content}>
                <Navbar color="light" fixed="top" className="headermain">
                   
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}>
                            <MenuIcon style={{ fontSize: 30 }} />
                        </IconButton>
                        <NavbarBrand href="/#" className={classes.LogoMain} >
                            <img src={require('../../images/logo.png')}
                                className={classes.headerLogo} alt="logo" />
                        </NavbarBrand>
                        <Nav className="ml-auto" navbar>
                        </Nav>

                        <Link to='/' className={classes.extramenu}>
                            <NavbarText className="headerItem" lang={props.lang.lang} >
                                {props.lang.headerhome}
                            </NavbarText>
                        </Link>
                       
                        <Link to='/Aboutus' className={classes.extramenu}>
                            <NavbarText className="headerItem" lang={props.lang.lang}>
                                {props.lang.headerAboutus}
                            </NavbarText>
                        </Link>
                        <Link to='/Packages' className={classes.extramenu}>
                            <NavbarText className="headerItem" lang={props.lang.lang}>
                                {props.lang.Packages}
                            </NavbarText>
                        </Link>
                        <Link to='/Story' className={classes.extramenu}>
                            <NavbarText className="headerItem" lang={props.lang.lang}>
                                {props.lang.Success}
                            </NavbarText>
                        </Link>
                        <Link to='/contact' className={classes.extramenu}>
                            <NavbarText className="headerItem" lang={props.lang.lang}>
                                {props.lang.headerContact}
                            </NavbarText>
                        </Link>
                      
                        {props.User.auth === false &&
                            <Link to='/Register' style={styles.regitem}>
                                <NavbarText className={classes.extramenu} >
                                    <div className="registerbtnnav">
                                        <p className="registertxt" >
                                            {props.lang.headerRegisterBtn}
                                        </p>
                                    </div>
                                </NavbarText>
                            </Link>}
                        {props.User.auth === false &&
                            <Link to='/login' style={styles.linkdecoration}>
                                <NavbarText className={classes.extramenu} >
                                    <button className={classes.buttonnav}>
                                        <p className="logintxt" >
                                            <span>{props.lang.HeaderloginBtn}
                                            </span></p>
                                    </button>
                                </NavbarText>
                            </Link>
                        }

                        {props.User.auth === true &&
                            <Popover
                                placement="bottomRight"
                                content={content}
                                trigger="click"
                                overlayStyle={{
                                    background: "transparent",
                                    width: "290px"
                                }}>
                                <NavbarText>
                                    {props.User.image ? <Badge size="small" count={props.Notification.NoNotification}><Avatar shape="square" src={props.User.image} style={{marginLeft:10}} /></Badge>
                                        : <Badge size="small" count={props.Notification.NoNotification}><Avatar shape="square" style={{marginLeft:10}}  src={require('../../images/placeholder.jpg')}  /></Badge>}
                                </NavbarText>
                            </Popover>}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.lang.lang}
                            onChange={handleChange}
                            className={classes.langdrop}
                            disableUnderline={true}  
                        >
                            <MenuItem style={{fontSize:12}} value={'en'}>English</MenuItem>
                            <MenuItem style={{fontSize:12}} value={'ml'}>മലയാളം</MenuItem>
                        </Select>
                    
                </Navbar>
            </header>
        </div>
        </div>
    );
}

Header.propTypes = {
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

function mapStateToProps(state) {
    return {
        User: state.User,
        lang: state.lang,
        Notification:state.Notification
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setenglish: () => {
            dispatch(setenglish());
        },
        setmalayalam: () => {
            dispatch(setmalayalam());
        },
        Logout: (data) => {
            dispatch({ type: 'LOGOUT', payload: data })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);