import React, { Component } from 'react';
import './styles.css';
import { Col, Row,Container  } from 'reactstrap';
import { message, List, Layout, Icon,Modal, Button  } from 'antd';
import { connect } from 'react-redux';
import ListItem2 from '../../components/ListItem2/Lisitem2';
import HomeStati from '../../User/HomeStati/HomeStati';
import Loadingplaceholder from '../../components/Loading_placeholder';
import Deactivated_card from '../../components/Deactivated_card';
import Not_aprooved_card from '../../components/not_aprooved_card';
import Payement_due_card from '../../components/payement_due_card';

import { Link } from 'react-router-dom';


import API from '../../API';
const { Content } = Layout;

const { confirm } = Modal;

function showConfirm() {
  confirm({
    title: 'Please upload Profile picture',
    content: 'Profile picture will help to more attraction in your profile',
    onOk() {
        
        this.props.history.push('../../Web/About/about')
           
       
    },
    onCancel() {
      console.log('Cancel');
    },
  });
}

  
class UserHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            Activated: false,
            deactivated: false,
            not_aprooved: false,
            payment_due: false,
            recomemded: [],
            ModalContactDetails:false
        };
        this.arrayholder = [];
    }
    componentDidMount = () => {
        this.Refrsh();
        
        if (this.props.User.Status === 'A') {/// ---A--- activated account
            this.setState({ Activated: true });
            message.config({ top: 100 });
            let obj = { user_id: this.props.User.user_id, Gender: this.props.User.gender }
            fetch(API.BASE_URL + API.HOME_DATA, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: JSON.stringify(obj)
            })
                .then(res => res.json())
                .then((result) => {
                    this.setState({
                        isLoaded: true,
                    },
                        function () { this.arrayholder = result });
                        if (result !== 'null') {
                            this.get_data();
                        } else {
                            this.arrayholder = [];
                        }
                },
                    (error) => {
                        message.error('faild');
                        alert(error);
                        this.setState({ isLoaded: true });
                    }
                )
        }
        else if (this.props.User.Status === 'D') {
            this.setState({ deactivated: true, isLoaded: true }); /// ---D--- Deactivated
        }
        else if (this.props.User.Status === 'P') {
            this.setState({ payment_due: true, isLoaded: true }); /// ---P--- exprired pay and continue
        }
        else {
            this.setState({ not_aprooved: true, isLoaded: true }); /// ---N--- new account
        }

    }

    Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
                if(responseJson.image===''){
                   
                    this.setState({
                        ModalContactDetails: true
                    })
                }

            })
            .catch((error) => {
                console.error(error);
            });
            
    }

    get_data() {
        let val = 'null';
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.Istatus.toUpperCase()
            const textData = val.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ items: newData })

    }

    SearchFilterFunction(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.Name.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ items: newData, text: text })
    }

    render() {
        const { items, isLoaded, deactivated, not_aprooved, payment_due, Activated } = this.state;
        return (
            <Content className="scrollBox1" style={{ background: '#f2f3f5'}}>
      
        <Container>
                    
                   <HomeStati/>
                 
             
                                      
                    <div style={{ marginBottom: 20, marginTop: 0 }}>
                        {!isLoaded &&
                            <Loadingplaceholder />
                        }
                        {deactivated &&
                            <Deactivated_card />
                        }
                        {not_aprooved &&
                            <Not_aprooved_card />
                        }
                        {payment_due &&
                            <Payement_due_card />
                        }
                        {Activated &&
                            <div >
                                <Row>
                              
                                    {items.map((item, i) =>
                                        <Col xs="12" sm="12" key={i}>
                                           
                                            <ListItem2
                                                item={item} />
                                        </Col>)}
                                       
                                </Row>

                            </div>
                        }
                    </div>
                 
                  

                <Modal
         visible={this.state.ModalContactDetails}
          title="Please Upload Profile Picture"
          onOk={this.handleOk}
          onCancel={() => this.setState({ ModalContactDetails: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ ModalContactDetails: false })}>
              Cancel
            </Button>,
           

<Link to='/PhotoUpdate' >
<Button key="submit" type="primary" style={{marginLeft:10}}  >
Upload 
</Button>

               </Link>
          ]}
        >
          <p>Get more response by adding your photo.</p>
        
        </Modal>
               
                </Container>
        </Content>

        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHome);