import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, Card, DatePicker, InputNumber, Steps, Modal } from 'antd';
import { Link } from 'react-router-dom';
import API from '../../API';
import ImageUpload from '../../components/imageUpload';
import { connect } from 'react-redux';
import FooterS from '../../components/Footer';
const { Step } = Steps;
const { TextArea } = Input;
const ButtonGroup = Button.Group;
const { Option } = Select;
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            loading: false,
            issuccess: false,
            current:0,
            status: 'process',
            value: '',
            user_id: null,
            REG_ID:null,
            date: null,
            //step1
            FullName: null, Gender: null, DOB: null, RelCaste: null, Height: null, Weight: null, MaritialS: null,
            PhysicalS: null, aboutCandidate: null, ProfileCreator: null, Creatorphone: null, creatoremail: null,
            confirm: null,
            //end step1
            //step2
            HouseName: null, Place: null, parish: null, Diocese: null, CAddress: null, Contactphone: null, Contactemail: null, Fathers_Name: null,
            Father_Occupation: null, Mothers_Name: null, Mother_Occupation: null, Financial_Status: null, Brothers_Ma: null, Brothers_Un: null,Brothers_Priest: null,
            Sisters_Ma: null, Sisters_Un: null,Sisters_Nuns: null,
            //end steop2
            //step3
            Education_Category: null, Education: null, Work_Category: null, Working: null,Working_place:null,Income: null, PQualification: null,
            PEmployment: null, Secondmrg: null, Handicapped: null, About: null
            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    ondateChange = (value) => {
        if (value != null) {
            this.setState({ DOB: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ DOB: null, date: null });
        }
    }

    step1 = () => {
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                let stat = this.state.current + 1;
                this.setState({ current: stat, status: 'process' });
            }
            else {
                this.setState({ status: 'error' });
            }
        });
        //alert(this.state.current);
    }
    //send registration
    handleSubmit = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'FullName': this.state.FullName,
                    'Gender': this.state.Gender,
                    'DOB': this.state.DOB,
                    'RelCaste': this.state.RelCaste,
                    'Height': this.state.Height,
                    'Weight': this.state.Weight,
                    'MaritialS': this.state.MaritialS,
                    'PhysicalS': this.state.PhysicalS,
                    'aboutCandidate': this.state.aboutCandidate,
                    'ProfileCreator': this.state.ProfileCreator,
                    'Creatorphone': this.state.Creatorphone,
                    'creatoremail': this.state.creatoremail,
                    'confirm': this.state.confirm,
                    'HouseName': this.state.HouseName,
                    'Place': this.state.Place,
                    'parish': this.state.parish,
                    'Diocese': this.state.Diocese,
                    'CAddress': this.state.CAddress,
                    'Contactphone': this.state.Contactphone,
                    'Contactemail': this.state.Contactemail,
                    'Fathers_Name': this.state.Fathers_Name,
                    'Father_Occupation': this.state.Father_Occupation,
                    'Mothers_Name': this.state.Mothers_Name,
                    'Mother_Occupation': this.state.Mother_Occupation,
                    'Financial_Status': this.state.Financial_Status,
                    'Brothers_Ma': this.state.Brothers_Ma,
                    'Brothers_Un': this.state.Brothers_Un,
                    'Brothers_Priest': this.state.Brothers_Priest,
                    'Sisters_Ma': this.state.Sisters_Ma,
                    'Sisters_Un': this.state.Sisters_Un,
                    'Sisters_Nuns': this.state.Sisters_Nuns,
                    'Education_Category': this.state.Education_Category,
                    'Education': this.state.Education,
                    'Work_Category': this.state.Work_Category,
                    'Working': this.state.Working,
                    'Working_place':this.state.Working_place,
                    'Income': this.state.Income,
                    'PQualification': this.state.PQualification,
                    'PEmployment': this.state.PEmployment,
                    'Secondmrg': this.state.Secondmrg,
                    'Handicapped': this.state.Handicapped,
                    'About': this.state.About
                }
                fetch(API.BASE_URL + API.REGISTER, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            let stat = this.state.current + 1;
                            this.setState({
                                loading: false,
                                issuccess: true,
                                current: stat, status: 'process',
                                user_id: responseJson.user_id,
                                REG_ID:responseJson.REG_ID
                            });
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            this.error(responseJson.message);
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        this.error('Something gone wrong!try again');
                        console.error(error);
                    });
            }
        });
    };
    FinalReg = () => {
       this.ProfilePercentage();
        this.props.history.push("/login");
     }
    //end registration
    ProfilePercentage = () => {
        let obj = { user_id: this.state.user_id}
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
            this.error('Something gone wrong! try again');
          
          });
      }
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    error = (messge) => {
        Modal.error({
            title:messge,
            content: 'your registration faild...',
        });
    }

  

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '+91',
        })(
            <Select style={{ width: 70 }}>
                <Option value="+91">+91</Option>
            </Select>,
        );
        const height_Array = [];
        for (let i = 100; i < 350; i++) {
            height_Array.push({
                value: i,
            });
        }
        const Weight_array = [];
        for (let i = 20; i < 260; i++) {
            Weight_array.push({
                value: i,
            });
        }
        return (
            <div className="fontsizelimit" style={{ fontSize: 10 }}>
                <div className="colorcontainer" style={{ height: 250 }}>
                    <Container >
                    <h5 style={{ color: '#fff', paddingTop: 25 }}>{this.props.lang.ProReg}</h5>
                        <p style={{ color: '#fff', paddingBottom: 10,fontSize:14 }}>Prolifemarry.com is a venture of KCBC (Kerala Catholic Bishops Council) Malabar Prolife.  </p>
                         </Container>
                </div>
                <Container style={{ marginTop: -150, marginBottom: 40 }}>
                    <Card style={{ borderRadius: 10 }}>
                        <Steps current={this.state.current} direction="horizontal" status={this.state.status}>
                            <Step />
                            <Step />
                            <Step />
                            <Step />
                        </Steps>
                        <hr />
                        <Form {...formItemLayout}>
                            {this.state.current === 0 &&
                                <Row>
                                    <Col sm="6" xs="12">
                                        <h6 style={{ paddingTop: 20 }}>{this.props.lang.PersonalDetails}</h6><hr />

                                        <Form.Item label={this.props.lang.FullName}>
                                            {getFieldDecorator('FullName', {
                                                initialValue: this.state.FullName,
                                                setFieldsValue: this.state.FullName,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please input your Full Name!' }]
                                            })(
                                                <Input
                                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                    placeholder="Full Name"
                                                    name="FullName"
                                                    maxLength={98}
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.Gender} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Gender', {
                                                initialValue: this.state.Gender,
                                                setFieldsValue: this.state.Gender,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please select Gender!' }],
                                            })(
                                                <Radio.Group name="Gender">
                                                    <Radio.Button value="Male">{this.props.lang.Male}</Radio.Button>
                                                    <Radio.Button value="Female">{this.props.lang.Female}</Radio.Button>
                                                </Radio.Group>,
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.DOB} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('date', {
                                                initialValue: this.state.date,
                                                setFieldsValue: this.state.date,
                                                rules: [{ type: 'object', required: true, message: 'Please select Date!' }],
                                            })(
                                                <DatePicker format="YYYY-MM-DD" placeholder="Select Date" onChange={this.ondateChange} />
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.Cast} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('RelCaste', {
                                                initialValue: this.state.RelCaste,
                                                setFieldsValue: this.state.RelCaste,
                                                rules: [{ required: true, message: 'Please select Religion/Caste!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    name={"RelCaste"}
                                                    placeholder="Religion/Caste"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'RelCaste', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Religion_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.Height} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Height', {
                                                initialValue: this.state.Height,
                                                setFieldsValue: this.state.Height,
                                                rules: [{ required: true, message: 'Please select Height!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    placeholder="Select Height"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Height', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {height_Array.map((item) => (
                                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>


                                        <Form.Item label={this.props.lang.Wight} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Weight', {
                                                initialValue: this.state.Weight,
                                                setFieldsValue: this.state.Weight,
                                                rules: [{ required: true, message: 'Please select Weight!' }],
                                            })(
                                                <Select
                                                name="Weight"
                                                showSearch 
                                                placeholder="Select Weight"
                                                optionFilterProp="children"
                                                onChange={(val) => { this.handleChange({ target: { name: 'Weight', value: val } }) }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                    {Weight_array.map((item) => (
                                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.MStatus} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('MaritialS', {
                                                initialValue: this.state.MaritialS,
                                                setFieldsValue: this.state.MaritialS,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please select Maritial Status!' }],
                                            })(
                                                <Radio.Group name="MaritialS" >

                                                    <Radio.Button value="U">{this.props.lang.UserSearchUnmarried}</Radio.Button>
                                                    <Radio.Button value="W">{this.props.lang.UserSearchWidow}</Radio.Button>
                                                    <Radio.Button value="D">{this.props.lang.UserSearchDivorcee}</Radio.Button>
                                                </Radio.Group>,
                                            )}

                                        </Form.Item>

                                        <Form.Item label={this.props.lang.PStatus} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('PhysicalS', {
                                                initialValue: this.state.PhysicalS,
                                                setFieldsValue: this.state.PhysicalS,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please select Physical Status!' }],
                                            })(
                                                <Radio.Group name="PhysicalS">
                                                    <Radio.Button value="Normal">{this.props.lang.UserSearchNormal}</Radio.Button>
                                                    <Radio.Button value="With Disability">{this.props.lang.UserSearchDisable}</Radio.Button>
                                                </Radio.Group>,
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.About} style={{ marginTop: -20 }}>
                                            <TextArea
                                                name="aboutCandidate"
                                                value={this.state.aboutCandidate}
                                                onChange={this.handleChange}
                                                maxLength={950}
                                                placeholder="About the Candidate"
                                                autosize={{ minRows: 2, maxRows: 6 }}
                                            />
                                        </Form.Item>



                                    </Col>
                                    <Col sm="6" xs="12">
                                        <h6 style={{ paddingTop: 20 }}>{this.props.lang.PCreator}</h6><hr />
                                        <Form.Item label={this.props.lang.PCreator}>
                                            {getFieldDecorator('ProfileCreator', {
                                                initialValue: this.state.ProfileCreator,
                                                setFieldsValue: this.state.ProfileCreator,
                                                rules: [{ required: true, message: 'Please select EProfile Creator!' }],
                                            })(
                                                <Select placeholder="Profile Creator" name="ProfileCreator"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'ProfileCreator', value: val } }) }}>
                                                   <Option value="Self">{this.props.lang.Self}</Option>
                                                    <Option value="Parents">{this.props.lang.Parents}</Option>
                                                    <Option value="Siblings">{this.props.lang.Siblings}</Option>
                                                    <Option value="Relatives">{this.props.lang.Relatives}</Option>
                                                    <Option value="Friends">{this.props.lang.Friends}</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.PCreMob} style={{ marginTop: -20 }}   extra="Only the Admin can see the Number">
                                            {getFieldDecorator('Creatorphone', {
                                                initialValue: this.state.Creatorphone,
                                                setFieldsValue: this.state.Creatorphone,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please input your phone number!' }],
                                            })(<Input
                                                addonBefore={prefixSelector}
                                                style={{ width: '100%', margin: 0, padding: 0 }}
                                                name="Creatorphone"
                                               
                                                placeholder="Enter 10 digit phone number"
                                                type='number'
                                                maxLength={10} />)}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.Email} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('creatoremail', {
                                                initialValue: this.state.creatoremail,
                                                setFieldsValue: this.state.creatoremail,
                                                onChange: this.handleChange,
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        required: false,
                                                        message: 'The input is not valid E-mail!',
                                                    },

                                                ],
                                            })(<Input name="creatoremail"
                                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Email"
                                                maxLength='48' />)}
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.Password} hasFeedback style={{ marginTop: -20 }}>
                                            {getFieldDecorator('password', {
                                                initialValue: this.state.confirm,
                                                setFieldsValue: this.state.confirm,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                    {
                                                        validator: this.validateToNextPassword,
                                                    },
                                                ],
                                            })(<Input.Password placeholder="Password" 
                                            maxLength='50'/>)}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.ConfirmPassword} hasFeedback style={{ marginTop: -20 }}>
                                            {getFieldDecorator('confirm', {
                                                initialValue: this.state.confirm,
                                                setFieldsValue: this.state.confirm,
                                                onChange: this.handleChange,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!',
                                                    },
                                                    {
                                                        validator: this.compareToFirstPassword,
                                                    },
                                                ],
                                            })(<Input.Password onBlur={this.handleConfirmBlur} name="confirm"  maxLength='50' placeholder="Confirm Password" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                                            <ButtonGroup>
                                                <Link to='/' style={{ textDecoration: 'none' }}>
                                                    <Button type="primary">
                                                        <Icon type="left" />
                                                        {this.props.lang.GoBack}   
                                                </Button>
                                                </Link>
                                                <Button type="primary" onClick={this.step1}>
                                                {this.props.lang.Next}   
                                                <Icon type="right" />
                                                </Button>
                                            </ButtonGroup>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            {this.state.current === 1 &&
                                <Row>
                                    <Col sm="6" xs="12">
                                        <h6 style={{ paddingTop: 20 }}>{this.props.lang.CInfo}</h6><hr />
                                        <Form.Item label={this.props.lang.HName} >
                                        {getFieldDecorator('HouseName', {
                                                initialValue: this.state.HouseName,
                                                setFieldsValue: this.state.HouseName,
                                                rules: [{ required: true, message: 'Please input House Name!' }],
                                            })(
                                            <Input
                                                name="HouseName"
                                                maxLength='95' 
                                                value={this.state.HouseName}
                                                onChange={this.handleChange}
                                                placeholder="House Name" />
                                                )}
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.Place} style={{ marginTop: -20 }}>
                                        {getFieldDecorator('Place', {
                                initialValue: this.state.Place,
                                setFieldsValue: this.state.Place,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please input Place!' }],
                            })(
                                <Input
                                    name="Place"
                                    maxLength='195' 
                                    placeholder="Place" />)}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.Parish} style={{ marginTop: -20 }}>
                                            <Input
                                             maxLength='95' 
                                                name="parish"
                                                value={this.state.parish}
                                                onChange={this.handleChange}
                                                placeholder="parish" />
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.Diocese} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Diocese', {
                                                initialValue: this.state.Diocese,
                                                setFieldsValue: this.state.Diocese,
                                                rules: [{ required: false, message: 'Please select Diocese!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    name={"Diocese"}
                                                    placeholder="Diocese"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Diocese', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Diocese_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.CAddress} style={{ marginTop: -20 }}>
                                            <TextArea
                                            maxLength='950' 
                                                name="CAddress"
                                                value={this.state.CAddress}
                                                onChange={this.handleChange}
                                                placeholder="Communicational Address"
                                                autosize={{ minRows: 2, maxRows: 6 }}
                                            />
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.PNumber} style={{ marginTop: -20 }} extra="All Profile Visitors can see this Number">
                                            {getFieldDecorator('Contactphone', {
                                                initialValue: this.state.Contactphone,
                                                setFieldsValue: this.state.Contactphone,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please input your phone number!' }],
                                            })(<Input
                                                name="Contactphone"
                                                type="number"
                                                maxLength='10' 
                                                placeholder="Enter 10 digit phone number"
                                                addonBefore={prefixSelector}
                                                style={{ width: '100%', margin: 0, padding: 0 }} />)}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.SEmail} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Contactemail', {
                                                initialValue: this.state.Contactemail,
                                                setFieldsValue: this.state.Contactemail,
                                                onChange: this.handleChange,
                                                rules: [{ type: 'email', message: 'The input is not valid E-mail!', },],
                                            })(<Input
                                                maxLength='48' 
                                                name="Contactemail"
                                                placeholder="E-mail" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col sm="6" xs="12">
                                        <h6 style={{ paddingTop: 20 }}>{this.props.lang.UserProfileFamily}</h6><hr />

                                        <Form.Item label={this.props.lang.FName}>
                                            {getFieldDecorator('Fathers_Name', {
                                                initialValue: this.state.Fathers_Name,
                                                setFieldsValue: this.state.Fathers_Name,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please input your Fathers Name!' }],
                                            })(
                                                <Input
                                                maxLength='49' 
                                                    name="Fathers_Name"
                                                    placeholder="Father's Name" />)}
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.FOccu} style={{ marginTop: -20 }}>
                                            <Input
                                              maxLength='49' 
                                                name="Father_Occupation"
                                                value={this.state.Father_Occupation}
                                                onChange={this.handleChange}
                                                placeholder="Father's Occupation"
                                            />
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.MName} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Mothers_Name', {
                                                initialValue: this.state.Mothers_Name,
                                                setFieldsValue: this.state.Mothers_Name,
                                                onChange: this.handleChange,
                                                rules: [{ required: true, message: 'Please input your Mothers Name!' }],
                                            })(
                                                <Input
                                                maxLength='49' 
                                                    name="Mothers_Name"
                                                    placeholder="Mother's Name" />)}
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.MOccu} style={{ marginTop: -20 }}>
                                            <Input
                                              maxLength='49' 
                                                name="Mother_Occupation"
                                                value={this.state.Mother_Occupation}
                                                onChange={this.handleChange}
                                                placeholder="Mother's Occupation" />
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.FinSta} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Financial_Status', {
                                                initialValue: this.state.Financial_Status,
                                                setFieldsValue: this.state.Financial_Status,
                                                rules: [{ required: false, message: 'Please select Financial Status!' }],
                                            })(
                                                <Select name="Financial_Status" placeholder="Financial Status"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Financial_Status', value: val } }) }}>
                                                    <Option value="Below Moderate">Below Moderate</Option>
                                                    <Option value="Moderate">Moderate</Option>
                                                    <Option value="Above Moderate">Above Moderate</Option>
                                                    <Option value="Rich">Rich</Option>
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.NBro} style={{ marginBottom: -20 }}>
                                            <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.Married}>
                                                <InputNumber
                                                    name="Brothers_Ma"
                                                   
                                                    type="number"
                                                    value={this.state.Brothers_Ma}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Brothers_Ma', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -20 }} />
                                            </Form.Item>
                                            <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.UnMarried}>
                                                <InputNumber
                                                    type="number"
                                                    name="Brothers_Un"
                                                    value={this.state.Brothers_Un}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Brothers_Un', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                                            </Form.Item>
                                            <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.Priest}>
                                                <InputNumber
                                                    type="number"
                                                    name="Brothers_Priest"
                                                    value={this.state.Brothers_Priest}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Brothers_Priest', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                                            </Form.Item>
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.NSis} style={{ marginBottom: 0 }}>
                                            <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.Married}>
                                                <InputNumber
                                                    type="number"
                                                    name="Sisters_Ma"
                                                    value={this.state.Sisters_Ma}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Sisters_Ma', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -20 }} />
                                            </Form.Item>
                                            <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.Unmarried}>
                                                <InputNumber
                                                    type="number"
                                                    name="Sisters_Un"
                                                    value={this.state.Sisters_Un}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Sisters_Un', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                                            </Form.Item>
                                            <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.Nuns}>
                                                <InputNumber
                                                    type="number"
                                                    name="Sisters_Nuns"
                                                    value={this.state.Sisters_Nuns}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Sisters_Nuns', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                                            </Form.Item>
                                        </Form.Item>
                                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                                            <ButtonGroup>
                                                <Button type="primary" onClick={() => this.setState({ current: this.state.current - 1 })}>
                                                    <Icon type="left" />
                                                    {this.props.lang.GoBack} 
                                                </Button>
                                                <Button type="primary" onClick={this.step1}>
                                                {this.props.lang.Next} 
                                                <Icon type="right" />
                                                </Button>
                                            </ButtonGroup>
                                        </Form.Item>

                                    </Col>
                                </Row>
                            }
                            {this.state.current === 2 &&
                                <Row>
                                    <Col sm="6" xs="12">
                                        <h6 style={{ paddingTop: 20 }}>{this.props.lang.UserProfileEducation}</h6><hr />

                                        <Form.Item 
                                        label={this.props.lang.EduCat}>
                                            {getFieldDecorator('Education_Category', {
                                                initialValue: this.state.Education_Category,
                                                setFieldsValue: this.state.Education_Category,
                                                rules: [{ required: true, message: 'Please select Maritial Status!' }],
                                            })(
                                                <Select 
                                                showSearch
                                                name="Education_Category" 
                                                placeholder="Work Category"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Education_Category', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Education_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.EduDet} style={{ marginTop: -20 }}>

                                            <Input
                                                name="Education"
                                                maxLength='98' 
                                                value={this.state.Education}
                                                onChange={this.handleChange}
                                                prefix={<Icon type="schedule" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Education"
                                            />

                                        </Form.Item>

                                        <Form.Item label={this.props.lang.WorkCat} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Work_Category', {
                                                initialValue: this.state.Work_Category,
                                                setFieldsValue: this.state.Work_Category,
                                                rules: [{ required: true, message: 'Please select Work Category!' }],
                                            })(
                                                <Select 
                                                showSearch
                                                name="Work_Category"
                                                 placeholder="Work Category"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Work_Category', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.Work_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.WorkFirm} style={{ marginTop: -20 }}>
                                            <Input
                                                name="Working"
                                                maxLength='48' 
                                                value={this.state.Working}
                                                onChange={this.handleChange}
                                                prefix={<Icon type="bank" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Working Details" />
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.WorkPlace} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Working_place', {
                                                initialValue: this.state.Working_place,
                                                setFieldsValue: this.state.Working_place,
                                                rules: [{ required: false, message: 'Please select Working Place!' }],
                                            })(
                                                <Select
                                                    showSearch
                                                    name={"Working_place"}
                                                    placeholder="Working place"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Working_place', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }>
                                                    {this.props.dropdown.WorkingPlace_array.map((item) => (
                                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.AnnualIn} style={{ marginTop: -20 }}>
                                            <Input
                                                name="Income"
                                                maxLength='19' 
                                                value={this.state.Income}
                                                onChange={this.handleChange}
                                                prefix={<Icon type="money-collect" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="Annual Income" />

                                        </Form.Item>
                                    </Col>
                                    <Col sm="6" xs="12">
                                        <h6 style={{ paddingTop: 20 }}>{this.props.lang.UserProfilePatner}</h6><hr />
                                        <Form.Item label={this.props.lang.PatQuli} >
                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Patner's Qualification"
                                                optionLabelProp="label"
                                                initialValue={this.state.PQualification}
                                                setFieldsValue={this.state.PQualification}
                                                onChange={(val) => { this.handleChange({ target: { name: 'PQualification', value: val } }) }}>
                                                {this.props.dropdown.Education_array.map((item) => (
                                                    <Option value={item.id} label={item.Name} key={item.id}>{item.Name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label={this.props.lang.PatnrEmpl} style={{ marginTop: -20 }}>


                                            <Select
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="Patner's Employment"
                                                optionLabelProp="label"
                                                initialValue={this.state.PEmployment}
                                                setFieldsValue={this.state.PEmployment}
                                                onChange={(val) => { this.handleChange({ target: { name: 'PEmployment', value: val } }) }}>
                                                {this.props.dropdown.Work_array.map((item) => (
                                                    <Option value={item.id} label={item.Name} key={item.id}>{item.Name}</Option>
                                                ))}
                                            </Select>


                                        </Form.Item>
                                        <Form.Item label={this.props.lang.Smrg} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Secondmrg', {
                                                initialValue: this.state.Secondmrg,
                                                setFieldsValue: this.state.Secondmrg,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please select Gender!' }],
                                            })(
                                                <Radio.Group name="Secondmrg">
                                                    <Radio.Button value="Yes">{this.props.lang.Yes}</Radio.Button>
                                                    <Radio.Button value="No">{this.props.lang.No}</Radio.Button>
                                                </Radio.Group>,
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.Handi} style={{ marginTop: -20 }}>
                                            {getFieldDecorator('Handicapped', {
                                                initialValue: this.state.Handicapped,
                                                setFieldsValue: this.state.Handicapped,
                                                onChange: this.handleChange,
                                                rules: [{ required: false, message: 'Please select Gender!' }],
                                            })(
                                                <Radio.Group name="Handicapped">
                                                    <Radio.Button value="Yes">{this.props.lang.Yes}</Radio.Button>
                                                    <Radio.Button value="No">{this.props.lang.No}</Radio.Button>
                                                </Radio.Group>,
                                            )}
                                        </Form.Item>

                                        <Form.Item label={this.props.lang.Patner} style={{ marginTop: -20 }}>
                                            <TextArea
                                                name="About"
                                                maxLength='498' 
                                                value={this.state.About}
                                                onChange={this.handleChange}
                                                placeholder="Expectations about the Partner"
                                                autosize={{ minRows: 2, maxRows: 6 }} />

                                        </Form.Item>
                                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                                            <ButtonGroup>
                                                <Button type="primary" onClick={() => this.setState({ current: this.state.current - 1 })}>
                                                    <Icon type="left" />
                                                    {this.props.lang.GoBack} 
                                                </Button>
                                                <Button type="primary" onClick={()=>this.handleSubmit()}>
                                                {this.props.lang.Register} 
                                                <Icon type="right" />
                                                </Button>
                                            </ButtonGroup>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            {this.state.current === 3 &&
                                <Row>
                                    <ImageUpload UserId={this.state.user_id}/>
                                    <Col>
                                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }} >
                                            <Link to='/login' style={{ textDecoration: 'none' }}>
                                                <Button type="primary" style={{ marginRight: 10 }} onClick={() => this.FinalReg()}>
                                                {this.props.lang.FinishLater}     
                                            </Button>
                                            </Link>

                                            <Link to='/login' style={{ textDecoration: 'none' }}>
                                                <Button type="primary" onClick={() => this.FinalReg()}>
                                                {this.props.lang.Finish}   
                                            </Button>
                                            </Link>
                                            
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </Card>
                </Container>

                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Processing</h6><p>Please wait..</p></Form.Item>
                    </Form>
                </Modal>

                <Modal
                    centered
                    visible={this.state.issuccess}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                    <img src={require('../../images/success1.gif')} style={{ height: 250, width: 350 }} alt="success" />
                  
                        <h5>Welcome</h5>
                        <h4 style={{color:'red'}}>Your User ID {this.state.REG_ID}</h4>
                        <p>Your Registration is successful</p>
                        <p>Please Upload your Photos</p>
                        <p>More details check your email</p>
                        <Button type="primary" onClick={() => { this.setState({ issuccess: false }) }}>
                            Next
                            </Button>
                    </div>
                </Modal>
                <FooterS />
            </div>
        )

    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown,
        lang:state.lang
    }
}
export default connect(mapStateToProps)(Form.create()(Register));