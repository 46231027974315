import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { message, List, Icon, Modal } from 'antd';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import Loading_placeholder from '../../components/Loading_placeholder';
import FooterS from '../../components/Footer';
import API from '../../API';
import ListItem2 from '../../components/MsgProfile/MsgProfile';
import { Link } from 'react-router-dom';
import styles from '../../styles';
class Msg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProfileId: this.props.match.params.id,
            showId: null,
            loading: true,
            items: [],
            loginreq: false
        };
    }
    componentDidMount() {
        this.setState({ showId: this.state.ProfileId, loading: true });
        message.config({ top: 100 });
        let obj = { profileID: this.state.ProfileId }
        fetch(API.BASE_URL + API.Msg, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result[0].status === 'success') {
                        this.setState({
                            loading: false,
                            items: result
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            items: [],
                        });
                    }
                },
                (error) => {
                    this.setState({
                        loading: false,
                    });
                    this.error();
                }
            )
    }
    onChangeid = (event) => {
        this.setState({
            ProfileId: event.target.value
        })
    }

    error = () => {
        Modal.error({
            title: 'Some thing gone wrong try again',
            content: 'your registration faild...',
        });
    }
    handleCancel = () => {
        this.setState({ loginreq: false })
    }
    showlogin = () => {
        this.setState({ loginreq: true })
    }
    render() {
        return (
            <div>
                <div className="logincontainer">
                    <Container style={{ marginTop: 10, marginBottom: 10 }}>
                        <Row>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                                <InputGroup style={{ marginTop: 40 }}>
                                    <FormControl
                                        placeholder="Search profile ID"
                                        aria-label="PROFILE ID"
                                        aria-describedby="PROFILE ID"

                                        onChange={this.onChangeid} />
                                    <InputGroup.Append>
                                        <Button
                                            style={{ backgroundColor: '#0070ba', borderColor: '#0070ba' }}
                                            onClick={() => { this.componentDidMount() }}>
                                            Search
                            </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                    {this.state.loading
                        ? <Container><Loading_placeholder /></Container>
                        : <Container>
                            <div>
                                <span>Result for</span><span style={{ fontSize: 17, fontWeight: 'bold' }}> {this.state.showId}</span>
                            </div><hr />
                            <Row>
                                <Col sm="12" md={{ size: 10, offset: 1 }}>
                                    <List
                                        itemLayout="vertical"
                                        size="large"
                                        dataSource={this.state.items}
                                        renderItem={item => (
                                            <ListItem2
                                                item={item}
                                                showlogin={() => this.showlogin()} />
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    }
                </div><br />
                <FooterS />
                <Modal
                    centered
                    visible={this.state.loginreq}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={500}
                >
                    <div className="aligitemncenter">
                        <div>
                            <img src={require('../../images/logo.png')} style={{ height: 30}} alt="logo" /><br />
                            <Icon type="login" style={{ fontSize: 50, color: '#99084F', marginTop: 20 }} />
                            <h6 style={{ marginTop: 10, marginBottom: 30 }}>Login Or Register to continue</h6>
                        </div>
                        <Row>
                            <Col sm="6" xs="6">
                                <Link to="/login" style={styles.linkdecoration}>
                                    <Button block size='sm' style={{ backgroundColor: '#0070ba', borderColor: '#0070ba' }}>Login</Button></Link>
                            </Col>
                            <Col sm="6" xs="6">
                                <Link to="/Register" style={styles.linkdecoration}>
                                    <Button block size='sm' style={{ backgroundColor: '#99084F', borderColor: '#99084F' }}>Register</Button></Link>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>


        )
    }
}
export default Msg;