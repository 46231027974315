import React from 'react';
import { connect } from 'react-redux';
import './commonstyles.css';
import { Icon } from 'antd';
import {addshrotlistSerives,removeshrotlistServices} from '../redux/Services/ShortListServices';
function Shortlistbutton(props) {
    return (
        <div>
            {props.Shortlist
            ?<button className="listiteminbtnactiveShort" lang={props.lang.lang}
            onClick={()=>props.removeshrotlistServices('remove',props.User.user_id,props.target_id,props.target)}>
           
            {props.lang.Shortlisted} 
                            </button>
            :<button className="listiteminbtnShort" lang={props.lang.lang}
            onClick={()=>props.addshrotlistSerives('add',props.User.user_id,props.target_id,props.target)} >
           
            {props.lang.Shortlist} 
                            </button>
            }
        </div>
    )
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        addshrotlistSerives: (act,user_id,target_id,target) => {
            dispatch(addshrotlistSerives(act,user_id,target_id,target));
        },
        removeshrotlistServices: (act,user_id,target_id,target) => {
            dispatch(removeshrotlistServices(act,user_id,target_id,target));
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Shortlistbutton);