

import React, { Component } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Button, Modal, Form, Input, Icon, message, Popover, Table, Popconfirm, Select, DatePicker } from 'antd';

import API from '../API';

import Highlighter from 'react-highlight-words';

const { Option } = Select;
const { TextArea } = Input;
class AdminUsers extends Component {
    state = {
        visibleSMSModal: false,
        loading: false,
        visible: false,
      };

      
      showModalSMS = (record) => {
       
        this.setState({
           
          visibleSMSModal: true,
          UserId: record.id,
          Name:record.Name,
           MainMob: record.MainMob
           

        });
      };
     
     
    constructor(props) {
        super(props);

     
        this.state = {
            loading: true,
            error: false,
            activeTab: 'All',
            data: []
          
        };
        this.arrayholder = [];
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    handleCancel = () => {
        this.setState({ visible: false });
      };
      handleCancelCustomSMS = () => {
        this.setState({ visibleSMSModal: false });
      };
    componentDidMount = () => {
        message.config({ top: 100 });
        fetch(API.BASE_URL + API.GET_USERS_Backup, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data: responsesJson, loading: false },
                    function () { this.arrayholder = responsesJson });
            })
            .catch((error) => {
                //message.error('Error. Try again !');
            });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }


   
  
    //search function
    SearchFilterFunction(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.Name.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    SearchFiltergisterID(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.UserId.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    SearchFiltergisterphone(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.MainMob.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    SearchFilterDate(text) {
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.RegDate.toUpperCase()
            const textData = text.target.value.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ data: newData, text: text })
    }
    //end search function 
    ondateChangePayed = (value) => {
        if (value != null) {
            this.setState({ DPLog: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ DPLog: null, date: null });
        }
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    render() {
        const { visible, loading } = this.state;
        const columns = [
            { title: 'No', dataIndex: 'id', key: 'id' },
            { title: 'Image', dataIndex: 'image1', 
            render:  (value, record) => <img style={{ width:100 }} src={record.image1} />
        },
            { title: 'User ID', dataIndex: 'UserId', key: 'UserId' },
            {
                title: 'Name', dataIndex: 'Name', key: 'Name',
                onFilter: (value, record) => record.Name.indexOf(value) === 0,
                sorter: (a, b) => a.Name.length - b.Name.length,
                sortDirections: ['descend']
            },
           
            { title: 'Mobile Numer', dataIndex: 'MainMob', key: 'MainMob' },
            { title: 'Number', dataIndex: 'PNumber', key: 'PNumber' },
            { title: 'DOB', dataIndex: 'DOB', key: 'DOB' },
            { title: 'Religion', dataIndex: 'Religion', key: 'Religion' },
            { title: 'EduDetail', dataIndex: 'EduDetail', key: 'EduDetail' },
            { title: 'WorkDetail', dataIndex: 'WorkDetail', key: 'WorkDetail' },

          

        ];
        const LogCol = [
            { title: 'No', dataIndex: 'id', key: 'id' },
            { title: 'Log Date', dataIndex: 'RegDate', key: 'RegDate', ...this.getColumnSearchProps('RegDate') },
            { title: 'Type', dataIndex: 'Type', key: 'Type', ...this.getColumnSearchProps('Type') },
            { title: 'Comment', dataIndex: 'Comment', key: 'Comment' },
            { title: 'Who Done', dataIndex: 'WhoDone', key: 'WhoDone', ...this.getColumnSearchProps('WhoDone') },
            

        ];
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        function onChangelog(date, dateString) {
            console.log(date, dateString);
          }
          const { getFieldDecorator } = this.props.form;
          const dateFormat = 'YYYY/MM/DD';
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>User - All</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                        <div>
                            <Row style={{ margin: 10, marginLeft: 0 }}>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="search Name"
                                        onChange={(text) => this.SearchFilterFunction(text)} />
                                </Col>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="User ID"
                                        onChange={(text) => this.SearchFiltergisterID(text)} />
                                </Col>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="Search Phone number"
                                        onChange={(text) => this.SearchFiltergisterphone(text)} />
                                </Col>
                                <Col sm="3" xs="12">
                                    <Input
                                        placeholder="Search Reg Date"
                                        onChange={(text) => this.SearchFilterDate(text)} />
                                </Col>
                                {/*<Col sm="3" xs="12" align="right">
                                    <Link to={`/AdminApp/AddUser/${null}`} style={{ textDecoration: 'none' }}>
                                        <Button type="primary">Add New +</Button>
                                    </Link>
                                </Col>*/}
                            </Row>

                            <Table columns={columns} dataSource={this.state.data} onChange={onChange} pagination={{ pageSize: 25 }} />
                        </div>
                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
              

       
            </Container>
        )
    }
}
export default Form.create()(AdminUsers);