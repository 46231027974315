
        import React, { Component } from 'react';
        import {Container,Row,Col} from 'reactstrap';
        import {Layout} from 'antd';
        const {Footer } = Layout;
        class Appdownload extends Component {
            render() {
                return (
                    <div style={{marginTop:10}}>
                      <Row>
                        <Col sm="4" xs="12">
                        <img src={require('../images/phone1.png')} alt="logo" style={{marginLeft:15}}/>
                        </Col>
                      
                      </Row>
                    </div>
                )
            }
        }
        export default Appdownload;