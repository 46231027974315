import * as types from './Types';

export const setenglishAction = (data) => ({
    type: types.SET_ENGLISH,
    payload: data
});

export const setmalayalamAction = (data) => ({
    type: types.SET_MALAYALAM,
    payload: data
});







