import React, { Component } from 'react';
import { Col, Row, CardBody, Card, CardFooter } from 'reactstrap';
import { Icon, message, Button, Popover } from 'antd';
import styles from '../styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import API from '../API';
import { FaUserAlt, FaUserGraduate, FaChurch } from 'react-icons/fa';
import { MdPlace, MdWork } from "react-icons/md";
const ButtonGroup = Button.Group;
class ListItem extends Component {
    intrested = () => {
        let obj = {
            user_id: this.props.User.user_id,
            target_id: this.props.item.user_id
        };
        fetch(API.BASE_URL + API.INTRESTED, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ isLoaded: true });
                    this.props.reload();
                },
                (error) => {
                    message.error('Loading faild');
                    this.setState({
                        isLoaded: true
                    });
                }
            )
    }

    //update status
    Change_Actions = (val) => {
        let obj = {
            R_ID: this.props.item.R_ID,
            value: val,
            user_id: this.props.User.user_id,
            target_id: this.props.item.user_id
        };
        fetch(API.BASE_URL + API.INTREST_ACTIONS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ isLoaded: true });
                    this.props.reload();
                },
                (error) => {
                    message.error('Loading faild');
                    this.setState({
                        isLoaded: true
                    });
                }
            )
    }
    //end update status
    //shortlist action
    Short_list = (act) => {
        let obj = {
            S_ID: this.props.item.S_ID,
            action: act,
            user_id: this.props.User.user_id,
            target_id: this.props.item.user_id
        };
        fetch(API.BASE_URL + API.SHORTLIST_ACTION, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ isLoaded: true });
                    this.props.reload();
                },
                (error) => {
                    message.error('Loading failed');
                    this.setState({
                        isLoaded: true
                    });
                }
            )
    }
    //end shortlist action
    Religion(Relig) {
        let array = this.props.dropdown.Religion_array;
        let filteredArray = '';
        return filteredArray = array.filter(function (obj) {
            return obj.id === Relig;
        }).map(function (obj) {
            return obj.Name;
        });
    }

    WorkCat(Work) {
        let array = this.props.dropdown.Work_array;
        let filteredArray = '';
        return filteredArray = array.filter(function (obj) {
            return obj.id === Work;
        }).map(function (obj) {
            return obj.Name;
        });
    }

    EduCat(Edu) {
        let array = this.props.dropdown.Education_array;
        let filteredArray = '';
        return filteredArray = array.filter(function (obj) {
            return obj.id === Edu;
        }).map(function (obj) {
            return obj.Name;
        });
    }
    render() {
        function age(DOB) {
            var birthdate = new Date(DOB);
            var cur = new Date();
            var diff = cur - birthdate;
            var age = Math.floor(diff / 31536000000);
            return age;
        }
        const Accept = (
            <Button style={styles.BtnReject} onClick={() => this.Change_Actions('Rejected')}>Reject Request</Button>
        );
        const Reject = (
            <Button style={styles.Btnaccept} onClick={() => this.Change_Actions('Accepted')}>Accept Request</Button>
        );
        const cancel = (
            <Button type="primary" style={{ fontWeight: 'bold' }} onClick={() => this.Change_Actions('Canceld')}>Cancel Request</Button>
        );
        return (
            <Card style={{ marginTop: 10, marginBottom: 8, borderRadius: 5 }} >
                <CardBody>
                    <Row style={{ margin: -10 }}>
                        <Col sm='4' xs="12" style={{ margin: 0 }}>
                            {this.props.item.image1 ? <img alt="logo" src={this.props.item.image1} className="recomend-image" style={{
                                borderRadius: 5
                            }} />
                                : <img alt="logo" src={require('../images/placeholder.jpg')} className="recomend-image" style={{ borderRadius: 5 }} />}
                        </Col>
                        <Col sm='8' xs="12">
                            <span className="ListHead" style={{ fontFamily: "Nunito-SemiBold", fontSize: 18, color: '#191919', marginTop: 10, marginRight: 5 }}>{this.props.item.Name}</span>
                            <span style={{ fontFamily: "Nunito-SemiBold", fontSize: 15, fontWeight: '500', color: '#949693' }}>  {this.props.item.UserId}</span>
                            <p className="ListItemSub" style={{ marginTop: 5 }}><FaUserAlt style={{ marginRight: 10 }} />{age(this.props.item.DOB)} yrs / {this.props.item.Height} Cm</p>
                            <p className="ListItemSub" style={{ marginTop: -10 }}> <FaChurch style={{ marginRight: 10 }} />{this.Religion(this.props.item.Religion)}</p>
                            <p className="ListItemSub" style={{ marginTop: -10 }}><FaUserGraduate style={{ marginRight: 10 }} />{this.EduCat(this.props.item.EduCat)}</p>
                            <p className="ListItemSub" style={{ marginTop: -10 }}><MdWork style={{ marginRight: 10 }} />{this.WorkCat(this.props.item.WorkCat)}</p>
                            <p className="ListItemSub" style={{ marginTop: -10 }}><MdPlace style={{ marginRight: 10 }} />{this.props.item.Place}</p>



                        </Col>

                    </Row>
                </CardBody>
                <CardFooter className="text-muted" style={{ backgroundColor: '#fff', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    {this.props.User.auth === true
                        ? <Row className="itemscentr" style={{ margin: 0 }}>

                            {this.props.item.intrested === 'IN' &&
                                <Col>
                                    {this.props.item.Istatus === 'Requested' &&

                                        <ButtonGroup>
                                            <Button style={styles.Btnaccept} onClick={() => this.Change_Actions('Accepted')}>
                                                <Icon type="check" style={{ fontSize: 18, verticalAlign: 1, marginLeft: 0 }} /> Accept</Button>
                                            <Button style={styles.BtnReject} onClick={() => this.Change_Actions('Rejected')}>
                                                <Icon type="close" style={{ fontSize: 18, verticalAlign: 1, marginLeft: 0 }} /> Reject </Button>
                                        </ButtonGroup>

                                    }
                                    {this.props.item.Istatus === 'Accepted' &&
                                        <Popover content={Accept}>
                                            <Button style={styles.Btnaccept} >
                                                <Icon type="check-circle" style={{ fontSize: 18, verticalAlign: 1, marginLeft: 0 }} />Interest Accepted</Button>
                                        </Popover>
                                    }
                                    {this.props.item.Istatus === 'Rejected' &&
                                        <Popover content={Reject} >
                                            <Button style={styles.BtnReject} >
                                                <Icon type="close-circle" style={{ fontSize: 18, verticalAlign: 1, marginLeft: 0 }} />Interest Rejected </Button>
                                        </Popover>
                                    }
                                </Col>
                            }

                            {this.props.item.intrested === 'OUT' &&
                                <Col>
                                    {this.props.item.Istatus === 'null' &&

                                        <Button block onClick={() => { this.intrested() }}
                                            style={{ borderColor: '#D51286', fontWeight: '400', color: '#D51286', fontFamily: 'Segoe UI', verticalAlign: 2, width: 'auto', margin: 10 }}> <Icon type="heart" style={{ fontSize: 18, verticalAlign: 2, marginLeft: 0 }} /><span style={{ verticalAlign: 2 }}>Send Interest</span></Button>

                                    }
                                    {this.props.item.Istatus === 'Requested' &&
                                        <Popover content={cancel} >
                                            <Button block
                                                style={{ backgroundColor: '#D51286', borderColor: '#D51286', fontWeight: '400', color: '#fff', fontFamily: 'Segoe UI', verticalAlign: 2, width: 'auto', margin: 10 }}> <Icon type="clock-circle" style={{ fontSize: 18, verticalAlign: 2, marginLeft: 0 }} /><span style={{ verticalAlign: 2 }}>Interest Requested</span></Button>
                                        </Popover>
                                    }
                                    {this.props.item.Istatus === 'Accepted' &&
                                        <Popover content={cancel} >
                                            <Button
                                                style={{ color: '#23a103', borderColor: '#23a103', fontWeight: '400', fontFamily: 'Segoe UI', verticalAlign: 2, width: 'auto', margin: 10 }}> <Icon type="check-circle" style={{ fontSize: 18, verticalAlign: 2, marginLeft: 0 }} /><span style={{ verticalAlign: 2 }}>Interest Accepted</span></Button>
                                        </Popover>
                                    }
                                    {this.props.item.Istatus === 'Rejected' &&
                                        <Popover content={cancel} >
                                            <Button
                                                style={{ color: '#bf0000', borderColor: '#bf0000', fontWeight: '400', fontFamily: 'Segoe UI', verticalAlign: 2, width: 'auto', margin: 10 }}> <Icon type="close-circle" style={{ fontSize: 18, verticalAlign: 2, marginLeft: 0 }} /><span style={{ verticalAlign: 2 }}>Interest Rejected</span></Button>
                                        </Popover>
                                    }
                                </Col>
                            }
                            <Col>
                                {this.props.item.Short_List === this.props.item.user_id
                                    ?
                                    <Button block onClick={() => { this.Short_list('remove') }}
                                        style={{ backgroundColor: '#d55d5d', borderColor: '#800000', fontWeight: '400', color: '#fff', fontFamily: 'Segoe UI', verticalAlign: 2, width: 'auto', margin: 10 }}> <Icon type="check" style={{ fontSize: 18, verticalAlign: 2, marginLeft: 0 }} /><span style={{ verticalAlign: 2 }}>Shortlisted</span></Button>

                                    :
                                    <Button onClick={() => { this.Short_list('add') }}
                                        style={{ borderColor: '#A52A2A', fontWeight: '400', color: '#A52A2A', fontFamily: 'Segoe UI', verticalAlign: 2, width: 'auto', marginLeft: 10 }}> <Icon type="book" style={{ fontSize: 18, verticalAlign: 2, marginLeft: 0 }} /><span style={{ verticalAlign: 2 }}>Shortlist</span></Button>


                                }
                            </Col>



                            <Col>


                                <Link to={`/UserApp/Details/${this.props.item.user_id}`} style={styles.linkdecoration}>
                                    <Button block
                                        style={{ fontWeight: '500', fontFamily: 'Segoe UI', verticalAlign: 2, width: 'auto', margin: 10 }}> <Icon type="user" style={{ fontSize: 18, verticalAlign: 2, marginLeft: 0 }} /><span style={{ verticalAlign: 2 }}>Profile</span></Button>

                                </Link></Col>

                        </Row>
                        : <Row className="itemscentr" style={{ margin: 0 }}>
                            <Col className="ton" onClick={() => { this.props.showlogin() }}>
                                <Icon type="heart" style={{ fontSize: 27, marginRight: 10 }} />
                                <span style={{ fontWeight: 'bold', fontSize: 12 }}>Intrested</span>
                            </Col>

                            <Col className="ton" onClick={() => { this.props.showlogin() }}>
                                <Icon type="file-add" style={{ fontSize: 27, marginRight: 10 }} />
                                <span style={{ fontWeight: 'bold', fontSize: 12 }}>Shortlist</span>
                            </Col>

                            <Col>
                                <Link to={`/Details/${this.props.item.user_id}`} style={styles.linkdecoration}>
                                    <Icon type="profile" style={{ fontSize: 27, marginRight: 10 }} />
                                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>Profile</span>
                                </Link></Col>
                        </Row>
                    }
                </CardFooter>

            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(ListItem);