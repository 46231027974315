

import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup } from 'reactstrap';
import { Form, Input, Radio, message, Button, Select, Tag } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class PartnerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3
            Secondmrg: this.props.data.SMarriage,
            Handicapped: this.props.data.Handi,
            About: this.props.data.About,
            PQualificationS: this.props.data.PQualification,
            PEmploymentS: this.props.data.PEmployment,
            PQualification: null,
            PEmployment: null

            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }
    ProfilePercentage = () => {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
           
          });
      }
      Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
               

            })
            .catch((error) => {
                console.error(error);
            });
            
    }
    componentDidMount() {
        this.props.reload();
        this.setState({
            Secondmrg: this.props.data.SMarriage,
            Handicapped: this.props.data.Handi,
            About: this.props.data.About,
            PQualificationS: this.props.data.PQualification,
            PEmploymentS: this.props.data.PEmployment,
            PQualification: null,
            PEmployment: null
        })
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    user_id: this.props.User.user_id,
                    Secondmrg: this.state.Secondmrg,
                    Handicapped: this.state.Handicapped,
                    About: this.state.About,
                    PQualification: this.state.PQualification,
                    PEmployment: this.state.PEmployment,
                }
                fetch(API.BASE_URL + API.EDIT_PARTNER_FILTER, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.ProfilePercentage();
                            this.Refrsh();
                            this.props.reload();
                            this.setState({
                                loading: false,
                                PQualification: [],
                                PEmployment: []
                            });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    deleteq = (idD) => {
        this.setState({ loading: true });
        let obj = { id: idD };
        fetch(API.BASE_URL + API.DELETE_CHIP, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Deleted Successfully');
                    this.componentDidMount();
                    this.setState({ loading: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false });
            });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            <Card style={{ borderRadius: 10 }} >
                <CardBody>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.UserProfilePatner}</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Form {...formItemLayout}>
                        <Form.Item label={this.props.lang.Smrg}>
                            {getFieldDecorator('Secondmrg', {
                                initialValue: this.state.Secondmrg,
                                setFieldsValue: this.state.Secondmrg,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Gender!' }],
                            })(
                                <Radio.Group name="Secondmrg">
                                    <Radio.Button value="Yes">{this.props.lang.Yes}</Radio.Button>
                                    <Radio.Button value="No">{this.props.lang.No}</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label={this.props.lang.Handi} style={{ marginTop: -20 }}>
                            {getFieldDecorator('Handicapped', {
                                initialValue: this.state.Handicapped,
                                setFieldsValue: this.state.Handicapped,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Gender!' }],
                            })(
                                <Radio.Group name="Handicapped">
                                    <Radio.Button value="Yes">{this.props.lang.Yes}</Radio.Button>
                                    <Radio.Button value="No">{this.props.lang.No}</Radio.Button>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label={this.props.lang.Patner} style={{ marginTop: -20 }}>
                            <TextArea
                                name="About"
                                maxLength='498' 
                                value={this.state.About}
                                onChange={this.handleChange}
                                placeholder="Expectations about the Partner"
                                autosize={{ minRows: 2, maxRows: 6 }} />

                        </Form.Item>

                        <Form.Item label={this.props.lang.PatQuli} style={{ marginTop: -20 }}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select Partner's Qualification"
                                optionLabelProp="label"
                                initialValue={this.state.PQualification}
                                setFieldsValue={this.state.PQualification}
                                onChange={(val) => { this.handleChange({ target: { name: 'PQualification', value: val } }) }}
                                filterOption={(input, option) =>
                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.dropdown.Education_array.map((item) => (
                                    <Option value={item.id} label={item.Name} key={item.id}>{item.Name}</Option>
                                ))}
                            </Select>
                            {this.state.PQualificationS.map((person, index) => (
                                <Tag
                                    closable
                                    onClose={() => { this.deleteq(person.id) }}
                                    key={person.id}>
                                    {person.Name}
                                </Tag>
                            ))}
                        </Form.Item>

                        <Form.Item label={this.props.lang.PatnrEmpl} style={{ marginTop: -20 }}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select Partner's Employment"
                                optionLabelProp="label"
                                initialValue={this.state.PEmployment}
                                setFieldsValue={this.state.PEmployment}
                                onChange={(val) => { this.handleChange({ target: { name: 'PEmployment', value: val } }) }}
                                filterOption={(input, option) =>
                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {this.props.dropdown.Work_array.map((item) => (
                                    <Option value={item.id} label={item.Name} key={item.id}>{item.Name}</Option>
                                ))}
                            </Select>
                            {this.state.PEmploymentS.map((person, index) => (
                                <Tag
                                    closable
                                    onClose={() => { this.deleteq(person.id) }}
                                    key={person.id}>
                                    {person.Name}
                                </Tag>
                            ))}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                                <Button type="primary" onClick={() => this.update()} style={{fontWeight:'bolder',backgroundColor:'#0070ba',borderColor:'#0070ba'}}>
                                              <span style={{marginLeft:15,marginRight:15}}>Update</span>
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(PartnerCard));