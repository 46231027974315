import * as types from '../Actions/Types';
import Eng from '../../lang/eng.json';
const response = Eng;

const LanguageReducer = (state = response, action) => {
    switch (action.type) {
        case types.SET_ENGLISH:
            return action.payload;

        case types.SET_MALAYALAM:
            return action.payload;

        default: {
            return state;
        }
    }
}
export default LanguageReducer;