const styles = ({

   
    mainnavbar:{
        position: 'fixed',
         zIndex:2000,
          width: '100%',
          backgroundColor:'#fff',
          background:'#fff'
    },
    upbutton:{
        color:'#0070ba',
        fontSize:40
    },
    brandname:{
        marginLeft:0,
        padding:0
    },
    mainnavitem:{
        color:'#0070ba',
        fontWeight:'500',
        fontSize:18,
        marginLeft:25,
        marginRight:25 
    },
    regitem:{
        marginLeft:25,
        marginRight:25 ,
        textDecoration: 'none'
    },
    mainnavbutton:{
        marginLeft:10,
        marginRight:10,
        marginTop:5
    },
    mainnavlogin:{
        color:'#0070ba',
        fontWeight:'500',
        fontSize:13,
        marginTop:7,
        marginBottom:7,
        marginLeft:25,
        marginRight:25
    },
    mainnavregister:{
        color:'#fff',
        fontWeight:'500',
        fontSize:13,
        marginTop:7,
        marginBottom:7,
        marginLeft:25,
        marginRight:25
    },
    linkdecoration:{
        textDecoration: 'none',
      
    },
    Btnaccept:{
        backgroundColor:'#e1f5dc',
        borderColor:'#449630',
        fontWeight:'500',
        color:'#23a103'
    },
    BtnReject:{
        backgroundColor:'#f0cece',
        borderColor:'#882d2d',
        fontWeight:'500',
        color:'#bf0000',
        marginRight:10

    },
   tableStyle:{
       fontWeight:'500'
   }

})
export default styles;