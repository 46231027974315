

import React, { Component } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Button, Modal, Form, Input, Icon, message, Popover, Table, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import API from '../API';
const { TextArea } = Input;
class ProfileReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            data: [], 
            searchText: '',
        };
        this.arrayholder = [];
    }
    handleCancelLog = () => {
      this.setState({ visibleLog: false });
    };
    componentDidMount = () => {
        message.config({ top: 100 });
        fetch(API.BASE_URL + API.GetProfileReport, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data: responsesJson.result, loading: false },
                    function () { this.arrayholder = responsesJson.result});
            })
            .catch((error) => {
                this.setState({loading: false })
                message.error(error);
            });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    showModalStatus = (record) => {
       
      this.setState({
         
        visibleLog: true,
        UserId: record.id,
       

      });
    };
    send_Status = (data) => {
      this.setState({ loading: true });
      let obj = {  id: this.state.UserId, Rply: this.state.StatusRply };
      fetch(API.BASE_URL + API.SetProfileReport, {
          method: 'POST',
          headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
      }).then((response) => response.json())
          .then((responsesJson) => {
              if (responsesJson.status === 'success') {
                  message.success('Send Successfully');
                  this.componentDidMount();
                  this.setState({ loading: false, form: false ,visibleLog: false});
              }
              else {
                  message.error(responsesJson.status);
                  this.setState({ loading: false, form: false });
              }
          })
          .catch((error) => {
              message.error('Error. Try again !');
              this.setState({ loading: false, form: false });
          });
  }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
      });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    //end search function 
    render() {
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
                className:'defultcolumn'
              },
              {
                title: 'Send date',
                dataIndex: 'SendDate',
                key: 'SendDate',
                className:'defultcolumn'
              },
            {
              title: 'user_Name',
              dataIndex: 'user_Name',
              key: 'user_Name',
              ...this.getColumnSearchProps('user_Name'),
              className:'targetcolomn'
            },
            {
              title: 'To',
              render: (value, record) =>
              <Icon type="arrow-right" style={{color:'#140cf2'}}/>
                      },
                      {
                        title: 'target_Name',
                        dataIndex: 'target_Name',
                        key: 'target_Name',
                        ...this.getColumnSearchProps('target_Name'),
                        className:'usercolomn'
                      },
            {
              title: 'Reason',
              dataIndex: 'Reason',
              key: 'Reason',
              ...this.getColumnSearchProps('Reason'),
              className:'defultcolumn'
            },
            {
              title: 'Comment',
              dataIndex: 'Comment',
              key: 'Comment',
              ...this.getColumnSearchProps('Comment'),
              className:'defultcolumn'
            },
          
            {
                title: 'Rply',
                dataIndex: 'Rply',
                key: 'Rply',
                ...this.getColumnSearchProps('Rply'),
                className:'defultcolumn'
              },
              {
                title: 'RplyDate',
                dataIndex: 'RplyDate',
                key: 'RplyDate',
                ...this.getColumnSearchProps('RplyDate'),
                className:'defultcolumn'
              },
              {
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                ...this.getColumnSearchProps('Status'),
                className:'defultcolumn'
              },
              {
                title: 'Action',
                render: (value, record) =>
                <div>
                       
                <Link to={`/AdminApp/AdminUserprofiles/${record.user_id}`} target="_blank" style={{ textDecoration: 'none' }}>
                             <Button  ghost size="small"   type="primary">View User</Button></Link>
                             <Link to={`/AdminApp/AdminUserprofiles/${record.tarID}`} target="_blank" style={{ textDecoration: 'none' }}>
                             <Button  ghost size="small"   type="primary">View Target</Button></Link>
 {record.Status === 'R'
                     ?  <Popover content={<p style={{  fontSize: 14 }}>{record.Comment}</p>} title="Status" >
                     <Button ghost size="small" style={{ marginRight: 10 }} type="primary" >Read</Button>
                </Popover>
                   
                     :
                     <Button ghost size="small" style={{ marginRight: 10 }} type="danger" onClick={() => this.showModalStatus(record) }>Unread</Button>
                 }

             

             </div>
                        },
          ];
          function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Profile Report</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                    <Table columns={columns} dataSource={this.state.data} onChange={onChange} pagination={{ pageSize: 25 }}/>
                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>

                <Modal
          visible={this.state.visibleLog}
          title="Status Book"
          onOk={this.handleOk}
          onCancel={this.handleCancelLog}
          footer={[
            <Button key="back" onClick={this.handleCancelLog}>
              Close
            </Button>,
            <Button key="submit" type="primary"  onClick={() => this.send_Status()}>
              Submit
            </Button>,
          ]}
        >
           <TextArea rows={2} placeholder="Type Reply" name="StatusRply" value={this.state.StatusRply}  style={{marginBottom:10}} onChange={this.handleChange}/>  
     
      
        </Modal>
            </Container>
        )
    }
}
export default Form.create()(ProfileReport);