import * as React from 'react';
import { connect } from 'react-redux';
import './styles.css';
import styles from './styles';
import { Container,Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
const UserBox = (props) => {
  return (
      <Container className="userbox">
          <Row>
          <Col sm='5' xs="5" style={{marginRight:-15}} >
          <div> {props.User.image ? <img src={props.User.image} className="profileimage" alt="prolife" />
            :<img src={require('../../images/placeholder.jpg')} className="profileimage" alt="prolife" />}</div>
          </Col>
          <Col  sm='7' xs="7">
          <span className="username">{props.User.userName}</span><br></br>
          <span className="registerd">{props.User.register_d}</span>
         
          </Col>
          </Row>
         
      </Container>
  );
};
function mapStateToProps(state) {
  return {
    User: state.User,
    lang: state.lang
  }
}
export default connect(mapStateToProps)(UserBox);