import * as types from '../Actions/Types';
const response = {
    profiles: [],
    refresh: false
};

const addintrested = (data, parse) => {
    return [...data, parse];
}

const removeintrested = (data, parse) => {
    let foundIndex = data.findIndex(x => x.user_id === parse.user_id);
    const stateTemp = [
        ...data.slice(0, foundIndex),
        ...data.slice(foundIndex + 1)
    ];
    return stateTemp;
}

const updateintrested = (data, parse) => {
    let foundIndex = data.findIndex(x => x.user_id === parse.user_id);
    return [
        ...data.slice(0, foundIndex),
        { ...data[foundIndex], Istatus: parse.Istatus },
        ...data.slice(foundIndex + 1),
    ]
}

const IntrestedReducer = (state = response, action) => {
    switch (action.type) {
        case types.LOAD_INTRESTED: {
            return {
                ...state,
                refresh: false,
                profiles: action.payload
            }
        }
        case types.ADD_INTRESTED: {
            return {
                ...state,
                refresh: false,
                profiles: addintrested(state.profiles, action.payload)
            }
        }
        case types.REMOVE_INTRESTED: {
            return {
                ...state,
                refresh: true,
                profiles: removeintrested(state.profiles, action.payload)
            }
        }
        case types.UPDATE_INTRESTED: {
            return {
                ...state,
                refresh: false,
                profiles: updateintrested(state.profiles, action.payload)
            }
        }
        case types.REFRESH_INTRESTED_SUCCESS:
            return {
                ...state,
                refresh: false
            }
        default: return state;

    }
}
export default IntrestedReducer;