
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Icon, Button,Card } from 'antd';
import {Link} from 'react-router-dom';
import './commonstyles.css';
class Payement_due_card extends Component {
    render() {
        return (
            <div>
                   
               
            <Card className="DeactivationCard" >
            <Row style={{padding:20}}>
                  <Col sm="1" xs="1">
                  <Icon type="exclamation-circle" style={{fontSize:40,marginBottom:20}}/>
                  </Col>
                  <Col sm="11" xs="12">
                  <h5 className="deactivationHead" >Account Deactivated</h5>
                  <p className="deactivationSub" >
                  Your account has been suspended for not receiving payment. Click on the Payment button to continue accessing the services.
Call to 6235445544 to activate your account after the payment.
                  </p>
                  <p className="deactivationSub">
                  For Girls Free And Boys 500
                  </p>
                  <Link to='/UserApp/UserPayment' style={{ textDecoration: 'none' }}>
                  <Button  >Pay Now</Button>
                      </Link>
                  </Col>
              </Row>
</Card></div>
           
        )
    }
}
export default Payement_due_card;