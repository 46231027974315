import React, { Component } from 'react';
import { Modal, List, Layout, message, PageHeader, Form, Icon ,Button} from 'antd';
import {Container} from 'reactstrap';
import { connect } from 'react-redux';
import ListItem from '../components/ListItem';
import Loadingplaceholder from '../components/Loading_placeholder';
import API from '../API';
import ListItem2 from '../components/ListItem2/Lisitem2';
const { Content } = Layout;
class UserSearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            issuccess: false,
            Age_from: this.props.match.params.Age_from,
            Age_to: this.props.match.params.Age_to,
            Diocese: this.props.match.params.Diocese,
            Height_from: this.props.match.params.H_from,
            Height_to: this.props.match.params.H_to,
            M_status: this.props.match.params.M_status,
            W_place: this.props.match.params.W_place,
            p_status: this.props.match.params.p_status,
            E_cat: this.props.match.params.E_cat,
            W_cat: this.props.match.params.W_cat,
            Religion: this.props.match.params.Religion,
            Place: this.props.match.params.Place,
            FStatus:this.props.match.params.FStatus,
            items: [],
            limit:20,
            list: [],
            page: 1,
            data: [],
            initLoading: true,
            loading: false, isLoaded: false,
        };
        this.arrayholder = [];
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    componentDidMount() {
        message.config({ top: 100 });
        let obj = {
            user_id: this.props.User.user_id,
            Gender: this.props.User.gender,
            Diocese: this.state.Diocese,
            Age_from: this.state.Age_from,
            Age_to: this.state.Age_to,
            Height_from: this.state.Height_from,
            Height_to: this.state.Height_to,
            M_status: this.state.M_status,
            W_place: this.state.W_place,
            p_status: this.state.p_status,
            E_cat: this.state.E_cat,
            W_cat: this.state.W_cat,
Religion:this.state.Religion,
            Place: this.state.Place,
            FStatus:this.state.FStatus, limit: 20,
            page: 1 ,
         
        }
       
        fetch(API.BASE_URL + API.ADVANCE_SEARCH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
        .then((response) => response.json())
        .then((responsesJson) => {
            if (responsesJson.data) {
                let listData = this.state.data;
                let data = listData.concat(responsesJson.result)
                this.setState({ data: data,isLoaded: false,   initLoading: false,loading:false,isLoaded: true})
               
            }
            else {
                this.setState({ isLoaded: true, initLoading: false, loading:true});
              
            }
           
        })
        .catch((error) => {
         
            this.setState({
                error: true,
                isLoaded: false,
               
            });
           
            //console.error(error);
        });
    }
    onLoadMore = () => {
        const hide = message.loading('Loading profiles..');
        var a=this.state.page;
        a=a+1;
       
        this.setState(
          { page: a, loading: true} )
    
        message.config({ top: 100 });
        let obj = { user_id: this.props.User.user_id,  limit: this.state.limit,
          page: a ,
       
          Gender: this.props.User.gender,
          Diocese: this.state.Diocese,
          Age_from: this.state.Age_from,
          Age_to: this.state.Age_to,
          Height_from: this.state.Height_from,
          Height_to: this.state.Height_to,
          M_status: this.state.M_status,
          W_place: this.state.W_place,
          p_status: this.state.p_status,
          E_cat: this.state.E_cat,
          W_cat: this.state.W_cat,
Religion:this.state.Religion,
          Place: this.state.Place,
          FStatus:this.state.FStatus,}
        fetch(API.BASE_URL + API.ADVANCE_SEARCH, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
        .then((response) => response.json())
        .then((responsesJson) => {
            if (responsesJson.data) {
                let listData = this.state.data;
                let data = listData.concat(responsesJson.result)
                this.setState({ data: data,isLoaded: false,   initLoading: false,loading:false,isLoaded: true})
                setTimeout(hide, 0);
            }
            else {
                this.setState({isLoaded: true,initLoading: false, loading:true});
                setTimeout(hide, 0);
            }
           
        })
        .catch((error) => {
            setTimeout(hide, 0);
            this.setState({
                error: true,
                isLoaded: false,
               
            });
           
            //console.error(error);
        });
      }
    
    get_data() {
        let val = 'null';
        const newData = this.arrayholder.filter(function (item) {
            const itemData = item.Istatus.toUpperCase()
            const textData = val.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({ items: newData })
    }
    render() {
        const { isLoaded } = this.state;
        const { initLoading, loading, list } = this.state;
        const loadMore =
          !initLoading && !loading ? (
            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
              }}
            >
              <Button onClick={this.onLoadMore}>loading more</Button>
            </div>
          ) : null;
  
        return (
            <div>
                <PageHeader onBack={() => window.history.back()} title="Search Result" />
                <Container >
                <Content className="scrollBox1" style={{ background: '#f2f3f5'}}>
                    <div className="padss" style={{ marginTop: 10,marginBottom:20 }}>
     
                    {!isLoaded &&
                            <Loadingplaceholder />
                        }  
                             {isLoaded &&
                           
                           <List
                             
                           loading={initLoading}
               loadMore={loadMore}
                           dataSource={this.state.data}
                           renderItem={item => (
                               <ListItem2 item={item}
                                   showlogin={() => alert('Clicked')}
                                   reload={() => { this.componentDidMount() }} />
                           )}
                       />
                        }  


                    

                    </div>
                </Content>
                </Container>
            </div>

        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(UserSearchResult);