import React, { Component } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { makeStyles } from '@material-ui/core/styles';
import 'react-image-crop/dist/ReactCrop.css';
import 'antd/dist/antd.css';
import { Modal, message,Form,Icon, Input, Select,Layout} from 'antd';
import { Row, Col } from 'reactstrap';
import API from '../API';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import { connect } from 'react-redux';

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
 class ImageCropUpload extends Component {
    reset = () => {
        this.props.form.resetFields()
      }
    constructor(props) {
        super(props);
        this.state = {
            cropResult: null,
            isLoaded: true,
            file: null,
            visible: false,
            croppedImage: null,
            previewImage: null,
            previewVisible: false,
            PhotoDeleteReq:false,
            msgReportReasondb:[],
            PhotoDeleteComment:null
        };
        this.cropImage = this.cropImage.bind(this);
        this.onChange = this.onChange.bind(this);
    }
  
    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({ src: reader.result, visible: true });
        };
        reader.readAsDataURL(files[0]);
    }
    handleSelectChange = value => {
        console.log(value);
        this.props.form.setFieldsValue({
         
          noteRply: `${value === value ? value : ''}`
        });
     
      this.setState({noteRply:`${value}`})
      }

   
    onChangeText = (e) => {
        this.setState({PhotoDeleteComment: e.target.value})
      }
    cropImage() {
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
            return;
        }
        let dataurl = this.cropper.getCroppedCanvas().toDataURL()
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) { u8arr[n] = bstr.charCodeAt(n); }
        let croppedImage = new File([u8arr], 'cropped.jpg', { type: mime });
        this.setState({
            croppedImage: croppedImage,
            cropResult: dataurl
        });

        this.upload({ croppedImage })
    }

 
    upload = ({ croppedImage }) => {
        const hide = message.loading('Image Uploading..', 0);
        // Dismiss manually and asynchronously
        this.setState({ visible: false });
        const formData = new FormData()
        formData.append('id', this.props.id);
      
        formData.append('file', croppedImage);
        fetch(API.BASE_URL + API.UploadPhotoStory, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(responsesJson => {
                if (responsesJson.status === 'success') {
                    setTimeout(hide, 0);
                  
                }
                else {
                    alert("try again");
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    deleteImage = () => {
        const hide = message.loading('Image Removing..', 0);
        let obj = {
            user_id: this.props.user_id,
            image_id: this.props.image_id,
            image: this.props.image
        }
        fetch(API.BASE_URL + API.DELETE_IMAGE, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    setTimeout(hide, 0);
                    this.props.handleChanges();
                }
                else if (responsesJson.status === 'Lock'){
                    setTimeout(hide, 0);
                    this.ReadReportReason();
                 
                }
                else {
                    alert("try again");
                }
            })
            .catch((error) => {
                alert(error);
            });
    }
    

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
        return (
            <div>
              
                            <input type="file" onChange={this.onChange} onClick={e => (e.target.value = null)} />
                        
              
                <Modal

                    title="Modal"
                    visible={this.state.visible}
                    onOk={this.cropImage}
                    onCancel={() => this.setState({
                        src: null,
                        visible: false,
                    })}
                    okText="Ok"
                    cancelText="Cancel"
                >
                    <Cropper
                        style={{ height: 520 }}
                        aspectRatio={1 / 1}
                        ref={cropper => { this.cropper = cropper }}
                        guides={false}
                        src={this.state.src}
                        viewMode={1}
                        dragMode="move"
                        cropBoxMovable={false}
                    />
                </Modal>

            
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.setState({ previewVisible: false })}>
                    <img alt="example" style={{ width: '100%' }} src={this.props.image} />
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
       
        lang:state.lang
    }
}

  
export default connect(mapStateToProps)(Form.create()(ImageCropUpload));