import React, { Component } from 'react';
import { Layout, Menu, Icon, Modal, Button, Divider } from 'antd';
import './styles.css';
import styles from './styles';
import {Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ProtectedRouter from '../../components/ProtectedRouter';
import { connect } from 'react-redux';
import Recommended_ListItem from './Recommended_ListItem';

//screens
import UserHome from '../userHome/userHome';
import UserNotification from '../UserNotification/userNotification';
import UserSearch from '../UserSearch/UserSearch';
import DetailswithtUser from '../DetailsPage/Detailswith_user';
import UserProfile from '../UserProfile/UserProfile';
import UserIntrested from '../UserIntrested/UserIntrested';
import UserProfilevisitors from '../UserProfilevisitors/UserProfilevisitors';
import UserSearchResult from '../UserSearchResult';
import searchIDwith_user from '../searchID/searchIDwith_user';
import userShortlist from '../UserShortlist/userShortlist';
import UserPayment from '../UserPayment/UserPayment';
import UserMessage from '../UserMessage/UserMessage';
import userMyMatches from '../userMyMatches/userMyMatches';
import TopUp from '../UserPayment/TopUp';
import UserBox from './UserBox';
import { refreshshrotlistService } from '../../redux/Services/ShortListServices';
import {loadintrestedService} from '../../redux/Services/IntrestedSerives';

//end screens
const { Content, Sider } = Layout;
class UserApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confermlog: false
        };
    }
    componentDidMount() {
        this.props.refreshshrotlistService(this.props.User.user_id);
        this.props.loadintrestedService(this.props.User.user_id);
    }
    toggle = () => {
        this.setState({
            collapsed: true
        });
    };
    logout = () => {
        this.setState({ confermlog: true })
    }
    conferm_logout = () => {
        const response = {
            auth: false,
            user_id: 0,
            userName: null,
            propic: null,
            state: 0
        };
        this.props.Logout(response);
        this.setState({ confermlog: false });
    }
    render() {
       
        return (
            <Layout >
                <Layout>
                    <Sider width={240}
                        breakpoint="lg"
                        collapsedWidth="0"
                       
                        trigger={null}>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100%' }}>
                            {this.props.User.auth === true
                                ? <Link to='/UserApp/UserProfile' style={styles.linkdec}>
                                    <UserBox />
                                </Link>
                                : null}

                            <Divider style={{marginBottom:12,marginTop:12}}/>
                            <Menu.Item key="1" className="DrawerMenu">
                                <Link to='/UserApp/UserHome'  style={styles.linkdec} >
                                    <Icon type="home" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerhome}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="3" className="DrawerMenu">
                                <Link to='/UserApp/UserNotification' style={styles.linkdec}>
                                    <Icon type="bell" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerNotification}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2" className="DrawerMenu">
                                <Link to='/UserApp/UserSearch' style={styles.linkdec}>
                                    <Icon type="search" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerAdvanceSearch}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="11" className="DrawerMenu">
                                <Link to='/UserApp/userMyMatches' style={styles.linkdec}>
                                    <Icon type="usergroup-add" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerMyMatches}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="4" className="DrawerMenu">
                                <Link to='/UserApp/Intrested' style={styles.linkdec}>
                                    <Icon type="heart" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerIntrested}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="5" className="DrawerMenu">
                                <Link to='/UserApp/userShortlist' style={styles.linkdec}>
                                    <Icon type="star" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerShortList}</span>
                                </Link>
                            </Menu.Item>

                            <Menu.Item key="6" className="DrawerMenu">
                                <Link to='/UserApp/UserProfilevisitors' style={styles.linkdec}>
                                    <Icon type="eye" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerVisitors}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="7" className="DrawerMenu">
                                <Link to='/UserApp/UserMessage' style={styles.linkdec}>
                                    <Icon type="mail" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.Messages}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="8" className="DrawerMenu">
                                <Link to='/UserApp/UserPayment' style={styles.linkdec}>
                                    <Icon type="credit-card" style={styles.menuitemicon} />
                                    <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerPayments}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="9" className="DrawerMenu">
                                <Link to='/UserApp/UserProfile' style={{ textDecoration: 'none' }}>
                                    <Icon type="profile" style={styles.menuitemicon} />
                                    <span  className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerMyProfile}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="10" onClick={() => { this.logout() }}>
                                <Icon type="logout" style={styles.menuitemicon} />
                                <span className="UserMenuItems" lang={this.props.lang.lang}>{this.props.lang.drawerlogout}</span>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                   
                    <Layout>
                        <Content style={{ margin: '0 16px 0', overflow: 'scroll' }}>
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserHome' component={UserHome} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserNotification' component={UserNotification} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserSearch' component={UserSearch} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/Details/:id' component={DetailswithtUser} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserProfile' component={UserProfile} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/Intrested' component={UserIntrested} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserProfilevisitors' component={UserProfilevisitors} />
                            <ProtectedRouter authed={this.props.User.auth}
                                path='/UserApp/UserSearchResult/:Diocese/:Age_from/:Age_to/:H_from/:H_to/:M_status/:W_place/:p_status/:E_cat/:W_cat/:Religion/:Place/:FStatus' component={UserSearchResult} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserSearchID/:id' component={searchIDwith_user} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/userShortlist' component={userShortlist} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserPayment' component={UserPayment} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/UserMessage' component={UserMessage} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/userMyMatches' component={userMyMatches} />
                            <ProtectedRouter authed={this.props.User.auth} path='/UserApp/TopUp' component={TopUp} />
                        </Content>
                      
                    </Layout>
                   
                    {this.props.User.Status === 'A'
                    ?
                    <Sider
                        className="RightSideBar"
                        width={230}
                       
                        breakpoint="lg"
                        collapsedWidth="0">
                        <Recommended_ListItem />
                       
                    </Sider>
                   :null}
                </Layout>
                <Modal
                    centered
                    visible={this.state.confermlog}
                    footer={false}
                    closable={false}
                    width={400}>
                    <div><span style={styles.logoutxt}>Logout</span></div>
                    <hr />
                    <div><span style={styles.logoutxt2} lang={this.props.lang.lang} className="Logout">{this.props.lang.UserAppLogoutMsg}</span></div>
                    <Row style={{ marginTop: 30 }}>
                        <Col sm="6" xs="6">
                            <Button block onClick={() => { this.setState({ confermlog: false }) }} style={styles.btncancel}>
                            {this.props.lang.No}
                            </Button>
                        </Col>
                        <Col sm="6" xs="6">
                            <Button block type="primary" onClick={() => { this.conferm_logout() }} style={styles.btnlogout}>
                            {this.props.lang.Yes}
                            </Button>
                        </Col>
                    </Row>
                </Modal>
            </Layout>
           
        )
    }
}

function mapStateToProps(state) {
    return {
        User: state.User,
        lang: state.lang
    }
}

function mapDispatchToProps(dispatch) {
    return {
        refreshshrotlistService: (user_id) => {
            dispatch(refreshshrotlistService(user_id));
        },
        loadintrestedService:(user_id)  =>{
            dispatch(loadintrestedService(user_id));
        },
        Logout: (data) => {
            dispatch({ type: 'LOGOUT', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserApp);