export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REFRESH = 'REFRESH';
//end user

//dropdowns
export const SAVE_DROPDOWNS = 'SAVE_DROPDOWNS';
//end dropdowns

//language
export const SET_MALAYALAM = 'SET_MALAYALAM';
export const SET_ENGLISH= 'SET_ENGLISH';
//end language

//shortlist
export const LOAD_SHORTLIST = 'LOAD_SHORTLIST';
export const REFRESH_SHORTLIST_SUCCESS = 'REFRESH_SHORTLIST_SUCCESS';
export const ADD_SHORTLIST= 'ADD_SHORTLIST';
export const REMOVE_SHORTLIST= 'REMOVE_SHORTLIST';
//end shortlist

//intrested
export const LOAD_INTRESTED = 'LOAD_INTRESTED';
export const REFRESH_INTRESTED_SUCCESS = 'REFRESH_INTRESTED_SUCCESS';
export const ADD_INTRESTED= 'ADD_INTRESTED';
export const REMOVE_INTRESTED= 'REMOVE_INTRESTED';
export const UPDATE_INTRESTED= 'UPDATE_INTRESTED';
//end intrested

//shortlist
export const LOAD_MESSAGE = 'LOAD_MESSAGE';

//end shortlist

//shortlist
export const VIEW_NOTIFICATION = 'VIEW_NOTIFICATION';

//end shortlist