import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Form, Icon, Input, Button, message, Modal } from 'antd';
import FooterS from '../../components/Footer';
import API from '../../API';
import { connect } from 'react-redux';
import './styles.css';
const { TextArea } = Input;
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false,
            Name: null,
            Email: null,
            Phone: null,
            Message: null
        }
    }
    componentDidMount = () => {

     //this.send();
    
    }
    send = () => {
      
        fetch(API.BASE_URL + API.HomeProfileSlide, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({
                   // slides: responsesJson
                                
                })
            })
            .catch((error) => {
                //message.error('Error. Try again !');
                alert(error)
            });
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    send = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    Name: this.state.Name,
                    Email: this.state.Email,
                    Phone: this.state.Phone,
                    Message: this.state.Message
                };
                fetch(API.BASE_URL + API.SEND_CONTACT, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                }).then((response) => response.json())
                    .then((responsesJson) => {
                        if (responsesJson.status === 'success') {
                            this.setState({ loading: false, success: true });
                            this.props.form.resetFields()
                        }
                        else {
                            this.error();
                            this.setState({ loading: false });
                        }
                    })
                    .catch((error) => {
                        this.error();
                        this.setState({ loading: false });
                    });
            }
        })
    }
    error = () => {
        Modal.error({
            title: 'Some thing gone wrong try again',
            content: 'your message not send...',
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="colorcontainer" style={{ height: 130 }}>
                    <Container >
                        <h5 style={{ color: '#fff', paddingTop: 40 }}>{this.props.lang.ContactUs}</h5>
                        <p style={{ color: '#fff', paddingBottom: 10 }}>{this.props.lang.ContactMsg}</p>
                    </Container>
                </div>
                <Container className="itemscentr">
                    <h2 lang={this.props.lang.lang} style={{ paddingTop: 20, paddingBottom: 30 }}>{this.props.lang.ContactWeWant}</h2>
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <Form>
                                <Form.Item>
                                    {getFieldDecorator('Name', {
                                        initialValue: this.state.Name,
                                        setFieldsValue: this.state.Name,
                                        onChange: this.handleChange,
                                        rules: [{ required: true, message: 'Please input your Full Name!' }]
                                    })(
                                        <Input
                                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder={this.props.lang.FullName}
                                            name="Name"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('Email', {
                                        initialValue: this.state.Email,
                                        setFieldsValue: this.state.Email,
                                        onChange: this.handleChange,
                                        rules: [{ type: 'email', required: true, message: 'Please input Email!' }]
                                    })(
                                        <Input
                                            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder={this.props.lang.Email}
                                            name="Email"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('Phone', {
                                        initialValue: this.state.Phone,
                                        setFieldsValue: this.state.Phone,
                                        onChange: this.handleChange,
                                        rules: [{ required: false, message: 'Please input Phone!' }]
                                    })(
                                        <Input
                                            prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder={this.props.lang.PNumber}
                                            name="Phone"
                                            type="number"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('Message', {
                                        initialValue: this.state.Message,
                                        setFieldsValue: this.state.Message,
                                        onChange: this.handleChange,
                                        rules: [{ required: true, message: 'Please input Message!' }]
                                    })(
                                        <TextArea
                                            rows="4"
                                            placeholder={this.props.lang.contactMesage}
                                            name="Message"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button" block size={'large'} style={{ backgroundColor: '#026c70', borderColor: '#026c70' }}
                                        onClick={() => this.send()}>
                                        {this.props.lang.ContactSend}
                                        </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                </Container>
                <hr />
                <Container>
                    <Row>
                        <Col sm="12" xs="12" md="4" lg="4" style={{ marginTop: 20}}>
                            <center>   <Icon type="contacts" style={{ fontSize: '50px', color: '#026c70', marginBottom: 15 }} theme="outlined" />
                                <h5> {this.props.lang.ContactAddress}</h5>
                                <p style={{ color: '#026c70',marginBottom:3 }}>{this.props.lang.ContactMP}</p>
                                <p style={{ color: '#026c70',marginBottom:3 }}>{this.props.lang.ContactRoad} </p>
                                <p style={{ color: '#026c70' }}>{this.props.lang.ContactDT}</p>
                            </center>
                        </Col>
                        <Col sm="12" xs="12" md="4" lg="4"  style={{ marginTop: 20 }}>
                            <center><Icon type="mail" style={{ fontSize: '50px', color: '#026c70', marginBottom: 15 }} theme="outlined" />
                                <h5>Prolifemarry@gmail.com</h5></center>

                        </Col>
                        <Col sm="12" xs="12"  md="4" lg="4"  style={{ marginTop: 20}}>
                            <center>   <Icon type="phone" style={{ fontSize: '50px', color: '#026c70', marginBottom: 15 }} theme="outlined" />
                            <h5>+91- 85909 90889</h5>
                                <h5>+91- 6235 445544</h5>
                                <p style={{ color: '#026c70' }}>
                                {this.props.lang.contact247}  
                            </p></center>
                        </Col>
                    </Row>
                </Container>
                <FooterS />
                <Modal
                    centered
                    visible={this.state.success}
                    footer={false}
                    closable={false}
                    width={500}
                >
                    <div className="aligitemncenter">
                        <img src={require('../../images/success1.gif')} style={{ height: 250, width: 350 }} alt="success" />
                        <h5>Send successfully</h5>
                        <p>Your Contact sended successfully</p>
                        <Button type="primary" onClick={() => { this.setState({ success: false }) }}>
                            Done
                            </Button>
                    </div>
                </Modal>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Sending</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
      
        lang: state.lang
    }
}
export default connect(mapStateToProps )(Form.create()(Contact));

