import * as Action from '../Actions/LanguageActions';
import Eng from '../../lang/eng.json';
import Mala from '../../lang/mala.json';


export const setenglish = () => {
    return async dispatch => {
        dispatch(Action.setenglishAction(Eng));
    }
}

export const setmalayalam = () => {
    return async dispatch => {
        dispatch(Action.setmalayalamAction(Mala));
    }
}