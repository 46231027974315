

import React, { Component } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Button, Modal, Form, Input, Icon, message, Popover, Table, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import API from '../API';

class AdminAccepted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            data: [], 
            searchText: '',
        };
        this.arrayholder = [];
    }

    componentDidMount = () => {
        message.config({ top: 100 });
        fetch(API.BASE_URL + API.GET_ACCEPTED_USERS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data: responsesJson.result, loading: false },
                    function () { this.arrayholder = responsesJson.result});
            })
            .catch((error) => {
                this.setState({loading: false })
                message.error(error);
            });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

   
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
      });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
    //end search function 
    render() {
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
                className:'defultcolumn'
              },
              {
                title: 'date',
                dataIndex: 'date',
                key: 'date',
                className:'defultcolumn'
              },
            {
              title: 'user_UserId',
              dataIndex: 'user_UserId',
              key: 'user_UserId',
              ...this.getColumnSearchProps('user_UserId'),
              className:'usercolomn'
            },
            {
              title: 'user_Name',
              dataIndex: 'user_Name',
              key: 'user_Name',
              ...this.getColumnSearchProps('user_Name'),
              className:'usercolomn'
            },
            {
              title: 'user_mobile',
              dataIndex: 'user_mobile',
              key: 'user_mobile',
              ...this.getColumnSearchProps('user_mobile'),
              className:'usercolomn'
            },
            {
                title: 'To',
                render: (value, record) =>
                <Icon type="arrow-right" style={{color:'#140cf2'}}/>
                        },
            {
                title: 'Target_UserId',
                dataIndex: 'Target_UserId',
                key: 'Target_UserId',
                ...this.getColumnSearchProps('Target_UserId'),
                className:'targetcolomn'
              },
              {
                title: 'target_Name',
                dataIndex: 'target_Name',
                key: 'target_Name',
                ...this.getColumnSearchProps('target_Name'),
                className:'targetcolomn'
              },
              {
                title: 'target_Mobile',
                dataIndex: 'target_Mobile',
                key: 'target_Mobile',
                ...this.getColumnSearchProps('target_Mobile'),
                className:'targetcolomn'
              },
              {
                title: 'Action',
                render: (value, record) =>
                <Button ghost size="small" style={{ marginLeft: 10, marginRight: 10 }} type="primary">Coming soon</Button>
                        },
          ];
          function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Accepted Users</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                    <Table columns={columns} dataSource={this.state.data} onChange={onChange} pagination={{ pageSize: 25 }}/>
                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </Container>
        )
    }
}
export default Form.create()(AdminAccepted);