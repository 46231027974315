import React, { Component } from 'react';
import { Container} from 'reactstrap';
import { message, Skeleton,Tabs,Layout, PageHeader} from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
import Edit_MyprofileCard from '../Update_pages/Edit_myprofileCard';
import ContactCard from '../Update_pages/Edit_contactCard';
import EducationworkCard from '../Update_pages/Edit_EducationWorkCard';
import FamilyCard from '../Update_pages/Edit_FamilyCard';
import PartnerCard from '../Update_pages/Edit_PartnerCard';
import MyphotosCard from '../Update_pages/Edit_MyphotosCard';
import PassChangeCard from '../Update_pages/Edit_passChangeCard';
import ContactView from '../Update_pages/Edit_ContactView';
import ProfileDelete from '../Update_pages/Edit_ProDelete';
const { TabPane } = Tabs;
const { Content } = Layout;
class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            visible: false,
            mode: 'top',
        };
    }
    componentDidMount() {
        this.Refrsh();
        message.config({ top: 100 });
        let obj = { user_id: this.props.User.user_id };
        fetch(API.BASE_URL + API.GET_EDIT_USER, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // message.success('success');
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    message.error('Loading faild');
                    alert(error);
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
 
    Refrsh = () => {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        const { isLoaded, mode } = this.state;
        if (!isLoaded) {
            return <Container className="scrollBox1" fluid={true} style={{ backgroundColor: '#fafafa', marginTop: 10 }}><Skeleton avatar paragraph={{ rows: 20 }} /></Container>;
        } else {
            return (

                <Content className="scrollBox1" style={{ background: '#f2f3f5' }}>
                
                <Container>
                <Tabs defaultActiveKey="1" tabPosition={mode} tabBarStyle={{ marginTop: 11, fontSize: 10 }} size={'large'} style={{ backgroundColor: '#fafafa' }} tabBarGutter={0}>
                    <TabPane tab={this.props.lang.UserProfileMyProfile} key={1} style={{ fontSize: 22 }}>
                        <div style={{ margin: 10 }}>
                            <Edit_MyprofileCard
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>

                    </TabPane>
                    <TabPane tab={this.props.lang.UserProfileContactAddress} key={2}>
                        <div style={{ margin: 10 }}>
                            <ContactCard
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>
                    </TabPane>
                    <TabPane tab={this.props.lang.UserProfileEducation}  key={3}>
                        <div style={{ margin: 10 }}>
                            <EducationworkCard
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>
                    </TabPane>
                    <TabPane tab={this.props.lang.UserProfileFamily}  key={4}>
                        <div style={{ margin: 10 }}>
                            <FamilyCard
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>
                    </TabPane>
                    <TabPane tab={this.props.lang.UserProfilePatner}  key={5}>
                        <div style={{ margin: 10 }}>
                            <PartnerCard
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>
                    </TabPane>
                    <TabPane tab={this.props.lang.UserProfileMyPhoto}  key={6}>
                        <div style={{ margin: 10 }}>
                            <MyphotosCard />
                        </div>
                    </TabPane>
                  
                    <TabPane tab={this.props.lang.UserProfileChangePW}  key={7}>
                        <div style={{ margin: 10 }}>
                            <PassChangeCard
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>
                    </TabPane>
                    <TabPane tab={this.props.lang.ContactViewPrivacy}  key={8}>
                        <div style={{ margin: 10 }}>
                            <ContactView
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>
                    </TabPane>

                    <TabPane tab={this.props.lang.Requestprofiledelete} key={9}>
                        <div style={{ margin: 10 }}>
                            <ProfileDelete
                                data={this.state.items}
                                reload={() => this.componentDidMount()} />
                        </div>
                    </TabPane>
                </Tabs>
                
  </Container>
        </Content>
            )
        }
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);