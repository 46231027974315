import React, { Component } from 'react';
import { Container, Row, Col, CardBody } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, Card, DatePicker, InputNumber, message } from 'antd';
import API from '../../API';
import { connect } from 'react-redux';
const { TextArea } = Input;
const ButtonGroup = Button.Group;
const { Option } = Select;
class EditAdmin_contactview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            View_Limit: this.props.data.View_Limit,
            SMSno: this.props.data.SMSno,
            SMSCount:this.props.data.SMSCount,
            ContactViewCount:this.props.data.ContactViewCount
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    update = () => {
        this.setState({ loading: true });
        let obj = {
            'user_id': this.props.data.user_id,
            'Limit': this.state.View_Limit,
        }
        fetch(API.BASE_URL + API.ADMIN_UPDATE_CONTACTVIEW, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 'success') {
                    this.props.reload();
                    this.setState({ loading: false });
                    message.success('Updated Successfully');
                }
                else {
                    this.setState({ loading: false, status: 'error', });
                    message.error('Error. Try again !');
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
                console.error(error);
            });
    }
    updateSMS = () => {
        this.setState({ loading: true });
        let obj = {
            'user_id': this.props.data.user_id,
            'Limit': this.state.SMSno,
        }
        fetch(API.BASE_URL + API.ADMIN_UPDATE_SMS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 'success') {
                    this.props.reload();
                    this.setState({ loading: false });
                    message.success('Updated Successfully');
                }
                else {
                    this.setState({ loading: false, status: 'error', });
                    message.error('Error. Try again !');
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
                console.error(error);
            });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            <Card>
                <CardBody>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item label="View Limit">
                            {getFieldDecorator('View_Limit', {
                                initialValue: this.state.View_Limit,
                                setFieldsValue: this.state.View_Limit,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please input your View Count!' }],
                            })(
                                <Input
                                    name="View_Limit"
                                    placeholder="View Limit" />)}
                        </Form.Item>

                        <Form.Item label="Contact View Count">
                          
                                {this.state.ContactViewCount}
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={() => this.update()}>
                                    Update Contact
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                        <Form.Item label="SMS Number">
                            {getFieldDecorator('SMSno', {
                                initialValue: this.state.SMSno,
                                setFieldsValue: this.state.SMSno,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please input SMS Number!' }],
                            })(
                                <Input
                                    name="SMSno"
                                    placeholder="SMS Number" />)}
                        </Form.Item>
   <Form.Item label="SMS Count">
                          
                                {this.state.SMSCount}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={() => this.updateSMS()}>
                                    Update SMS
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(EditAdmin_contactview));