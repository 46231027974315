import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Form, Icon, Input, Button, Card, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FooterS from '../../components/Footer';
import Appdownload from '../../components/Appdownload';
import API from '../../API';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      UserID: null,
      password: null,
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
    console.log(event.target.name);
  }
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let obj = {
          'UserID': this.state.UserID,
          'password': this.state.password,
        }
        fetch(API.BASE_URL + API.LOGIN, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.auth) {
              this.props.Login(responseJson);
              this.props.history.push("/UserApp/UserHome");
              this.setState({ loading: false })
            }
            else {
              this.error();
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            this.error();
            console.error(error);
          });
      }
    });
  }

  error = () => {
    Modal.error({
      title: 'Login Faild',
      content: 'Check your user name and password...',
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div >
        <Container className="usercontainer">
          <Row>
            <Col sm="4" md={{ size: 4, offset: 2 }}>
              <Card style={{ marginTop: 40, borderRadius: 5 }} className="itemscentr">
                <img src={require('../../images/logo.png')} style={{ height: 33, margin: 20 }} alt="logo" />
                <h5 style={{ color: '#026c70' }}>Login</h5>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <Form.Item>
                    {getFieldDecorator('UserID', {
                      initialValue: this.state.UserID,
                      setFieldsValue: this.state.UserID,
                      onChange: this.handleChange,
                      rules: [{ required: true, message: 'Please input your UserID!' }],
                    })(
                      <Input
                        name="UserID"
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="UserID or Email"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item style={{ marginTop: -10 }}>
                    {getFieldDecorator('confirm', {
                      initialValue: this.state.password,
                      setFieldsValue: this.state.password,
                      onChange: this.handleChange,
                      rules: [
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        }
                      ],
                    })(<Input.Password onBlur={this.handleConfirmBlur} name="password" placeholder="Password" />)}
                  </Form.Item>
                  <Form.Item>

                    <Button type="primary" htmlType="submit" className="login-form-button" block style={{ backgroundColor: '#026c70', borderColor: '#026c70', fontWeight: '500' }} size={'large'}>
                      Login
              </Button>
                    <hr />
                    <Link to='/forgottpassword' className="login-form-forgot">
                      <span style={{fontWeight:'500',color:'#026c70'}}>Forgot password ?</span>
              </Link><br></br>
              <span style={{fontWeight:'500'}}>New to Prolifemarry?</span>
              <Link to='/Register'> <span style={{fontWeight:'500',color:'#026c70'}}>Register</span></Link>
                  </Form.Item>
                </Form>
              </Card>
            
              
            

              <Row style={{ margin: 10 }}>
                <Col sm="6" xs="6" style={{ marginTop: 10 }}>
                  <a href="https://play.google.com/store/apps/details?id=com.prolifemarry">
                    <img src={require('../../images/play.png')} alt="logo" className="recomend-image" />
                  </a>

                </Col>
                <Col sm="6" xs="6" style={{ marginTop: 10 }}>
                  <Link to="/download">
                    <img src={require('../../images/appstore.png')} alt="logo" className="recomend-image" />
                  </Link>

                </Col>
              </Row>
            </Col>
            <Col sm="6" xs="0" style={{ marginTop: 20 }} className="hidesection">
              <Appdownload />
            </Col>
          </Row>

        </Container>
        <Modal
          centered
          visible={this.state.loading}
          footer={false}
          closable={false}
          width={300}
        >
          <Form layout="inline">
            <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
            <Form.Item><h6>Please wait</h6></Form.Item>
          </Form>
        </Modal>

        <FooterS />
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    User: state.User,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    Login: (data) => {
      dispatch({ type: 'LOGIN', payload: data })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(Login));