
        import React, { Component } from 'react';
        import {Container,Row,Col} from 'reactstrap';
        import {Layout,Divider } from 'antd';
        import CacheBuster from '../CacheBuster';
        import { Link } from 'react-router-dom';
        const {Footer } = Layout;
        class FooterS extends Component {
            render() {
                return (
                    <Footer style={{backgroundColor:'#f2f3f5',padding: '18px 50px 0px'}}>
                    <Container>
                    <Row>
                        <Col sm="4" xs="4" md="4" lg="4">
                        <Link to='/Privacy' style={{ textDecoration: 'none' }}>
      
                          <p style={{fontFamily:'Ubuntu-Medium',textAlign:'center'}}>Privacy Policy</p>
                          </Link>
                        </Col>
                        <Col sm="4" xs="4" md="4" lg="4">
                        <Link to='/Terms' style={{ textDecoration: 'none' }}>
                         <p style={{fontFamily:'Ubuntu-Medium',textAlign:'center'}}>Terms & Conditions</p>
                         </Link>
                       </Col>
                       <Col sm="4" xs="4" md="4" lg="4">
                       <Link to='/Refund' style={{ textDecoration: 'none' }}>
                         <p style={{fontFamily:'Ubuntu-Medium',textAlign:'center'}}>Cancellation/Refund Policy</p>
                         </Link>
                       </Col>
                    
                      </Row>
                      <Divider style={{marginTop:-5,marginBottom:10}}/>
                      <Row >
                        <Col sm="8" xs="8" md="8" lg="8">
                         
                          <p style={{fontFamily:'Ubuntu-Regular'}}>Copyright © 2021 Prolifemarry.com</p>
                        </Col>
                        <Col sm="4" xs="4" md="4" lg="4">
                         
                         <p style={{fontFamily:'Ubuntu-Regular',textAlign:'end'}}>Version: {global.appVersion}</p>
                       </Col>
                      </Row>
                    </Container>
                  </Footer>
                )
            }
        }
        export default FooterS;