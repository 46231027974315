

import React, { Component } from 'react';
import { Card, CardBody, Row, Col, CardImg, ButtonGroup } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, DatePicker, message,Badge } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class EditAdmin_AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            issuccess: false,
            current: 0,
            value: '',
         
            //step1
            Status:this.props.data.Status,
          
            RegDate:this.props.data.RegDate,
            UserId:this.props.data.UserID,
            PaymentS:this.props.data.PaymentS,
            PaidAmount:this.props.data.PaidAmount,
            paid_date:this.props.data.paid_date,
            ActivateDate:this.props.data.ActivateDate,
            ExpDate:this.props.data.ExpDate,
            Password:this.props.data.Password,
            Type:this.props.data.AcType,
            ViewDate:this.props.data.ViewDate,
            Device:this.props.data.Device,
            Count:this.props.data.Count
            //end step1
        };
        
      
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }

    ondateChange = (value) => {
        if (value != null) {
            this.setState({ DOB: value.format('YYYY-MM-DD'), date: value });
        } else {
            this.setState({ DOB: null, date: null });
        }
    }

 
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '+91',
        })(
            <Select style={{ width: 70 }}>
                <Option value="+91">+91</Option>
            </Select>,
        );
        const height_Array = [];
        for (let i = 30; i < 250; i++) {
            height_Array.push({
                value: i,
            });
        }
        const Weight_array = [];
        for (let i = 20; i < 160; i++) {
            Weight_array.push({
                value: i,
            });
        }
        const dateFormat = 'YYYY/MM/DD';
        return (
            <Card>
                <CardBody>
<Row>
    <Col>Account Status
    </Col>
    <Col>   {(() => {
                    if (this.state.Status === 'A') {/// ---A--- activated account
      return(
                        <div>  <Badge status="processing" /><b>Active</b></div> )
        }
        else if (this.state.Status === 'D') {
            return(
                <div>  <Badge status="warning" /><b>Deactive</b></div>)
        }
        else if (this.state.Status === 'N') {
            return(
                <div>  <Badge status="warning" /><b>New Registration - Waiting for Approval</b></div>)
        }
    })()}     
    </Col>
    <Col>Type</Col>
    <Col> {(() => {
                    if (this.state.Type === '1') {/// ---A--- activated account
      return(
                        <div>  <Badge status="processing" /><b>ON</b></div> )
        }
        else if (this.state.Type === '0') {
            return(
                <div>  <Badge status="warning" /><b>OFF</b></div>)
        }
     
    })()}  </Col>
</Row>
<Row>
    <Col>User Id
    </Col>
    <Col><b> {this.state.UserId} </b>  </Col>
    <Col>Password</Col>
    <Col><b>{this.state.Password}</b></Col>
</Row>
<Row>
    <Col>Payment Status
    </Col>
    <Col> <b>{this.state.PaymentS}</b></Col>
    <Col>Register Date</Col>
    <Col><b>{this.state.RegDate}</b></Col>
</Row>
<Row>
    <Col>Payed Date
    </Col>
    <Col> <b>{this.state.paid_date}</b></Col>
    <Col>Activated Date</Col>
    <Col><b>{this.state.ActivateDate}</b></Col>
</Row>
<Row>
    <Col>Expiry Date
    </Col>
    <Col><b>{this.state.ExpDate}</b></Col>
    <Col>Payed Amount</Col>
    <Col><b>{this.state.PaidAmount}</b></Col>
</Row>
<Row>
    <Col>Last seen
    </Col>
    <Col><b>{this.state.ViewDate}</b></Col>
    <Col>Device</Col>
    <Col><b>{this.state.Device}</b></Col>
</Row>
<Row>
    <Col>Profile Percentage
    </Col>
    <Col><b>{this.state.Count}</b></Col>
    <Col>
    </Col>
    <Col></Col>
</Row>

                   
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(EditAdmin_AccountDetails));