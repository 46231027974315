import * as Action from '../Actions/DropDownAction';
import API from '../../API';

export const loaddropdowns = () => {
    
    return async dispatch => {
        fetch(API.BASE_URL + API.DROPDOWNS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                const response = {
                    Religion_array: responsesJson.Religion_array,
                    Education_array: responsesJson.Education_array,
                    Work_array: responsesJson.Work_array,
                    WorkingPlace_array: responsesJson.Workingplace_array,
                    Diocese_array: responsesJson.Diocese_array,
                };
                dispatch(Action.saveDropDowns(response));
            },
                (error) => {
                    console.error(error);
                }
            )
    }
}