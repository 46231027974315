import * as types from './Types';
export const loadshortlistAction = (data) => ({
    type: types.LOAD_SHORTLIST,
    payload: data
});
export const addshrotlistAction = (data) => ({
    type: types.ADD_SHORTLIST,
    payload: data
});
export const removeshrotlistAction = (data) => ({
    type: types.REMOVE_SHORTLIST,
    payload: data
});

export const shortlistrefreshsucessAction = () => ({
    type: types.REFRESH_SHORTLIST_SUCCESS
});







