

import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup ,Jumbotron} from 'reactstrap';
import { Form, Input, Radio, message, Button, Select, Tag } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class PartnerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3
            ConLock: this.props.data.ConLock,
         

            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
   

    update = () => {
     
                this.setState({ loading: true });
                let obj = {
                    user_id: this.props.User.user_id,
                    ConLock: this.state.ConLock,
                  
                }
                fetch(API.BASE_URL + API.EDIT_ContactLock, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                        
                            this.props.reload();
                            this.setState({
                                loading: false,
                               
                            });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
          
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            
            <Card style={{ borderRadius: 10 }} >
                <CardBody>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.ContactViewPrivacy}</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Form {...formItemLayout}>
                        <Form.Item label={this.props.lang.ContactViewPrivacy}>
                            {getFieldDecorator('ConLock', {
                                initialValue: this.state.ConLock,
                                setFieldsValue: this.state.ConLock,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Contact view privacy!' }],
                            })(
                                <Radio.Group name="ConLock">
                                    <Radio.Button value="1">ON</Radio.Button>
                                    <Radio.Button value="0">OFF</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                     
                     
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                                <Button type="primary" onClick={() => this.update()} style={{fontWeight:'bolder',backgroundColor:'#0070ba',borderColor:'#0070ba'}}>
                                              <span style={{marginLeft:15,marginRight:15}}>Update</span>
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                    <Jumbotron style={{paddingTop:15,paddingBottom:10}}>
       
        <p className="lead">If someone is calling and disturbing you through profile calling, you can use the new feature contact view lock. If you use it, only the people whom you have partner filtered will see your contact.</p>
        <hr className="my-2" />
        
        <p className="lead" style={{fontSize:14}}>
        ഫോൺ കോളിംഗ് നിങ്ങളെ ബുദ്ധിമുട്ടിപ്പിക്കുന്നുണ്ടെങ്കിൽ കോൺടാക്റ്റ് വ്യൂ ലോക്ക്  ON  ചെയ്യുക. ശേഷം നിങ്ങൾ accept ചെയ്ത ഇന്ട്രെസ്റ്കാർക്കും Patner Details നിങ്ങൾ നൽകിയ ഡീറ്റൈൽസിനും മാച്ച് ആയ ആൾക്കാർക്ക് മാത്രമേ നിങ്ങളുടെ കോൺടാക്ട് കാണുവാൻ സാധിക്കുകയൊള്ളു.
        </p>
      </Jumbotron>
                </CardBody>
            </Card>
            
          
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(PartnerCard));