
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Icon, Button,Card  } from 'antd';
import {Link} from 'react-router-dom';
import './commonstyles.css';
import { connect } from 'react-redux';
class Deactivated_card extends Component {
    render() {
        return (
            <div>
                   
               
            <Card className="DeactivationCard" >
            <Row style={{padding:20}}>
                  <Col sm="1" xs="1">
                  <Icon type="exclamation-circle" style={{fontSize:40,marginBottom:20}}/>
                  </Col>
                  <Col sm="11" xs="12">
                  <h5 className="deactivationHead" >{this.props.lang.DeactiveCardTitile}</h5>
                  <p className="deactivationSub" lang={this.props.lang.lang} >
                  {this.props.lang.DeactiveCardDis} 
                  </p>
                 
                  <Link to='/UserApp/UserPayment' style={{ textDecoration: 'none' }}>
                  <Button  >Pay Now</Button>
                      </Link>
                  </Col>
              </Row>
</Card></div>
        )
    }
}

function mapStateToProps(state) {
    return {
       
        lang:state.lang
    }
}
export default connect(mapStateToProps)(Deactivated_card);