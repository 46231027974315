import * as Action from '../Actions/NotificationAction';
import API from '../../API';

export const loadNotification = (user_id) => {
    
    return async dispatch => {
        let obj = {user_id: user_id }
        fetch(API.BASE_URL + API.BadgeNotification, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                const response = {
                    NoNotification: responsesJson.rowcount
                  
                };
                dispatch(Action.ViewNotification(response));
            },
                (error) => {
                    console.error(error);
                }
            )
    }
}