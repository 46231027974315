import React from 'react';
import { connect } from 'react-redux';
import './styles.css';
import { Row, Col,Container } from 'reactstrap';

import Card from '@material-ui/core/Card';
import { FaUserAlt, FaUserGraduate, FaChurch } from 'react-icons/fa';
import { MdPlace, MdWork } from "react-icons/md";
import Shortlistbutton from '../Shortlistbutton';
import IntrestedButton from '../IntrestedButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Popover,Icon } from 'antd';
import { Link } from 'react-router-dom';
function age(DOB) {
    var birthdate = new Date(DOB);
    var cur = new Date();
    var diff = cur - birthdate;
    var age = Math.floor(diff / 31536000000);
    return age;
}

function ListItem2(props) {
    const Religion = function (Relig) {
        let array = props.dropdown.Religion_array;
        let filteredArray = '';
        return filteredArray = array.filter(function (obj) {
            return obj.id === Relig;
        }).map(function (obj) {
            return obj.Name;
        });
    }

    const WorkCat = function (Work) {
        let array = props.dropdown.Work_array;
        let filteredArray = '';
        return filteredArray = array.filter(function (obj) {
            return obj.id === Work;
        }).map(function (obj) {
            return obj.Name;
        });
    }

    const EduCat = function (Edu) {
        let array = props.dropdown.Education_array;
        let filteredArray = '';
        return filteredArray = array.filter(function (obj) {
            return obj.id === Edu;
        }).map(function (obj) {
            return obj.Name;
        });
    }

    const content = (
        <div>
             {props.User.auth === true
                        ? 
            <Link to={`/UserApp/Details/${props.item.user_id}`} target="_blank" ><button block className="listbtn">View Profile</button></Link>
      :
      <div>
<button block  onClick={() => { props.showlogin() }} className="listbtn">View Profile</button>

      </div>
             }
            </div>
    );

    return (
        <Container style={{paddingLeft:8,paddingRight:8}}>
              <Row> <Col sm={{ size: 12, offset: 0 }}  xs={{ size: 12, offset: 0 }}  md={{ size: 10, offset: 1}}  lg={{ size: 12, offset: 0}} xl={{ size: 10, offset: 1}}>

       
        <Card className="listitem">
            <Row >
                <Col sm="4" xs="12" md="5" lg="4" xl="4">
               
                    {props.item.image1
                        ? <img alt="logo" src={props.item.image1} className="image" />
                        : <img alt="logo" src={require('../../images/placeholder.jpg')} className="image" />}
                      
                </Col>
                <Col sm="8" xs="12" md="7" lg="8" xl="8" className="listitemsub2">
                    <div className="listboxheight">
                        <Row>
                            <Col sm="10" xs="9">
                            {props.User.auth === true
                        ? 
                            <Link to={`/UserApp/Details/${props.item.user_id}`} target="_blank" className="ListNameLink"><div className="listname">{props.item.Name}</div></Link>
                            :<div className="listname">{props.item.Name}</div>

}

                            <div className="listid">{props.item.UserId}</div>
                            </Col>
                            <Col sm="2" xs="3" className="morebuttonbox">
                                <Popover placement="bottomRight" content={content} trigger="click"
                                    overlayStyle={{
                                        background: "transparent",
                                        width: "170px"
                                    }}>
                                    <MoreHorizIcon className="morebtn" />
                                </Popover>
                            </Col>
                        </Row>
                        <div className="listitem3"><FaUserAlt style={{ marginRight: 10 }} />{age(props.item.DOB)} yrs / {props.item.Height} cm</div>
                        <div className="listitem3"><FaChurch style={{ marginRight: 10 }} />{Religion(props.item.Religion)}</div>
                        <div className="listitem3"><FaUserGraduate style={{ marginRight: 10 }} />{EduCat(props.item.EduCat)}</div>
                        <div className="listitem3"><MdWork style={{ marginRight: 10 }} />{WorkCat(props.item.WorkCat)}</div>
                        <div className="listitem3"><MdPlace style={{ marginRight: 10 }} />{props.item.Place}</div>
                    </div>
                    {props.User.auth === true
                        ? 
                    <div className="listfooter">
                        <Row className="listitemsub3">
                            <Col style={{ margin: 0, paddingRight: 0 }}>
                                <IntrestedButton 
                                target={props.item}
                                Intrested={props.Intrested.profiles.filter(cartitem => cartitem.user_id === props.item.user_id)[0]}/>
                            </Col>
                            <Col style={{ margin: 0 }}>
                                <Shortlistbutton
                                    target={props.item}
                                    target_id={props.item.user_id}
                                    Shortlist={props.Shortlist.profiles.filter(cartitem => cartitem.user_id === props.item.user_id)[0]} />
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
 <div className="listfooter">
                        <Row className="listitemsub3">
                            <Col style={{ margin: 0, paddingRight: 0 }}>
                            <button  onClick={() => { props.showlogin() }} 
                    className="DetailPageContactLock">  <Icon type="heart" style={{ fontSize: 19 ,verticalAlign:4,marginRight: 4}}/><span  style={{ verticalAlign:2}}>Shortlist</span></button>
                                   </Col>
                            <Col style={{ margin: 0 }}>
                            <button  onClick={() => { props.showlogin() }} 
                    className="DetailPageContactLock">  <Icon type="star" style={{ fontSize: 19 ,verticalAlign:4,marginRight: 4}}/><span  style={{ verticalAlign:2}}>Send Interest</span></button>
                                   </Col>
                        </Row>
                    </div>

                   

                     
                    </div>
}
                </Col>
            </Row>
            
        </Card>
         </Col></Row> </Container>
    )
    
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        Shortlist: state.Shortlist,
        Intrested:state.Intrested,
        lang:state.lang
    }
}
export default connect(mapStateToProps)(ListItem2);