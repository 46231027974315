import React, { Component } from 'react';
import { Card, Container, Col, Row, CardTitle, CardText, CardImg, CardImgOverlay, CardSubtitle, CardBody } from 'reactstrap';
import { Upload, Icon, message, Modal, Button } from 'antd';
import ImageCropUpload from '../../components/ImageCropUpload';
import { connect } from 'react-redux';
import API from '../../API';
class EditAdmin_MyphotosCard extends Component {
    state = {
        loading: false,
        selectedFile: null,
        previewVisible: false,
        previewImage: '',
        image1: null, image2: null,
        image3: null, image4: null,
        image5: null, image6: null
    };

    componentDidMount = () => {
        this.handleChanges()
    }

    customRequest = (files, { file }) => {
        this.setState({ loading: true });
        const formData = new FormData()
        formData.append('user_id', this.props.data.user_id);
        formData.append('image_id', files.target.image_id);
        formData.append('file', file);
        fetch(API.BASE_URL + API.UPLOAD_IMAGE, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(responsesJson => {
                if (responsesJson.status === 'success') {
                    this.handleChanges();
                }
                else {
                    alert("try again");
                }

            })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handleChanges = () => {
        let obj = { user_id: this.props.user_id }
        fetch(API.BASE_URL + API.ALL_IMAGES, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                this.setState({
                    loading: false,
                    image1: responsesJson.image1,
                    image2: responsesJson.image2,
                    image3: responsesJson.image3,
                    image4: responsesJson.image4,
                    image5: responsesJson.image5,
                    image6: responsesJson.image6,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    deleteImage = (imageId, image_value) => {
        let obj = {
            user_id: this.props.user_id,
            image_id: imageId,
            image: image_value
        }
        fetch(API.BASE_URL + API.DELETE_IMAGE, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    this.handleChanges();
                }
                else {
                    alert("try again");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    render() {
        return (
            <div style={{ borderRadius: 10 }}>
            <p style={{ fontSize:20, fontWeight: 'bolder' }}>Your Photos</p>
            <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
            <Row>
                <Col sm="4" xs="12">
                    <ImageCropUpload
                        image={this.state.image1}
                        image_id={'image1'}
                        user_id={this.props.user_id}
                        handleChanges={() => this.handleChanges()} />
                </Col>
                <Col sm="4" xs="12">
                    <ImageCropUpload
                        image={this.state.image2}
                        image_id={'image2'}
                        user_id={this.props.user_id}
                        handleChanges={() => this.handleChanges()} />
                </Col>
                <Col sm="4" xs="12">
                    <ImageCropUpload
                        image={this.state.image3}
                        image_id={'image3'}
                        user_id={this.props.user_id}
                        handleChanges={() => this.handleChanges()} />
                </Col>
            </Row>
            <Row>
                <Col sm="4" xs="12">
                    <ImageCropUpload
                        image={this.state.image4}
                        image_id={'image4'}
                        user_id={this.props.user_id}
                        handleChanges={() => this.handleChanges()} />
                </Col>
                <Col sm="4" xs="12">
                    <ImageCropUpload
                        image={this.state.image5}
                        image_id={'image5'}
                        user_id={this.props.user_id}
                        handleChanges={() => this.handleChanges()} />
                </Col>
                <Col sm="4" xs="12">
                    <ImageCropUpload
                        image={this.state.image6}
                        image_id={'image6'}
                        user_id={this.props.user_id}
                        handleChanges={() => this.handleChanges()} />
                </Col>
            </Row>
    </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(EditAdmin_MyphotosCard);