

import React, { Component } from 'react';
import { CardBody, Container } from 'reactstrap';
import { Select, message, Table, Modal, Icon, Divider , Card } from 'antd';
import { Statistic, Row, Col, Button } from 'antd';
import API from '../API';
const { Option } = Select;
class AdminHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            items: [],

        };
    }
    componentDidMount = () => {
        message.config({ top: 100 });
        this.success();
        this.setState({ loading: true });
        fetch(API.BASE_URL + API.GET_COUNTS)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    items: res
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
    }
    success = () => {
        const hide = message.loading('Loading..', 0);
        // Dismiss manually and asynchronously
        setTimeout(hide, 2500);
    };
    render() {

        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox1">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Home</p>
              
                {this.state.items.map((item) =>
 <Card style={{ borderRadius: 10, marginTop: 20 }}>
<Row gutter={16}>
<Col span={4}>
  <Statistic title="Total Users" value={item.Total} />
</Col>
<Col span={4}>
  <Statistic title="Active Users" value={item.Active} />
</Col>
<Col span={4}>
  <Statistic title="Deactive Users" value={item.AllDeactive}   valueStyle={{ color: '#cf1322' }} />
</Col>
<Col span={4}>
<Statistic title="New Users" value={item.New} />
</Col>
<Col span={4}>
<Statistic title="Total ON" value={item.AllON} />
</Col>
<Col span={4}>
<Statistic title="Total OFF" value={item.AllOFF}    valueStyle={{ color: '#cf1322' }}/>
</Col>
</Row>
<Divider />
<Row gutter={16}>

<Col span={4}>
  <Statistic title="Male Users" value={item.Male} />
</Col>
<Col span={4}>
  <Statistic title="Male Active" value={item.MaleActive} />
</Col>
<Col span={4}>
  <Statistic title="Male New Reg" value={item.MaleNewReg} />
</Col>
<Col span={4}>
<Statistic title="Male Deactive" value={item.MaleDeactive}   valueStyle={{ color: '#cf1322' }}/>
</Col>
<Col span={4}>
<Statistic title="Male ON" value={item.MaleON} />
</Col>
<Col span={4}>
<Statistic title="Male OFF" value={item.MaleOFF}    valueStyle={{ color: '#cf1322' }}/>
</Col>
</Row>
<Divider />
<Row gutter={16}>

<Col span={4}>
  <Statistic title="Female Users" value={item.Female} />
</Col>
<Col span={4}>
  <Statistic title="Female Active" value={item.FemaleActive} />
</Col>
<Col span={4}>
  <Statistic title="Female New Reg" value={item.FemaleNewReg} />
</Col>
<Col span={4}>
<Statistic title="Female Deactive" value={item.FemaleDeactive}   valueStyle={{ color: '#cf1322' }}/>
</Col>
<Col span={4}>
<Statistic title="Female ON" value={item.FemaleON} />
</Col>
<Col span={4}>
<Statistic title="Female OFF" value={item.FemaleOFF}   valueStyle={{ color: '#cf1322' }}/>
</Col>
</Row>
<Divider />
<Row gutter={16}>

<Col span={8}>
  <Statistic title="Payed Users" value={item.Payed} />
</Col>
<Col span={8}>
  <Statistic title="Unpayed Users" value={item.Unpayed}   valueStyle={{ color: '#cf1322' }}/>
</Col>
<Col span={8}>
<Statistic title="Unknown Payment Users" value={item.Unk} />
</Col>
</Row>

<Divider />
<Row gutter={16}>

<Col span={6}>
  <Statistic title="Today Payment" value={item.TodayPayment} />
</Col>
<Col span={6}>
  <Statistic title="Yesterday Payment" value={item.YesterdayPayment} />
</Col>
<Col span={6}>
  <Statistic title="Last 10 Days" value={item.TenDayPayment}   />
</Col>
<Col span={6}>
<Statistic title="last 30 day Payment" value={item.ThirtDayPayment} />
</Col>
</Row>
</Card>
                   

                )}
            </Container>


        )
    }
}

export default AdminHome;