const styles = ({
    mainnavitem: {
        color: '#0070ba',
        fontWeight: '500',
        fontSize: 18,
        marginLeft: 25,
        marginRight: 25
    },
    regitem: {
        marginLeft: 25,
        marginRight: 15,
        textDecoration: 'none'
    },
    mainnavbutton: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 5
    },
    mainnavlogin: {
        color: '#0070ba',
        fontWeight: '500',
        fontSize: 14,
        marginTop: 7,
        marginBottom: 7,
        marginLeft: 25,
        marginRight: 25,
        fontfamily: "Nunito",
    },
    mainnavregister: {
        color: '#fff',
        fontWeight: '500',
        fontSize: 14,
        marginTop: 7,
        marginBottom: 7,
        marginLeft: 25,
        marginRight: 25,
        fontFamily: "Nunito-Bold"
    },
    linkdecoration: {
        textDecoration: 'none',
        marginRight:20
    },
    Drawerimage: {
        width: '100%',
    },
    popupimage: {
        width: 50,
        height: 50
    },
    popupprofilebtn: {
        backgroundColor: '#e9ecf0',
        color: '#0070ba',
        fontWeight: 'bold',
        marginTop: 13,
        borderColor: '#e9ecf0',
        fontSize: 14,
        fontfamily: 'Nunito-SemiBold',
    },
    drawerItem: {
        marginLeft: -15,
        color: 'black',
        fontSize: 15,
        fontWeight: 'initial',
        fontFamily: "Nunito-SemiBold"
    },
    drawericon: {
        fontSize: 23,
        color: 'black'
    },
    hraderItem2: {
        marginLeft: -15,
        color: '#026c70',
        fontSize: 15,
        fontWeight: '500',
        fontfamily: "Nunito-SemiBold",
        textDecoration: 'none'
    },
    linkdec: {
        textDecoration: 'none'
       
        
    },
    menuitemicon:{
        fontSize: 20,
         marginRight: 20,
          color: '#797676' 
    },
   


})
export default styles;