

import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 280;
const UseStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      backgroundColor: '#fff'
    },
  },
  menuButton: {
    marginRight: 0,
    marginLeft: -10,
    [theme.breakpoints.down('sm')]: {
      color: "black"
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  LogoMain: {
    
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 0
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#f8f9fa'
  },
  content: {
    flexGrow: 1,
    margin: 0,
    backgroundColor: '#fff'
  },
  extramenu: {
    textDecoration: 'none',
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
     
    },
    [theme.breakpoints.up('lg')]: {
     
    },
  },
  headerLogo: {
    height: 33,
    [theme.breakpoints.down('sm')]: {
      height: 28,
    },
    [theme.breakpoints.up('sm')]: {
      height: 30,
    },
    [theme.breakpoints.up('md')]: {
      height: 33,
    },
  },
  headerText: {
    fontWeight: 'bold', marginLeft: 10, fontSize: 20, color: '#d13b7c', fontfamily: "Nunito",
    [theme.breakpoints.down('xs')]: {
      fontWeight: 'bold', marginLeft: 10, fontSize: 15, color: '#d13b7c', fontfamily: "Nunito",
    },
  },
  buttonnav: {
    backgroundColor: 'transparent',
    borderColor: '#026c70',
    borderWidth: 1,
    fontWeight: '500',
    color: '#026c70',
    borderRadius:6,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      borderColor: '#026c70',
      borderWidth: 1,
      fontWeight: '500',
      color: '#026c70',
      marginRight: -20,
      borderRadius: 6,
    }
  },
  userimage:{
    width: 35,
     height: 35,
     marginRight:10,marginLeft:20, 
     [theme.breakpoints.down('sm')]: {
      width: 35,
     height: 35,
     marginRight:0,
     }
},
langdrop:{
  marginLeft:10,
 marginRight:20,
  fontSize:14,
  fontWeight:'500',
  borderColor:'#FF6949',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {

  },
  [theme.breakpoints.up('lg')]: {

  },
},
langdrop2:{
  marginLeft:10,
  fontfamily: "Nunito-Bold",
  fontSize:12,
  fontWeight:'500'
}

}));

export default UseStyles;

