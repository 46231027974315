import React, { Component } from 'react';
import './styles.css';

import { message, List ,Button,Skeleton } from 'antd';
import { connect } from 'react-redux';
import ListItem2 from '../../components/ListItem2/Lisitem2';

import Loadingplaceholder from '../../components/Loading_placeholder';

import API from '../../API';

  
class AdminSugg extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            isLoaded: false,
       
            page: 1,
            data: [],
            initLoading: true,
            loading: false,
            limit:20,
        
        };
        this.arrayholder = [];
    }
 

    componentDidMount() {
     
          this.setState({ Activated: true,page:1 });
          message.config({ top: 100 });
          let obj = { user_id: this.props.User.user_id,  limit: this.state.limit, Gender: this.props.User.gender ,
            page: this.state.page }
          fetch(API.BASE_URL + API.AdminSugg, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
          })
          .then((response) => response.json())
          .then((responsesJson) => {
              if (responsesJson.data) {
               
                  this.setState({ data: responsesJson.Home,  initLoading: false  ,isLoaded: true,loading:false })
              }
              else {
               
                  this.setState({ isLoaded: true, initLoading: false, loading:true});
              }
             
          })
          .catch((error) => {
              this.setState({
                 
                  isLoaded: false,
                
              });
             
              //console.error(error);
          });
      
    
      }
    
      onLoadMore = () => {
        const hide = message.loading('Loading profiles..');
        var a=this.state.page;
        a=a+1;
       
        this.setState(
          { page: a, loading: true} )
    
        message.config({ top: 100 });
        let obj = { user_id: this.props.User.user_id,  limit: this.state.limit,Gender: this.props.User.gender ,
          page: a }
        fetch(API.BASE_URL + API.AdminSugg, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
        .then((response) => response.json())
        .then((responsesJson) => {
            if (responsesJson.data) {
                let listData = this.state.data;
                let data = listData.concat(responsesJson.Home)
             
                this.setState({ data: data,   initLoading: false,loading:false,  isLoaded: true})
                setTimeout(hide, 0);
            }
            else {
            
                this.setState({ isLoaded: true,initLoading: false, loading:true});
                setTimeout(hide, 0);
            }
        
        })
        .catch((error) => {

            setTimeout(hide, 0);
            this.setState({
              
                isLoaded: false,
               
            });
           
            //console.error(error);
        });
      }
    





    render() {
        const { isLoaded } = this.state;
        const { initLoading, loading} = this.state;
        const loadMore =
          !initLoading && !loading ? (
            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,marginBottom:10,
                lineHeight: '32px',
              }}
            >
              <Button onClick={this.onLoadMore}>loading more</Button>
            </div>
          ) : null;
        return (
                            <div style={{marginBottom:15}}>
                              
                              
                            {!isLoaded &&
                            <Loadingplaceholder />
                        }  
                             {isLoaded &&
                           
                                  <List
                                  loading={initLoading}
                                  loadMore={loadMore}
                                
                                dataSource={this.state.data}
                                renderItem={item => ( 
                                    <ListItem2 item={item}
                                        showlogin={() => alert('Clicked')}
                                        reload={() => { this.componentDidMount() }} />
                                )}
                            />
                        }  

                            </div>
                           
                   

        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
    }
}


export default connect(mapStateToProps)(AdminSugg);