import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup } from 'reactstrap';
import { Form, Input, Select, Button, message, InputNumber,Modal } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class ContactCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3

            HouseName: this.props.data.HouseName,
            Place: this.props.data.Place,
            Parish: this.props.data.Parish,
            Diocese: this.props.data.Diocese,
            CAddress: this.props.data.CAddress,
            Contactphone: this.props.data.Contactphone,
            Contactemail: this.props.data.Email
            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    ProfilePercentage = () => {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
            this.refs.toast.show('Something went wrong!');
          });
      }
      Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
               

            })
            .catch((error) => {
                console.error(error);
            });
            
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    'user_id': this.props.User.user_id,
                    'HName': this.state.HouseName,
                    'Place': this.state.Place,
                    'Parish': this.state.Parish,
                    'Diocese': this.state.Diocese,
                    'Address': this.state.CAddress,
                    'PNumber': this.state.Contactphone,
                    'Email': this.state.Contactemail
                }
                fetch(API.BASE_URL + API.EDIT_CONTACT, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.ProfilePercentage();
                            this.Refrsh();
                            this.props.reload();
                            this.setState({ loading: false,ModalVerify:true  });
                          //  message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '+91',
        })(
            <Select style={{ width: 70 }}>
                <Option value="+91">+91</Option>
            </Select>,
        );
        return (
            <Card style={{ borderRadius: 4 }} >
                <CardBody>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.UserProfileContactAddress}</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Form {...formItemLayout}>
                        <Form.Item label={this.props.lang.HName} >
                        {getFieldDecorator('HouseName', {
                                                initialValue: this.state.HouseName,
                                                setFieldsValue: this.state.HouseName,
                                                rules: [{ required: true, message: 'Please input House Name!' }],
                                            })(
                                            <Input
                                                name="HouseName"
                                                maxLength='95' 
                                                value={this.state.HouseName}
                                                onChange={this.handleChange}
                                                placeholder="House Name" />
                                                )}
                        </Form.Item>


                        <Form.Item label={this.props.lang.Place} style={{ marginTop: -20 }}>
                            {getFieldDecorator('Place', {
                                initialValue: this.state.Place,
                                setFieldsValue: this.state.Place,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please input Place!' }],
                            })(
                                <Input
                                    name="Place"
                                    maxLength='195' 
                                    placeholder="Place" />)}
                        </Form.Item>
                        <Form.Item label={this.props.lang.Parish} style={{ marginTop: -20 }}>
                            <Input
                                name="Parish"
                                maxLength='95' 
                                value={this.state.Parish}
                                onChange={this.handleChange}
                                placeholder="parish" />
                        </Form.Item>

                        <Form.Item label={this.props.lang.Diocese} style={{ marginTop: -20 }}>
                            {getFieldDecorator('Diocese', {
                                initialValue: this.state.Diocese,
                                setFieldsValue: this.state.Diocese,
                                rules: [{ required: false, message: 'Please select Diocese!' }],
                            })(
                                <Select
                                    showSearch
                                    name={"Diocese"}
                                    placeholder="Diocese"
                                    optionFilterProp="children"
                                    onChange={(val) => { this.handleChange({ target: { name: 'Diocese', value: val } }) }}
                                    filterOption={(input, option) =>
                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.props.dropdown.Diocese_array.map((item) => (
                                        <Option value={item.id} key={item.id}>{item.Name}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label={this.props.lang.CAddress} style={{ marginTop: -20 }}>
                            <TextArea
                                name="CAddress"
                                maxLength='950' 
                                value={this.state.CAddress}
                                onChange={this.handleChange}
                                placeholder="Communicational Address"
                                autosize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Form.Item>


                        <Form.Item label={this.props.lang.PNumber}  style={{ marginTop: -20 }}>
                            {getFieldDecorator('Contactphone', {
                                initialValue: this.state.Contactphone,
                                setFieldsValue: this.state.Contactphone,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please input your phone number!' }],
                            })(<Input
                                name="Contactphone"
                                type="number"
                                maxLength='10' 
                                placeholder="Phone Number"
                                addonBefore={prefixSelector}
                                style={{ width: '100%', margin: 0, padding: 0 }} />)}
                        </Form.Item>

                        <Form.Item label={this.props.lang.SEmail} style={{ marginTop: -20 }}>
                            {getFieldDecorator('Contactemail', {
                                initialValue: this.state.Contactemail,
                                setFieldsValue: this.state.Contactemail,
                                onChange: this.handleChange,
                                rules: [{ type: 'email', message: 'The input is not valid E-mail!', },],
                            })(<Input
                             maxLength='48' 
                                name="Contactemail"
                                placeholder="E-mail" />)}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={() => this.update()} style={{ fontWeight: 'bolder', backgroundColor: '#0070ba', borderColor: '#0070ba' }}>
                                    <span style={{ marginLeft: 15, marginRight: 15 }}>Update</span>
                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
                <Modal  title="Please Wait.."
                           onOk={() => this.setState({ ModalVerify: false })}
                          onCancel={() => this.setState({ ModalVerify: false })}
                          width={400}
                          visible={this.state.ModalVerify}
                      >
            <h6 style={{textAlign:'justify'}}>Your some details will be updated only after admin verification.</h6>
            <p style={{textAlign:'justify'}}>അഡ്‌മിൻ പരിശോധനയ്ക്ക് ശേഷം മാത്രമേ നിങ്ങളുടെ ചില വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യുകയുള്ളൂ.</p>
            
             
              </Modal>
            </Card>

            
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(ContactCard));