

import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Form, Input, Icon, Radio, Select, Button, DatePicker, InputNumber, message } from 'antd';
import API from '../../API';
import { connect } from 'react-redux';
const { TextArea } = Input;
const ButtonGroup = Button.Group;
const { Option } = Select;
class FamilyCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step2
            Fathers_Name:this.props.data.FName,
            Father_Occupation:this.props.data.FOccupation,
            Mothers_Name:this.props.data.MName,
            Mother_Occupation:this.props.data.MOccupation,
            Financial_Status:this.props.data.FinStatus,
            Brothers_Ma:this.props.data.MBros,
            Brothers_Un:this.props.data.UBros,
            Brothers_Priest:this.props.data.PBros,
            Sisters_Ma:this.props.data.MSis,
            Sisters_Un:this.props.data.USis,
            Sisters_Nuns:this.props.data.NSis
            //end steop2
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    ProfilePercentage = () => {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.ProfilePercentage, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify(obj)
        })
         
          .catch((error) => {
            //console.error(error);
            this.refs.toast.show('Something went wrong!');
          });
      }
      Refrsh() {
        let obj = { user_id: this.props.User.user_id }
        fetch(API.BASE_URL + API.REFRESH, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.props.refresh(responseJson);
               

            })
            .catch((error) => {
                console.error(error);
            });
            
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    user_id: this.props.User.user_id,
                    FName:this.state.Fathers_Name,
                    FOccupation:this.state.Father_Occupation,
                    MName:this.state.Mothers_Name,
                    MOccupation:this.state.Mother_Occupation,
                    FinStatus:this.state.Financial_Status,
                    MBros:this.state.Brothers_Ma,
                    UBros:this.state.Brothers_Un,
                    PBros:this.state.Brothers_Priest,
                    MSis:this.state.Sisters_Ma,
                    USis:this.state.Sisters_Un,
                    NSis:this.state.Sisters_Nuns
                }
                fetch(API.BASE_URL + API.EDIT_FAMILY, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.ProfilePercentage();
                            this.Refrsh();
                            this.props.reload();
                            this.setState({ loading: false });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again !');
                        console.error(error);
                    });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            <Card style={{ borderRadius: 4 }} >
                <CardBody>
                    <p className="UpdateHeader" lang={this.props.lang.lang}>{this.props.lang.UserProfileFamily}</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item label= {this.props.lang.FName} >
                            {getFieldDecorator('Fathers_Name', {
                                initialValue: this.state.Fathers_Name,
                                setFieldsValue: this.state.Fathers_Name,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please input your Fathers Name!' }],
                            })(
                                <Input
                                maxLength='49' 
                                    name="Fathers_Name"
                                    placeholder="Father's Name" />)}
                        </Form.Item>
                        <Form.Item label= {this.props.lang.FOccu}  style={{ marginTop: -20 }}>
                            <Input
                             maxLength='49'
                                name="Father_Occupation"
                                value={this.state.Father_Occupation}
                                onChange={this.handleChange}
                                placeholder="Father's Occupation"
                            />
                        </Form.Item>
                        <Form.Item label={this.props.lang.MName}  style={{ marginTop: -20 }}>
                            {getFieldDecorator('Mothers_Name', {
                                initialValue: this.state.Mothers_Name,
                                setFieldsValue: this.state.Mothers_Name,
                                onChange: this.handleChange,
                                rules: [{ required: true, message: 'Please input your Mothers Name!' }],
                            })(
                                <Input
                                maxLength='49'
                                    name="Mothers_Name"
                                    placeholder="Mother's Name" />)}
                        </Form.Item>
                        <Form.Item label={this.props.lang.MOccu}  style={{ marginTop: -20 }}>
                            <Input
                             maxLength='49'
                                name="Mother_Occupation"
                                value={this.state.Mother_Occupation}
                                onChange={this.handleChange}
                                placeholder="Mother's Occupation" />
                        </Form.Item>
                        <Form.Item label={this.props.lang.FinSta}  style={{ marginTop: -20 }}>
                            {getFieldDecorator('Financial_Status', {
                                initialValue: this.state.Financial_Status,
                                setFieldsValue: this.state.Financial_Status,
                                rules: [{ required: false, message: 'Please select Financial_Status!' }],
                            })(
                                <Select name="Financial_Status" placeholder="Work Category"
                                    onChange={(val) => { this.handleChange({ target: { name: 'Financial_Status', value: val } }) }}>
                                    <Option value="Below Moderate">Below Moderate</Option>
                                                    <Option value="Moderate">Moderate</Option>
                                                    <Option value="Above Moderate">Above Moderate</Option>
                                                    <Option value="Rich">Rich</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label={this.props.lang.NBro}  style={{ marginBottom:-20 }}>
                            <Form.Item style={{ display: 'inline-block', width: 'calc(33% - 12px)' }}
                                help={this.props.lang.Married} >
                                <InputNumber
                                    name="Brothers_Ma"
                                    type="number"
                                    
                                    value={this.state.Brothers_Ma}
                                    onChange={(val) => { this.handleChange({ target: { name: 'Brothers_Ma', value: val } }) }}
                                    min={0} max={10} defaultValue={0} style={{ marginTop: -20 }} />
                            </Form.Item>
                            <Form.Item style={{ display: 'inline-block', width: 'calc(33% - 12px)' }}
                                help={this.props.lang.UnMarried} >
                                <InputNumber
                                    type="number"
                                    name="Brothers_Un"
                                    value={this.state.Brothers_Un}
                                    onChange={(val) => { this.handleChange({ target: { name: 'Brothers_Un', value: val } }) }}
                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                            </Form.Item>
                               <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.Priest} >
                                                <InputNumber
                                                    type="number"
                                                    name="Brothers_Priest"
                                                    value={this.state.Brothers_Priest}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Brothers_Priest', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                                            </Form.Item>
                        </Form.Item>
                        <Form.Item label={this.props.lang.NSis} style={{ marginBottom: 0 }}>
                            <Form.Item style={{ display: 'inline-block', width: 'calc(33% - 12px)' }}
                                help={this.props.lang.Married} >
                                <InputNumber
                                    type="number"
                                    name="Sisters_Ma"
                                    value={this.state.Sisters_Ma}
                                    onChange={(val) => { this.handleChange({ target: { name: 'Sisters_Ma', value: val } }) }}
                                    min={0} max={10} defaultValue={0} style={{ marginTop: -20 }} />
                            </Form.Item>
                            <Form.Item style={{ display: 'inline-block', width: 'calc(33% - 12px)' }}
                                help={this.props.lang.UnMarried}>
                                <InputNumber
                                    type="number"
                                    name="Sisters_Un"
                                    value={this.state.Sisters_Un}
                                    onChange={(val) => { this.handleChange({ target: { name: 'Sisters_Un', value: val } }) }}
                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                            </Form.Item>
                            <Form.Item style={{ display: 'inline-block', width: '33%' }}
                                                help={this.props.lang.Nuns}>
                                                <InputNumber
                                                    type="number"
                                                    name="Sisters_Nuns"
                                                    value={this.state.Sisters_Nuns}
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Sisters_Nuns', value: val } }) }}
                                                    min={0} max={10} defaultValue={0} style={{ marginTop: -50 }} />
                                            </Form.Item>
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                            <Button type="primary" onClick={() => this.update()} style={{fontWeight:'bolder',backgroundColor:'#0070ba',borderColor:'#0070ba'}}>
                                              <span style={{marginLeft:15,marginRight:15}}>Update</span>
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(FamilyCard));