import * as types from '../Actions/Types';
const response ={
    NoNotification: 0
          
};
const Save = (data,parse) =>{
return parse;
}

const NotificationReducer = (state = response, action) =>{
    switch (action.type) {
        case types.VIEW_NOTIFICATION:{
            return Save(state,action.payload);
        }
        default:{
            return state;
        }       
    }
    }
    export default NotificationReducer ;