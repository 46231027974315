

import React, { Component } from 'react';
import { Card, CardBody, Container, TabContent, TabPane, Nav, NavItem, NavLink,Table} from 'reactstrap';
import { Button, Modal, Form, Input, Icon, Select, message } from 'antd';
import API from '../API';
import classnames from 'classnames';
const { Option } = Select;
class AdminDropDowns extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            loading: true,
            error: false,
            activeTab: 'E',
            data: [],
            editID: null,
            Name: null,
            Type: null,
            form: false,
            FomTitle: 'Add New DropDown',
            Add: false,
            Edit: false,
            items: [
                { id: 1, Name: 'Education Category (E)', Type: 'E' },
                { id: 2, Name: 'Work Category (W)', Type: 'W' },
                { id: 3, Name: 'Religion/Cast (R)', Type: 'R' },
                { id: 4, Name: 'Working Place (WP)', Type: 'WP' },
                { id: 5, Name: 'Diocese (D)', Type: 'D' },
                { id: 6, Name: 'Profile Delete Request (PR)', Type: 'PR' },
                { id: 7, Name: 'Photo Delete Request (ID)', Type: 'ID' },
                { id: 8, Name: 'Profile Report (RP)', Type: 'RP' },
                { id: 8, Name: 'Message(TS)', Type: 'TS' },
                { id: 8, Name: 'Message Reply(TR)', Type: 'TR' },
            ]
        };
    }

    componentDidMount = () => {
        message.config({ top: 100 });
        fetch(API.BASE_URL + API.GET_DROPDOWNS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        }).then((response) => response.json())
            .then((responsesJson) => {
                this.setState({ data:responsesJson, loading: false })
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.error();
            });
    }

    set_defualt = () => {
        this.setState({
            editID: null,
            Name: null,
            Type: null,
            form: false,
            FomTitle: 'Add New DropDown',
            Add: false,
            Edit: false
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }



    Set_Add = () => {
        this.setState({
            FomTitle: 'Add New Dropdown',
            form: true,
            Add: true,
            Edit: false,
            editID: null,
            Name: null,
            Type: null,
        })
    }

    Add_new = () => {
        this.setState({ loading: true });
        let obj = { Name: this.state.Name, Type: this.state.Type };
        fetch(API.BASE_URL + API.ADD_DROPDOWNS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Added Successfully');
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }


    Set_Edit = (item) => {
        console.log(item);
        this.setState({
            FomTitle: 'Update Dropdown',
            form: true,
            Edit: true,
            Add: false,
            editID: item.id,
            Name: item.Name,
            Type: item.Type,
        })
    }
    Edit_item = () => {
        this.setState({ loading: true });
        let obj = { id: this.state.editID, Name: this.state.Name, Type: this.state.Type };
        fetch(API.BASE_URL + API.UPDATE_DROPDOWNS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Item updated');
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }


    delete_item = (idD) => {
        this.setState({ loading: true });
        let obj = { id: idD };
        fetch(API.BASE_URL + API.DELETE_DROPDOWNS, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        }).then((response) => response.json())
            .then((responsesJson) => {
                if (responsesJson.status === 'success') {
                    message.success('Deleted Successfully');
                    this.componentDidMount();
                    this.set_defualt();
                    this.setState({ loading: false, form: false });
                }
                else {
                    message.error('Error. Try again !');
                    this.setState({ loading: false, form: false });
                }
            })
            .catch((error) => {
                message.error('Error. Try again !');
                this.setState({ loading: false, form: false });
            });
    }



    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleCancel = () => {
        this.setState({ form: false });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Dropdowns</p>
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                        <div>
                            <div align="Right" style={{ margin: 10 }}>
                                <Button type="primary" onClick={()=>{this.Set_Add()}}>Add New +</Button>
                            </div>
                            <Nav tabs>
                                {this.state.items.map((item) => (
                                    <NavItem key={item.id}>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === item.Type })}
                                            onClick={() => { this.toggle(item.Type); }}>
                                            <span style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Name}</span>
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>

                            <Table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data && this.state.data.filter(book => book.Type === this.state.activeTab).map((item) => (
                                        <tr key={item.id}>
                                            <th scope="row" style={{ fontWeight: 'bold', fontSize: 13 }}>{item.id}</th>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{this.state.activeTab}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>{item.Name}</td>
                                            <td style={{ fontWeight: 'bold', fontSize: 13 }}>
                                                <Button ghost size="small" style={{ marginRight: 10 }} type="primary" onClick={() => { this.Set_Edit(item) }}>Edit</Button>
                                                <Button ghost size="small" type="danger" onClick={() => { this.delete_item(item.id) }}>Remove</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>

                <Modal
                    centered
                    title={this.state.FomTitle}
                    visible={this.state.form}
                    footer={false}
                    onCancel={this.handleCancel}
                    width={500}
                >
                    <Form style={{ marginTop: -20 }}>
                        <Form.Item label="Name">
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Name"
                                name="Name"
                                value={this.state.Name}
                                onChange={this.handleChange}
                            />
                        </Form.Item>
                        <Form.Item label="Type" style={{ marginTop: -20 }}>
                            <Select placeholder="Type"
                                value={this.state.Type}
                                onChange={(val) => { this.handleChange({ target: { name: 'Type', value: val } }) }}>
                                {this.state.items.map((item) => (
                                    <Option value={item.Type} key={item.id}>{item.Name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item align="right" style={{ textAlign: "right" }}>
                            <Button type="defult" onClick={() => { this.setState({ form: false }) }} style={{ marginRight: 10 }}>Cancel</Button>
                            {this.state.Edit ? <Button type="primary" onClick={() => this.Edit_item()}>Update -></Button> : null}
                            {this.state.Add ? <Button type="primary" onClick={() => this.Add_new()}>Add +</Button> : null}
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
            </Container>
        )
    }
}
export default Form.create()(AdminDropDowns);