

import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup ,Jumbotron} from 'reactstrap';
import { Form, Input, Radio, message, Button, Select, Tag } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class PartnerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3
            ConLock: this.props.data.ConLock,
            PhotoStatus:this.props.data.PhotoStatus,
          
            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
   

    update = () => {
     
               
                let obj = {
                    'user_id': this.props.data.user_id,
                    ConLock: this.state.ConLock,
                  
                }
                fetch(API.BASE_URL + API.AdminUpdate_ContactLock, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                        
                          
                         
                            message.success('Updated Successfully');
                        }
                        else {
                          
                            message.error('Error. Try again !');
                        }
                    })
                    .catch((error) => {
                      
                        message.error('Error. Try again !');
                       
                    });
          
    }
    updatePic = () => {
     
               alert(this.state.PhotoStatus);
        let obj = {
            'user_id': this.props.data.user_id,
            PhotoStatus: this.state.PhotoStatus,
          
        }
        fetch(API.BASE_URL + API.AdminUpdate_PicLock, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 'success') {
                
                  
                 
                    message.success('Updated Successfully');
                }
                else {
                  
                    message.error('Error. Try again !');
                }
            })
            .catch((error) => {
              
                message.error('Error. Try again !');
               
            });
  
}
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            
            <Card style={{ borderRadius: 10 }} >
                <CardBody>
                    <p className="UpdateHeader" >Cotact View lock and Profile Pic lock</p>
                    <div style={{ backgroundColor: '#d1cfcf', height: 1, marginTop: -5, marginBottom: 10 }} />
                    <Form {...formItemLayout}>
                        <Form.Item label={this.props.lang.ContactViewPrivacy}>
                            {getFieldDecorator('ConLock', {
                                initialValue: this.state.ConLock,
                                setFieldsValue: this.state.ConLock,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Contact view privacy!' }],
                            })(
                                <Radio.Group name="ConLock">
                                    <Radio.Button value="1">ON</Radio.Button>
                                    <Radio.Button value="0">OFF</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                                <Button type="primary" onClick={() => this.update()} style={{fontWeight:'bolder',backgroundColor:'#0070ba',borderColor:'#0070ba'}}>
                                              <span style={{marginLeft:15,marginRight:15}}>Update Contact</span>
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                        <Form.Item label="Profile Pic Lock">
                            {getFieldDecorator('PhotoStatus', {
                                initialValue: this.state.PhotoStatus,
                                setFieldsValue: this.state.PhotoStatus,
                                onChange: this.handleChange,
                                rules: [{ required: false, message: 'Please select Profile pic Lock!' }],
                            })(
                                <Radio.Group name="PhotoStatus">
                                    <Radio.Button value="Y">ON</Radio.Button>
                                    <Radio.Button value="N">OFF</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                                <Button type="primary" onClick={() => this.updatePic()} style={{fontWeight:'bolder',backgroundColor:'#0070ba',borderColor:'#0070ba'}}>
                                              <span style={{marginLeft:15,marginRight:15}}>Update Pic</span>
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                   
                </CardBody>
            </Card>
            
          
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
function mapDispatchToProps(dispatch) {
    return {
        refresh: (data) => {
            dispatch({ type: 'REFRESH', payload: data })
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(PartnerCard));