

import React, { Component } from 'react';
import {  CardBody, Container, Row, Col } from 'reactstrap';
import { Select, message, Table, Modal, Icon,Input,Button, Form,Popover,Statistic,Card } from 'antd';
import API from '../API';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
const { Option } = Select;
const { TextArea } = Input;
class AdminPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            cno: [],
        };
    }  
    state = {
       
        loading: false,
        visible: false,
      };
   

     
      handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
  
     getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
          ) : (
            text
          ),
      });

      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
 
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };
  
   
    componentDidMount = () => {
        this.setState({ loading: true });
        fetch(API.BASE_URL + API.GET_All_Payment)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    data: res
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
            fetch(API.BASE_URL + API.GET_Payment_Count)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    cno: res
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
    }

    render() {
        const { visible, loading } = this.state;
        function onChange(pagination, filters, sorter) {
            console.log('params', pagination, filters, sorter);
        }
        const columns = [
            { title: 'No', dataIndex: 'id', key: 'id',fixed: 'left' },
            { title: 'User Id', dataIndex: 'user_id', key: 'user_id' ,fixed: 'left', ...this.getColumnSearchProps('user_id')},
            { title: 'Name', dataIndex: 'Name', key: 'Name' ,fixed: 'left', ...this.getColumnSearchProps('Name')},
            { title: 'Paid Date', dataIndex: 'PayedDate',fixed: 'left', key: 'PayedDate' , ...this.getColumnSearchProps('PayedDate')},
            { title: 'Paid Date', dataIndex: 'PayedDate', key: 'PayedDate' , ...this.getColumnSearchProps('PayedDate')},
            { title: 'Amount', dataIndex: 'Amount', key: 'Amount' },
            { title: 'Activate Date', dataIndex: 'ActivateDate', key: 'ActivateDate' , ...this.getColumnSearchProps('ActivateDate')},
            { title: 'Expiry Date', dataIndex: 'ExpDate', key: 'ExpDate' , ...this.getColumnSearchProps('ExpDate')},
            { title: 'Transaction Name', dataIndex: 'TransName', key: 'TransName' },
            { title: 'Pay Type', dataIndex: 'PayType', key: 'PayType' , ...this.getColumnSearchProps('PayType')},
            { title: 'Pay Method', dataIndex: 'PayMethod', key: 'PayMethod' , ...this.getColumnSearchProps('PayMethod')},
            { title: 'Comment', dataIndex: 'Comment', key: 'Comment' },
            {
                title: 'operation',
                dataIndex: 'operation',fixed: 'right',
                render: (value, record) =>
                    <div>
                       
                       <Link to={`/AdminApp/AdminUserprofiles/${record.user_id}`} target="_blank" style={{ textDecoration: 'none' }}>
                                    <Button  ghost size="small"   type="primary">View</Button></Link>
                      
      
      

                    

                    </div>

            },
        ];
        return (

            <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
               {this.state.cno.map((item) =>  <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>Payments -  Total  {item.PaymentCount}</p>  )}


  
               
                <Card style={{ borderRadius: 10, marginBottom: 10 }}>
                    <CardBody>
                        <Table columns={columns} dataSource={this.state.data} onChange={onChange} scroll={{ x: 1300 }} pagination={{ pageSize: 25 }} />

                    </CardBody>
                </Card>
                <Modal
                    centered
                    visible={this.state.loading}
                    footer={false}
                    closable={false}
                    width={300}
                >
                    <Form layout="inline">
                        <Form.Item><Icon type="loading" style={{ fontSize: 50, color: '#0070ba' }} spin /></Form.Item>
                        <Form.Item><h6>Loading</h6><p>please wait</p></Form.Item>
                    </Form>
                </Modal>
           

       
            </Container>
        )
    }
}
export default AdminPayment;