/* App config for apis*/
const API = {
    BASE_URL: 'https://prolifemarry.com/API/',//change to 'http://localhost/mmonialAPI/ https://prolifemarry.com/API/' 
    LOGIN: 'Login.php',
    DROPDOWNS: 'Dropdowns.php',
    REGISTER: 'Registration.php',
    SEARCH_ID: 'SearchById.php',
    ProfileSearch:'ProfileSearch.php',
    ProfileSearchWithoutlogin:'ProfileSearchWithoutlogin.php',
    ALL_IMAGES: 'get_photos.php',
    UPLOAD_IMAGE: 'imageupload.php',
    DELETE_IMAGE: 'delete_photos.php',
    SEND_CONTACT: 'contact.php',
    DETAILS_PAGE: 'get_details.php',
    USER_DATA: 'get_user_data.php',
    FORGOT_PASSWORD: 'forgotpassword.php',
    REFRESH: 'refresh.php',
    UserThanks:'UserThanks.php',
    Msg:'Msg.php',
    //END user API

    //admin API
    InserProPer:'Admin/etc/InserProPer.php',
    InserLastSeen:'Admin/etc/InsertLastSeen.php',
    InsertSMS:'Admin/etc/InsertSMS.php',
    //ADMIN USERS
    GET_USERS_NewReg: 'Admin/users/GetUser_NewReg.php',
    GET_USERS_All: 'Admin/users/GetUser_All.php',
    GET_USERS_Expiry: 'Admin/users/GetUser_Expiry.php',
    GET_USERS_Deactive: 'Admin/users/GetUser_Deactive.php',
    GET_USERS_Delete: 'Admin/users/GetUser_Delete.php',
    GET_USERS_Active: 'Admin/users/GetUser_Active.php',
    ADD_USERS: 'Admin/users/add_users.php',
    UPDATE_USERS: 'Admin/users/update_users.php',
    DELETE_USERS: 'Admin/users/delete_users.php',
    DELETE_CHIP: 'Admin/users/delete_chip.php',
    SEND_SMS: 'Admin/users/send_sms.php',
    SEND_SMS_CUSTOM:'Admin/users/send_sms_Custom.php',
    ACTIVATION: 'Admin/users/Activation.php',
    OnOff: 'Admin/users/OnOff.php',
    GET_USERS_All_Photo: 'Admin/users/GetUser_All_Photo.php',
    GET_USERS_All_Photo_Male: 'Admin/users/GetUser_All_Photo_Male.php',
    GET_USERS_Search:'Admin/users/GetUser_Search.php',
    Backup:'Admin/users/Backup.php',
    GET_USERS_Backup:'Admin/users/GetUser_Backup.php',
    GET_USERS_LastSeen:'Admin/users/GetUser_LastSeen.php',
    //admin end USERS

    //admin Update USERS
    GET_UPDATE_USER: 'Admin/users/get_update_user.php',
    ADMIN_UPDATE_PROFILE: 'Admin/users/update/AdminUpdate_Profile.php',
    ADMIN_UPDATE_CONTACT: 'Admin/users/update/AdminUpdate_contact.php',
    ADMIN_UPDATE_EDUCATIONWORK: 'Admin/users/update/AdminUpdate_eduactiotionwork.php',
    ADMIN_UPDATE_FAMILY: 'Admin/users/update/AdminUpdate_family.php',
    ADMIN_UPDATE_PARTNER: 'Admin/users/update/AdminUpdate_partner.php',
    ADMIN_UPDATE_CONTACTVIEW:'Admin/users/update/AdminUpdate_viewcontact.php',
    ADMIN_UPDATE_SMS:'Admin/users/update/AdminUpdate_SMS.php',
    //end Update USERS

    //admin payments
    GET_PAYMENT: 'Admin/payments/get_payment.php',
    DELETE_PAYMENT: 'Admin/payments/delete_payment.php',
    ADD_PAYMENT: 'Admin/payments/add_payment.php',
    UPDATE_PAYMENT: 'Admin/payments/update_payment.php',
    GET_All_Payment: 'Admin/payments/GET_All_Payment.php',
    GET_Payment_Count: 'Admin/payments/get_Payment_count.php',
    FullActivationLadies: 'Admin/payments/FullActivationLadies.php',
    //end payemts

       //Lock
       AdminUpdate_ContactLock: 'Admin/users/update/AdminUpdate_ContactLock.php',
       AdminUpdate_PicLock: 'Admin/users/update/AdminUpdate_PicLock.php',
       //end Lock


    // ADMIN DROPDOWNS
    GET_DROPDOWNS: 'Admin/dropdowns/get_dropdowns.php',
    ADD_DROPDOWNS: 'Admin/dropdowns/add_dropdown.php',
    DELETE_DROPDOWNS: 'Admin/dropdowns/delete_dropdowns.php',
    UPDATE_DROPDOWNS: 'Admin/dropdowns/update_dropdown.php',
    //end ADMIN DROPDOWNS
    get_EditRequest: 'Admin/EditRequest/get_EditRequest.php',
    set_Accept:'Admin/EditRequest/set_Accept.php',
    set_Reject:'Admin/EditRequest/set_Reject.php',
    delete_Request:'Admin/EditRequest/delete_Request.php',
    get_EditContactRequest: 'Admin/EditRequest/get_EditContactRequest.php',
    set_AcceptContact:'Admin/EditRequest/set_AcceptContact.php',
    set_RejectContact:'Admin/EditRequest/set_RejectContact.php',
    delete_RequestContact:'Admin/EditRequest/delete_RequestContact.php',

    Information: 'Admin/Information/GetInformation.php',
    SaveInformation: 'Admin/Information/SaveInformation.php',
    DeleteInformation: 'Admin/Information/DeleteInformation.php',
    // ADMIN report
    GET_REPORT: 'Admin/reports/get_report.php',
    //end ADMIN report

     // ADMIN Log
     GET_LOG_LIST: 'Admin/Log/get_Log_List.php',
     UserLog: 'Admin/Log/UserLog.php',
    GET_User_Log: 'Admin/Log/GetUserLog.php',
     //end ADMIN report

    //ADMIN Home
    GET_COUNTS: 'Admin/Home/get_count.php',
    //end ADMIN Home
    //Contact 
    GET_CONTACTS: 'Admin/contact/get_contact.php',
    Contact_Status_Change: 'Admin/contact/Contact_Status.php',
    //end contact
    //accepted users
    GET_ACCEPTED_USERS: 'Admin/Accepted/get_accepted_users.php',
    //end accepted users
    //view_contact
    GET_VIEW_CONTACT: 'Admin/ViewContact/get_viewcontact.php',
    UPDATE_VIEW_CONTACT: 'Admin/ViewContact/update_viewcontact.php',
    //end view contact
    //SuccessStory
    GetSuccessStory:'Admin/SuccessStory/GetStory.php',
    SaveStory:'Admin/SuccessStory/SaveStory.php',
    DeleteStory:'Admin/SuccessStory/DeleteStory.php',
    DeletePhotoStory:'Admin/SuccessStory/delete_PhotoStory.php',
    UploadPhotoStory:'imageuploadStory.php',
//Profile delete request
GetProfileDeleteRequest:'Admin/DeleteRequest/GetProfileDeleteRequest.php',
SetProfileDeleteRequest:'Admin/DeleteRequest/SetProfileDeleteRequest.php',
GetPhotoDeleteRequest:'Admin/DeleteRequest/GetPhotoDeleteRequest.php',
SetPhotoDeleteRequest:'Admin/DeleteRequest/SetPhotoDeleteRequest.php',

GetProfileReport:'Admin/ProfileReport/GetProfileReport.php',
SetProfileReport:'Admin/ProfileReport/SetProfileReport.php',

    //end Admin API
    

    //user API
    HOME_DATA: 'Users/get_home_data.php',
    ADVANCE_SEARCH: 'Users/AdvanceSearch.php',
    GET_DETAILS: 'Users/get_details.php',
    INTRESTED: 'Users/intrested.php',
    GET_INTRESTED: 'Users/get_intrested.php',
    INTREST_ACTIONS: 'Users/intrest_actions.php',
    INTREST_ACTIONS_NEW: 'Users/intrested_action_new.php',
    GET_NOTIFICATION: 'Users/get_notification.php',
    GET_PROFILE_VISITOR: 'Users/get_profileVisitors.php',
    PROFILVISITORS_ACTION: 'Users/Visitors_Action.php',
    GET_SHORTLIST: 'Users/get_shortlist.php',
    SHORTLIST_ACTION: 'Users/shortlist_action.php',
    SHORTLIST_ACTION_NEW: 'Users/shortlist_action_new.php',
    VIEW_CONATCT: 'Users/View_Contact_Action.php',
    GET_PAYMENT_DETAILS: 'Users/payment_details.php',
    PAYMENT_ACTION:'Users/payment_action.php',
    CONTACTVIEWCOUNT:'Users/ContactViewCount.php',
    REFRESH_SHORTLIST:'Users/refresh_shortlist.php',
    REFRESH_INTRESTED:'Users/refresh_intrested.php',
    GET_UserBasicDetail:'Users/GET_UserBasicDetail.php',
    SMSCounter:'Users/SMSCounter.php',
    VIEW_SMS:'Users/View_SMS.php',
    WhatsappSend:'Users/WhatsappSend.php',
    WhatsappSendWithout:'Users/WhatsappSendWithout.php',
    SendMsg:'Users/SendMsg.php',
    ReadMsg:'Users/ReadMsg.php',
    SendMsgRply:'Users/SendMsgRply.php',
    DeleteMsg:'Users/DeleteMsg.php',
    DeleteMsgMain:'Users/DeleteMsgMain.php',
    GetReadMessage:'Users/GetReadMessage.php',
    GetUserForPayment:'Users/GetUserForPayment.php',
    GetPlace:'Users/GetPlace.php',
    MyMatches:'Users/MyMatches.php',
    GetPlan:'Users/GetPlan.php',
    ReadMsgTemp:'Users/ReadMsgTemp.php',
    ProfilePercentage:'Users/ProfilePercentage.php',
    ReadPhotoDeleteReqReason:'Users/ReadPhotoDeleteReqReason.php',
    PhotoDeleteRequest:'Users/PhotoDeleteRequest.php',
    EDIT_ContactLock:'Users/updateContactLock.php',
    DeleteProfile:'Users/DeleteProfile.php',
    ProfileReportSend:'Users/ProfileReportSend.php',
    TopupRecharge:'Users/TopupRecharge.php',
    ProfilePercentage:'Users/ProfilePercentage.php',
    //edit account 
    GET_EDIT_USER: 'Users/get_edit_user.php',
    EDIT_MYPROFILE: 'Users/Edit_MyprofileCard.php',
    EDIT_EDUCATIONWORK: 'Users/Edit_EducationWorkCard.php',
    EDIT_CONTACT: 'Users/Edit_Contact.php',
    EDIT_FAMILY: 'Users/Edit_family.php',
    EDIT_PARTNER_FILTER: 'Users/Edit_partner_fliter.php',
    EDIT_PASSWORD: 'Users/Edit_password.php',
    DropDownItem:'DropDownItem.php',
    Newest:'Users/Newest.php',
    SuperMatches:'Users/SuperMatches.php',
    AdminSugg:'Users/AdminSugg.php',
    WhoVisitMyPro:'Users/WhoVisitMyPro.php',
    WhoVisitContact:'Users/WhoVisitContact.php',
    MyVisitContact:'Users/MyVisitContact.php',
    MyVisitProfile:'Users/MyVisitProfile.php',
    WhoSendSms:'Users/WhoSendSms.php',
    MySentSMS:'Users/MySentSMS.php',
    HomeProfileSlide:'HomeProfileSlide.php',
    SuccessStory:'SuccessStory.php',
    BadgeNotification:'Users/BadgeNotification.php'
    //end edit account
    //end user API

};
export default API;