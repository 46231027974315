

import React, { Component } from 'react';
import { Card, CardBody, ButtonGroup } from 'reactstrap';
import { Form, Input, Icon, Radio, message, Button, Select, Tag } from 'antd';
import { connect } from 'react-redux';
import API from '../../API';
const { TextArea } = Input;
const { Option } = Select;
class EditAdmin_UserType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //step3
            UserType: this.props.data.UserType,
            SearchType: this.props.data.SearchType
           

            //end step3
        };
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.props.reload();
        this.setState({
            UserType: this.props.data.UserType,
            SearchType: this.props.data.SearchType
           
        })
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        console.log(event.target.name);
    }
    update = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let obj = {
                    user_id: this.props.data.user_id,
                    UserType: this.state.UserType,
            SearchType: this.state.SearchType
           
                
                }
                fetch(API.BASE_URL + API.ADMIN_UPDATE_USERTYPE, {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: JSON.stringify(obj)
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.status === 'success') {
                            this.props.reload();
                            this.setState({
                                loading: false,
                              
                            });
                            message.success('Updated Successfully');
                        }
                        else {
                            this.setState({ loading: false, status: 'error', });
                            message.error('Error. Try again 1!');
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        message.error('Error. Try again 2!');
                        console.error(error);
                    });
            }
        });
    }
  
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 6,
                },
            },
        };
        return (
            <Card>
                <CardBody>
                    <Form {...formItemLayout}>
                    <Form.Item label="User Type">
                            {getFieldDecorator('UserType', {
                                initialValue: this.state.UserType,
                                setFieldsValue: this.state.UserType,
                                rules: [{ required: false, message: 'Please select User Type!' }],
                            })(
                                <Select placeholder="User Type" name="UserType"
                                    onChange={(val) => { this.handleChange({ target: { name: 'UserType', value: val } }) }}>
                                    <Option value="1">Type 1</Option>
                                    <Option value="2">Type 2</Option>
                                  
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Search Type">
                            {getFieldDecorator('SearchType', {
                                initialValue: this.state.SearchType,
                                setFieldsValue: this.state.SearchType,
                                rules: [{ required: false, message: 'Please select Search Type!' }],
                            })(
                                <Select placeholder="Search Type" name="SearchType"
                                    onChange={(val) => { this.handleChange({ target: { name: 'SearchType', value: val } }) }}>
                                    <Option value="1">Type 1</Option>
                                    <Option value="2">Type 2</Option>
                                  
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} align="right" style={{ textAlign: "right" }}>
                            <ButtonGroup>
                                <Button type="primary" onClick={() => this.update()}>
                                    Update
                                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}
function mapStateToProps(state) {
    return {
        dropdown: state.dropdown
    }
}
export default connect(mapStateToProps)(Form.create()(EditAdmin_UserType));