import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { Tab, Nav } from 'react-bootstrap';
import { Form, Select, Button, Card,Layout,Input ,Tabs,PageHeader,message } from 'antd';
import './styles.css';
import API from '../../API';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Deactivated_card from '../../components/Deactivated_card';
import Not_aprooved_card from '../../components/not_aprooved_card';
import Payement_due_card from '../../components/payement_due_card';



const {Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;
class UserSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Activated: false,
            deactivated: false,
            payment_due: false,
            not_aprooved: false,
            loading: false,

            issuccess: false,
            Age_from: null,
            Age_to: null,
            Diocese: null,
            H_from: null,
            H_to: null,
            MaritialS: null,
            Wplace: null,
            PhysicalS: null,
            ECategory: null,
            WCategory: null,
            Search_ID: null,
Place:null,
FStatus:null,
Religion:null,
            items: []
        };
       
        this.arrayholder = [];
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = () => {
        message.config({ top: 100 });
        
        this.setState({ loading: true });
        let obj = { user_id: this.props.User.user_id, Gender: this.props.User.gender }
        fetch(API.BASE_URL + API.GetPlace, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    loading: false,
                    items: res.Place
                })
            })
            .catch((error) => {
                this.setState({ loading: false });
                message.error('Error. Try again !');
            });
    }

    handleChange = (event) => {
        
        if (event.target.value == ''){
                
        this.setState({
            [event.target.name]: null
        });
     }
     else
     { 
        this.setState({
            [event.target.name]: event.target.value
        });
     }
     
        console.log(event.target.name);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {  mode } = this.state;
        const height_Array = [];
        for (let i = 100; i < 350; i++) {
            height_Array.push({
                value: i,
            });
        }
        const Age_Array = [];
        for (let i = 18; i < 70; i++) {
            Age_Array.push({
                value: i,
            });
        }
        return (
         
                        <Content className="scrollBox1" style={{ background: '#f2f3f5' }}>
        <PageHeader  title={this.props.lang.drawerAdvanceSearch} lang={this.props.lang.lang} className="HeaderTitile" style={{ marginTop:10}}/>
        <Container>
           
        <div >
                        <Card style={{marginLeft:10,marginRight:10}}>
                <Tab.Container defaultActiveKey="link-1" >
                   
                          
                          
                            { this.props.User.Status === 'D'&&
                                <Deactivated_card />
                            }
                            {this.props.User.Status === 'N' &&
                                <Not_aprooved_card />
                            }
                            {this.props.User.Status === 'P' &&
                                <Payement_due_card />
                            }  
                         
                            {this.props.User.Status === 'A' &&

<Tabs defaultActiveKey="1" tabPosition={mode} tabBarStyle={{ marginTop: -15, fontSize: 10 }} size={'large'} tabBarGutter={0}>
<TabPane tab={this.props.lang.drawerAdvanceSearch} key={1} style={{ fontSize: 22 }}>
<Form layout="inline" > 
                                            <Row>
                                                {/*First Column*/}
                                                <Col sm="4" xs="12">
                                                <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchWorkCategory}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name="Work_Category"
                                                                placeholder="Work Category"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'WCategory', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {this.props.dropdown.Work_array.map((item) => (
                                                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchWorkingPlace}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name={"Working_place"}
                                                                placeholder="Working place"
                                                                optionFilterProp="children"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'Wplace', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {this.props.dropdown.WorkingPlace_array.map((item) => (
                                                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                
                                                
                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchPhysicalStatus}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                              mode="multiple"
                                                                showSearch
                                                                name={"PhysicalS"}
                                                                placeholder="Physical Status"
                                                                optionFilterProp="children"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'PhysicalS', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                <Option value="Normal" key="Normal">{this.props.lang.UserSearchNormal}</Option>
                                                                <Option value="With Disability" key="With Disability">{this.props.lang.UserSearchDisable}</Option>
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.Cast}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name="Religion"
                                                                placeholder="Religion/Caste"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'Religion', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {this.props.dropdown.Religion_array.map((item) => (
                                                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                               

                                                </Col>
                                                {/*end First Column*/}







                                                {/*second column*/}
                                                <Col sm="4" xs="12">
                                                    
                                                <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchEducationCategory}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name="Education_Category"
                                                                placeholder="Education Category"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'ECategory', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {this.props.dropdown.Education_array.map((item) => (
                                                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>Place</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name="Place"
                                                                placeholder="Place"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'Place', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {this.state.items.map((item) => (
                                                                    <Option value={item.Place} key={item.Place}>{item.Place}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>

                                                    </Row>
                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchMaritalStatus}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name={"MaritialS"}
                                                                placeholder="Maritial Status"
                                                                optionFilterProp="children"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'MaritialS', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                <Option value="U" key="U" >{this.props.lang.UserSearchUnmarried}</Option>
                                                                <Option value="w" key="w">{this.props.lang.UserSearchWidow}</Option>
                                                                <Option value="D" key="D">{this.props.lang.UserSearchDivorcee}</Option>
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                               
                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchDiocese}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name={"Diocese"}
                                                                placeholder="Diocese"
                                                                optionFilterProp="children"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'Diocese', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                                {this.props.dropdown.Diocese_array.map((item) => (
                                                                    <Option value={item.id} key={item.id}>{item.Name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {/*end second column*/}






                                                {/*thired column*/}
                                                <Col sm="4" xs="12">
                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchAge}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Row>
                                                                <Col sm="5" xs="5" >

                                                                <Select
                                                    showSearch
                                                    placeholder="From"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Age_from', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {Age_Array.map((item) => (
                                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                                    ))}
                                                </Select>
                                                                  

                                                                </Col>
                                                                <Col sm="1" xs="1" > <span className="SearchItems" style={{ fontSize: 10 }} lang={this.props.lang.lang}>To</span></Col>
                                                                <Col sm="5" xs="5" >
                                                                <Select
                                                    showSearch
                                                    placeholder="To"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'Age_to', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {Age_Array.map((item) => (
                                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                                    ))}
                                                </Select>
                                                                  
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchHeight}</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Row>
                                                                <Col sm="5" xs="5" >

                                                                <Select
                                                    showSearch
                                                    placeholder="From"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'H_from', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {height_Array.map((item) => (
                                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                                    ))}
                                                </Select>
                                                                  

                                                                </Col>
                                                                <Col sm="1" xs="1" > <span style={{ fontWeight: 'bolder', fontSize: 10 }} lang={this.props.lang.lang}>To</span></Col>
                                                                <Col sm="5" xs="5" >
                                                                <Select
                                                    showSearch
                                                    placeholder="To"
                                                    optionFilterProp="children"
                                                    onChange={(val) => { this.handleChange({ target: { name: 'H_to', value: val } }) }}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {height_Array.map((item) => (
                                                        <Option value={item.value} key={item.value}>{item.value}</Option>
                                                    ))}
                                                </Select>
                                                                  
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>Financial Status</span></Col>
                                                        <Col sm="12" xs="12">
                                                            <Select
                                                             mode="multiple"
                                                                showSearch
                                                                name="FStatus"
                                                                placeholder="Financial Status"
                                                                onChange={(val) => { this.handleChange({ target: { name: 'FStatus', value: val } }) }}
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }>
                                                           
                                                    <Option value="Below Moderate">Below Moderate</Option>
                                                    <Option value="Moderate">Moderate</Option>
                                                    <Option value="Above Moderate">Above Moderate</Option>
                                                    <Option value="Rich">Rich</Option>
                                                            </Select>
                                                        </Col>

                                                    </Row>
                                                 
                                                    <Row className="mobpad">
                                                        <Col sm="12" xs="12" style={{ marginTop: 25 }}>
                                                            <Link
                                                                to={`/UserApp/UserSearchResult/${this.state.Diocese}/${this.state.Age_from}/${this.state.Age_to}/${this.state.H_from}/${this.state.H_to}/${this.state.MaritialS}/${this.state.Wplace}/${this.state.PhysicalS}/${this.state.ECategory}/${this.state.WCategory}/${this.state.Religion}/${this.state.Place}/${this.state.FStatus}`}
                                                                target="_blank" style={{ textDecoration: 'none' }}>
                                                                <Button block   className="SearchButton" lang={this.props.lang.lang}>
                                                                {this.props.lang.UserSearchSearch}
                </Button>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {/*end thired column*/}
                                            </Row>
                                        </Form>
                                        </TabPane>
                    <TabPane tab={this.props.lang.Homesearchinput} key={2}>
                                        <Container>
                                        <Row>
                                                <Col sm="12" md={{ size: 6, offset: 3 }}>
                                                    <Row className="mobpad" style={{ marginTop: 25 }}>
                                                        <Col sm="12" xs="12" style={{ marginBottom: 6 }}><span className="SearchItems" lang={this.props.lang.lang}>{this.props.lang.UserSearchSearchID}</span></Col>
                                                        <Col sm="9" xs="8">
                                                            <Input
                                                                placeholder="Search Profile ID/Name"
                                                                name="Search_ID"
                                                                onChange={this.handleChange}
                                                            />

                                                        </Col>
                                                        <Col sm="3" xs="4" >
                                                            <Link
                                                              target="_blank"  to={`/UserApp/UserSearchID/${this.state.Search_ID}`}
                                                                style={{ textDecoration: 'none' }}>
                                                                <Button block type="primary" className="SearchButton" lang={this.props.lang.lang}>
                                                                {this.props.lang.UserSearchSearch}
                                                                </Button>
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                        </TabPane>
                                        
                                     
                   
                   </Tabs>
                            }
                      
                </Tab.Container>
                </Card>
                    
            </div>
              </Container>
              </Content>
        )
    }
}
function mapStateToProps(state) {
    return {
        User: state.User,
        dropdown: state.dropdown,
        lang:state.lang
    }
}
export default connect(mapStateToProps)(Form.create()(UserSearch));