import React, { Component } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { Form, Input, Icon, Radio, Select, Button, Card, Steps, Modal, message, Tag, Skeleton } from 'antd';
import API from '../API';
import EditAdmin_MyprofileCard from './updatePages/EditAdmin_MyprofileCard';
import EditAdmin_contactCard from './updatePages/EditAdmin_contactCard';
import EditAdmin_EducationWorkCard from './updatePages/EditAdmin_EducationWorkCard';
import EditAdmin_FamilyCard from './updatePages/EditAdmin_FamilyCard';
import EditAdmin_PartnerCard from './updatePages/EditAdmin_PartnerCard';
import EditAdmin_MyphotosCard from './updatePages/EditAdmin_MyphotosCard';
import EditAdmin_Payment from './updatePages/EditAdmin_Payment';
import EditAdmin_contactview from './updatePages/EditAdmin_contactview';
import EditAdmin_AccountDetails from './updatePages/EditAdmin_AccountDetails';
import EditAdmin_IdProof from './updatePages/EditAdmin_IdProof';
import EditAdmin_UserType from './updatePages/EditAdmin_UserType';
import EditAdmin_Lock from './updatePages/EditAdmin_Lock';
class AdminUserprofiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            items: [],
            isLoaded: true
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    componentDidMount() {
        message.config({ top: 100 });
        let obj = { user_id: this.props.match.params.id };
        fetch(API.BASE_URL + API.GET_UPDATE_USER, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify(obj)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    message.success('success');
                    this.setState({
                        isLoaded: false,
                        items: result
                    });
                },
                (error) => {
                    message.error('Loading faild');
                    alert(error);
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }
    render() {
        const { isLoaded, items } = this.state;
        if (isLoaded) {
            return <Container className="scrollBox1" fluid={true} style={{ backgroundColor: '#fff', marginTop: 10 }}><Skeleton avatar paragraph={{ rows: 20 }} /></Container>;
        } else {
            return (
                <Container fluid={true} style={{ borderRadius: 10 }} className="fixedbox2">
                    <p style={{ fontSize: 17, fontWeight: 'bolder', margin: 20 }}>User Details</p>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Profile</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Contact and Address</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Education and Work</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '4' })}
                                onClick={() => { this.toggle('4'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Family Details</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '5' })}
                                onClick={() => { this.toggle('5'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Partner Filter</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '6' })}
                                onClick={() => { this.toggle('6'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Photos</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '7' })}
                                onClick={() => { this.toggle('7'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Payments</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '8' })}
                                onClick={() => { this.toggle('8'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Contact View</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '9' })}
                                onClick={() => { this.toggle('9'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>ID Proof</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '10' })}
                                onClick={() => { this.toggle('10'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Account Details</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '11' })}
                                onClick={() => { this.toggle('11'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>User Type</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '12' })}
                                onClick={() => { this.toggle('12'); }}
                            >
                                <span style={{ fontWeight: 'bold' }}>Lock</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <EditAdmin_MyprofileCard
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="2">
                            <EditAdmin_contactCard
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="3">
                            <EditAdmin_EducationWorkCard
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>

                        <TabPane tabId="4">
                            <EditAdmin_FamilyCard
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="5">
                            <EditAdmin_PartnerCard
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="6">
                            <EditAdmin_MyphotosCard
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="7">
                            <EditAdmin_Payment
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="8">
                            <EditAdmin_contactview
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="9">
                            <EditAdmin_IdProof
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="10">
                            <EditAdmin_AccountDetails
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="11">
                            <EditAdmin_UserType
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                        <TabPane tabId="12">
                            <EditAdmin_Lock
                                user_id={this.props.match.params.id}
                                data={items}
                                reload={() => this.componentDidMount()} />
                        </TabPane>
                    </TabContent>
                </Container>
            )

        }
    }
}
export default AdminUserprofiles;