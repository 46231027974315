import React, { Component } from 'react';
import {PageHeader} from 'antd';
import SearchID from './searchID';
class searchIDwith_user extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProfileId: this.props.match.params.id
        };
    }
    render() {
        return (
            <div fluid={true} className="fixedbox">
                    <PageHeader onBack={() =>window.history.back()} title="Search Results"/>
                <SearchID user_id={this.props.match.params.id}/>
            </div>
        )
    }
}
export default searchIDwith_user;