import React, { Component } from 'react';
import './App.css';
import { Layout, Icon, BackTop,message,Modal} from 'antd';
import Header from './components/Header/Header';
import styles from './styles';
import Router from './Router';
import { connect } from 'react-redux';
import { loaddropdowns } from './redux/Services/DropDownServices';
import CacheBuster from './CacheBuster';
import { setenglish, setmalayalam } from './redux/Services/LanguageServices';
import { loadNotification } from './redux/Services/NotificationServices';

class App extends Component {
  constructor(props) {
  
    super(props);
    this.state = {
      isOpen: false,ModalUpdate:false
    };
  }
  
  componentDidMount(){
   
    this.props.loaddropdowns();
    this.props.loadNotification(this.props.User.user_id);
    message.config({
      top: 100,
      duration:20,
    });
  }
  render() {
   
    return (
      
      <Layout >
        <BackTop>
          <Icon type="up-circle" theme="filled" style={styles.upbutton} />
        </BackTop>
        <Header/>
        <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
            this.props.setenglish();
this.setState({ModalUpdate:true});

          }

          return (
            <div >
             
            </div>
          );
        }}
      </CacheBuster>
        <div style={{background: '#fff'}}>
          <div style={{height:55}}>
            
          </div>
          <Router />
        </div>
        <Modal  title="Website Updated"
                           onOk={() => this.setState({ ModalUpdate: false })}
                          onCancel={() => this.setState({ ModalUpdate: false })}
                          width={400}
                          visible={this.state.ModalUpdate}
                      >
            <h6 style={{textAlign:'justify'}}>വെബ്സൈറ്റ് അപ്ഡേറ്റ് ചെയ്തിരിക്കുന്നു. ദയവായി വെബ്സൈറ്റ് ഹാർഡ് റിഫ്രഷ് ചെയ്തു ഉപയോഗിക്കുക. പേർസണൽ കമ്പ്യൂട്ടറിൽ ആണ് ഉപയോഗിക്കുന്നത് എങ്കിൽ Ctrl+F5  പ്രസ്സ്  ചെയ്യുക. മൊബൈലിൽ ആണെങ്കിൽ ബ്രൗസർ ക്ലോസ് ചെയ്തു ഓൺ ചെയ്യുക.</h6>
 
            
             
              </Modal>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    User: state.User,
    lang:state.lang
  }
}
function mapDispatchToProps(dispatch) {
  return {
    loaddropdowns: () => {
      dispatch(loaddropdowns());
    },
    loadNotification: (user_id) => {
      dispatch(loadNotification(user_id));
    },
    setenglish: () => {
      dispatch(setenglish());
  }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
