import * as types from '../Actions/Types';
const response ={
            Religion_array: [{ id:0, name: "NULL" }],
            Maritial_array: [{ id: 0, name: "NULL" }],
            Creator_array: [{ id: 0, name: "NULL" }],
            Education_array: [{ id: 0, name: "NULL" }],
            Work_array: [{ id: 0, name: "NULL" }],
            Financial_array: [{ id: 0, name: "NULL" }],
            WorkingPlace_array:[{ id: 0, name: "NULL" }]
};
const Save = (data,parse) =>{
return parse;
}
const dropdownReducer = (state = response, action) =>{
    switch (action.type) {
        case types.SAVE_DROPDOWNS:{
            return Save(state,action.payload);
        }
        default:{
            return state;
        }       
    }
    }
    export default dropdownReducer ;